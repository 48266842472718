<section class="mt-lg-5">

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-xl-3 col-md-6 mb-r">

            <!--Card-->
            <mdb-card cascade="true" class="cascading-admin-card">

                <!--Card Data-->
                <div class="admin-up">
                    <mdb-icon far icon="money-bill-alt" class="primary-color"></mdb-icon>
                    <div class="data">
                        <p>SALES</p>
                        <h4><strong>$2000</strong></h4>
                    </div>
                </div>
                <!--/.Card Data-->

                <!--Card content-->
                <mdb-card-body>
                    <div class="progress">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <!--Text-->
                    <p class="card-text">Better than last week (25%)</p>
                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-3 col-md-6 mb-r">

            <!--Card-->
            <mdb-card cascade="true" class="cascading-admin-card">

                <!--Card Data-->
                <div class="admin-up">
                    <mdb-icon fas icon="chart-line" class="warning-color"></mdb-icon>
                    <div class="data">
                        <p>SUBSCRIPTIONS</p>
                        <h4><strong>200</strong></h4>
                    </div>
                </div>
                <!--/.Card Data-->

                <!--Card content-->
                <mdb-card-body>
                    <div class="progress">
                        <div class="progress-bar bg grey darken-2" role="progressbar" style="width: 25%"
                             aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <!--Text-->
                    <p class="card-text">Worse than last week (25%)</p>
                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-3 col-md-6 mb-r">

            <!--Card-->
            <mdb-card cascade="true" class="cascading-admin-card">

                <!--Card Data-->
                <div class="admin-up">
                    <mdb-icon fas icon="chart-pie" class="light-blue lighten-1"></mdb-icon>
                    <div class="data">
                        <p>TRAFFIC</p>
                        <h4><strong>20000</strong></h4>
                    </div>
                </div>
                <!--/.Card Data-->

                <!--Card content-->
                <mdb-card-body>
                    <div class="progress">
                        <div class="progress-bar grey darken-2" role="progressbar" style="width: 75%" aria-valuenow="75"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <!--Text-->
                    <p class="card-text">Worse than last week (75%)</p>
                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-3 col-md-6 mb-r">

            <!--Card-->
            <mdb-card cascade="true" class="cascading-admin-card">

                <!--Card Data-->
                <div class="admin-up">
                    <mdb-icon fas icon="chart-bar" class="red accent-2"></mdb-icon>
                    <div class="data">
                        <p>ORGANIC TRAFFIC</p>
                        <h4><strong>2000</strong></h4>
                    </div>
                </div>
                <!--/.Card Data-->

                <!--Card content-->
                <mdb-card-body>
                    <div class="progress">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <!--Text-->
                    <p class="card-text">Better than last week (25%)</p>
                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
