import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { CaseService } from 'src/app/service/case.service';
import { EmailService } from 'src/app/service/email.service';
import { DocumentService } from 'src/app/service/document.service';
import { NotesService } from 'src/app/service/notes.service';
import { CaseOutcomeService } from 'src/app/service/caseOutcome.service';
import { LoginDataService } from 'src/app/service/loginData.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';
import { ModalContainerComponent } from 'angular-bootstrap-md';


@Component({
  selector: 'app-caseOutcomeApprover',
  templateUrl: './caseOutcomeApprover.component.html',
  styleUrls: ['./caseOutcomeApprover.component.scss']

})
export class CaseOutcomeApproverComponent implements OnInit {
  @ViewChild('notesModal', { static: true }) notesModal: ModalContainerComponent;
  ​tempEmailTemplate="";
  //tempEmailTemplate="Dear "+this.dataservice.getOption().PrimaryContact+",<br/><br/>The approved provider has now submitted their Service Context Details and Self Assessment form for <span style='font-weight: bold;'>"+this.dataservice.getOption().ServiceName+" :# "+this.dataservice.getOption().SENumber+"</span> <br><br>Please login to abd site provide explanation <span style='font-weight: bold;'>$ASR_NO.</span><br><br>Yours sincerely, <br><br><br>Network Coordination Unit<br>Statewide Operations<br>Early Childhood Education Directorate<br>Department of Education<br>T: 1800 619 113<br>E: ECnetworkcoordination@det.nsw.edu.au<br>";
  headElementsNotesSort = ['notes', 'type', 'createdDate', 'user'];
  headElementsNotes = ['notes', 'type', 'created Date', 'created By'];
  passeddata:any;
  outcomeStatus = ["Choose Final Outcome","Admin Letter", "Compliance Notice", "Compliance Direction", "Suspension of Provider Approval","Suspension of Service Approval","Show Cause Notice: Cancellation of Provider Approval","Notice of Decision to Cancel Provider Approval"];
  noteType =["choose Note Type","Note","Approval","status Change","Feedback","other"];
  notesList:any[]=[];
  @Input()
  summaryreport:any;
  @Input()
  recordNumber:string;
  historyList:any[]=[];
  @Output() 
  summaryReport = new EventEmitter<boolean>();
  @Output() 
  message = new EventEmitter<any>();
  @Input()
  accessList: string[] = [];
  @Input()
  lawRegList: any[] = [];
  
  @Input()
  visitList: any[] = [];
  refreshOutcomeTable=false;

  summaryText:string;
  selectedFile:any;
  filename="choose one";
  editablePdf:any;
  usersList:any[]=[];
  user:string='';
    ngOnInit() {
    //this.user = this.loginDataService.getData().firstName+" "+this.loginDataService.getData().surname;
    //this.passeddata = this.dataservice.getOption();
    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId')
      
       //roleId: localStorage.getItem('roleId')
 
     });
 
    
    }

    this.user = (this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname:'';
    
    
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }

    this.passeddata = this.dataservice.getOption();
    this.tempEmailTemplate="Dear "+this.passeddata.PrimaryContact+",<br/><br/>The approved provider has now submitted their Service Context Details and Self Assessment form for <span style='font-weight: bold;'>"+this.dataservice.getOption().ServiceName+" :# "+this.dataservice.getOption().SENumber+"</span> <br><br>Please login to abd site provide explanation <span style='font-weight: bold;'>$ASR_NO.</span><br><br>Yours sincerely, <br><br><br>Network Coordination Unit<br>Statewide Operations<br>Early Childhood Education Directorate<br>Department of Education<br>T: 1800 619 113<br>E: ECnetworkcoordination@det.nsw.edu.au<br>";
 
    this.getNotesfromDB();
    this.getHistoryDocs();
    this.getAllpendingDocs();
    this.getAllUsers();
    //this.getAllTemplates();
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.notesList.length
    };
  }
  outcomeTemplate:any;
  constructor(private userDetailsService:UserDetailsService,private loginDataService: LoginDataService,private caseOutcomeService:CaseOutcomeService,private documentService :DocumentService,private documentServce:DocumentService,private notesService :NotesService,private dataservice: DataService, private caseService: CaseService,private emailService:EmailService) {
   
  }
  //outcomeTemplateList : any[]=[];
  /*getAllTemplates()
  {
    this.emailTemplateService.getAllEmailTemplate().subscribe(data => {
      this.outcomeTemplateList.push({'templateName':data.templateName,'templateDoc':data.templateDoc});
    
      console.log(this.outcomeTemplateList);
          
    });
  }

  getTemplate(outcomeType:string)
  {
   
    this.outcomeTemplateList.find(el=>{
      if(el.templateName===outcomeType)
      {
     
       return el.templateDoc;
         
      }
    });
    
  }*/
  getAllUsers()
  {
    this.userDetailsService.getAllUsers().subscribe(data => {
      for (const d of (data as any)) {


        this.usersList.push({
          userId: d.intUserId,
          email: d.email
        }
        );

      }
      console.log(this.pendingOutcomesList);
          
    });
  }

  emailtoaddress:string='serviceemail@detnsw.edu.au';
  updateManagerReviewer()
  {
  //alert(this.passeddata.ManagerReviewer);
   this.usersList.find(el=>{
     if(el.userId===this.passeddata.ManagerReviewer)
     {
      this.emailtoaddress = el.email;
        
     }
   });
   
   
  }
  

  editPdf(outcometype : string)
  {
    this.documentServce.editPdf(outcometype,this.passeddata.CaseId).subscribe(data => {
      console.log("data"+data)
    });
    
  }


  resetRefreshTable(refreshtable: boolean)
  {
    if(!refreshtable)
    {
      this.refreshOutcomeTable=false;
    }
  }
  saveText()
  {
    //alert(this.editablePdf);
  }
  pendingOutcomesList:any[]=[];
  getAllpendingDocs()
  {
    this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'Pending Approval').subscribe(data => {
      for (const d of (data as any)) {


        this.pendingOutcomesList.push({
          outcomeType: d.outcomeType,
               
        }
        );


      }
      console.log(this.pendingOutcomesList);
    });
  }

  
  getHistoryDocs()
  {
    this.documentServce.getHistoryDocs(this.passeddata.ProviderId,this.passeddata.SENumber,this.passeddata.CaseId)
    .subscribe(data => {
      for (const d of (data as any)) {


        this.historyList.push({
          attachmentName: d.attachmentName,
          docTrimId: d.docTrimId
       
        }
        );


      }
      console.log(this.notesList);
    });

  }

  getNotesfromDB()
  {
    this.notesService.getAllNotes(this.passeddata.CaseId)
    .subscribe(data => {
      for (const d of (data as any)) {


        this.notesList.push({
          notes: d.comments,
          user: d.createdBy,
          createdDate: d.createdDate,
          type: d.action
        }
        );


      }
      this.notesList =  this.notesList.sort((a,b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0)); 

      console.log(this.notesList);
    });
  }

  config: any;
  pageChanged(event : any){
    this.config.currentPage = event;
  }

  showsummary() {
    this.summaryreport=!this.summaryreport;
    this.summaryReport.emit(this.summaryreport);
    
  }
  async saveOutcomes(caseOutcome:string)
  {
    var userId=this.loginDataService.getData().firstName+this.loginDataService.getData().surname;
     await this.caseOutcomeService.saveCaseOutcome(caseOutcome,this.passeddata.CaseId,'',userId)
    .toPromise().then(
    resp => {
             console.log(resp);
             //this.message.emit({'status':'success','messageTxt':'case Outcome updated to '+ this.passeddata.ApprovalStatus});
        },
        err => {
         
          console.log('Error Occured duringng save outcome ' + err);
          this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
        }
        );
  }


  async updateOutcomes(caseOutcome:any)
  {
    //var userId=this.loginDataService.getData().firstName+this.loginDataService.getData().surname;
     await this.caseOutcomeService.updateCaseOutcome(caseOutcome.outcomeId,'Approved',caseOutcome.approvedBy,caseOutcome.reviewedBy,caseOutcome.trimId)
    .toPromise().then(
    resp => {
             console.log(resp);
             this.refreshOutcomeTable=true;
             //this.message.emit({'status':'success','messageTxt':'case Outcome updated to '+ this.passeddata.ApprovalStatus});
        },
        err => {
         
          console.log('Error Occured duringng save outcome ' + err);
          this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
        }
        );
  }
  loadingApprove:boolean=false;
  sendEmail(message:string,to:string)
  {
   
    this.loadingApprove=true;
    this.saveCase();


    this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'sent for Approval').subscribe(data => {
     
      if(data.length>0)
      {
          this.emailService.sendEmail(message,data,to,"Approved",this.passeddata.CaseId,this.passeddata.SENumber,this.passeddata.ProviderId,this.recordNumber,this.user).subscribe((response) => {
    
          console.log(response);   
           
          this.message.emit({'status':'success','messageTxt':'Mail sent to Service'});
          this.refreshOutcomeTable=true;
    

        });
      }
      this.loadingApprove=false;
      });


     
    /*  var tempList = [...this.pendingOutcomesList];
    this.emailService.sendEmail(message,tempList,to,"Approved",this.passeddata.CaseId,this.passeddata.SENumber,this.passeddata.ProviderId,this.recordNumber).subscribe((response) => {
    
      console.log(response);   
       
      this.message.emit({'status':'success','messageTxt':'Mail sent to Service'});


      this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'sent for Approval').subscribe(data => {
        for (const d of (data as any)) {
  
  
          this.pendingOutcomesList.push({
            outcomeType: d.outcomeType
              }
          );
  
        }
        console.log(this.pendingOutcomesList);
        
             
      });

      tempList.forEach(el =>
        {
      
          this.uploadtoTrim(el.outcomeType);
         
          this.updateOutcomes(el);
         
        }
        );
     
      this.pendingOutcomesList = [];
    },
    err => {
     
      console.log('Error Occured duringng send email ' + err);
      this.message.emit({'status':'error','messageTxt':'Email Sent to Service failed'});
    }); */
   // this.showSuccess('Email Sent');

   
  }


  setValues(outcome:any)
  {
    localStorage.setItem("SelectedOutcome",outcome);
   // this.passeddata.SelectedOutcome=outcome;
    //this.passeddata.lawRegList=this.lawRegList;
    localStorage.setItem("lawRegList",JSON.stringify(this.lawRegList));
    this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.passeddata.breachVisitDates= this.passeddata.breachVisitDates+","+el.startDt;
      }
    });
     
  }

  sendEmailtoUser(message:string,to:string)
  {
   
 
    
    this.emailService.sendEmailtoUser(message +" for caseId "+this.passeddata.CaseId,to).subscribe((response) => {
    
      console.log(response);   
       
      this.message.emit({'status':'success','messageTxt':'Mail sent to User'+to});

    },
    err => {
     
      console.log('Error Occured duringng send email ' + err);
      this.message.emit({'status':'error','messageTxt':'Email Sent to User failed'});
    });
   // this.showSuccess('Email Sent');

   
  }
  

  onUpload()
  {
    this.uploadtoTrim(this.selectedFile.name);
  }
  

  public onFileChanged(event: any) {
    console.log(event);
    this.selectedFile = event.target.files[0];
    this.filename=this.selectedFile.name;
  

      }

  saveCase()
  {
   
   
    this.passeddata.ApprovalStatus="Approved";
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
      console.log(resp);   
      this.message.emit({'status':'success','messageTxt':'case Outcome updated to '+ this.passeddata.ApprovalStatus});
        },
        err => {
         
          console.log('Error Occured duringng save case' + err);
          this.message.emit({'status':'error','messageTxt':'error saving case'});
        }
        );
  }

  onCaseStatusChange(newValue:string) {
    
    this.passeddata.CaseStatus = newValue;
   
  }
  helpText(message:any)
  {
      if(message.status==='success')
     this.message.emit({'status':'success','messageTxt':message.messageTxt});
      else if(message.status==='error')
      this.message.emit({'status':'error','messageTxt':message.messageTxt});
     
  }

  file:any;
  generateAttachmentPdf(outcomeType:string)
  {
    
    this.documentServce.getOutcomeStatusPdf(outcomeType,this.passeddata.CaseId,"",this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      this.file = new Blob([res], { type: 'application/pdf' });
        
      var fileURL = URL.createObjectURL(this.file);
   

       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.message.emit({'status':'error','messageTxt':'error generating pdf'});
    }
    );

  }
  sendClicked : boolean = false;

  updateCaseStatus(status:string)
  {
   
    if(status==='Approval')
    {
      this.passeddata.CaseStatus = 'Approval';
      this.sendClicked = true;
    }
    if(status==='Decision Pending')
    {
      this.passeddata.CaseStatus = 'Decision Pending';
    }
    this.dataservice.setOption(this.passeddata);
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
      return resp;
        }
        );

  }

  fileURL: any;
 
 async uploadtoTrim(outcome:any) {

   
     await this.documentService.uploadAttachmenttoTrim(this.passeddata.ProviderId,this.passeddata.SENumber,outcome.outcomeType,this.recordNumber,this.passeddata.CaseId)
      .toPromise().then( async res=>{
      
        outcome.docTrimId= res.trimId;

        await this.caseOutcomeService.updateCaseOutcome(outcome.outcomeId,"Approved",outcome.approvedBy,outcome.reviewedBy, outcome.docTrimId).subscribe(res=>
          {
              console.log("sent draft for Approval"+res);
              this.refreshOutcomeTable=true;
          });

         /* this.historyList.push({
            attachmentName: res.attachmentName,
            docTrimId: res.trimId
         
          });*/

          this.message.emit({'status':'success','messageTxt':'pdf upload to Trim for '+outcome.outcomeType});
         },
      err =>
       {
        console.log(err);
        this.message.emit({'status':'error','messageTxt':'pdf upload to Trim failed for '+outcome.outcomeType});
        } );
     

  }


  loadDoc(docId: any) {


       this.documentService.getTrimDoc(docId).subscribe((response) => {
       var file:any;
 
          file = new Blob([response], { type: 'application/pdf' });
 
        var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
      
  
     });
   }

  notesText:string="";
  notesType:string="";
  resetNotes()
  {
    this.notesText="";
    this.notesType="";
  }
  finalizeCase()
  {
   
    
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
      return resp;
        }
        );
  }
  errormessage:string="";
  saveNotes()
  {
    if(this.notesType!=="" && this.notesType!=="choose Note Type" && this.notesText!=="")
    {
      this.errormessage="";
      this.notesService.saveNotes(this.notesType,this.notesText,this.loginDataService.getData().firstName+this.loginDataService.getData().surname,this.passeddata.CaseId)
      .subscribe(  res => {
        console.log(res);
        this.notesList=[];
        this.getNotesfromDB();
        this.message.emit({'status':'success','messageTxt':'notes added'});
        this.notesModal.hide(); 
      },
      err => {
       
        console.log('Error Occured duringng generate Pdf ' + err);
        this.message.emit({'status':'error','messageTxt':'update notes failed'});
      }
      );
    } else{
      this.errormessage = "choose noteType and enter Notes before save"
    }
  
  }

  //get input() { return this.validatingForm.get('required'); }

  //get childCountSize() { return this.validationForm.get('childCountSize'); }
 // get serviceNameLength() { return this.validationForm.get('serviceNameLength'); }
 // get serviceAddressLength() { return this.validationForm.get('serviceAddressLength'); }






}
