import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from './AppConfigService';
const getAllUrl = '/api/email-admins';

const updateUrl = '/api/email-admins/update';
const getEmailNotificationFunctionUrl = '/api/email-admins';
const addUrl = '/api/email-admins/create';

@Injectable({
  providedIn: 'root'
})
export class EmailAdminService {
  baseUrl:any;

  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  
  getAllAdminEmail(): Observable<any> {

 
    return this.http.get(this.baseUrl+getAllUrl).pipe(
      catchError(this.handleError('getAllRegs', getAllUrl))
    );
     

  }

  addEmailAdmin(inputrequestobj:any):Observable<any> {
   
   // var inputrequest=JSON.stringify(inputrequestobj);
 //  alert(this.baseUrl+addUrl);
      return this.http.post(this.baseUrl+addUrl,inputrequestobj);
      
  }

  updateEmailAdmin(inputrequestobj:any):Observable<any> {
   // alert(this.baseUrl+updateUrl+inputrequestobj.emailFunctId);
      return this.http.put(this.baseUrl+updateUrl,inputrequestobj)
      
  }

//   deleteLawReg(lawregId:any):Observable<any> {
   
//     return this.http.post(this.baseUrl+deleteUrl+'/'+lawregId,null)
    
// }

 getEmailAdminByEmailNotificationFunction(emailNotificationFunction:any):Observable<any>{
  return this.http.get(this.baseUrl+getEmailNotificationFunctionUrl+"/"+emailNotificationFunction).pipe(
    catchError(this.handleError('getEmailNotificationFunctionUrl', getEmailNotificationFunctionUrl))
  );
 }

private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(JSON.stringify(error)); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
} 

}
