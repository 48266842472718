
<!-- <button printSectionId="print-section" [useExistingCss]="true" (click)="onPrint();">Print me</button> -->

<mdb-card class="mb-3 mt-3" >
  <mdb-card-header class="p-0 subcardheadercolor">
      <table mdbTable class="table-hover table-condensed mb-0">
          <tbody class="p-0">
              <tr class="p-0">
                  <td class="subcardheader py-1 font-weight-bold ">Evidence/Comments</td>
                 
              </tr>
          </tbody>
      </table>

  </mdb-card-header>
  <mdb-card-body class="p-4" id="evidence">
 
      <div class="evidenceText">
         
          <label class="headertexts">Your Comments :</label>
          <textarea class="form-control p-2"  rows="10" maxlength="1000"  name="QuestionComments">
          
          </textarea> 
         

      </div>
      <div>
                  
      <table mdbTable class="p-0 table-fixed border border-0" > 
          <tbody class="p-0">
          <tr class="p-0">
        
          <td  class="mt-0 reminingText text-left p-0"><button type="button" mdbBtn size="sm"
              class="relative waves-light buttoncolor rounded text-white font-weight-bold trigger text-right ml-0"
              mdbWavesEffect > Save</button>
   

          </td>
          </tr>
          </tbody>
      </table>
  </div> 

  </mdb-card-body>
</mdb-card>

