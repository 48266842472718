<div>
    <a (click)="changeOfficerModal.show();" class="linkcolor">edit Officer</a>
</div>    
<div mdbModal #changeOfficerModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="changeOfficerModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="changeOfficerModal.hide()">
                <span aria-hidden="true" class="text-black">×</span>
            </button>
         
        </div>
        <div class="modal-body p-0">
                     
            
            <form class="doe-table-body p-0">
            <table mdbTable class="p-0 table-fixed" > 
                    <tbody class="p-0">
                    <tr class="p-0">
                    <td class="mt-0 w-50">
                      <select class="browser-default custom-select"   name="selectType" [(ngModel)]="userval">
                                    
                            <option *ngFor="let user of usersList" [value]="user.userId" >{{user.userId}}
                            </option>
                          </select> 
                        </td>
                        <td class="mt-0">
                            <button type="button" mdbBtn size="sm"
                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger "
                            mdbWavesEffect (click)="updateUser();changeOfficerModal.hide();">Update</button>
                            <button type="button" mdbBtn size="sm"
                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger "
                            mdbWavesEffect (click)="changeOfficerModal.hide();">Cancel</button>
                        </td>
                        </tr>
                        </tbody>

               </table>
               
                <!-- Grid row -->
              
              </form>
           
        
        
        </div>

    </div>
</div>
</div>