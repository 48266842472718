import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
declare var google: any;
@Component({
  selector: 'app-map1',
  templateUrl: './map1.component.html',
  styleUrls: ['./map1.component.scss']
})
export class Map1Component implements OnInit {
  public map: any = { lat: 51.678418, lng: 7.809007 };
  geocoder: any;

  longitude = 20.728218;
latitude = 52.128973;
@Input()
markers: any[] = [];

/*markers = [
{ latitude: 52.228973, longitude: 20.728218 },
{ latitude: 52.218246104200134, longitude: 21.44232932812497 },
{ latitude: 52.224976427376085, longitude: 19.01984397656247 }

];*/

placeMarker(position: any) {
const lat = position.coords.lat;
const lng = position.coords.lng;

this.markers.push({ latitude: lat, longitude: lng });
}
  
@ViewChild(AgmMap, { static: true }) map1: AgmMap;
constructor(public mapsApiLoader: MapsAPILoader) { 
  this.mapsApiLoader = mapsApiLoader;

  this.mapsApiLoader.load().then(() => {
    this.geocoder = new google.maps.Geocoder();
  });


}

  ngOnInit() {
    this.findAddressByCoordinates(52.228973,20.728218);
  }

  findAddressByCoordinates(latitude : any, longitude: any) {
    this.geocoder.geocode({
      'location': {
        lat: latitude,
        lng: longitude
      }
    }, (results: any, status: any) => {
     console.log(results+status);
    });
  }

}
