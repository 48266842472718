import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from './AppConfigService';
const getUrl = '/notes/all';
const uploadUrl='/notes/add';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  baseUrl:any;

  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  
 
 
  saveNotes(notesType: string, notesText: string,user: string,caseId:string): Observable<any> {
    var myjson = {caseId: caseId, type: notesType, notes: notesText,user: user};
    
    return this.http.post<any>(this.baseUrl+uploadUrl, myjson)
      .pipe(
        catchError(this.handleError('saveNotes', myjson))
      );
  }



  getAllNotes(caseId: any) {
    
       
    return this.http.get<any>(this.baseUrl+getUrl+"?caseId="+caseId) .pipe(
      catchError(this.handleError('getAllNotes', caseId))
    );

  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead
      alert("error message"+error.message);
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
