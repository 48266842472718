import { Component, OnInit, OnChanges,OnDestroy} from '@angular/core';
import { EmailTemplateService } from 'src/app/service/emailTemplate.service';
import { GeneratedEmailDocService } from 'src/app/service/generatedEmailDoc.service';
import { CaseOutcomeService } from 'src/app/service/caseOutcome.service';
import { DataService } from '../../visits/visit1/visit1.service';
import { EvidenceService } from 'src/app/service/evidence.service';
import {ToastrService} from 'ngx-toastr';
import { LoginDataService } from 'src/app/service/loginData.service';
import { DocumentService } from 'src/app/service/document.service';
import { DatePipe } from '@angular/common';
import { INAService } from 'src/app/service/ina.service';
import {SelectItem} from 'primeng/api';

//import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-emergencyActionTemplate',
  templateUrl: './emergencyAction.component.html',
  styleUrls: ['./emergencyAction.component.scss']

})
export class EmergencyActionComponent implements OnInit,OnChanges,OnDestroy {
  passeddata:any;
  date:Date;
  todaysDate:Date
  headElements = ['Provision of the National Regulations', 'Description of risk posed', 'Steps to be taken to remove or reduce the risk ', 'Due date for compliance'];
  headElementsLetterOne = ['Reference to the National Law and /or Regulations (Short title only)', 'Breach/s identified', 'Action taken by service', 'Evidence provided Yes/No'];
  onlyTableheader = ['Reference to the National Law and /or Regulations (Short title only)', 'Breach/s identified'];
  complianceNoticeheader=['Provision of the National Regulations', 'Description of risk posed', 'Steps to be taken to remove or reduce the risk '];
  user:string='';
 /* @Input()
 
  @Input()
  
  @Input()
  visitList: any[] = [];
  
  dueDate:string='';
  emergencyActionText:string;*/
  lawRegList: any[] = [];
  backuplist:any[]=[];
  selectedLawReg:any[]=[];
  visitdates:string='';
  outcomeTemplate : string =""; //pretable template
  outcomeType:string="";
  emailTemplatePart1: string =""; 
  postTableTemplate: string=""; // after the table - penalities template
  revisitDate :string =""; 
  responseReceivedDate:Date ; 
  initialVisitDate:string =""; 
  dateToBeImposed:Date;
  personNamePresentOnVisit:string =""; 
  issuesAndSteps	:string  =""; 
  responseDueDate :string =""; 
  incidentOccurred :string =""; 
  visitDates :string =""; 
  daysAfterSent :string =""; 
  lawApplicable :string =""; 
  ragulationsApplicable:string =""; 
  visitSummary:string =""; 
  officerName:string =""; 
  hubEmailAddress:string =""; 
  showCauseIssueDateToServiceProvider:string =""; 
  assessmentConditions:string =""; 
  pmcNameWithTitle:string =""; 
  approvalName:string =""; 
  SelectedOutcomeApprovalDate:string =""; 
  signatureDate:Date;
  signedBy:string  =""; 
  complienceType:string;
  considerRiskAndWorkRquiredDate:string =""; 
  headerPart1:string =""; 
  headerPart2:string =""; 
  headerPart3:string =""; 
  endPart:string =""; 
  breachTableHeader: string =""; 
  templateHeaderPart1:string =""; 
  templateHeaderPart2:string =""; 
  templateHeaderPart3:string =""; 
  templateEndPart:string =""; 
  templateBreachTableHeader: string =""; 
  confirmedRegListTextArea:string =""; 
  confirmedBreachAreas:string =""; 
  suspensionReasons:string =""; 
  groundForSuspension:string =""; 
  baseGroundForCancellation:string =""; 
  attachmentName:string =""; 
  breachAreaSelectItem: SelectItem[] = [];

  selectedBreachAreaStringArr: string[] = [];

  groundSelectItem: SelectItem[] = [];

  selectedGroundStringArr: string[] = [];

  ngOnInit() {

    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId')
      
       //roleId: localStorage.getItem('roleId')
 
     });
 
    
    }

    
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }
    
    this.user = (this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname:'';
    this.passeddata = this.dataservice.getOption();
   
   var outcome =localStorage.getItem("SelectedOutcome");
    //var selectedOutcomeApprovalDate  = localStorage.getItem("SelectedOutcomeApprovalDate");
     if(outcome!==null)
     {
       this.outcomeType=outcome;
    //   this.showCauseIssueDateToServiceProvider = this.datePipe.transform(selectedOutcomeApprovalDate, 'dd-MM-yyyy')+"";
     }
    
    this.date = new Date();
    this.date.setDate( this.date.getDate() + 14 );
    this.todaysDate=new Date();
   
    //this.outcomeType=(localStorage.getItem("SelectedOutcome")!==null)?localStorage.getItem("SelectedOutcome"):'';
   
   //this.lawRegList = (this.passeddata && this.passeddata!=null)?this.passeddata.lawRegList:[];
   
  // this.lawRegList =JSON.parse(localStorage.getItem('lawRegList') || '{}');


   this.inaService.getINAs( this.passeddata.CaseId)
   .subscribe(data => {
     for (const d of (data as any)) {
      
       if(d!==null && (d.discard==null || d.discard==undefined || d.discard=='null' || d.discard!=='true') && d.breachConfim!==null && d.breachConfim.toLowerCase()==='Confirmed breach'.toLowerCase())
       {
         var evidenceobj="";
       
       this.evidenceService.getEvidence(d.breachId).subscribe(data2 => {
        
          if(data2!==null && data2.length>0 && data2[0]!==null)
          {
            evidenceobj = data2[0].evidenceDetails
   
        this.lawRegList.push({
          inaid: d.breachId,
          priority: 'high',
          lawid: d.regNumber,
          description: d.regDescription,
          complianceStatus:d.breachConfim,
          lawregIndicator:d.lawregIndicator,
           evidenceFlag: true,
          showEvidenceFlag: false,
          visitId: d.visitId,
          visitNotDone:d.notReviewedFlag,
          serviceId: this.passeddata.SENumber,
          evidence: evidenceobj,
          discard:d.discard,
          questionairre:'',
          reviewComments:'',
          officerComments:'',
          selectedFile: temp,
          updatedBy:d.updatedBy   
      
      });

 
      this.backuplist=[...this.lawRegList];
  
     this.breachAreaSelectItem.push({label: d.regNumber +" : "+d.regDescription, value: d.breachId});
   
    }
    
       });
      }
     }
   
    });
    
    this.groundSelectItem = [];
    
    this.groundSelectItem.push({label:'the Regulatory Authority is satisfied that the approved provider or a person with management or control of an education and care service operated by the approved provider is not a fit and proper person to be involved in the provision of an education and care service;',
     value:'the Regulatory Authority is satisfied that the approved provider or a person with management or control of an education and care service operated by the approved provider is not a fit and proper person to be involved in the provision of an education and care service;'});
   
     this.groundSelectItem.push({label:'the Regulatory Authority is satisfied that the continued provision of education and care services by the approved provider would constitute an unacceptable risk to the safety, health or wellbeing of any child or class of children being educated and cared for by an education and care service operated by the approved provider; or',
     value:'the Regulatory Authority is satisfied that the continued provision of education and care services by the approved provider would constitute an unacceptable risk to the safety, health or wellbeing of any child or class of children being educated and cared for by an education and care service operated by the approved provider;'});

     this.groundSelectItem.push({label:'the approved provider has been found guilty of an indictable offence or an offence that if committed in this jurisdiction would be an indictable offence; or ',
     value:'the approved provider has been found guilty of an indictable offence or an offence that if committed in this jurisdiction would be an indictable offence; or '});

     this.groundSelectItem.push({label:'the approved provider has been found guilty of an indictable offence or an offence that if committed in this jurisdiction would be an indictable offence; or ',
     value:'the approved provider has been found guilty of an indictable offence or an offence that if committed in this jurisdiction would be an indictable offence; or '});


     this.groundSelectItem.push({label:'the approved provider has been found guilty of an offence under this Law as applying in any participating jurisdiction; or',
     value:'the approved provider has been found guilty of an offence under this Law as applying in any participating jurisdiction; or'});

     this.groundSelectItem.push({label:'the approved provider has not operated any education and care service for a period of more than 12 months (including any period of suspension)',
     value:'the approved provider has not operated any education and care service for a period of more than 12 months (including any period of suspension)'});

     this.baseGroundForCancellation = "(a) the Regulatory Authority is satisfied that the approved provider or a person with management or control of an education and care service operated by the approved provider is not a fit and proper person to be involved in the provision of an education and care service; or "
    +"<br/>"
    +"(b) the Regulatory Authority is satisfied that the continued provision of education and care services by the approved provider would constitute an unacceptable risk to the safety, health or wellbeing of any child or class of children being educated and cared for by an education and care service operated by the approved provider; or" 
    +"<br/>"
    +"(c) the approved provider has been found guilty of an indictable offence or an offence that if committed in this jurisdiction would be an indictable offence; or"
    +"<br/>"
    +"(d) the approved provider has been found guilty of an offence under this Law as applying in any participating jurisdiction; or" 
    +"<br/>"
    +"(e) the approved provider has breached a condition of the provider approval." 
    +"<br/>"
    +"(f) the approved provider has not operated any education and care service for a period of more than 12 months (including any period of suspension)."
  
  
    this.visitdates=(this.passeddata && this.passeddata!=null)?this.passeddata.breachVisitDates:'';

   /* this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.visitdates=this.visitdates+","+el.startDt;
      }
    });*/

  
   
    this.getTemplate();
    }

    ngOnChanges()
    {
           
    }

    

    getTemplate()
    {
      
      this.emailTemplateService.getEmailTemplate(this.outcomeType).subscribe(data2 => {
       
          this.emailTemplatePart1 = data2.templateBody;
          this.templateHeaderPart1 = data2.headerPart1;
          this.templateHeaderPart2 = data2.headerPart2;
        
          this.templateHeaderPart3 = data2.headerPart3;
          this.templateEndPart = data2.endPart;
          
          this.templateBreachTableHeader = data2.breachTableHeader;
        
      });
      
      this.generatedEmailDocService.getExistingGeneratedDoc(this.passeddata.CaseId,this.outcomeType).subscribe(data => {
      
        if(data!==null)
        {
         
          // this.outcomeTemplate = data.docBody1;
          // this.headerPart1=this.templateHeaderPart1 ;
          // this.headerPart2=this.templateHeaderPart2 ;
          // this.headerPart3=this.templateHeaderPart3 ;
          // this.endPart = this.templateEndPart;
          this.responseReceivedDate = data.responseReceivedDate;
          this.revisitDate = data.revisitDate;
          this.initialVisitDate = data.initialVisitDate;
          this.dateToBeImposed = data.dateToBeImposed;
          this.personNamePresentOnVisit=data.personNamePresentOnVisit;
          this.issuesAndSteps	= data.issuesAndSteps;
          this.responseDueDate = data.responseDueDate;
          this.incidentOccurred = data.incidentOccurred;
          this.visitDates = data.visitDates;
          this.daysAfterSent= data.daysAfterSent;
          this.lawApplicable = data.lawApplicable;
          this.ragulationsApplicable= data.ragulationsApplicable;
          this.visitSummary= data.visitSummary;
          this.officerName=data.officerName;
          this.hubEmailAddress=data.hubEmailAddress;
          this.pmcNameWithTitle=data.pmcNameWithTitle;
         
          this.showCauseIssueDateToServiceProvider =  data.showCauseIssueDateToServiceProvider;
        
          this.assessmentConditions=data.assessmentConditions;
          this.considerRiskAndWorkRquiredDate = data.considerRiskAndWorkRquiredDate;
          this.groundForSuspension = data.groundForSuspension;
          this.suspensionReasons = data.suspensionReasons ;
          this.signatureDate = data.signatureDate;
          this.signedBy = data.signedBy;
          this.selectedBreachAreaStringArr = [];
          this.approvalName = data.approvalName;
          this.attachmentName = data.attachmentName;
          this.confirmedBreachAreas =  data.confirmedBreachAreas;
          this.selectedBreachAreaStringArr = data.confirmedBreachAreas.split(',');
          if(this.passeddata.responseType === '1')
          {
            this.outcomeTemplate =this.outcomeTemplate +"<br>"+  data.responseRecieved;
          }
          if(this.passeddata.responseType === '2')
          {
            this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data.responseNotRecieved;
          }
          if(this.passeddata.responseType === '3')
          {
            this.outcomeTemplate =this.outcomeTemplate  +"<br>"+  data.responseRecievedToCancel;
          }
          this.postTableTemplate = data.docBody2;
          this.outcomeTemplate = this.outcomeTemplate.replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/null/g,'').replace(/\$VisitDates/g,this.visitdates).replace(/\$ServiceName/g,this.passeddata.ServiceName).replace(/\$ServiceAddress/g,this.passeddata.Address).replace(/\$ServiceId/g,this.passeddata.SENumber).replace(/\$ProviderAddress/g,this.passeddata.ProviderAddress).replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/\$ProviderNumber/g,this.passeddata.ProviderId).replace(/\$responseDate/g,this.passeddata.responseDate);
          console.log(this.outcomeTemplate);
        }
        else{
           this.emailTemplateService.getEmailTemplate(this.outcomeType).subscribe(data2 => {
          if(data2!== null && data2!==undefined)
          {
            
          this.templateHeaderPart1 = data2.headerPart1;
          if(this.outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'){

            this.groundForSuspension = this.baseGroundForCancellation;
          }
         
          this.outcomeTemplate = "";
           
           if(this.passeddata.responseType === '1')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data2.responseRecieved;
           }
           if(this.passeddata.responseType === '2')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+  data2.responseNotRecieved;
           }
           if(this.passeddata.responseType === '3')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data2.responseRecievedToCancel;
           }

           this.postTableTemplate = data2.postTableTemplate;
           this.outcomeTemplate = this.outcomeTemplate.replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/null/g,'').replace(/\$VisitDates/g,this.visitdates).replace(/\$ServiceName/g,this.passeddata.ServiceName).replace(/\$ServiceAddress/g,this.passeddata.Address).replace(/\$ServiceId/g,this.passeddata.SENumber).replace(/\$ProviderAddress/g,this.passeddata.ProviderAddress).replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/\$ProviderNumber/g,this.passeddata.ProviderId).replace(/\$responseDate/g,this.passeddata.responseDate);
           console.log(this.outcomeTemplate);
               
         }
        });
        }
        

       });

    
    }

    
    

    saveCorrectionSteps(item:any)
    {
    
      item.updatedBy=this.user;
      item.questionairre=null;
      item.reviewComments=null;
      this.evidenceService.addEvidence(item).subscribe(resp => {
        //this.message.emit({'status':'success','messageTxt':'correction steps saved'});
        this.showSuccess("correction steps saved'");
        return resp;
      },  err => {
        this.showError("Error Occured at save email templates'");
        console.log('Error Occured duringng save email templates' + err);
        //this.message.emit({'status':'error','messageTxt':'correction steps save failed'});
      });
    }

    saveComplianceDueDate(item:any)
    {

      let newDate = new Date(item.complianceDueDate);
      newDate.setHours(0,0,0,0);
      var now = new Date();
      now.setHours(0,0,0,0);
      if(newDate<now)
      {
    
        this.showError('date cannot be today or less than today');
        return; 
      }

      item.updatedBy=this.user;
      item.questionairre=null;
      item.reviewComments=null;
      this.evidenceService.addEvidence(item)
      .subscribe(resp => {
       // this.message.emit({'status':'success','messageTxt':'changes to compliance due date saved saved'});
       this.showSuccess("changes to compliance due date saved'");
       return resp;
      },  err => {
        this.showError("Error Occured at save email templates'");
        console.log('Error Occured duringng save email templates' + err);
        //this.message.emit({'status':'error','messageTxt':'compliance due date save failed'});
      });
    }
    prepareDocumentContent(){
      this.outcomeTemplate = this.emailTemplatePart1;
      this.headerPart1=this.templateHeaderPart1;
      this.headerPart2=this.templateHeaderPart2;
      this.headerPart3=this.templateHeaderPart3;
      this.endPart = this.templateEndPart;
      
     
        this.headerPart1 =  this.headerPart1.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
        this.headerPart1 = this.headerPart1.replace(/#Case Number#/g,this.passeddata.CaseId);
        this.headerPart1 = this.headerPart1.replace(/#Service Number#/g,this.passeddata.SENumber);
     
        this.headerPart2 = this.headerPart2.replace(/#Approved Provider Name#/g,this.approvalName);
       
        var providerAddressSplit = this.passeddata.ProviderAddress.split(',');
       
        var formattedProviderAddress="";
        if(providerAddressSplit !==null){
          formattedProviderAddress = providerAddressSplit[0]+"<br/>";
        }
       
        if(providerAddressSplit[1] !== ''){
          formattedProviderAddress = formattedProviderAddress+ providerAddressSplit[1]+"<br/>";
        }
     
        for (let i = 2; i < providerAddressSplit.length; i++) {
          // @ts-ignore
          if(providerAddressSplit[i] !== ''){
            formattedProviderAddress = formattedProviderAddress+providerAddressSplit[i]+" ";
         }
        }
       
        this.headerPart2 = this.headerPart2.replace(/#Provider Address#/g,formattedProviderAddress);
      //  this.headerPart2 = this.headerPart2.replace(/#Approved Provider Address Line 1#/g,this.passeddata.addressLine1);
      //  this.headerPart2 = this.headerPart2.replace(/#Approved Provider Suburb Town State Postcode#/g,this.passeddata.suburbTown+" "+this.passeddata.postcode);
        this.headerPart2 = this.headerPart2.replace(/#Approved Provider Email#/g,this.passeddata.ProviderEmail);
        this.headerPart2 = this.headerPart2.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
        this.headerPart2 = this.headerPart2.replace(/#Provider PMC Name#/g,this.passeddata.ProviderPMC);
        this.headerPart2 = this.headerPart2.replace(/#Provider Name#/g,this.passeddata.ProviderName);
       
        this.headerPart2 = this.headerPart2.replace(/#Case Number#/g,this.passeddata.CaseId);
        this.headerPart2 = this.headerPart2.replace(/#Service Number#/g,this.passeddata.SENumber);
        this.headerPart2 = this.headerPart2.replace(/#Service Address#/g,this.passeddata.Address);
        this.headerPart2 = this.headerPart2.replace(/#Service Name#/g,this.passeddata.ServiceName);
        this.headerPart2 = this.headerPart2.replace(/#Service PMC Name#/g,this.passeddata.PrimaryContact);
        this.headerPart2 = this.headerPart2.replace(/#Service Email#/g,this.passeddata.ServiceEmail); 
        this.headerPart2 = this.headerPart2.replace(/#Primary Contact#/g,this.pmcNameWithTitle); 
      
        if(this.headerPart3 !== null){
       
        this.headerPart3 = this.headerPart3.replace(/#Provider PMC Name#/g,this.passeddata.ProviderPMC);
       
       this.headerPart3 = this.headerPart3.replace(/#Service PMC Name#/g,this.passeddata.PrimaryContact);
       
       this.headerPart3 = this.headerPart3.replace(/#Provider Name#/g,this.passeddata.ProviderName);
       
        this.headerPart3 = this.headerPart3.replace(/#Provider Address#/g,this.passeddata.ProviderAddress);
       
        //this.headerPart3 = this.headerPart3.replace(/#Approved Provider Address Line 1#/g,this.passeddata.addressLine1);
        //this.headerPart3 = this.headerPart3.replace(/#Approved Provider Suburb Town State Postcode#/g,this.passeddata.suburbTown+" "+this.passeddata.postcode);
        //this.headerPart3 = this.headerPart3.replace(/#Approved Provider Email#/g,this.passeddata.ProviderEmail);
       
        this.headerPart3 = this.headerPart3.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
        this.headerPart3 = this.headerPart3.replace(/#Case Number#/g,this.passeddata.CaseId);
        this.headerPart3 = this.headerPart3.replace(/#Service Number#/g,this.passeddata.SENumber);
        this.headerPart3 = this.headerPart3.replace(/#Service Address#/g,this.passeddata.Address);
        this.headerPart3 = this.headerPart3.replace(/#Service Name#/g,this.passeddata.ServiceName);
    }
     
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service Number#/g,this.passeddata.SENumber);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service Name#/g,this.passeddata.ServiceName);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service Address#/g,this.passeddata.Address);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Case Number#/g,this.passeddata.CaseId);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service Email#/g,this.passeddata.ServiceEmail); 
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service PMC Name#/g,this.passeddata.PrimaryContact);
       
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Provider PMC Name#/g,this.passeddata.ProviderPMC);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Provider Name#/g,this.passeddata.ProviderName);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Provider Address#/g,this.passeddata.ProviderAddress);
        //this.outcomeTemplate = this.outcomeTemplate.replace(/#Approved Provider Address Line 1#/g,this.passeddata.addressLine1);
        //this.outcomeTemplate = this.outcomeTemplate.replace(/#Approved Provider Suburb Town State Postcode#/g,this.passeddata.suburbTown+" "+this.passeddata.postcode);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Approved Provider Email#/g,this.passeddata.ProviderEmail);
      
       // this.outcomeTemplate = this.outcomeTemplate.replace("#PMC Surname#","Mr");
       
  
        this.outcomeTemplate = this.outcomeTemplate.replace(/#PMC Name#/g,this.pmcNameWithTitle);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Response Received Date#/g,this.datePipe.transform(this.responseReceivedDate, 'dd MMM yyyy')+"");
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Show Cause Issued Date#/g,this.showCauseIssueDateToServiceProvider);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Revisit Date#/g,this.revisitDate);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Initial Visit Date#/g,this.initialVisitDate);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Date to Be Imposed#/g,this.datePipe.transform(this.dateToBeImposed, 'dd MMM yyyy')+"");
        
        
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Conditions Under Assessment#/g,this.assessmentConditions); 
     
    
      //alert ("this.signatureDate"+this.signatureDate);
        //letter 1 report 
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Visit Date#/g,this.visitDates);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Weeks From Sent Date#/g,this.daysAfterSent);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Responsible Person Present#/g,this.personNamePresentOnVisit);
        
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service History For Similar Non-Complience#/g,this.incidentOccurred);  
    //   this.outcomeTemplate = this.outcomeTemplate.replace(/#Service History and Department Action or Responses#/g,this.incidentOccurred);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service Response Due Date#/g,this.responseDueDate);
       
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Officer Name#/g,this.officerName);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Hub Email Address#/g,this.hubEmailAddress);
        
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Summary of ongoing issues#/g,this.issuesAndSteps);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Service History and Department Action or Responses#/g,this.issuesAndSteps);
      
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Suspension Reasons#/g,this.suspensionReasons);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Cancellation Reasons#/g,this.suspensionReasons);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Reasons For The Proposed Cancellation#/g,this.suspensionReasons);

        this.outcomeTemplate = this.outcomeTemplate.replace(/#Ground For Suspension#/g,this.groundForSuspension);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Ground For Cancellation#/g,this.groundForSuspension);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Ground For Proposed Cancellation#/g,this.groundForSuspension);

        this.outcomeTemplate = this.outcomeTemplate.replace(/#Visit Summary, including the purpose and date#/g,this.visitSummary);
        
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Show Cause Notice Date#/g,this.showCauseIssueDateToServiceProvider);
        
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Date Cancellation Take Effect#/g,this.responseDueDate);
     
     
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Date that considers risk and the work required#/g,this.considerRiskAndWorkRquiredDate);
        
       
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Attachment Names#/g,this.attachmentName);
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Complience Type#/g,this.complienceType);

        this.endPart  = this.endPart.replace(/#Signed By#/g,this.signedBy); 
        this.endPart  = this.endPart.replace(/#Signature Date#/g,this.datePipe.transform(this.signatureDate, 'dd MMM yyyy')+""); 
       
         
         this.selectedLawReg= [];
         for (let i = 0; i < this.selectedBreachAreaStringArr.length; i++) {
          
           for (let j = 0; j< this.lawRegList.length; j++) {
           if(this.lawRegList[j].inaid === this.selectedBreachAreaStringArr[i]){
            this.selectedLawReg[i]= this.lawRegList[j];
            }
          }
         }
      this.confirmedRegListTextArea = "";
      this.confirmedBreachAreas = "";
      for (let i = 0; i < this.selectedLawReg.length; i++) {

        this.confirmedRegListTextArea = this.confirmedRegListTextArea + "<b><li>" + this.selectedLawReg[i].lawid + ":" + this.selectedLawReg[i].description + "</li></b>";
        // this.confirmedRegListTextArea = this.confirmedRegListTextArea + "<br/>";
        this.confirmedRegListTextArea = this.confirmedRegListTextArea + "&nbsp;&nbsp;&nbsp;&nbsp;<p>" + this.selectedLawReg[i].evidence + "</p>";
        //  this.confirmedBreachAreas = "";
        if (i === 0) {
          this.confirmedBreachAreas = this.selectedLawReg[i].inaid;
          if (this.selectedLawReg[i].lawregIndicator !== null) {
            if (this.selectedLawReg[i].lawregIndicator === 'Law') {
              this.lawApplicable = this.selectedLawReg[i].inaid;
            }
            if (this.selectedLawReg[i].lawregIndicator === 'Regulation') {
              this.ragulationsApplicable = this.selectedLawReg[i].inaid;
            }
          }
        } else {
          this.confirmedBreachAreas = this.confirmedBreachAreas + "," + this.selectedLawReg[i].inaid;
          if (this.selectedLawReg[i].lawregIndicator !== null) {
            if (this.selectedLawReg[i].lawregIndicator === 'Law') {
              this.lawApplicable = this.lawApplicable + ", " + this.selectedLawReg[i].inaid;
            }
            if (this.selectedLawReg[i].lawregIndicator === 'Regulation') {
              this.ragulationsApplicable = this.ragulationsApplicable + ", " + this.selectedLawReg[i].inaid;
            }
          }
        }
      }

      this.outcomeTemplate = this.outcomeTemplate.replace(/#Confirmed Breach Areas#/g, this.confirmedRegListTextArea);
      if(this.lawApplicable!==null){
       this.outcomeTemplate = this.outcomeTemplate.replace(/#Law Applicable#/g,this.lawApplicable+"<i>(Education and Care Services) National Law (NSW) (the National Law) </i> and, "); 
      }
      if(this.ragulationsApplicable!==null){
        this.outcomeTemplate = this.outcomeTemplate.replace(/#Regulation Applicable#/g,this.ragulationsApplicable +" of the <i>Education and Care Services National Regulations (the Regulations).</i>");
      }
    }

    prepareTableContent(){
      this.breachTableHeader =  this.templateBreachTableHeader;
      //alert(this.breachTableHeader);
      if( this.breachTableHeader !== null){
        this.breachTableHeader = this.breachTableHeader.replace(/#Hub Email Address#/g,this.hubEmailAddress);
        this.breachTableHeader = this.breachTableHeader.replace(/#Service Name#/g,this.passeddata.ServiceName);
        this.breachTableHeader = this.breachTableHeader.replace(/#Visit Date#/g,this.visitDates);
        this.breachTableHeader = this.breachTableHeader.replace(/#Weeks From Sent Date#/g,this.daysAfterSent);
        this.breachTableHeader = this.breachTableHeader.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
           //this.breachTableHeader = this.breachTableHeader.replace(/#Provider PMC Name#/g,this.passeddata.ProviderPMC);
           this.breachTableHeader = this.breachTableHeader.replace(/#Provider Name#/g,this.passeddata.ProviderName);
         
           this.breachTableHeader = this.breachTableHeader.replace(/#Case Number#/g,this.passeddata.CaseId);
           this.breachTableHeader = this.breachTableHeader.replace(/#Service Number#/g,this.passeddata.SENumber);
           this.breachTableHeader = this.breachTableHeader.replace(/#Outcome Type#/g,this.outcomeType);
           this.breachTableHeader = this.breachTableHeader.replace(/#Service Name#/g,this.passeddata.ServiceName);
       
       }
    }
  saveOutcomeTemplate1() {
    var isEmpty: string = "No";

    if (this.signatureDate === null || this.signatureDate === undefined ||
      this.signedBy === '' ||
      this.approvalName === '' ||
      this.pmcNameWithTitle === '') {
      isEmpty = "Yes";
    }
    if (this.outcomeType === 'Admin Letter') {
      if (this.visitDates === '' ||
        this.personNamePresentOnVisit === '' ||
        this.hubEmailAddress === '' ||
        this.responseDueDate === '' ||
        this.selectedBreachAreaStringArr.length === 0) {
        isEmpty = "Yes";
      }
    }
   
    if (this.outcomeType === 'Emergency Action') {
      if (this.selectedBreachAreaStringArr.length === 0 ||
        this.incidentOccurred === '' ||
        this.visitDates === '') {
       
        isEmpty = "Yes";
      }
    }
   
    if (this.outcomeType === 'Compliance Direction') {
      if (this.selectedBreachAreaStringArr.length === 0 ||
        this.considerRiskAndWorkRquiredDate === '' ||
      
        this.visitSummary === '' ||
        this.hubEmailAddress === '' ||
        this.officerName === '') {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Compliance Notice') {
      if (this.daysAfterSent === '' ||
        this.visitDates === '' ||
        this.visitSummary === '' ||
        this.issuesAndSteps === ''||
        this.hubEmailAddress === '' ||
        this.officerName === '' ||
        this.selectedBreachAreaStringArr.length === 0) {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Caution Letter') {
      if (this.visitDates === '' ||
        this.issuesAndSteps === '' ||
        this.selectedBreachAreaStringArr.length === 0) {
        isEmpty = "Yes";
      }
    }

    if (this.outcomeType === 'Show Cause Notice to Cancellation of Provider Approval') {
      if (this.visitDates === '' ||
     
        this.attachmentName === '' ||
        this.suspensionReasons === '' ||
        this.groundForSuspension === '') {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Show Cause Notice to Cancellation of Service Approval') {
      if (this.suspensionReasons === '' ||
        this.groundForSuspension === '') {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Cancellation of Provider Approval') {
      if (this.suspensionReasons === '' ||
        this.issuesAndSteps === '' ||
        this.showCauseIssueDateToServiceProvider === '' ||
        this.attachmentName === '' ||
        this.responseDueDate === null || this.responseDueDate === undefined ||
        this.responseReceivedDate === null || this.responseReceivedDate === undefined ) {
        isEmpty = "Yes";
      }

    }

    if (this.outcomeType === 'Cancellation of Service Approval') {
      if (this.suspensionReasons === '' ||
        this.issuesAndSteps === '' ||
        this.showCauseIssueDateToServiceProvider === '' ||
        this.attachmentName === '' ||
        this.responseDueDate === null || this.responseDueDate === undefined ||
        this.responseReceivedDate === null || this.responseReceivedDate === undefined ) {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Suspension of Provider Approval') {
      if (this.visitDates === '' ||
        this.groundForSuspension === '' ||
        this.suspensionReasons === '') {
        isEmpty = "Yes";
      }
    }
    if (this.outcomeType === 'Suspension of Service Approval') {
      if (this.visitDates === '' ||
        this.visitSummary === '' ||
        this.incidentOccurred === '') {
        isEmpty = "Yes";
      }
    }

    if (this.outcomeType === 'Decision not to Cancel Provider Approval') {
      if (this.showCauseIssueDateToServiceProvider === '' ||
        this.revisitDate === '' ||
        this.responseReceivedDate === null || this.responseReceivedDate === undefined  ||
        this.initialVisitDate === '') {
        isEmpty = "Yes";
      }

    }
    if (this.outcomeType === 'Decision not to Cancel Service Approval') {
      if (this.showCauseIssueDateToServiceProvider === '' ||
        this.revisitDate === '' ||
        this.responseReceivedDate === null || this.responseReceivedDate === undefined  ||
        this.initialVisitDate === '' ||
        this.dateToBeImposed === null ||
        this.assessmentConditions === '') {
        isEmpty = "Yes";
      }

    }
   
    if (isEmpty === "Yes") {
      this.showError("Please fill all the inputs");
      return;
    }
  
    this.prepareDocumentContent();
    this.prepareTableContent();
   


    this.generatedEmailDocService.saveGeneratedDoc(this.passeddata.CaseId, this.outcomeType, this.outcomeTemplate, this.postTableTemplate,
      this.revisitDate, this.responseReceivedDate, this.initialVisitDate, this.dateToBeImposed, this.personNamePresentOnVisit, this.issuesAndSteps,
      this.responseDueDate, this.incidentOccurred, this.visitDates, this.daysAfterSent,
      this.lawApplicable, this.ragulationsApplicable, this.visitSummary, this.officerName, this.hubEmailAddress, this.pmcNameWithTitle,
      this.showCauseIssueDateToServiceProvider, this.signatureDate, this.considerRiskAndWorkRquiredDate, this.headerPart1, this.headerPart2, this.headerPart3,
      this.assessmentConditions, this.confirmedBreachAreas, this.approvalName, this.endPart,
      this.breachTableHeader, this.groundForSuspension, this.suspensionReasons, this.attachmentName,
      this.signedBy, this.outcomeType)
      .subscribe(resp => {
        this.showSuccess("changes to template saved.'");


        //  this.message.emit({'status':'success','messageTxt':'changes to template saved'});
        return resp;
      }, err => {
        this.showError("Error Occured at save email templates'");
        console.log('Error Occured duringng save email templates' + err);
        // this.message.emit({'status':'error','messageTxt':'changes to template failed to save'});
      });

  }
    saveOutcomeTemplateStatus(outcomeType:string)
    {
      this.caseOutcomeService.saveCaseOutcomeStatus(outcomeType,this.passeddata.CaseId,this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname,'','Draft Completed','')
      .subscribe(resp => {
               console.log(resp);
              
              
               
              // this.refreshOutcomeTable = true;
              this.showSuccess("outcome document  status is changed from In progess to Draft Completed'");
               
          },
          err => {
           
            console.log('Error Occured during save outcome ' + err);
            this.showError("Error Occured at save outcome document  status '");
          }
          );
  //    this.generatedEmailDocService.saveGeneratedDocStatus(this.passeddata.CaseId, outcomeType, 'Completed')
  //    .subscribe(resp => {
      //   this.showSuccess("changes to status saved'");
        
       
      //   //  this.message.emit({'status':'success','messageTxt':'changes to template saved'});
      //   return resp;
      // },  err => {
      //  this.showError("Error Occured at save email status'");
      //   console.log('Error Occured duringng save email status' + err);
      //  // this.message.emit({'status':'error','messageTxt':'changes to template failed to save'});
      // });
      
    }

    showSuccess(message:string)
  {

    this.toastr.success(message, 'Success!',
    {timeOut: 2000});;
  }
  showError(message:string)
  {

    this.toastr.error(message, 'Error!',
    {timeOut: 2000});;
  }

  

  showInfo(message:string)
  {

    this.toastr.info(message, 'Info!',
    {timeOut: 2000});;
  }
  generateAttachmentPdf(outcome:string)
  {
  
 
     
   //   this.saveOutcomeTemplate1();
      this.documentService.getOutcomeStatusPdf(outcome,this.passeddata.CaseId,null,this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      var file:any;
     /* file = new Blob([res], { type: 'application/pdf' });
      
      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);*/


       file = new Blob([res], { type: 'application/pdf' });
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.showError('Error generating Pdf');
    }
    );
  //  this.getTemplate();
  }
  generateInitialAttachmentPdf(outcome:string)
  {
    this.outcomeTemplate = this.emailTemplatePart1;
    this.headerPart1=this.templateHeaderPart1;
    this.headerPart2=this.templateHeaderPart2;
    this.headerPart3=this.templateHeaderPart3;
    this.endPart = this.templateEndPart;
 
   
   
    this.outcomeTemplate = this.outcomeTemplate.replace(/#Ground For Proposed Cancellation#/g,this.baseGroundForCancellation);
   // this.saveOutcomeTemplate1();
  
      this.documentService.getOutcomeTempletePdf(outcome,this.passeddata.CaseId,this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      var file:any;
     /* file = new Blob([res], { type: 'application/pdf' });
      
      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);*/


       file = new Blob([res], { type: 'application/pdf' });
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.showError('Error generating Pdf');
    }
    );

  }
  generateBreachTableTempletePdf(outcome:string)
  {
    
   
   // this.saveOutcomeTemplate1();
  
 //  alert(this.breachTableHeader);
      this.documentService.generateBreachTableTempletePdf(outcome,this.passeddata.CaseId,null,this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      var file:any;
     /* file = new Blob([res], { type: 'application/pdf' });
      
      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);*/


       file = new Blob([res], { type: 'application/pdf' });
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.showError('Error generating Pdf');
    }
    );

  }

  constructor(private inaService: INAService,private loginDataService:LoginDataService,private toastr:ToastrService,private evidenceService: EvidenceService,private dataservice:DataService,
    private emailTemplateService: EmailTemplateService,private generatedEmailDocService: GeneratedEmailDocService,
    private documentService:DocumentService,private datePipe: DatePipe,private caseOutcomeService:CaseOutcomeService,)
  {
   
  }
  ngOnDestroy(): void {
  
  }
 

 

  /* this.evidenceDocService.uploadEvidenceDoc(uploadData, item)
     .subscribe(
       res => {
         console.log(res);
         this.showSuccess('upload successful');
         this.getEvidenceDocsforVisitfromDB();

       },
       err => {
         this.showError('upload Failed !');
         console.log('Error Occured duringng saving: ' + err);}


     );*/

 

}
