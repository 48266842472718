import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DataService } from './visit1.service';
import { MdbTablePaginationComponent, MdbTableDirective, MDBModalRef } from 'angular-bootstrap-md';
import { CaseService} from '../../../service/case.service';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/service/loginData.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';
import { ToastrService } from 'ngx-toastr';
import { VisitService } from 'src/app/service/visit.service';





@Component({
  selector: 'app-basic-table',
  templateUrl: './visit1.component.html',
  styleUrls: ['./visit1.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BasicTableComponent implements OnInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  maxVisibleItems=3;
  previous: any = [];
  role:string;
  searchValue: string='';
  modalRef: MDBModalRef;
  addcoldList=['Case Officer','Hub','Reviewer','Approver','Case Details'];

  //headElementsSort=['CaseId','ServiceName', 'SENumber','ServiceType','CaseStatus','CreatedDate','Officer','Hub','Reviewer','Approver','Case Details']
  //headElements = ['Case ID','Service Name', 'Service ID','Service Type','Status','Created Date','Officer','Hub','Reviewer','Approver','Case Details'];
  
  headElementsSort=['CaseId','ServiceName', 'SENumber','ServiceType','CaseStatus','CreatedDate','Officer','Hub','Reviewer','Approver','Case Details']
  headElements = ['Case ID','Service Name', 'Service ID','Service Type','Status','Created Date','Case Officer','Hub','Reviewer','Approver','Case Details'];

  visitsCols = new Map([['Case Officer',true],['Hub',true],['Reviewer',false],['Approver',false],['Case Details',true]]);


  visitDetailsList : any[]=[];
  backupList: any[]=[];

  page = 1;
  pageSize = 4;
  filename="choose one";
  //collectionSize = this.visitDetailsList.length;
  constructor(private visitservice:VisitService,private toastr:ToastrService,private userDetailsService: UserDetailsService,private loginService: LoginDataService,private ref: ChangeDetectorRef, private dataservice :DataService, private caseservice: CaseService,private datePipe: DatePipe) {

    ref.detach();
    setInterval(() => {
        this.ref.detectChanges();
    }, 500);

  }
  user:string='';
  loggedinUser:string='';
  accessList:string[]=[];
  config:any;
  ngOnInit() {
   if(this.loginService.getData() == null || this.loginService.getData() == undefined){
     console.log(localStorage.getItem('displayLoginName'));
    
    this.loginService.setData({firstName:localStorage.getItem('firstName'),
      surname:localStorage.getItem('surname'),
      displayLoginName:localStorage.getItem('displayLoginName'),
      email:localStorage.getItem('email'),
      status:localStorage.getItem('status'),
      roleId:localStorage.getItem('roleId'),
      uid:localStorage.getItem('uid')
     
      //roleId: localStorage.getItem('roleId')

    });

    this.user=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
    this.role = this.loginService.getData().roleId;
    this.loggedinUser =  this.loginService.getData().uid;
   }else{

    this.user=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
    this.role = this.loginService.getData().roleId;
    this.loggedinUser =  this.loginService.getData().uid;
   }

    this.getCasesFromDb();
   

    this.ref.detectChanges();
   /* this.activatedRoute.queryParams.subscribe(params => {
      this.role = params['role'];

    });*/
  
    this.accessList = [...this.loginService.getAccessList()];
    //alert(this.accessList);
    //console.log(this.accessList[0]);

    if(this.accessList[0] == undefined) {
      //alert(this.accessList);
      this.loginService.setAccessList([]);  //empty the list as it is a reviewer role
            this.userDetailsService.getAccessDetails("ECOMP_"+this.role.toUpperCase()).toPromise().then( (data) => {
          
              for (const d of (data as any)) {
      
                
                this.loginService.getAccessList().push(d.pageName);

               //alert(this.accessList);
      
              }

            
              
        });

        this.accessList =  this.loginService.getAccessList();

        console.log('Accesslist '  + this.accessList);

       
}

    this.getAllUsers();
   
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.visitDetailsList.length
    };

  }
  pageChanged(event : any){
    this.config.currentPage = event;
  }
  usersList:any[]=[];
  userListMgrs:any[]=[];
  userListACOs:any[]=[];
  modaluser:string;
  getAllUsers()
  {
    this.userDetailsService.getAllUsers().subscribe(data => {
      for (const d of (data as any)) {


        this.usersList.push({
          userId: d.intUserId,
          roleId:d.roleId,
          email: d.email
        }
        );

      }
      console.log(this.usersList);
      this.userListACOs=this.usersList.filter(el => 
        (el.roleId === 'ACO' && el.userId.toUpperCase()!==(this.loggedinUser.toUpperCase()))
       
      );
  
      this.userListMgrs=this.usersList.filter(el => 
        (el.roleId === 'ROM' && el.userId.toUpperCase()!==(this.loggedinUser.toUpperCase()))
       
      );
          
    });

    

  }
  visitList:any[]=[];
  reviewerUserList:any[]=[];
  async getACOsforReviewer(caseId:string,event:any)
  {
    
    this.reviewerUserList = [...this.userListACOs];

   
    await this.visitservice.getVisits(caseId)
    .subscribe(data => {
    
      for (const d of (data as any)) {
        if(d!==null)
        {
          
             var userobj = this.reviewerUserList.find(el => (d.officer1 === el.userId || d.officer2 === el.userId));
           
            if(userobj!==null)
             {
             
               var index = this.reviewerUserList.indexOf(userobj);
          
               if(index!==-1)
               {
                this.reviewerUserList.splice(index,1);
               }
              
             }

           }

       
        }
    
    
 });
 event.stopPropagation();
}

leadUserList:any[]=[];
  async getACOsforLead(caseId:string,event:any,reviewer:string)
{
    
  this.leadUserList = [...this.userListACOs];

 
  await this.visitservice.getVisits(caseId)
  .subscribe(data => {
  
    for (const d of (data as any)) {
      if(d!==null)
      {
        
           var userobj = this.leadUserList.find(el => (d.officer1 === el.userId || (d.officer2!==null && d.officer2 === el.userId) || (reviewer!==null && reviewer=== el.userId )));
         
          if(userobj!==null)
           {
           
             var index = this.leadUserList.indexOf(userobj);
        
             if(index!==-1)
             {
              this.leadUserList.splice(index,1);
             }
            
           }

         }

     
      }
  
  
});
event.stopPropagation();
}


  helpText(message:any)
  {
      if(message.status==='success')
        this.showSuccess(message.messageTxt);
      else if(message.status==='error')
        this.showError(message.messageTxt);
      
  }


  checkReviewer(name:string)
  {

    if(name && name!=null && (name.toLowerCase()=== this.loggedinUser.toLowerCase()))
    {
     
      return true;
    }
    else{
      return false;
    }
  }

  isReviewComplete(name:string,status:string)
  {
  
    if(name!==undefined && name!==null && name.toLowerCase()=== this.loggedinUser.toLowerCase() ) //is reviewer for this case
    {
      if(status!=='Peer Review Sent')
      {
        return true;
      }
      else{
        return false;
      }
      
    }
    else{
      return false;
    }

  }
  onuserSelect(username: boolean,userType:string,item:any)
  {
  
    if(username)
    {
       this.visitDetailsList.find(el => {
         if(el === item)
         {
         
           if(userType === 'Officer')
           {
         
            el.Officer=username;
            this.caseservice.updateCase({Officer:username , CaseId:el.CaseId}).subscribe(res =>
                console.log("officer updated"+res) );
          
           }
          else if(userType === 'Reviewer')
           {
           
            el.Reviewer=username;
        
            this.caseservice.updateCase({Reviewer:username , CaseId:el.CaseId}).subscribe(res =>
                console.log("officer updated"+res) );
          
           }
           else if(userType === 'Approver')
           {
           
         
            el.Approver=username;
            this.caseservice.updateCase({Approver:username , CaseId:el.CaseId}).subscribe(res =>
                console.log("officer updated"+res) );
          
           }
          
         }
       })
    }
  }


  onStatusSelect(status: boolean,item:any)
  {
  
    if(status)
    {
       this.visitDetailsList.find(el => {
         if(el === item)
         {
          
            el.CaseStatus=status;
            this.caseservice.updateCase({CaseStatus:status , CaseId:el.CaseId}).subscribe(res =>
                console.log("case status updated"+res) );
                this.showSuccess("case status updated");
         
          
         }
       })
    }
  }

  addOfficer(event:any)
  {
    event.stopPropagation();
  }
  

  changeCaseStatus(event:any)
  {
    event.stopPropagation();
  }

  /*isAccessible(accessStr:string)
  {
  
    if(this.accessList.filter(el => el.pageName === accessStr).length>0)
        return true;
    else
        return false
  }*/

  run() {

 if(this.mdbTable && this.mdbTable!=null && this.visitDetailsList && this.visitDetailsList!==null)
 {

 
    this.mdbTable.setDataSource(this.visitDetailsList);

    this.visitDetailsList = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();

    if (this.visitDetailsList.length > this.maxVisibleItems) {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    } else {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.visitDetailsList.length);
    }
  }

  }
  onseraching(searchValue:string) {
    
    if(searchValue==='')
    {
      this.visitDetailsList =[...this.backupList];
    }
    else
    {
       this.visitDetailsList = this.backupList.filter(el => (el.CaseId.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }

  }

  onFilter(searchValue:string,fieldName:string) {
    
    if(searchValue==='')
    {
      this.visitDetailsList =[...this.backupList];
    }
    else
    {
    
      if(fieldName!==null && fieldName==='Case ID')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.CaseId!==null && el.CaseId!==undefined && el.CaseId.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Service Name')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.ServiceName!==null && el.ServiceName!==undefined && el.ServiceName.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Service ID')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.SENumber!==null && el.SENumber!==undefined && el.SENumber.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Service Type')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.ServiceType!==null && el.ServiceType!==undefined && el.ServiceType.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Status')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.CaseStatus!==null && el.CaseStatus!==undefined && el.CaseStatus.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Created Date')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.CreatedDate!==null && el.CreatedDate!==undefined && el.CreatedDate.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Reviewer')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.Reviewer!==null && el.Reviewer!==undefined && el.Reviewer.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Case Officer')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.Officer!==null && el.Officer!==undefined && el.Officer.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Approver')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.Approver!==null && el.Approver!==undefined && el.Approver.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Hub')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.Hub!==null && el.Hub!==undefined && el.Hub.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if(fieldName!==null && fieldName==='Case Details')
      {
        this.visitDetailsList = this.backupList.filter(el => (el.CaseDetails!==null && el.CaseDetails!==undefined && el.CaseDetails.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
    }

  }
  getsortedList()
  {

    return this.visitDetailsList.sort((a,b) => (a.CaseStatus > b.CaseStatus) ? 1 : ((b.CaseStatus > a.CaseStatus) ? -1 : 0)); 
  }

  //{id: 1,CaseId:"123243", ServiceName:"eSAM Hedgehog 4",SENumber:"123453",ServiceType:"Center-Based Care",CaseStatus:"Pending",Officer:"Marvin , Paul",Hub:"Dharug",Reviewer:"Kim",Approver:"Alfred",CaseDetails:"some free text"},

  getCasesFromDb() {
   
    this.visitDetailsList=[];
    var isAdmin = false;
    if((this.loginService && this.loginService.getData()) && (this.loginService.getData().roleId === 'ADMIN' || this.loginService.getData().roleId === 'READ_ONLY' || this.loginService.getData().roleId === 'ROM'))
    {
      isAdmin=true;
    }

      this.caseservice.getVisits(this.loggedinUser,isAdmin)
      .subscribe(data => {
        for (const d of (data as any)) {
         
          this.visitDetailsList.push({
            id: 1,
            CaseId: d.caseId,
            CreatedDate:d.createdOn,
            ServiceName: d.serviceName,
            SENumber:d.serviceId,
            ProviderId:d.providerId,
            ServiceType:d.serviceType,
            CaseStatus: d.status,
            CaseSummary:d.caseSumary,
            ApprovalStatus:d.approvalStatus,
            OfficerComments:d.officerComments,
            ReviewerComments:d.reviewerComments,
            ApproverComments:d.approverComments,
            HistoryDocs:d.historyDocs,
            CompletedOn:this.datePipe.transform(d.completedOn, 'dd-MM-yyyy'),
            Officer:d.caseOfficer,
            Hub:d.managingHub,
            responseType:d.responseType,
            responseDate:d.responseDate,
            Reviewer:d.caseReviewer,
            Approver:d.caseApprover,
            ManagerReviewer:d.caseManagerReviewer,
            CaseDetails:d.caseSumary,
            Address:d.addressLine1+", "+d.addressLine2+", "+d.suburbTown+", "+d.stateTerritory+", "+d.postcode,
            ProviderName:d.providerName,
            PrimaryContact:d.primaryContact,
            NominatedSupervisor:d.nominatedSupervisor,
            ServiceEmail:d.serviceEmail,
            Latitude:d.serviceLatitude,
            Suburb:d.suburbTown,
            Longitude:d.serviceLongitude,
            ProviderPMC:d.providerPMC,
            ProviderEmail:d.providerEmail,
            ProviderAddress:d.providerAddress
          });
       

        }

        if(this.loginService.getData()!==null && this.loginService.getData().roleId !== 'ADMIN')
        {
          this.visitDetailsList = this.visitDetailsList.filter(e => e.CaseStatus!=='Closed');
        }
       
        this.visitDetailsList= this.visitDetailsList.sort((a,b) => (a.CreatedDate < b.CreatedDate) ? 1 : ((b.CreatedDate < a.CreatedDate) ? -1 : 0)); 

        this.backupList=[...this.visitDetailsList];

        this.run();
        this.ref.detectChanges();

        console.log(this.visitDetailsList);
      });
  }

  isColEnabled(keyname: string)
  {
    return this.visitsCols.get(keyname);
  }
  showSuccess(message:string)
  {

    this.toastr.success(message, 'Success!',
    {timeOut: 2000});;
  }
  showError(message:string)
  {

    this.toastr.error(message, 'Error!',
    {timeOut: 2000});;
  }
  showInfo(message:string)
  {

    this.toastr.info(message, 'Info!',
    {timeOut: 2000});;
  }

  addMoreVisitCols(key :string, event : boolean)
  {

    //let flag = this.visitsCols.get(key);

    if(event == true){
      this.visitsCols.set(key,false);
    }

   else{
          this.visitsCols.set(key,true);
       }

  // setTimeout( () => this.visitsCols.set(key,!flag), 0);


    this.ref.detectChanges();


  }

  selectedFile:any;


  public onFileChanged(event: any) {
     console.log(event);

  this.selectedFile = event.target.files[0];
  this.filename=this.selectedFile.name;


  }
  setFileEmpty()
  {
    this.selectedFile=null;
  }

  // This part is for uploading
  fileURL: any;
  loading = false;
  onUpload() {
   if(this.selectedFile)
    {
     this.loading=true;
    const uploadData = new FormData();
  
    this.ref.detectChanges();

    uploadData.append('caseFile', this.selectedFile, this.selectedFile.name);
     this.caseservice.uploadCase(uploadData).toPromise().then(
      async resp => {
               await this.getCasesFromDb();
                 this.ref.detectChanges();
                this.showSuccess("download successful");
                this.showInfo("If you cannot see the case listed here, you might not be assigned officer for this case Please check in NQAITS for downloaded case");
                this.filename="choose one";
                this.selectedFile=null;
                this.loading=false;
               console.log(resp);
             },
          err => {
            this.showSuccess("download failed");
            console.log('Error Occured during download ' + err);
            this.filename="choose one";
            this.selectedFile=null;
         
          }
          );


   
    }

  }
/*
  synching=false;
  async onSync(caseId:string) {


    this.synching=true;
    this.ref.detectChanges();


    const res = await  this.caseservice.downloadCase(").toPromise();
    console.log("res: " + JSON.stringify(res));


    this.getVisitsFromDb();
    this.loading=false;
    this.ref.detectChanges();

  }*/

  saveDatatoService(data : any)
  {
     // data.CaseStatus='Scheduled';  // temporary change
     //alert("in save data"+data.responseType);
      this.dataservice.setOption(data);
      this.dataservice.setRole(this.loginService.getData().roleId);
 
      if(data.Reviewer && (data.Reviewer.toLowerCase()===(this.loginService.getData().uid).toLowerCase()))
      {
        this.dataservice.setRole('Reviewer');
       
      }
      else if(data.Officer && (data.Officer.toLowerCase()===(this.loginService.getData().uid).toLowerCase()))
      {
        this.dataservice.setRole('LEADACO');
       
      }
      
      localStorage.setItem("selectedCase",JSON.stringify(data));
      localStorage.setItem("selectedRole",this.dataservice.getRole());
      

     
  }


  ngAfterViewInit() {

    this.run();

    //this.mdbTablePagination.calculateFirstItemIndex();
    //this.mdbTablePagination.calculateLastItemIndex();


    this.ref.detectChanges();
  }
}






