import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './AppConfigService';

const localUrl = '/visit/all';
const putUrl = '/visit/update';
@Injectable({
  providedIn: 'root'
})
export class VisitService {
  baseUrl :any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  
  getVisits(caseId: string) {
    return this.http.get(this.baseUrl+localUrl+'?caseId='+caseId);
  }
 updateVisits(visit:any) {

   var jsonObject={visitId:visit.visitId, notReviewedFlag: visit.visitNotDone,visitstatus:visit.visitstatus,endDt:visit.endDt,startDt:visit.startDt,location:visit.location,officerComments:visit.officerComments,reviewerComments:visit.reviewerComments,approverComments:visit.approverComments};
    return this.http.put(this.baseUrl+putUrl,jsonObject);
  }
}
