
<div class="dark-font bc-icons-2" mt-2>
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" [queryParams]="{ role:role}"  mdbWavesEffect  >
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> <span class="headertexts">Home</span>
            </a>
            
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" [queryParams]="{ role:role}"  class="headertexts" mdbWavesEffect  >
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Visit</span>
            </a>
           
        </mdb-breadcrumb-item>
         <mdb-breadcrumb-item>
            <a routerLink="/details/pages/detail1/fdc" routerLinkActive="active" [queryParams]="{ role:role}"  class="font-weight-bold text-black headertexts" mdbWavesEffect  >
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> FDC
            </a>
            
          
        </mdb-breadcrumb-item>
    </mdb-breadcrumb>
</div>


    <mdb-card class="mb-3">
        <mdb-card-header class="p-0 cardheadercolor">
            <table mdbTable class="table-hover table-condensed mb-0">
                <tbody class="p-0">
                    <tr class="p-0">
                        <td class="cardheader py-1 font-weight-bold">Plan for your Visit</td>
                        <td class="text-right p-0">
                            <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="test.toggle()"></mdb-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
    
        </mdb-card-header>
        <mdb-card-body>
            <div mdbCollapse [isCollapsed]="false" #test="bs-collapse">
                <mdb-card-title>
                    <span class="font-weight-bolder">VISIT NUMBER :</span> VISIT-{{passeddata.VisitNumber}} | <span
                        class="font-weight-bolder">VISIT STATUS </span>: {{passeddata.VisitStatus}}
                </mdb-card-title>
    
    
                <!--Table-->
                <table mdbTable bordered="true" class="table-condensed">
    
                    <!--Table head-->
                    <!--Table body-->
                    <tbody class="doe-table-body">
    
                        <tr>
                            <th scope="row">Case Number:</th>
                            <td>{{passeddata.CaseId}}</td>
                            <th scope="row">Service Name:</th>
                            <td>{{passeddata.ServiceName}}</td>
                            <th scope="row">Delegated Person:</th>
                            <td>person1</td>
                            <th scope="row">Service Address:</th>
                            <td>382 Kissing Point Road, ERMINGTON,NSW-2115</td>
    
                        </tr>
                        <tr>
                            <th scope="row">Nominated Supervisor:</th>
                            <td>Gertie Goldfish</td>
                            <th scope="row">Approved Provider:</th>
                            <td>Henrietta Hedgehog </td>
    
                            <th scope="row">Educational Leader:</th>
                            <td>leadername1</td>
    
                            <th scope="row">Hub Manager:</th>
                            <td>SANJEEV.BHAKRI</td>
                        </tr>
                        <tr>
                            <th scope="row" class="py-2">Officer:</th>
                            <td>
                                <label *ngFor="let v of assignedACOs">
                                    <div class="chip">
                                        {{v}},
    
                                        <mdb-icon fas icon="times" class="mr-3" type="button" (click)="deleteACO(v)" *ngIf="role=='admin'">
                                        </mdb-icon>
                                    </div>
                                </label>
    
                                <button type="button" mdbBtn size="sm" *ngIf="role=='admin'" 
                                    class="relative waves-light backcolor rounded text-white font-weight-bold "
                                    (click)="basicModal.show()" mdbWavesEffect>Add Officers</button>
    
    
    
    
                                <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
                                    aria-labelledby="myBasicModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="close pull-right" aria-label="Close"
                                                    (click)="basicModal.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <h4 class="modal-title w-100" id="myModalLabel">Add more Officers</h4>
                                            </div>
                                            <div class="modal-body">
                                                <form class="p-0">
                                                    <!-- Subject -->
                                                    <table mdbTable class="p-0">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <select [(ngModel)]="selectedValue"
                                                                        class="browser-default custom-select mb-4"
                                                                        name="acolist">
                                                                        <option *ngFor="let c of ACOs" [ngValue]="c">
                                                                            {{c.name}}</option>
                                                                    </select>
    
                                                                </td>
                                                                <td>
    
                                                                    <!-- add button -->
                                                                    <button mdbBtn
                                                                        class="relative waves-light backcolor rounded text-white font-weight-bold"
                                                                        size="sm" type="submit"
                                                                        (click)="basicModal.hide()">Add</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </td>
    
                        </tr>
    
                    </tbody>
                    <!--Table body-->
                </table>
            </div>
    
            <!--Table-->
        </mdb-card-body>
    </mdb-card>

<button mdbBtn class="backcolor rounded text-white font-weight-bold " size="sm" type="submit" (click)="addLocations()" >Add more locations
</button>     


  <mdb-card class="mb-4" [ngClass]="{'d-none': showLocation}"  class="col-md-10">
        <mdb-card-header class="p-0">
            <table mdbTable >
                <tbody>
    
                    <tr class="p-0">
                        <td class="black-text font-weight-bold py-0 px-0"> Add Locations for Visit</td>
                        <td class="text-right">
                            <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="locations.toggle()"></mdb-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
    
        </mdb-card-header>
        <mdb-card-body >
            <div mdbCollapse [isCollapsed]="false" #locations="bs-collapse">
            <!-- Form -->
            <form class="text-center" style="color: #757575;">
        
              <!-- Email -->
              <div class="md-form">
                <input type="text" #locationName id="locationName" class="form-control" mdbInput>
                <label for="locationName">Location Name</label>
              </div>
        
              <!-- Password -->
              <div class="md-form">
                <input type="text" #locationAddress id="locationAddress" class="form-control" mdbInput>
                <label for="locationAddress">Location Address</label>
              </div>
              
                <button mdbBtn class="primary-color-dark rounded text-white font-weight-bold " size="sm" type="submit" (click)="saveLocation(locationName.value,locationAddress.value)">Save Location
                </button> 
              
        
            </form>
            <!-- Form -->
        </div>
          </mdb-card-body>
    </mdb-card>

    <mdb-card class="p-0">
        <mdb-card-header class="p-0 cardheadercolor">
            <table mdbTable class="p-0 mb-0 ">
                <tbody class="p-0">
    
                    <tr class="p-0 ">
                        <td class="headertexts py-1 "> Add more laws and Regulations</td>
                        <td class="text-right p-0 ">
                            <mdb-icon fas icon="caret-down" class="mr-3" type="button"
                                (click)="locationstab.toggle()">
                            </mdb-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
    
        </mdb-card-header>
        <mdb-card-body class="p-0">

    <div mdbCollapse [isCollapsed]="false" #locationstab="bs-collapse" >
        <table mdbTable id="locationstable" hover="true" class="z-depth-1 p-0" >
            <thead>
            <tr class="headertexts">
              <th *ngFor="let head of headElements; let i = index" aria-controls="locationstable" [mdbTableSort]="locationList" [sortBy]="headElements[i]"
                  scope="col">{{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
              </th>
            </tr>
            </thead>
            <tbody>
                <tr mdbTableCol *ngFor="let el of locationList; let i = index" routerLink="../centerbased" [queryParams]="{ role:role}" routerLinkActive="active" class="doe-table-body">
                    <th  scope="row">{{el.id}}</th>
                    <td>{{el.name}}</td>
                    <td>{{el.address}}</td>
                    <td>
                        {{passeddata.Officer}}
                    
                    </td>
                  </tr>
            </tbody>
            
          </table>
   
    </div>
  
</mdb-card-body>
</mdb-card>

  <!-- Editable table -->
    


    

                    



