import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/service/loginData.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';


@Component({
  selector: 'app-manageUserDetails',
  templateUrl: './manageUserDetails.component.html',
  styleUrls: ['./manageUserDetails.component.scss']

})
export class ManageUserDetailsComponent implements OnInit {
 
  lawregIndicator:any="Choose Law/Reg";
  lawregNumber:any;
  lawregDesc:any;
  priority:any="Choose Priority";

  headElementsUsersSort = ['userFirstName', 'userLastName', 'status','intUserId','nqaUserId',
  'roleId','region','email','title'];
  headElementsUsers = ['First Name', 'Last Name', 'Status', 'INT User Id','NQA User Id',
  'Role','Region','Email','Title'];
  user:any;

  ngOnInit() {
    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId'),
       uid:localStorage.getItem('uid'),
       //roleId: localStorage.getItem('roleId')
 
     });
 
   

    }
    
    this.user=localStorage.getItem('firstName')+ ' '+localStorage.getItem('surname');
    this.getListofUsers();

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: (this.usersList==undefined || this.usersList==null)?0:this.usersList.length  }; 
 
   
  }
  changeCaseStatus(event:any)
  {
    event.stopPropagation();
  }
 clickcreate:boolean=false;
 clickedit:boolean=false;
 createObj:any={};
  onAddUser()
  {
    this.createObj.createdBy=this.user;
    this.userDetailsService.addUser(this.createObj).subscribe(response=>{
     console.log(response);
      this.showSuccess("User Created Successfully");
      this.usersList=[];
      this.getListofUsers();
   },err=>{
     console.log(err);
    
     this.showError("User Creation Failed");
   })
    
  }

  usersList:any[]=[];
  config:any;
  getListofUsers() {


    this.userDetailsService.getAllUsers()
      .subscribe(data => {

        for (const d of (data as any)) {

          this.usersList.push({
            userId:d.userId,
            userFirstName:d.userFirstName,
            userLastName:d.userLastName,
            status:d.status,
            intUserId:d.intUserId,
            createdBy:d.createdBy,
            createdDate:(d.createdDate==null || d.createdDate=='')?new Date():d.createdDate,
            updatedBy:d.updatedBy,
            updatedDate:(d.updatedDate==null || d.updatedDate=='')?new Date():d.updatedDate,
            roleId:d.roleId,
            roleName:(d.roleId!==null && d.roleId!==undefined && d.roleId.toUpperCase()=='ADMIN')?'ADMIN':(d.roleId!==null && d.roleId!==undefined && d.roleId.toUpperCase()=='ACO')?'OFFICER'
            :(d.roleId!==null && d.roleId!==undefined && d.roleId.toUpperCase()=='ROM')?'MANAGER':(d.roleId!==null && d.roleId!==undefined && d.roleId.toUpperCase()=='READ_ONLY')?'READONLY':'',
            assessorId:d.assessorId,
            region:d.region,
            email:d.email,
            title:d.title,
            nqaUserId:d.nqaUserId
          }
         
          );
         
      }
      this.backupList = [...this.usersList];
     // alert(this.usersList.length);
   // this.usersList = this.usersList.sort((a,b) => (a.createdDate > b.createdDate) ? 1 : ((b.createdDate > a.createdDate) ? -1 : 0)); 
    this.ref.detectChanges();
    //  alert(this.listOfLawRegs.length);
    });

    
}
selectedObj:any={};
setselected(user:any)
{
 
 this.selectedObj=user;
  
}

pageChanged(event : any){
  this.config.currentPage = event;
}

onSelectRegs(lawId:any)
{
 console.log(lawId);
 

  
}
onEditUser()
{
  if(this.selectedObj)
  {
  this.selectedObj.updatedBy=this.user;
    this.userDetailsService.updateUser(this.selectedObj).subscribe(response=>{
     console.log(response);
      this.showSuccess("User updated Successfully");
      this.usersList=[];
      this.getListofUsers();
   },err=>{
     console.log(err);
     this.showError("User updation Failed");
   })
  }else{
    this.showSuccess("please select an user to continue")
  }
 
}

validateEdit()
{
 
  
  if(this.selectedObj!==null && this.selectedObj!==undefined && this.selectedObj.userId!==undefined)
  {
  this.clickcreate=false;
  this.clickedit=true;
  }
  else{
    this.showError("select a User to continue");
  }
  
}

backupList:any[]=[];
onFilter(searchValue:string,fieldName:string) {
    
  console.log(searchValue+fieldName);


}
  onDeleteUser()
  {
    if(this.selectedObj)
    {
    
      this.userDetailsService.deleteUser(this.selectedObj.userId).subscribe(response=>{
       console.log(response);
        this.showSuccess("User deleted Successfully");
        this.usersList=[];
        this.getListofUsers();
     },err=>{
       console.log(err);
       this.showError("User deletion Failed");
     })
    }else{
      this.showSuccess("please select an user to continue")
    }
 
  }

  showSuccess(message:string)
  {
   
    this.toastr.success(message, 'Success!',
    {timeOut: 2000});;
  }
  showError(message:string)
  {

    this.toastr.error(message, 'Error!',
    {timeOut: 2000});;
  }

  

  showInfo(message:string)
  {

    this.toastr.info(message, 'Info!',
    {timeOut: 2000});;
  }

  constructor(private ref: ChangeDetectorRef,private userDetailsService:UserDetailsService,private toastr:ToastrService,private loginDataService: LoginDataService) {}


}
