<div class="dark-font bc-icons-2" mt-2>
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" [queryParams]="{ role:role}" mdbWavesEffect>
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> <span class="headertexts">Home</span>
            </a>

        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" [queryParams]="{ role:role}"
                class="headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Visit</span>
            </a>

        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item>
            <a routerLink="/details/pages/detail1/centerbased" routerLinkActive="active" [queryParams]="{ role:role}"
                class="font-weight-bold text-black headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> Center Based
            </a>


        </mdb-breadcrumb-item>
    </mdb-breadcrumb>
</div>




<mdb-card class="mb-3">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold">Plan for your Visit</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="test.toggle()"></mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body>
        <div mdbCollapse [isCollapsed]="false" #test="bs-collapse">
            <mdb-card-title>
                <span class="font-weight-bolder">VISIT NUMBER :</span> VISIT-{{passeddata.VisitNumber}} | <span
                    class="font-weight-bolder">VISIT STATUS </span>: {{passeddata.VisitStatus}}
            </mdb-card-title>


            <!--Table-->
            <table mdbTable bordered="true" class="table-condensed">

                <!--Table head-->
                <!--Table body-->
                <tbody class="doe-table-body">

                    <tr>
                        <th scope="row">Case Number:</th>
                        <td>{{passeddata.CaseId}}</td>
                        <th scope="row">Service Name:</th>
                        <td>{{passeddata.ServiceName}}</td>
                        <th scope="row">Delegated Person:</th>
                        <td>person1</td>
                        <th scope="row">Service Address:</th>
                        <td>382 Kissing Point Road, ERMINGTON,NSW-2115</td>

                    </tr>
                    <tr>
                        <th scope="row">Nominated Supervisor:</th>
                        <td>Gertie Goldfish</td>
                        <th scope="row">Approved Provider:</th>
                        <td>Henrietta Hedgehog </td>

                        <th scope="row">Educational Leader:</th>
                        <td>leadername1</td>

                        <th scope="row">Hub Manager:</th>
                        <td>SANJEEV.BHAKRI</td>
                    </tr>
                    <tr>
                        <th scope="row" class="py-2">Officer:</th>
                        <td>
                            <label *ngFor="let v of assignedACOs">
                                <div class="chip">
                                    {{v}},

                                    <mdb-icon fas icon="times" class="mr-3" type="button" (click)="deleteACO(v)"
                                        *ngIf="role=='admin' ||  role=='caseOfficer'">
                                    </mdb-icon>
                                </div>
                            </label>

                         
                           
                        </td>

                    </tr>

                </tbody>
                <!--Table body-->
            </table>
        </div>

        <!--Table-->
    </mdb-card-body>
</mdb-card>


<button mdbBtn class="backcolor rounded text-white font-weight-bold " size="sm" type="submit"
    (click)="addLocations()">Add more locations
</button>


<mdb-card class="mb-4" [ngClass]="{'d-none': showLocation}" class="col-md-10">
    <mdb-card-header class="p-0">
        <table mdbTable>
            <tbody>

                <tr class="p-0">
                    <td class="black-text font-weight-bold py-0 px-0"> Add Locations for Visit</td>
                    <td class="text-right">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="locations.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body>
        <div mdbCollapse [isCollapsed]="false" #locations="bs-collapse">
            <!-- Form -->
            <form class="text-center" style="color: #757575;">

                <!-- Email -->
                <div class="md-form">
                    <input type="text" #locationName id="locationName" class="form-control" mdbInput>
                    <label for="locationName">Educator Name</label>
                </div>

                <!-- Password -->
                <div class="md-form">
                    <input type="text" #locationAddress id="locationAddress" class="form-control" mdbInput>
                    <label for="locationAddress">Location Address</label>
                </div>

                <div>
                      <select  [(ngModel)]="selectedValue" class="browser-default custom-select" name="acolist">
                     <option selected>Choose Officer</option>
                     <option *ngFor="let c of ACOs" [ngValue]="c">
                        {{c.name}}</option>
                 </select>
                   
                </div>


          
                <button mdbBtn class="primary-color-dark backcolor rounded text-white font-weight-bold " size="sm" type="submit"
                  >Save Location
                </button>


            </form>
            <!-- Form -->
        </div>
    </mdb-card-body>
</mdb-card>


<ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
    <li class="nav-item" *ngFor="let loc of locationList">
        <a id="home-tab{{loc.name}}" class="nav-link headertexts rounded-pill" [ngClass]="{'active border-dark border-bottom-0':(loc.selected==true)}" type="button" data-toggle="tab" (click)="tabclick(loc);opentab(loc)" role="tab" aria-controls="home{{loc.name}}"
            aria-selected="true">{{loc.name}}</a>
    </li>

</ul>
<div class="tab-content" id="myTabContent">
       <div class="tab-pane fade show active" id="home{{selectedtab}}" role="tabpanel" aria-labelledby="home-tab{{selectedtab}}">
        <mdb-card >
            <mdb-card-body class="px-3 py-3">
           <div  *ngFor="let loc of locationList" >

            <mdb-card class="p-0" [ngClass]="{'d-none': (passeddata.VisitStatus=='completed' || loc.showtab == false)}">
                <mdb-card-header class="p-0 cardheadercolor">
                    <table mdbTable class="table-hover table-condensed mb-0">
                        <tbody class="p-0">
                            <tr class="p-0">
                                <td class="cardheader py-1 font-weight-bold ">Location Details</td>
                            </tr>
                        </tbody>
                    </table>
            
                </mdb-card-header>
            <mdb-card-body >
             <table mdbTable bordered="true" class="table-condensed">

                <!--Table head-->
                <!--Table body-->
                <tbody class="doe-table-body">

                    <tr>
                        <th scope="row">Location Name:</th>
                        <td>{{loc.name}}</td>
                    </tr> <tr>
                        <th scope="row">Service Address:</th>
                        <td>{{loc.address}}</td>
                    </tr><tr>
                        <th scope="row">Officer:</th>
                        <td >
                            <span  *ngFor="let officer of loc.officer" >{{officer}}  <mdb-icon fas icon="times" class="mr-3" type="button" (click)="deleteACO(loc,officer)">
                        </mdb-icon> </span>
                    
                        <button type="button" mdbBtn size="sm" 
                                class="relative waves-light backcolor rounded text-white font-weight-bold "
                                (click)="basicModal.show(loc)" mdbWavesEffect>Add Officers</button>
                        <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
                                aria-labelledby="myBasicModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close pull-right" aria-label="Close"
                                                (click)="basicModal.hide()">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <h4 class="modal-title w-100" id="myModalLabel">Add more Officers</h4>
                                        </div>
                                        <div class="modal-body">
                                            <div class="md-form">
                                                <!-- Subject -->
                                                <table mdbTable class="p-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                               
                                                                <select [(ngModel)]="selectedValue"
                                                                    class="form-control browser-default custom-select mb-4"
                                                                    name="acolist">
                                                                    <option *ngFor="let c of ACOs" [ngValue]="c">
                                                                        {{c.name}}</option>
                                                                </select>
                                                             

                                                            </td>
                                                            <td>

                                                                <!-- add button -->
                                                                <button mdbBtn
                                                                    class="relative waves-light backcolor rounded text-white font-weight-bold"
                                                                    size="sm" type="submit"
                                                                    (click)="basicModal.hide()">Add</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                    </td>
                       
                    </tr>
                </tbody>
             </table>
             </mdb-card-body>
            </mdb-card>

            <mdb-card class="p-0 mt-2" [ngClass]="{'d-none': (passeddata.VisitStatus=='completed' || loc.showtab == false)}">
                <mdb-card-header class="p-0 cardheadercolor">
                    <table mdbTable class="p-0 mb-0 backcolor">
                        <tbody class="p-0">

                            <tr class="p-0 text-white">
                                <td class="headertexts py-1 "> Add more laws and Regulations</td>
                                <td class="text-right p-0 text-white">
                                    <mdb-icon fas icon="caret-down" class="mr-3" type="button"
                                        (click)="morelaws.toggle();sublaws.toggle()">
                                    </mdb-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mdb-card-header>
                <mdb-card-body class="py-0">

                    <div mdbCollapse [isCollapsed]="true" #morelaws="bs-collapse">
                        <div class="md-form mb-0">
                            <input type="search" class="form-control mt-0 mb-0" id="input1" [(ngModel)]="searchValue"
                                mdbInput (search)="onseraching(searchValue);sublaws.toggle()"
                                (keydown)="onseraching(searchValue)">
                            <label class="p-0" for="input1">Search</label>
                        </div>
                        <!-- Default unchecked -->
                        <ul class="list-group list-group-flush p-0 " *ngFor="let laws of sortedLawRegList">
                            <li class="list-group-item headertexts cursor" (click)="sublaws.toggle()">
                                <mdb-icon fas icon="flag" class="mr-3 red-text" *ngIf="laws.priority=='high'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 green-text" *ngIf="laws.priority=='medium'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 blue-text" *ngIf="laws.priority=='low'">
                                </mdb-icon>

                                {{laws.lawid}}:{{laws.description}}
                            </li>
                            <div mdbCollapse [isCollapsed]="true" #sublaws="bs-collapse" class="px-4">

                                <ul class="list-group px-4" *ngFor="let sublaw of getsublaws(laws.id)">
                                    <li class="list-group-item">
                                        <mdb-checkbox [default]="false" (change)="addMoreLawsandRegs(sublaw)">
                                            {{sublaw.lawid}}:{{sublaw.description}}</mdb-checkbox>
                                    </li>
                                </ul>
                            </div>



                        </ul>



                    </div>

                </mdb-card-body>
            </mdb-card>
           


            <mdb-card class="mb-3 mt-3" [ngClass]="{'d-none': passeddata.VisitStatus=='completed'  || loc.showtab == false}">
                <mdb-card-header class="p-0 cardheadercolor">
                    <table mdbTable class="table-hover table-condensed mb-0">
                        <tbody class="p-0">
                            <tr class="p-0">
                                <td class="cardheader py-1 font-weight-bold ">Law and Regulations</td>
                                <td class="text-right p-0">
                                    <mdb-icon fas icon="caret-down" class="mr-3" type="button"
                                        (click)="lawreg.toggle()">
                                    </mdb-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mdb-card-header>
                <mdb-card-body class="p-0">
                    <div mdbCollapse [isCollapsed]="false" #lawreg="bs-collapse">


                        <form class="p-0">
                            <!-- Subject -->
                            <table mdbTable id="lawregTable" bordered="true"
                                class=".table-sm .table-fixed .table-hover doe-table-body">

                                <!--Table head-->
                                <thead class="lawReg-table-header">
                                    <tr>
                                        <th *ngFor="let head of headElementsLawReg; let i = index"
                                            aria-controls="lawregTable" [mdbTableSort]="lawRegList"
                                            [sortBy]="headElementsLawReg[i]" scope="col">
                                            {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                                        </th>
                                    </tr>

                                </thead>
                                <!--Table body-->
                                <tbody *ngFor="let item of lawRegList">

                                    <!-- <tr >
                            <th scope="row">Law/Regulations</th>
                            <th scope="row">Description</th>
                            <th scope="row">Compliance</th>
                            
                      
                           
                        </tr>      -->

                                    <tr>
                                        <td class="w-20%">{{item.inaid}}: </td>
                                        <td class="w-20%">{{item.lawid}}: </td>

                                        <td class="w-5%">
                                            <mdb-icon fas icon="flag" class="mr-3 red-text"
                                                *ngIf="item.priority=='high'">
                                            </mdb-icon>
                                            <mdb-icon fas icon="flag" class="mr-3 green-text"
                                                *ngIf="item.priority=='medium'">
                                            </mdb-icon>
                                            <mdb-icon fas icon="flag" class="mr-3 blue-text"
                                                *ngIf="item.priority=='low'">
                                            </mdb-icon>

                                        </td>

                                        <td class="w-50%">{{item.description}}</td>
                                        <td class="w-25%">
                                            <select class="browser-default custom-select" name="compStatus1"
                                                (change)="updatestatus(item,$event.target.value)"
                                                *ngIf="role!='caseOfficer' ">
                                                <option selected>Choose Compliance status</option>
                                                <option *ngFor="let comp of statusCompliance" [ngValue]="comp">{{comp}}
                                                </option>
                                            </select>
                                            <!-- <a class="trigger" (click)="askEvidence(item)"><span class="white rounded text-dark font-weight-bold border-color mb-2 ">Add Evidence </span> <i class="fas fa-plus ml-1"></i></a> -->
                                            <div *ngIf="role=='caseOfficer'">{{item.complianceStatus}} </div>
                                            <button type="button" mdbBtn size="sm" *ngIf="role!='caseOfficer'"
                                                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                                                (click)="askEvidence(item)" mdbWavesEffect>Add Evidence </button>
                                            <button type="button" mdbBtn size="sm" *ngIf="role=='caseOfficer'"
                                                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                                                (click)="showEvidence(item)" mdbWavesEffect>Show Evidence </button>


                                        </td>


                                    </tr>

                                    <tr [ngClass]="{'d-none': item.evidenceFlag}">
                                        <td colspan="5">
                                            <mdb-card class="mb-4">
                                                <mdb-card-header class="p-0">
                                                    <table mdbTable class="mb-0">
                                                        <tbody>

                                                            <tr class="p-0">
                                                                <td class="headertexts">Evidence for non compliance</td>
                                                                <td class="text-right">
                                                                    <mdb-icon fas icon="caret-down" class="mr-3"
                                                                        type="button" (click)="evidence.toggle()">
                                                                    </mdb-icon>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </mdb-card-header>
                                                <mdb-card-body class="p-0">
                                                    <div mdbCollapse [isCollapsed]="false" #evidence="bs-collapse">


                                                        <form class="p-0">
                                                            <table mdbTable class="p-0" bordered="false">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="md-form">
                                                                                <mdb-icon fas icon="pencil-alt"
                                                                                    class="prefix">
                                                                                </mdb-icon>
                                                                                <textarea type="text" id="form8"
                                                                                    class="md-textarea form-control"
                                                                                    mdbInput></textarea>
                                                                                <label
                                                                                    for="form8">Evidence/Comments</label>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="custom-file text-center ">

                                                                                <div class="input-group ">

                                                                                    <div class="custom-file mt-5">
                                                                                        <input type="file"
                                                                                            class="custom-file-input "
                                                                                            accept=".jpg,.gif,.png"
                                                                                            id="inputGroupFile01"
                                                                                            multiple
                                                                                            (change)="getFiles($event.target.value)">
                                                                                        <label class="custom-file-label"
                                                                                            for="inputGroupFile01">Choose
                                                                                            file</label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div>
                                                                                <label class="mt-4">( file can be of
                                                                                    type
                                                                                    .jpg,.gif,.png
                                                                                    only)</label>
                                                                            </div>
                                                                            <div>
                                                                                <ul class="list-group list-group-flush p-0 "
                                                                                    *ngFor="let name of filenames; let i = index">
                                                                                    <li class="list-group-item">
                                                                                        {{name}}
                                                                                    </li>
                                                                                </ul>
                                                                                <label class="mt-4">
                                                                                    {{name}}
                                                                                </label>
                                                                            </div>

                                                                            <div class="md-form">
                                                                                <button type="button" mdbBtn size="sm"
                                                                                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                                                                                    mdbWavesEffect>Save</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>
                                                        </form>
                                                        <!-- Default form contact -->
                                                    </div>
                                                </mdb-card-body>
                                            </mdb-card>
                                        </td>
                                    </tr>
                                    <tr [ngClass]="{'d-none': item.showEvidenceFlag}">
                                        <td colspan="4">
                                            <mdb-card class="mb-4">
                                                <mdb-card-header class="p-0">
                                                    <table mdbTable class="mb-0">
                                                        <tbody>

                                                            <tr class="p-0">
                                                                <td class="headertexts">Evidence for non compliance</td>
                                                                <td class="text-right">
                                                                    <mdb-icon fas icon="caret-down" class="mr-3"
                                                                        type="button" (click)="evidenceList.toggle()">
                                                                    </mdb-icon>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </mdb-card-header>
                                                <mdb-card-body class="p-0">
                                                    <div mdbCollapse [isCollapsed]="false" #evidenceList="bs-collapse">
                                                        <form class="p-0">

                                                            <table mdbTable id="EvidenceTable" bordered="true"
                                                                class=".table-sm .table-fixed .table-hover doe-table-body">

                                                                <!--Table head-->
                                                                <thead class="Evidence-table-header">
                                                                    <tr>
                                                                        <th *ngFor="let head of headEvidenceList; let i = index"
                                                                            aria-controls="EvidenceTable"
                                                                            [mdbTableSort]="EvidenceList"
                                                                            [sortBy]="headEvidenceList[i]" scope="col">
                                                                            {{head | titlecase}} <mdb-icon fas
                                                                                icon="sort">
                                                                            </mdb-icon>
                                                                        </th>
                                                                    </tr>

                                                                </thead>
                                                                <!--Table body-->
                                                                <tbody *ngFor="let item of EvidenceList">



                                                                    <tr>
                                                                        <td class="w-20%">{{item.locationName}} </td>

                                                                        <td class="w-20%">{{item.officer}} </td>
                                                                        <td class="w-60%">{{item.description}}</td>



                                                                    </tr>



                                                                </tbody>
                                                            </table>
                                                        </form>


                                                    </div>
                                                </mdb-card-body>
                                            </mdb-card>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </form>
                    </div>
                </mdb-card-body>
            </mdb-card>
            </div>
            </mdb-card-body>
        </mdb-card>
        </div>
   
</div>


<mdb-card class="mb-3 mt-2" [ngClass]="{'d-none': passeddata.VisitStatus!='completed'}">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Summary Report</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="summary.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="p-0">
        <div mdbCollapse [isCollapsed]="false" #summary="bs-collapse">


            <form class="p-0">
                <!-- Subject -->
                <table mdbTable mdbTableScroll scrollX="true" id="summaryTable" bordered="true"
                    class=".table-sm .table-fixed .table-hover w-auto doe-table-body">

                    <!--Table head-->
                    <thead class="lawReg-table-header">
                        <tr>
                            <th *ngFor="let head of headElementsSummary; let i = index" aria-controls="summaryTable"
                                [mdbTableSort]="lawRegList" [sortBy]="headElementsLawReg[i]" scope="col">
                                {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                            </th>
                        </tr>

                    </thead>
                    <!--Table body-->
                    <tbody *ngFor="let item of lawRegList">



                        <tr>
                            <td>{{item.locationName}} </td>
                            <td>{{item.officer}} </td>
                            <td>{{item.inaid}} </td>
                            <td>{{item.lawid}}</td>

                            <td>
                                <mdb-icon fas icon="flag" class="mr-3 red-text" *ngIf="item.priority=='high'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 green-text" *ngIf="item.priority=='medium'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 blue-text" *ngIf="item.priority=='low'">
                                </mdb-icon>

                            </td>

                            <td>{{item.description}}</td>
                            <td>
                                <div>{{item.complianceStatus}}</div>


                            </td>

                            <td>{{item.evidence}}</td>
                            <td>{{item.attachment}}</td>


                        </tr>



                    </tbody>
                </table>
            </form>
        </div>
    </mdb-card-body>
</mdb-card>



<mdb-card class="mb-4 mt-4">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Visit Outcome</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="finalize.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="doe-table-body">
        <div mdbCollapse [isCollapsed]="false" #finalize="bs-collapse">
            <!-- Form -->
            <form class="">
                <div class="row d-flex px-3">
                    <div class="col-md-3 headertexts">
                        <span class="font-weight-bolder">VISIT NUMBER :</span> VISIT-{{passeddata.VisitNumber}}
                    </div>

                    <div class="col-md-3 headertexts" *ngIf="role=='manager'">
                        <span class="font-weight-bolder">Final Outcome :</span> Further Investigation
                    </div>



                </div>
                <div class="row d-flex mt-2">
                    <div class="md-form px-3">
                        <mdb-icon fas icon="calendar-alt" size="sm" class="prefix"></mdb-icon><input mdbInput
                            type="date" id="form1" class="form-control">
                        <label for="form1" class="">Visit Start Date:</label>
                    </div>
                    <div class="md-form">
                        <mdb-icon fas icon="calendar-alt" size="sm" class="prefix"></mdb-icon><input mdbInput
                            type="date" id="form1" class="form-control" (change)="updateSatus($event.target.value)">
                        <label for="form1" class="">Visit End Date:</label>
                    </div>
                    <div class="col-md-4 py-4">
                        <select class="browser-default custom-select headertexts" name="outcomeStatus">
                            <option selected>Choose Final Outcome</option>
                            <option *ngFor="let status of outcomeStatus" [ngValue]="status">{{status}}</option>
                        </select>
                    </div>

                </div>

                <div class="md-form" *ngIf="role!='manager'">
                    <mdb-icon fas icon="pencil-alt" class="prefix"></mdb-icon>
                    <textarea type="text" id="form8" class="md-textarea form-control" mdbInput></textarea>
                    <label for="form8">Officer's Comments</label>
                </div>
                <div class="form-group shadow-textarea" *ngIf="role=='manager'">
                    <label for="reviewerCommentsReadonly" class="headertexts">Officers Comments</label>
                    <textarea class="form-control z-depth-1" id="reviewerCommentsReadonly" rows="3" readonly
                        placeholder={{reviewerComments}}></textarea>
                </div>

                <!-- reviewer comments -->
                <div class="md-form" *ngIf="role!='manager'">
                    <mdb-icon fas icon="pencil-alt" class="prefix"></mdb-icon>
                    <textarea type="text" id="form8" class="md-textarea form-control" mdbInput></textarea>
                    <label for="form8">Reviewer Comments</label>
                </div>

                <div class="form-group shadow-textarea" *ngIf="role=='manager'">
                    <label for="reviewerCommentsReadonly" class="headertexts">Reviewer Comments</label>
                    <textarea class="form-control z-depth-1" id="reviewerCommentsReadonly" rows="3" readonly
                        placeholder={{reviewerComments}}></textarea>
                </div>

                <!-- Approver Comments -->
                <div class="md-form" *ngIf="(role=='admin' || role=='manager')">
                    <mdb-icon fas icon="pencil-alt" class="prefix"></mdb-icon>
                    <textarea type="text" id="form8" class="md-textarea form-control" mdbInput></textarea>
                    <label for="form8">Approver Comments</label>
                </div>

                <div class="form-group shadow-textarea" *ngIf="!(role=='admin' || role=='manager')">
                    <label for="approverCommentsReadonly" class="headertexts">Approver Comments</label>
                    <textarea class="form-control z-depth-1" id="approverCommentsReadonly" rows="3" readonly
                        placeholder={{approverComments}}></textarea>
                </div>

                <button mdbBtn class=" backcolor rounded text-white font-weight-bold "
                    *ngIf="(role=='admin' || role=='manager')" size="sm" type="submit">Finalize
                </button>

                <button mdbBtn class="backcolor rounded text-white font-weight-bold "
                    *ngIf="(role=='admin' || role=='manager')" size="sm" type="submit" (click)="emailModal.show()">Send
                    email
                </button>

                <button mdbBtn class="backcolor rounded text-white font-weight-bold "
                    *ngIf="(role=='admin' || role=='manager')" size="sm" type="submit">Generate Report
                </button>



            </form>
            <!-- Form -->
        </div>
    </mdb-card-body>
</mdb-card>






<div mdbModal #emailModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="emailModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2>
            </div>
            <div class="modal-body">

                <!--Section: Contact v.2-->
                <section class="mb-4">

                    <!--Section heading-->

                    <p class="text-center w-responsive mx-auto mb-3">Non Compliance email</p>

                    <div class="row">

                        <!--Grid column-->
                        <div class="col-md-9 mb-5">
                            <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                                <!--Grid row-->
                                <div class="row">

                                    <!--Grid column-->
                                    <div class="col-md-6">
                                        <div class="md-form mb-0">
                                            <input type="text" id="name" name="name" class="form-control">
                                            <label for="name" class="">{{passeddata.Approver}}</label>
                                        </div>
                                    </div>
                                    <!--Grid column-->

                                    <!--Grid column-->
                                    <div class="col-md-6">
                                        <div class="md-form mb-0">
                                            <input type="text" id="email" name="email" class="form-control">
                                            <label for="email" class="">{{passeddata.Approver}}@det.nsw.edu.au</label>
                                        </div>
                                    </div>
                                    <!--Grid column-->

                                </div>
                                <!--Grid row-->

                                <!--Grid row-->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="md-form mb-0">
                                            <input type="text" id="subject" name="subject" class="form-control">
                                            <label for="subject" class="">Subject</label>
                                        </div>
                                    </div>
                                </div>
                                <!--Grid row-->

                                <!--Grid row-->
                                <div class="row">

                                    <!--Grid column-->
                                    <div class="col-md-12">

                                        <div class="md-form">
                                            <textarea type="text" id="message" name="message" rows="2"
                                                class="form-control md-textarea"></textarea>
                                            <label for="message">Your message</label>
                                        </div>

                                    </div>
                                </div>
                                <!--Grid row-->

                            </form>

                            <div class="text-center text-md-left">
                                <a class="btn btn-primary"
                                    onclick="document.getElementById('contact-form').submit();">Send</a>

                            </div>
                            <div class="status"></div>
                        </div>
                        <!--Grid column-->


                    </div>

                </section>
                <!--Section: Contact v.2-->

            </div>

        </div>
    </div>
</div>