<!--<div class="dark-font bc-icons-2" mt-2 >
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" mdbWavesEffect>
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> <span class="headertexts">Home</span>
            </a>

        </mdb-breadcrumb-item>-->
    <!--   <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" 
                class="headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Case</span>
            </a>

        </mdb-breadcrumb-item> --> 

   <!-- </mdb-breadcrumb>
</div> -->
<fieldset class="scheduler-border" *ngIf="accessList.indexOf('caseDownload')>-1">
    <legend class="scheduler-border" icon="ui-icon-check">Download Case</legend>

<mdb-card class="col-md-3 p-0" *ngIf="accessList.indexOf('caseDownload')>-1">
    <mdb-card-header class="p-0 cardheadercolor showpointer" (click)="downloadCase.toggle()">
        <table mdbTable class="p-0 mb-0">
            <tbody class="p-0">

                <tr class="p-0">
                    <td class="headertexts py-1"> Download Case </td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" >
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="py-0">
        <div mdbCollapse [isCollapsed]="true" #downloadCase="bs-collapse" class="p-0">
            <!-- Form -->
            <form class="text-center" style="color: #757575;">

                <!-- case number -->
                <!-- <div class="md-form">
          <input type="text" #caseNumber id="caseNumber" class="form-control" mdbInput>
          <label for="caseNumber">Case Number</label>
        </div> -->

                <!-- visit id
        <div class="md-form">
          <input type="text" #visitId id="visitId" class="form-control" mdbInput>
          <label for="visitId">Visit Id</label>
        </div> -->

                <div class="custom-file text-left mt-2">

                    <div class="input-group ">

                        <div style="font-size:12px;font-weight: bold;" class="custom-file ">
                            <input #fileInput style="font-size:12px;" type="file" class="custom-file-input " accept=".xlsx" id="inputGroupFile01"
                            (click)="fileInput.value=null"    (change)="onFileChanged($event)">
                            <label class="custom-file-label" for="inputGroupFile01">{{filename}}</label>
                        </div>
                    </div>

                </div>

                <button mdbBtn class="buttoncolor rounded text-white font-weight-bold white-hover mt-2"   [ngClass]="{'disabled': (filename==='choose one')?true:false}" size="sm"
                    type="submit" (click)="onUpload();" >
                    <div *ngIf="loading" mt-3>
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <span *ngIf="!loading"> Download </span>
                </button>


            </form>
            <!-- Form -->
        </div>
    </mdb-card-body>
</mdb-card>
</fieldset>
<!--
<mdb-card class="col-md-3 p-0">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="p-0 mb-0">
            <tbody class="p-0">

                <tr class="p-0">
                    <td class="headertexts py-1"> Sync Visits for Case </td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="newvisits.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="py-0">
        <div mdbCollapse [isCollapsed]="false" #newvisits="bs-collapse">
        
            <form class="text-center" style="color: #757575;">

            
                <div class="md-form">
                    <input type="text" #caseNumber id="caseNumber" class="form-control" mdbInput>
                    <label for="caseNumber">Case Number</label>
                 </div> 
               <button mdbBtn class="buttoncolor rounded text-white font-weight-bold white-hover mt-2" size="sm"
                    type="submit" (click)="onSync();">
                    <div *ngIf="loading" mt-3>
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <span *ngIf="!loading"> Sync </span>
                </button>


            </form>
         
        </div>
    </mdb-card-body>
</mdb-card>
-->

<!--
<div class="btn-group" mdbDropdown mt-4  *ngIf="accessList.indexOf('caseList')>-1">
    <a class="trigger dropdown-toggle waves-light headertexts mt-5 px-2 py-2 " mdbDropdownToggle>Add Columns</a>



    <div class="dropdown-menu dropdown-primary p-4 colselection">-->
        <!-- Default unchecked -->
     <!--   <div *ngFor="let cols of addcoldList" class="headertexts">
            <mdb-checkbox [checked]="!visitsCols.get(cols)" (change)="addMoreVisitCols(cols,$event.checked)">{{cols}}</mdb-checkbox>

        </div>

    </div>
</div>
-->

<fieldset class="scheduler-border" [ngClass]="{'disabled':loading==true}">
    <legend class="scheduler-border" icon="ui-icon-check">Case List</legend>

<!--Card-->
<mdb-card cascade="true" narrower="true" class="mb-3 col-md-16 mt-2 p-0" *ngIf="accessList.indexOf('caseList')>-1">
    <!--Card image-->

    <!-- <mdb-card-header class="p-0 backcolor h-25">

                <table mdbTable class="p-0 mb-0">
                    <tbody class="p-0">
          
                        <tr class="p-0">
                            <td class="cardheader text-white  px-0 text-center"> Compliance Case List </td>
                            <td class="text-right p-0 w-25">
                                <form class="form-inline d-flex md-form form-sm active-white active-white-2 mt-2 p-0 text-right">
                                  
                                      
                                    <i class="fas fa-search text-white text-right p-0" aria-hidden="true"></i>
                                    <input class="form-control form-control-sm ml-3 w-50 text-white p-0" type="text" placeholder="Search"
                                      aria-label="Search">
                              </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
     
            </mdb-card-header>-->

    <mdb-card-header class="p-0 backcolor ">
     <span class="btn-group" mdbDropdown mt-4  *ngIf="accessList.indexOf('caseList')>-1">
    <a class="trigger dropdown-toggle waves-light "  mdbDropdownToggle><span style="color:white;margin-left: 2em;font-size: 12px;font-weight:bolder">Add Columns</span></a>



    <div class="dropdown-menu dropdown-primary p-4 colselection">
        <!-- Default unchecked -->
        <div *ngFor="let cols of addcoldList" class="headertexts">
            <mdb-checkbox [checked]="!visitsCols.get(cols)" (change)="addMoreVisitCols(cols,$event.checked)">{{cols}}</mdb-checkbox>

        </div>

    </div>
</span>
<!-- <span style="float:right">

                        <form
                            class="form-inline d-flex md-form form-sm active-white active-white-2 mb-0 ml-3 mt-1 p-0 text-right">

                            
                            <input style="font-size:12px;" class="form-control form-control-sm ml-3  text-white p-0" type="search"
                                placeholder="Search Case Id" mdbInput id="input1" aria-label="Search"
                                (search)="onseraching($event.target.value)" (keyup)="onseraching($event.target.value)">
                                <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i>
                        </form>
                    </span> -->
    </mdb-card-header>

    <!-- <div class="view gradient-card-header backcolor mb-0 py-1 px-1">
               
                <h4 class="h4-responsive text-white cardheader text-center">Compliance Case List</h4>
            </div> -->
    <!--/Card image-->
    <!--Card content-->
    <mdb-card-body>


        <table mdbTable #tableEl hover="true" class="z-depth-1 doe-table-body" style="font-size:12px;" striped="true">
            <thead class="tableheadercolor" style="font-size:12px;">
                <tr style="font-size:12px;">
                    <th *ngFor="let head of headElements; let i = index" aria-controls="visitTable"
                        [mdbTableSort]="visitDetailsList" [sortBy]="headElementsSort[i]" scope="col"

                        [ngClass]="{'d-none': isColEnabled(head)?true:false}" >{{head}} <mdb-icon fas
                            icon="sort"></mdb-icon>
                     <div (click)="changeCaseStatus($event)">
                            <input style="font-size:12px;" class="form-control form-control-sm text-black p-0 border border-dark" type="search"
                            placeholder="" mdbInput id="input1" aria-label="Search"
                            (search)="onFilter($event.target.value,head)" (keyup)="onFilter($event.target.value,head)">
                          <!--  <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i> -->
                        </div>
                    </th>
                </tr>

            </thead>
            <tbody>
                <tr mdbTableCol *ngFor="let el of visitDetailsList| paginate: config  ; let i = index" (click)="saveDatatoService(el);"
                    [routerLink]="['/details/pages/detail1/case']" 
                    routerLinkActive="active" class="blue-hover" [ngClass]="{'reviewrColor': (checkReviewer(el.Reviewer)?true:false ), 'd-none':(isReviewComplete(el.Reviewer,el.CaseStatus)?true:false)}">

                    <!--<th scope="row">{{i+1}}</th> -->
                    <td style="white-space: nowrap;">{{el.CaseId}}</td>
                    <td >{{el.ServiceName}}</td>
                    <td style="white-space: nowrap;">{{el.SENumber}}</td>
                    <td >{{el.ServiceType}}</td>
                    <td >{{el.CaseStatus}}
                       <div *ngIf="accessList.indexOf('changeCaseStatus')!=-1" (click)="changeCaseStatus($event);">
                        <app-changeCaseStatus (changedStatus)="onStatusSelect($event,el);" [currentStatus]="el.CaseStatus"></app-changeCaseStatus> 
                     </div> 

                    </td>
                    <td >{{el.CreatedDate | date: 'dd-MM-yyyy'}}

                    </td>
                    <td  [ngClass]="{'d-none': isColEnabled('Case Officer')}">{{(el.Officer && el.Officer!==null && el.Officer!=='undefined')?el.Officer.replace('.',' '):''}} 
                      
                         <div *ngIf="accessList.indexOf('AddOfficer')!=-1" (click)="getACOsforLead(el.CaseId,$event,el.Reviewer)">
                            <app-chooseOfficer [caseId]="el.CaseId" (message)="helpText($event)" [usersList]="leadUserList" (modaluser)="onuserSelect($event,'Officer',el);"></app-chooseOfficer> 
                         </div> 
        

                    </td>
                    <td  [ngClass]="{'d-none': isColEnabled('Hub')}">{{el.Hub}}</td>
                    <td  [ngClass]="{'d-none': isColEnabled('Reviewer')}">{{(el.Reviewer && el.Reviewer!==null && el.Reviewer!=='undefined')?el.Reviewer.replace('.',' '):''}}
                        <div *ngIf="accessList.indexOf('AddReviewer')!=-1" (click)="getACOsforReviewer(el.CaseId,$event)">
                            <app-chooseOfficer [caseId]="el.CaseId" (message)="helpText($event)" [usersList]="reviewerUserList" (modaluser)="onuserSelect($event,'Reviewer',el);"></app-chooseOfficer> 
                         </div> 
        

                    </td>
                    <td  [ngClass]="{'d-none': isColEnabled('Approver')}">{{(el.Approver && el.Approver!==null && el.Approver!=='undefined')?el.Approver.replace('.',' '):''}}
                        <div *ngIf="accessList.indexOf('AddManager')!=-1" (click)="addOfficer($event);">
                            <app-chooseOfficer [caseId]="el.CaseId" (message)="helpText($event)" [usersList]="userListMgrs" (modaluser)="onuserSelect($event,'Approver',el);"></app-chooseOfficer> 
                         </div>
                    </td>
                    <td [ngClass]="{'d-none': isColEnabled('Case Details')}" style="font-size:12px;">{{el.CaseDetails}}</td>
                </tr>

            </tbody>

        </table>
        <div style="font-size:12px;" class="mt-2 text-center">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
        </div>




    </mdb-card-body>
    <!--/.Card content-->
</mdb-card>
<!--/.Card-->
</fieldset>

