import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, Output, EventEmitter} from '@angular/core';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { CaseOutcomeService } from 'src/app/service/caseOutcome.service';
import { DocumentService } from 'src/app/service/document.service';
import { LoginDataService } from 'src/app/service/loginData.service';



@Component({
  selector: 'app-documentsList',
  templateUrl: './documentsList.component.html',
  styleUrls: ['./documentsList.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DocumentsListComponent implements OnInit, OnChanges {
  passeddata:any;
  outcomeStatus = ["Admin Letter", "Caution Letter","Emergency Action","Compliance Notice", "Compliance Direction", "Suspension of Provider Approval","Show Cause Notice: Cancellation of Provider Approval","Notice of Decision to Cancel Provider Approval","Decision to Suspend Provider","Decision to Cancel Provider"];
  
 @Input() 
  refreshOutcomeTable=false;
  @Output()
  refreshOutcomeTableReset = new EventEmitter<boolean>();
  @Output()
  readyForReview = new EventEmitter<boolean>();
  @Output()
  readyForApproval = new EventEmitter<boolean>();
  @Input()
  lawRegList:any[]=[];
  @Output() 
  message = new EventEmitter<any>();
  @Input()
  accessList:string[]=[];
  @Input()
  recordNumber:string=''
  @Input()
  visitList:any[]=[];


  config: any;
  pageChanged(event : any){
    this.config.currentPage = event;
  }

  ngOnInit() {
    //this.passeddata = this.dataservice.getOption();
   
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }

    this.passeddata = this.dataservice.getOption();
    this.getAllpendingDocs();
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.outcomeDocumentsList.length
    };
    
    
  }
  outcomeDocumentsList:any[]=[];
  outcomeTemplate:any;
  headElementsDocuments=["Outcome Type","PDF Link","Status","Drafted By","Drafted Date","Approver","Approved Date","Reviewer","Reviewed Date",""];
  headElementsDocumentsSort=["outcomeType","outcomeType","status","draftedBy","draftedDate",'ApprovedBy',"approvedDate","reviewedBy","reviewedDate","Delete"];
  constructor(private documentService:DocumentService,private loginDataService:LoginDataService,private documentServce:DocumentService,private dataservice: DataService,private caseOutcomeService:CaseOutcomeService,private ref: ChangeDetectorRef) {
   
  }
  async ngOnChanges(): Promise<void> {
  
    if( this.refreshOutcomeTable)
    {
      this.refreshOutcomeTable=false;
     
      await this.getAllpendingDocs();
      
      this.refreshOutcomeTableReset.emit(false);
    
    }
    
  }

  loadDoc(docId: any) {


    this.documentService.getTrimDoc(docId).subscribe((response) => {
    var file:any;

       file = new Blob([response], { type: 'application/pdf' });

     var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
   

  });
}
selectedFile:any;
filename:string="";

public onFileChanged(event: any) {
  console.log(event);
  this.selectedFile = event.target.files[0];
  if(this.selectedFile!== null && this.selectedFile!==undefined)
  {
    this.filename=this.selectedFile.name;

  }
  

 }

 onUpload()
  {
    if(this.selectedFile!==null && this.selectedFile.type !== 'application/pdf' && this.selectedFile.type !== 'image/jpeg')
    {
       this.message.emit({'status':'error','messageTxt':'File TYpe not valid'});
      return;
    }
    if((this.selectedFile!==null && (this.selectedFile.size/1000)>20000))
    {
     
      this.message.emit({'status':'error','messageTxt':'File size too big'});
      return;
    }
    this.uploadtoTrim();
  }
  

  fileURL: any;
  uploading:boolean=false;
 async uploadtoTrim() {


  
  this.uploading = true;
  const uploadData = new FormData();
  uploadData.append('myFile', this.selectedFile, this.selectedFile.name);

  this.documentService.uploadEvidencetoTrim(uploadData, null,this.passeddata,this.recordNumber)
    .subscribe(
      res => {
      
        console.log(res);
        this.uploading = false;
        this.message.emit({'status':'success','messageTxt':'File uploaded to trim'});
       
       
        this.message.emit({'status':'success','messageTxt':'pdf upload to Trim for '+this.filename});   

        this.caseOutcomeService.saveCaseOutcomeStatus(this.filename,this.passeddata.CaseId,this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname,'','Attached Document',res.trimId)
        .subscribe(resp => {
                 console.log(resp);
                 this.getAllpendingDocs();
                 //this.ref.detectChanges();
             
                this.filename="choose one";
                this.message.emit({'status':'success','messageTxt':'pdf addedd to caseoutcome '});   
                 
            },
            err => {
             
              console.log('Error Occured during save outcome ' + err);
              this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
            }
            );


      },
      err => {
        
        this.uploading = false;
        this.message.emit({'status':'success','messageTxt':'upload failed'});
        console.log('Error Occured duringng saving: ' + err);
      });



  }
 


  addtoOutcomeTable(outcome:string,event:boolean)
  {
    if(event=== true)
    {
      if(this.outcomeDocumentsList!==null && this.outcomeDocumentsList!== undefined)
      {
       var templist = this.outcomeDocumentsList.filter(el => (el.outcomeType===outcome && el.status!=='Approved'));
       if(templist!==null && templist.length<=0)
       {


      this.caseOutcomeService.saveCaseOutcomeStatus(outcome,this.passeddata.CaseId,this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname,'','In Progress','')
      .subscribe(resp => {
               console.log(resp);
               this.getAllpendingDocs();
              
               
              // this.refreshOutcomeTable = true;
               this.message.emit({'status':'success','messageTxt':'case Outcome added as In Progress '+ outcome});
               
          },
          err => {
           
            console.log('Error Occured during save outcome ' + err);
            this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
          }
          );
       
       }
    else{
      this.message.emit({'status':'error','messageTxt':'cannot add as document is already created and not yet approved '});
      console.log('cannot add as document is already created and not yet approved ');
    }
  }
  }
    
  
   
  }
 
  generateAttachmentPdf(outcome:string)
  {
    
    this.documentServce.getOutcomeStatusPdf(outcome,this.passeddata.CaseId,null,this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      var file:any;
     /* file = new Blob([res], { type: 'application/pdf' });
      
      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);*/


       file = new Blob([res], { type: 'application/pdf' });
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.message.emit({'status':'error','messageTxt':'Error generating Pdf'});
    }
    );

  }

  getAllpendingDocs()
  {
   
       this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'').subscribe(data => {
        this.outcomeDocumentsList=[];
      for (const d of (data as any)) {


        this.outcomeDocumentsList.push({
          outcomeId: d.outcomeId,
          outcomeType: d.outcomeType,
          draftedBy:d.draftBy,
          status:d.status,
          approvedDate:d.approvedDate,
          approvedBy:d.approvedBy,
          draftedDate:d.draftedDate,
          trimId:d.trimId,
          reviewedDate:d.reviewedDate,
          reviewedBy:d.reviewedBy

            }
        );
       

      }
    
      this.outcomeDocumentsList =  this.outcomeDocumentsList.sort((a,b) => (a.draftedDate < b.draftedDate) ? 1 : ((b.draftedDate < a.draftedDate) ? -1 : 0)); 
      this.ref.detectChanges();
     
      console.log(this.outcomeDocumentsList);
      
    });
  }

  removeDoc(doc:any)
  {
    if(confirm("are you sure you want to delete?"))
    {
    this.caseOutcomeService.deleteCaseOutcome(doc.outcomeId).subscribe(data => {
      console.log("deleted doc"+data);
      var i = this.outcomeDocumentsList.indexOf(doc);
      this.outcomeDocumentsList.splice(i,1);
      this.ref.detectChanges();
    },err => {
      console.log("failed to delete doc"+err);
    });
  }
  }
  

  setValues(outcome:any)
  {
    //this.passeddata.SelectedOutcome=outcome;
    localStorage.setItem("SelectedOutcome",outcome);
    //this.passeddata.lawRegList=this.lawRegList;
    localStorage.setItem("lawRegList",JSON.stringify(this.lawRegList));
    this.passeddata.breachVisitDates='';

    this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.passeddata.breachVisitDates= this.passeddata.breachVisitDates+","+el.startDt;
      }
    });

  }

}
