import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';
import { LoginDataService } from './loginData.service';

const getUrl = '/users/all';
const addUserUrl = '/users/add';
const updateUserUrl = '/users/update';
const deleteUserUrl = '/users/delete';
const getforUserId='/users';
const getAccessDetails='/users/access';
const getLogoutUrl = '/users/logout';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  baseUrl :any;

  constructor( private http: HttpClient,private config: AppConfigService,private loginService: LoginDataService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  
 

  getAllUsers() {
        
    const httpOptions = {
      headers: new HttpHeaders({
                 'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };

  
    return this.http.get<any>(this.baseUrl+getUrl,httpOptions) .pipe(
      catchError(this.handleError('getAllUsers', null))
    );

  }

  
  addUser(createObj:any) {
   
    var jsonObject={userFirstName:createObj.userFirstName, userLastName: createObj.userLastName,
      status:createObj.status,intUserId:createObj.intUserId,roleId:createObj.roleId,region:createObj.region,
      email:createObj.email,createdBy:createObj.createdBy,nqaUserId:createObj.nqaUserId,title:createObj.title};
   //var jsonObject= JSON.stringify(createObj);
    return this.http.post<any>(this.baseUrl+addUserUrl,jsonObject);

  }
  updateUser(createObj:any) {
        
    var jsonObject={userId:createObj.userId,userFirstName:createObj.userFirstName, userLastName: createObj.userLastName,
      status:createObj.status,intUserId:createObj.intUserId,roleId:createObj.roleId,region:createObj.region,
      email:createObj.email,updatedBy:createObj.updatedBy,nqaUserId:createObj.nqaUserId,title:createObj.title};
    return this.http.post<any>(this.baseUrl+updateUserUrl,jsonObject);

  }
  deleteUser(userId:any){
    return this.http.get<any>(this.baseUrl+deleteUserUrl+'/'+userId);

  }
  getUserforId(userId: string) {
   // alert("userid"+userId);
    const httpOptions = {
      headers: new HttpHeaders({
                 'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
    return this.http.get<any>(this.baseUrl+getforUserId+"/"+userId,httpOptions) .pipe(
      catchError(this.handleError('getUserforId', userId))
    );

  }


  getAccessDetails(roleId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
                 'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
       //  alert("in access details"+roleId) ;
    return this.http.get<any>(this.baseUrl+getAccessDetails+"/"+roleId,httpOptions) .pipe(
      catchError(this.handleError('getAccessDetails', roleId))
    );

  }


  doLogout(logoutHint:any) {
    const httpOptions = {
      headers: new HttpHeaders({
                 'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
       //  alert("in access details"+roleId) ;
    return this.http.get<any>(this.baseUrl+getLogoutUrl+"/"+logoutHint,httpOptions)

  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead
      alert("error message"+JSON.stringify(error));
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
