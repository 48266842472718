import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViewsModule } from './views/views.module';
import { SharedModule } from './shared/shared.module';
import { ErrorModule } from './views/errors/error.module';
import { DataService } from './views/visits/visit1/visit1.service';

import { DatePipe } from '@angular/common'
import {CookieService} from 'ngx-cookie-service';
import {NgxPrintModule} from 'ngx-print';
// main layout
import { NavigationModule } from './main-layout/navigation/navigation.module';

import {ToastrModule} from 'ngx-toastr';
import { LoginDataService } from './service/loginData.service';
import { AppConfigService } from './service/AppConfigService';
import { TokenInterceptorService } from './service/TokenInterceptorService';
import { EditorModule } from 'primeng/editor';

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: ''
    }),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut:2000,
      positionClass:'toast-top-right',
      preventDuplicates:true,
    }),
    NavigationModule,
    AppRoutes,
    EditorModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ViewsModule,
    ErrorModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    NgxPrintModule
    
  ],
  providers: [DataService,DatePipe,CookieService,LoginDataService,{
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [AppConfigService],
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS, 
    useClass: TokenInterceptorService, 
    deps: [AppConfigService],
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
