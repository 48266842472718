
    <a> <mdb-icon fas icon="pencil-alt" class="prefix text-danger" (click)="complianceNoticeModal.show();"></mdb-icon></a>
   
<div mdbModal #complianceNoticeModal="mdbModal" class="modal fade doe-table-body" style="overflow: auto" tabindex="-1" role="dialog" aria-labelledby="complianceNoticeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content templateText">

            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="complianceNoticeModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Compliance Notice Template</p>
            </div>


            <div class="modal-body templateText">

                <div class="templateText" >



<p class="templateText">
    <img
        width="225"
        height="80"
        src="../../../../assets/NSWEducationColor.png"
    />
</p>
<p align="right">
    Provider Approval Number: {{passeddata.ProviderId}}
</p>
<p>
{{passeddata.ProviderName}}  <strong></strong>
</p>
<p>
    {{passeddata.Address}}

</p>
<p>
    <em>Also by email: </em>
    <em>{{passeddata.ServiceEmail}}</em>
    <em></em>
</p>
<p align="center">
    <strong>COMPLIANCE NOTICE</strong>
</p>
<p align="center">
    Section 177<em> </em>
</p>
<p align="center">
    <em>Children (Education and Care Services) National Law (NSW)</em>
</p>
<p align="center">
    (the “National Law”)
</p>
<p>
    In accordance with section 177 of the National Law, in the exercise of
    power delegated by the Secretary, Department of Education, I am giving a
    compliance notice to you as the approved provider (Provider Approval Number
    {{passeddata.ProviderId}}).
</p>
<p>
    This compliance notice relates to the service {{passeddata.ServiceName}},
    operating from {{passeddata.Address}} (Service Approval Number
    {{passeddata.SENumber}}).
</p>
<p>
    I am satisfied that as the approved provider for {{passeddata.ServiceName}} you have not complied with add Laws applicable
    <em>
        (Education and Care Services) National Law (NSW) (the “National Law”)
    </em>
    and, add Regulations applicable of the
    <em>
        Education and Care Services National Regulations (the “Regulations”).
    </em>
</p>
<p>
    <em></em>
</p>
<p>
    On {{visitdates}}, authorised officers conducted a visit to
    {{passeddata.ServiceName}} to investigate the claims of a number of
    complaints received by the Department. During the visit authorised officers
    observed several areas of non-compliance which present a risk to the safety
    of children and which demonstrate administrative inadequacies in the
    operations of the service.
</p>
<p>
    In accordance with Section 177 of the National Law, I require you to take
    steps, as outlined below, to comply with the relevant provisions.
</p>
<p>
    You must comply with this compliance notice, and provide evidence of your
    compliance by <strong>{{date | date}}</strong> to
    the Regulatory Authority (NSW Department of Education (“the Department”)).
</p>

<table mdbTable id="complianceTable" 
                    class=".table-sm .table-fixed .table-hover doe-table-body summaryborder" bordered="true">

                    <!--Table head-->
                    <thead class="lawReg-table-header border summaryborder">
                        <tr>
                            <th *ngFor="let head of headElements; let i = index"
                                aria-controls="lawregTable1" 
                                scope="col" class="border summaryborder" >
                                {{head }} 
                            </th>
                        </tr>

                    </thead>
                    <!--Table body-->
                    <tbody *ngFor="let item of lawRegList" class="border summaryborder">
                        <tr *ngIf="item.complianceStatus!==null && item.complianceStatus.toLowerCase() === 'Confirmed breach'.toLowerCase()">
                            <td class="w-25">
                                {{item.lawid}}:{{item.description}}
                            </td>
                            <td >
                               {{item.evidence}} 
                            </td>
                            <td>
                                                          
                                <textarea class="form-control evidenceText border border-info" id="stepstoTake" rows="10" maxlength="4000"   [(ngModel)]="item.correctionSteps" >{{item.correctionSteps}}</textarea>
                                <button type="button" mdbBtn size="sm" 
                                class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="saveComplianceNotice(item);">Save</button>
                          
                            </td>
                        </tr>
                    </tbody>
</table>

<!--<table border="1" cellspacing="0" cellpadding="0" width="0">
    <tbody>
        <tr>
            <td width="125" valign="top">
                <p>
                    <strong>Provision of the National Regulations</strong>
                </p>
            </td>
            <td width="255" valign="top">
                <p>
                    <strong>Description of non-compliance</strong>
                </p>
            </td>
            <td width="225" valign="top">
                <p>
                    <strong>
                        Steps to be taken by the Approved Provider
                    </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
        <tr>
            <td width="125" valign="top">
            </td>
            <td width="255" valign="top">
            </td>
            <td width="225" valign="top">
            </td>
        </tr>
    </tbody>
</table> -->
<p>
    <strong></strong>
</p>
<p>
    <strong>Penalties</strong>
</p>
<p>
    A penalty of $6,000 in the case of an individual, or $30,000 in any other
    case, may apply if this notice is not complied with.
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>Review of decision</strong>
</p>
<p>
    Under section 191 of the National Law, you may apply to the Regulatory
    Authority for an internal review of the decision to issue this compliance
    notice. Such application must be lodged within 14 days after you are
    notified of the decision to issue this compliance notice or within 14 days
    after you become aware of the decision to issue this compliance notice.
</p>
<p>
    An application seeking a review of this decision will be conducted by a
    Regulatory Authority officer who was not involved in the assessment or
    investigation which resulted in the issue of this compliance notice, and
    that review will be decided within 30 days of the application being
    received.
</p>
<p>
    <strong>All correspondence to: </strong>
</p>
<p>
    Attn: {{passeddata.NominatedSupervisor}}
</p>
<p>
    &lt;&lt;hub email address&gt;&gt;
</p>
<p>
    Should you have any questions about this letter, please contact us on 1800
    619 113.
</p>
<p>
    Yours sincerely,
</p>
<p>
    Hub Coordinator
</p>
<p>
    <strong>State Operations Network</strong>
</p>
<p>
    <strong>Early Childhood Education Directorate</strong>
</p>
<p>
    <strong>Delegate of the Secretary, Department of Education</strong>
</p>
<p>
    {{todaysDate }}
</p>


</div>
  </div>         
</div>
</div>
</div>
