import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';

const getUrl = '/document/generatePdf';
const getComplianceOutcomeUrl = '/document/generateCompliancePdf';
const getComplianceOutcomeTempleteUrl = '/document/generateComplianceTempletePdf';
const generateBreachTableTempleteUrl = '/document/generateBreachTableTempletePdf';

const uploadUrl='/document/upload';
const createUrl='/document/create';
const uploadAttachmentURL = '/document/uploadEmailAttachment';
const getAllDocsUrl = '/document/getDocs';  // get documents from DB for breach Id
const getFromTrimUrl = '/TrimServiceAPIWrapper/downloadDocument'; //get Document from Trim
const getFSRecordNumberURL ='/document/getFSRecordNumber';
const getAttachmentsforCaseURL ='/document/getAttachmentsforCase';
const editPdf = '/document/editablePdf';
const deleteDocURL='/document/deleteDoc';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseUrl : any;
  trimurl : any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
    this.trimurl = this.config.getConfig().trimurl;
   
  }

  
 
  getPdf(caseId :string): Observable<any> {
   
    const httpOptions = {
      'responseType': 'blob' as 'json'
     };
    return this.http.get<any>(this.baseUrl+getUrl+"/"+caseId,httpOptions)
      .pipe(
        catchError(this.handleError('generatePdf', ""))
      );
  }

  getOutcomeStatusPdf(compOutcome:string,caseId:string,summary:any,responseType:any): Observable<any> {
    
    const httpOptions = {
     // 'responseType': 'blob' as 'json'
     'responseType'  : 'arraybuffer' as 'json'
     };
     var myjson = {caseId: caseId, compOutcome: compOutcome, summaryText: summary,responseType: responseType};
   
    return this.http.post<any>(this.baseUrl+getComplianceOutcomeUrl,myjson,httpOptions)
      .pipe(
        catchError(this.handleError('generatePdf', ""))
      );
  }
  getOutcomeTempletePdf(compOutcome:string,summary:any,responseType:any): Observable<any> {
    
    const httpOptions = {
     // 'responseType': 'blob' as 'json'
     'responseType'  : 'arraybuffer' as 'json'
     };
     var myjson = { compOutcome: compOutcome, summaryText: summary,responseType: responseType};
   
    return this.http.post<any>(this.baseUrl+getComplianceOutcomeTempleteUrl,myjson,httpOptions)
      .pipe(
        catchError(this.handleError('generatePdf', ""))
      );
  }
  generateBreachTableTempletePdf(compOutcome:string,caseId:string,summary:any,responseType:any): Observable<any> {
    
    const httpOptions = {
     // 'responseType': 'blob' as 'json'
     'responseType'  : 'arraybuffer' as 'json'
     };
     var myjson = {caseId: caseId, compOutcome: compOutcome, summaryText: summary,responseType: responseType};
   
    return this.http.post<any>(this.baseUrl+generateBreachTableTempleteUrl,myjson,httpOptions)
      .pipe(
        catchError(this.handleError('generatePdf', ""))
      );
  }
  
  
  deleteDoc(imageData: any)
  {
    return this.http.post<any>(this.baseUrl+deleteDocURL+"/"+imageData.docId,null)
      .pipe(
        catchError(this.handleError('deletedoc', ""))
      );
  }

  uploadEvidencetoTrim(uploadData: any, evidence: any,passeddata:any,recordNumber:any): Observable<any> {
   var inaid:string ='';
   var visitId:string = '';

   if(evidence!=null)
   {
    inaid=evidence.inaid;
    visitId=evidence.visitId;
   }

    return this.http.post<any>(this.baseUrl+uploadUrl + "?breachId=" + inaid + "&visitId=" +visitId+"&recordNumber=" + recordNumber+"&serviceId=" + passeddata.SENumber+"&providerId=" + passeddata.ProviderId+"&caseId="+passeddata.CaseId, uploadData);
   
  }


  createRecordinTrim(passeddata: any, visitId:string,user:string): Observable<any> {
    
    var myjson = {visitId: visitId, caseId: passeddata.CaseId, serviceId: passeddata.SENumber, serviceName: passeddata.ServiceName,providerId: passeddata.ProviderId, providerName: passeddata.ProviderName, suburb: passeddata.Suburb,user:user};
    
    return this.http.post<any>(this.baseUrl+createUrl , myjson)
      .pipe(
        catchError(this.handleError('createRecordinTrim', passeddata))
      );
  }

  getAllDocs(breachId: any) {
    
       
    return this.http.get<any>(this.baseUrl+getAllDocsUrl+"/"+breachId);

  }

  
  getTrimDoc(docId: any) {
   // var tokenval = localStorage.getItem("access_token");
    const httpOptions = {
    'responseType': 'blob' as 'json',
       
    };
    //var myjson = {applicationName: 'ecompliance', environment: 'test', uniqueRowIdentifier:docId};
   
    return this.http.get<any>(this.trimurl+getFromTrimUrl+"?applicationName=ecompliance&environment=test&uniqueRowIdentifier="+docId, httpOptions);
   

  }


  getRecordNumber(SENumber:any,ProviderId:any,caseId:any)
  {
    return this.http.get<any>(this.baseUrl+getFSRecordNumberURL+"?serviceId="+SENumber+"&providerId="+ProviderId+"&caseId="+caseId)
    .pipe(
      catchError(this.handleError('getRecordNumber', SENumber+ProviderId))
    );
  }

  uploadAttachmenttoTrim(providerId:any,serviceId:any,casestatus:any,recordnumber:any,caseId:any) : Observable<any>
  {
 
    return this.http.post<any>(this.baseUrl+uploadAttachmentURL+"?status="+casestatus+"&recordNumber="+recordnumber+"&caseId="+caseId+"&providerId="+providerId+"&serviceId="+serviceId , null)
      .pipe(
        catchError(this.handleError('uploadAttachmenttoTrim', caseId))
      );

  }

  getHistoryDocs(providerId:any,serviceId:any,caseId:any)
  {
    return this.http.get<any>(this.baseUrl+getAttachmentsforCaseURL+"?serviceId="+serviceId+"&providerId="+providerId+"&caseId="+caseId)
    .pipe(
      catchError(this.handleError('getAttachmentsforCaseURL', serviceId+providerId))
    );
  }

  editPdf(outcome:string,caseId:string )
  {
    var myjson = {compOutcome: outcome, caseId: caseId}
    return this.http.post<any>(this.baseUrl+editPdf,myjson)
    .pipe(
      catchError(this.handleError('editPdf', myjson))
    );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead
      alert("error message"+JSON.stringify(error));
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
