import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';





@Component({
  selector: 'app-changeAddress',
  templateUrl: './changeAddress.component.html',
  styleUrls: ['./changeAddress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ChangeAddressComponent implements OnInit {
  inputAddress1:string="";
  educatorName:string="";
  inputAddress2:string="";
  inputSuburb:string="";
  inputPostCode:string="";
  errormsg:string="";
  
  @Output()
  address = new EventEmitter<any>();
  passeddata:any;
  ngOnInit() {
 // alert("in init.. content"+ this.modalRef.content);
 //this.passeddata = this.dataservice.getOption();
 

      if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
      
        var temp =localStorage.getItem("selectedCase");
        (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');



      }

      this.passeddata = this.dataservice.getOption();
 
  }
  @ViewChild('changeAddressModal', { static: true }) changeAddressModal: ModalContainerComponent;
​

  constructor(private dataservice: DataService) {
   
  }



changeAddress(event:any)
{
  event.stopPropagation();
}


saveAddress()
{
  if(this.inputAddress1!=="" && this.inputSuburb!=="" && this.inputPostCode!=="")
  {
    var addressjson={educatorName:this.educatorName,inputAddress1:this.inputAddress1,inputAddress2:this.inputAddress2,inputSuburb:this.inputSuburb,inputPostCode:this.inputPostCode}
    this.address.emit(addressjson);
    this.errormsg="";
    
    this.changeAddressModal.hide();
  }
  else
  {
    this.errormsg="Address1, Suburb and Postcode are Required";
  }
}

}
