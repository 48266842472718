
<app-navigation></app-navigation>

<main [ngClass]="{'py-0 px-0 my-0 mx-0': specialPage}"  class="mx-lg-1" >
    
  <div class="mt-4 px-0 py-0 ">
    <router-outlet></router-outlet>
  </div>
</main>

<!-- <app-footer></app-footer> -->
