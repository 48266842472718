
<!-- <button printSectionId="print-section" [useExistingCss]="true" (click)="onPrint();">Print me</button> -->

<mdb-card class="mb-3 mt-3" >
  <mdb-card-header class="p-0 subcardheadercolor">
      <table mdbTable class="table-hover table-condensed mb-0">
          <tbody class="p-0">
              <tr class="p-0">
                  <td class="subcardheader py-1 font-weight-bold ">Questionnaire</td>
                 
              </tr>
          </tbody>
      </table>

  </mdb-card-header>
  <mdb-card-body class="p-4" >
   <!--<div class="headertexts mb-0">
    <table mdbTable class="p-0 table-fixed" > 
      <tbody class="p-0">
       <tr class="p-0">
         <td class="p-0 noborder w-75">
          <div class="form-group p-0">
          </div>
         </td>
     <td class="ml-2 noborder p-0">  <button type="button" mdbBtn size="sm"
  class="relative waves-light buttoncolor rounded text-white font-weight-bold trigger text-left ml-2" [ngClass]="{'disabled':(CaseStatus!=='Evidence Collection and Analysis' && CaseStatus!=='Peer Review Modifications')}"
  mdbWavesEffect (click)="addQuestion()"> Add</button></td>
          </tr>
      </tbody>
      </table> 
  

      </div>--> 
      
      <div class="evidenceText mt-1">
         
          <label class="headertexts">Your Comments :</label>
       <!--<div id="questionairre" class=" questionArea overflow-auto border border-info w-100"  rows="5" [innerHTML]="answer"  ngDefaultControl contenteditable="true" style="height: 100px;" (input)="updateAnswer($event.target.value)"></div>
        --> 
      <!-- <ckeditor [editor]="Editor" [data]="answer" [(ngModel)]="answer" [maxLength]='4000'   style="overflow:scroll; max-height:300px">
        
        </ckeditor> --> 
     <editor
        apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" 
        [initialValue]="(answer)?answer:''"
        [(ngModel)]="answer" 
        (onInit)="handleEditorInit($event)" 
        (ngModelChange)="checkSizeLimit($event)"
      
        [ngClass]="{'disabled':(CaseStatus!=='Evidence Collection and Analysis' && CaseStatus!=='Peer Review Modifications')}"
        [init]="{
          height: 200,
          charLimit : 10,
          branding:false,
          menubar: false,
          plugins: 'wordcount lists advlist',
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | help'
        }"
       
  
      >
      </editor> 

      
         

      <!--  <ejs-richtexteditor #toolsRTE id='alltoolRTE' [(ngModel)]="answer"  [maxLength]='4000'   style="overflow:scroll; max-height:300px">
          <ng-template>
            {{answer}}
          </ng-template>


        </ejs-richtexteditor>-->
                                                            

      </div>
      <div>
                  
      <table mdbTable class="p-0 table-fixed border border-0" > 
          <tbody class="p-0">
          <tr class="p-0">
          <td>
            <span class="mt-0 reminingText py-0">maximum word count is 600</span>
         
          </td>
          <td  class="mt-0 reminingText text-right p-0"><button type="button" mdbBtn size="sm" 
              class="relative waves-light backcolor rounded text-white font-weight-bold trigger text-right ml-0" [ngClass]="{'disabled':(CaseStatus!=='Evidence Collection and Analysis' && CaseStatus!=='Peer Review Modifications')}"
              mdbWavesEffect (click)="saveQuestionaire()"> Save</button>
   

          </td>
          </tr>
          </tbody>
      </table>
  </div> 

  </mdb-card-body>
</mdb-card>

