import { Component, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import {CookieService} from "ngx-cookie-service";
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { SSOLogonService } from 'src/app/service/ssologon.service';



@Component({
  selector: 'app-landing1',
  templateUrl: './landingpage1.component.html',
  styleUrls: ['./landingpage1.component.scss']
})
export class LandingComponent implements OnInit  {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild('row', { static: true }) row: ElementRef;



  previous: any = [];
  headElements = ['id', 'name', 'address', 'officer'];

  searchText: string = '';

  maxVisibleItems: number = 2;


  showLocation = true;
  showlocTable = true;
  locCounter = 0;

  assignedACOs = ["mahinder", "newACO"];

  statusCompliance = ["Pending", "Compliant","non Compliant","NA"];

  moreLawRegList = [
    {id: 5, lawId: "S.5.1", desc:"Is the preschool program has first aid kit",evidenceFlag:true,selectedflag:false},
    {id: 6, lawId: "R.73.2", desc:"Develop a program that contributes to each child's learning and development ?",evidenceFlag:true,selectedflag:false},
    {id: 7, lawId: "R.62(21)", desc:"Provide a preschool program with reading and writing skills?",evidenceFlag:true,selectedflag:false},
    {id: 8, lawId: "S.168.5", desc:"Use an approved development and learning framework?",evidenceFlag:true,selectedflag:false}

  ];


  ACOs = [
    {id: 1, name: "ACO1"},
    {id: 2, name: "ACO2"},
    {id: 3, name: "ACO3"},
    {id: 4, name: "ACO4"},
    {id: 5, name: "ACO5"},
    {id: 6, name: "mahinder"},
    {id: 7, name: "newACO"}
  ];
  lawRegList = [
    {id: 1, lawId: "S.5", desc:"Is the preschool program (R62(2)) delivered by a qualified early childhood teacher?",evidenceFlag:true},
    {id: 2, lawId: "R.73", desc:"Develop a program that contributes to each child's learning and development outcomes outlined in the learning framework?",evidenceFlag:true},
    {id: 3, lawId: "R.62(2)", desc:"R.62(2):Provide a preschool program or have a documented arrangement with an approved provider of another service to provide a preschool program and informs parents of this arrangement?",evidenceFlag:true},
    {id: 4, lawId: "S.168", desc:"Use an approved learning framework to guide development of the program?",evidenceFlag:true}

  ];
  locationList: any[] = [
    {id:1,name:"little Zaks academy", address:"south wentworthville",officer:"Shaun"},
    {id:1,name:"little Zaks academy", address:"carlingford",officer:"Paul"}
  ];

 selectedValue = null;

 emptyLocations : false;
 editField: string;

 passeddata:any;
 cookieValue = 'UNKNOWN';
constructor(private dataservice : DataService,private cookieService : CookieService, private ssologonservice: SSOLogonService ) { }



@HostListener('input') oninput() {
  this.mdbTablePagination.searchText = this.searchText;
}



getLoggedSSOUserAttributes(cookieValue: string) {
  this.ssologonservice.getLoggedSSOUserAttributes(cookieValue).subscribe(
    result => {
      console.log('RESULT identity/attributes: ' + result);
      // window.location.href='https://sso.dev.det.nsw.edu.au/sso/UI/Login?goto=http://myhost.dev.det.nsw.edu.au:4200/';
      // this.router.navigate(['/ssoLogin']);
      //   if (result.valid == 'false') {
      //     console.log('FALSE VALUE');
      //   } else if (result.valid == 'true') {
      //     console.log('TRUE VALUE');
      //   }
    }, error => {console.log('ERROR' + error);});
  // this.cookieService.set( 'iPlanetDirectoryPro', this.cookieService.get('iPlanetDirectoryPro') );
  // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
  // console.log('COOKIE ' + this.cookieValue);
}

getLogin() {


  this.ssologonservice.getLogin() ; //(
  // result => {
  //   console.log('RESULT' + result);
  //
  //   // this.router.navigate(['/ssoLogin']);
  //   //   if (result.valid == 'false') {
  //   //     console.log('FALSE VALUE');
  //   //   } else if (result.valid == 'true') {
  //   //     console.log('TRUE VALUE');
  //   //   }
  // }, error => {console.log('ERROR' + error);});
  // this.cookieService.set( 'iPlanetDirectoryPro', this.cookieService.get('iPlanetDirectoryPro') );
  this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');

  console.log('COOKIE ' + this.cookieValue);
}



  /*getLogin() {


   // window.location.href='http://localhost:4200/visits/pages/visit1';

this.censusService.getLogin().subscribe(
    async result => {
      console.log('RESULT' + result);
      window.location.href='https://sso.dev.det.nsw.edu.au/sso/UI/Login?goto=http://localhost:4200/visits/pages/visit1';
     
    });*/
  /*  alert(this.cookieService.get('iPlanetDirectoryPro'));

    if(JSON.stringify(result).indexOf('false')>=0)
    {


      const res2 = await this.censusService.getData(this.cookieService.get('iPlanetDirectoryPro')).subscribe( res =>{
        console.log('RESULT' + res);
      }, error => {alert('ERROR' + JSON.stringify(error));});

      alert("res2 " + JSON.stringify(res2));

    }

  });*/
    /*  this.cookieService.set( 'Test', 'Hello World' );
      this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
      console.log('COOKIE ' + this.cookieValue);
}*/

  ngOnInit() {

    this.passeddata =  this.dataservice.getOption();
   if(this.locationList.length > 0)
   {
    this.showlocTable=false;
   }


  }

  addACO(aconame : string ) {
      this.assignedACOs.push(aconame);

  }

  deleteACO(aconame : string) {
    const index: number = this.assignedACOs.indexOf(aconame);
    if (index !== -1) {
        this.assignedACOs.splice(index, 1);
    }

  }

  askEvidence(obj:any) {

    obj.evidenceFlag = !obj.evidenceFlag;


  }

  addMoreLawsandRegs(item:any)
  {

    item.selectedflag = !item.selectedflag;

    if(item.selectedflag)
    {
      this.lawRegList.push(item);
    }
    else{
      const index: number = this.lawRegList.indexOf(item);
      if (index !== -1) {
        this.lawRegList.splice(index, 1);
    }

    }


  }

  addLocations()
  {
    this.showLocation = !this.showLocation;
  }

  saveLocation(name:string,address:string)
  {


    let temploc = new Location();
    temploc.id=this.locCounter++;
    temploc.name = name;
    temploc.address=address;
    temploc.officer='';


    this.locationList.push(temploc);
    if(this.locationList.length > 0)
    {
     this.showlocTable=false;
    }

  }

  ngAfterViewInit() {


  }






}


export class Location{
  id: number;
  name: string;
  address: string;
  officer:string;
}
