import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';

const postUrl = '/sendEmail/';

const postUrlSelected = '/sendEmail/selected';
const sendEmailtoUserUrl = '/sendEmail/user';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  baseUrl:any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
   }
  
 
  sendEmail(message:string,pendingOutcomesList:any,to:string,approvalStatus:string,caseId:string,serviceId:string,providerId:string,recordnumber:string,user:string): Observable<any> {
    const emailjson={'serviceId':serviceId,'providerId':providerId,'approverName':user,'recordnumber':recordnumber,'caseId':caseId,'message':message,'pendingOutcomes':pendingOutcomesList,'to':to,'approvalStatus':approvalStatus};
    return this.http.post<any>(this.baseUrl+postUrl , emailjson)
      .pipe(
        catchError(this.handleError('sendEmail', emailjson))
      );
  }

  sendEmailSelected(message:string,pendingOutcomesList:any,to:string,subject:string,approvalStatus:string,caseId:string,serviceId:string,providerId:string,recordnumber:string,user:string): Observable<any> {
     const emailjson={'serviceId':serviceId,'providerId':providerId,'approverName':user,'recordnumber':recordnumber,'caseId':caseId,'message':message,'pendingOutcomes':pendingOutcomesList,'to':to,'subject':subject,'approvalStatus':approvalStatus};
    return this.http.post<any>(this.baseUrl+postUrlSelected , emailjson);
     /* .pipe(
        catchError(this.handleError('sendEmail', emailjson))
      );*/
  }



  sendEmailtoUser(message:string,to:string)
  {
    
    const emailjson={'message':message,'to':to,'pendingOutcomes':null};
    return this.http.post<any>(this.baseUrl+sendEmailtoUserUrl , emailjson)
      .pipe(
        catchError(this.handleError('sendEmail to User', emailjson))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
