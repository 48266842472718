<!--Main Navigation-->
<header>
 

<!--Navbar-->
<mdb-navbar [containerInside]="false" SideClass="navbar backcolor navbar-expand-lg" >

    <!-- Navbar brand -->
    
    <logo class="ml-7">     
  
                    <img src="../../../assets/doe-logo-white.png" style="width:40px;size:14px" alt="">  </logo>
                
                    <div class="navbar-nav w-100 border-0 ml-4 white-text ">
                       eCompliance
                
                    </div>                
                           
                      
                

     
  
    <div class="navbar-nav justify-content-end w-100 border-0 " *ngIf="getlink()">
            <span  style="color:white;font-size:14px">G'Day {{firstName}} {{role}}</span>
           <!--<a (click)="getLogout();" title="Logout">
                <img src="../../../assets/logoutChanged.png" style="width:60px;size:20px" alt=""> 
                <mdb-icon fas icon="user-circle"  class="ml-3 text-danger  fa-lg"></mdb-icon>
            </a> --> 
            <button type="button" mdbBtn size="sm"
                            class="relative waves-light logoutcolor rounded text-white font-weight-bold ml-3 mt-0 p-1"
                            mdbWavesEffect (click)="getLogout();"><mdb-icon fas icon="user-circle" size="lg" class="mr-1"></mdb-icon>Logout</button>
          
        </div>
   
</mdb-navbar>



<mdb-navbar *ngIf="getlink()" [containerInside]="false" style="height:1em" SideClass="navbar navbar-expand-lg navbar-light ">

    <!-- Navbar brand -->
    <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>

        <!-- Links -->
        <ul class="navbar-nav mr-auto" >
            <li class="nav-item active">
              
                <a routerLink="visits/pages/visit1" routerLinkActive="active"  style="font-size: 10;"  mdbWavesEffect  >
                    <mdb-icon fas icon="home" class="mr-1"></mdb-icon>Home</a> 
            </li>
           


            <!-- Dropdown -->
           <!-- <li class="nav-item active navcolor">
              
                <a routerLink="pages/details/detail1/fdc" routerLinkActive="active"  class="nav-link font-weight-bold text-black  waves-light white-hover lighten-2-hover sidenavcolor cardheader" mdbWavesEffect  >
                    <mdb-icon fas icon="users-cog" class="mr-3"></mdb-icon>Admin</a> 
                    
            </li>-->


            <li class="nav-item dropdown btn-group active " style="margin-left: 2em" dropdown *ngIf="role==='ADMIN'">
              
                <a dropdownToggle type="button"  mdbWavesEffect>
                    <mdb-icon fas icon="users-cog" class="mr-1"></mdb-icon> Administration
                    <i class="fas fa-angle-down"></i>
                </a>
                <div class="dropdown-menu dropdown-primary waves-light font-weight-bold text-black  waves-light white-hover lighten-2-hover sidenavcolor cardheader" role="menu">
                <a class="dropdown-item" routerLink="/Admin/pages/admin/manageUsers" routerLinkActive="active" >Manage User</a>
                <a class="dropdown-item" routerLink="/Admin/pages/admin/addRegulations" routerLinkActive="active"  >Law/Reg Maintenance</a>
             <!--   <a class="dropdown-item" routerLink="/Admin/pages/admin/manageLOV" routerLinkActive="active"  >LOV Maintenance</a> -->
                <a class="dropdown-item" routerLink="/Admin/pages/admin/manageEmail" routerLinkActive="active">Create Email Templates</a>
                </div>
         </li>


        </ul>
        <!-- Links -->
        <app-help title="help text"></app-help>
       <!--<a (click)="getHelp();"><img src="../../../assets/help_5.png" class="img-fluid" alt=""> </a> --> 
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
  </header>
  <!--Main Navigation-->

  