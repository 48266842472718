
<fieldset class="scheduler-border">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-briefcase"
            style="color:#041e42;padding-right: 6px;"></i>Email Template List</legend>

    <div class="mb-3 ">
        <table mdbTable id="emailTemplateListTable" class="z-depth-1 doe-table-body border border-1" hover="true">

            <!--Table head-->
            <thead class="lawReg-table-header tableheadercolor">
                <tr class="">
                    <th *ngFor="let head of headElementsEmailTemplate; let i = index" aria-controls="emailTemplateList"
                        [mdbTableSort]="listOfEmailAdmin" [sortBy]="headElementsEmailTemplateSort[i]" scope="col"
                        style="white-space: nowrap;">
                        {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                        <div (click)="changeCaseStatus($event)">
                            <input style="font-size:12px;" class="form-control form-control-sm text-black p-0 border border-dark" type="search"
                            placeholder="" mdbInput id="input1" aria-label="Search"
                            (search)="onFilter($event.target.value,head)" (keyup)="onFilter($event.target.value,head)">
                          <!--  <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i> -->
                        </div>
                    </th>
                 </tr>
           </thead>
            <!--Table body-->
            <tbody>


                <tr class="showpointer" *ngFor="let emailTemplate of listOfEmailAdmin | paginate: config"
                    (click)="setselected(emailTemplate);"
                    [ngClass]="{'selectedcolor': (this.editEmailFunctId===emailTemplate.emailFunctId)?true:false}">
                    <td class="doe-table-body">{{emailTemplate.emailNotificationFunction}}</td>
                    <td class="doe-table-body">{{emailTemplate.emailFrom}}</td>
                    <td class="doe-table-body">{{emailTemplate.emailTo}}</td>
                    <td class="doe-table-body">{{emailTemplate.emailCc}}</td>
                    <td class="doe-table-body">{{emailTemplate.emailSubject}}</td>
                    <td class="doe-table-body">{{emailTemplate.emailContent}}</td>
                </tr>


            </tbody>
        </table>
        <div class="mt-2 text-center doe-table-body">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>

    <div class="text-right">
        <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5" size="sm" type="submit"
            (click)="clickcreate=true;clickedit=false;">Create
        </button>

        <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5" size="sm" type="submit"
            (click)="validateEdit();">Edit
        </button>
        <!-- <button mdbBtn class=" buttoncolor rounded text-white font-weight-bold px-5"
                  size="sm" type="submit" (click)="(editLawId!==null && editLawId!==undefined)?ConfirmationDeleteModal.show():validateEdit()">Delete
               </button> -->
    </div>

</fieldset>
<fieldset class="scheduler-border" *ngIf="this.clickcreate===true">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-plus"
            style="color:#041e42;padding-right: 6px;"></i>Create Email Template</legend>

    <div>

        <form class="">
            <div class=" py-2">
                <label class="headertexts">Notification Function Name</label>
                <input class="form-control" type="text" name="emailNotificationFunction" placeholder="Outcome Type Name"
                    [(ngModel)]="emailNotificationFunction" />
            </div>

            <div class=" py-2">
                <label class="headertexts">Email From</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailFrom"
                    [(ngModel)]="emailFrom" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email To</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailTo" [(ngModel)]="emailTo" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email CC </label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailCc" [(ngModel)]="emailCc" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Subject</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailSubject"
                    [(ngModel)]="emailSubject" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email Body</label>
                <textarea class="form-control" rows="8" maxlength="4000" name="emailBody"
                    [(ngModel)]="emailContent"></textarea>
            </div>
            <div class=" text-right">
                <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5" size="sm" type="submit"
                    (click)="onAddEmailAdmin()">Save
                </button>
            </div>

        </form>
        <!-- Form -->
    </div>
</fieldset>
<fieldset class="scheduler-border" *ngIf="this.clickedit===true">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-edit"
            style="color:#041e42;padding-right: 6px;"></i>Edit Email Template</legend>

    <div>

        <form class="">
            <div class=" py-2">
                <label class="headertexts">Notification Function Name</label>
                <input class="form-control" type="text" name="editEmailNotificationFunction"
                    [(ngModel)]="editEmailNotificationFunction" />
            </div>

            <div class=" py-2">
                <label class="headertexts">Email From</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailFrom"
                    [(ngModel)]="editEmailFrom" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email To</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailTo"
                    [(ngModel)]="editEmailTo" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email CC </label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailCc"
                    [(ngModel)]="editEmailCc" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Subject</label>
                <input class="form-control" type="text" rows="3" maxlength="100" name="emailSubject"
                    [(ngModel)]="editEmailSubject" />
            </div>
            <div class=" py-2">
                <label class="headertexts">Email Body</label>
                <textarea class="form-control" rows="8" maxlength="4000" name="emailBody"
                    [(ngModel)]="editEmailContent"></textarea>
            </div>
            <div class=" text-right">
                <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5" size="sm" type="submit"
                    (click)="onEditEmailAdmin()">Save
                </button>
            </div>

        </form>
        <!-- Form -->
    </div>
</fieldset>