import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';

import {DataService} from '../../../visits/visit1/visit1.service';

@Component({
  selector: 'app-profile1',
  templateUrl: './centerbased.component.html',
  styleUrls: ['./centerbased.component.scss']
})
export class Profile1Component implements OnInit  {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild('row', { static: true }) row: ElementRef;

 
  
  previous: any = [];
  headElements = ['id', 'name', 'address', 'officer'];
 selectedtab:string;
  searchText: string = '';
  searchValue:string;
  maxVisibleItems: number = 2;

  headElementsLawReg = ['INA-ID','lawid','priority','description', 'Status'];
  headEvidenceList = ['location Name','Officer','Evidence Description'];
  showLocation = true;
  showlocTable = true;
  locCounter = 0;

  assignedACOs = ["mahinder", "newACO"];
  headElementsSummary=['Location Name','Officer Name','INA-ID','lawid','priority','description', 'Status','evidence','attachments']
  statusCompliance = ["Pending", "Compliant","non Compliant","NA"];
  outcomeStatus = ["Further Investigation", "Issue Notice","Close","Letter notice"];
  reviewerComments = "reviewer comments here";
  approverComments = "approver comments here";
  EvidenceList =[
    {id: 1, locationName: 'chatswood',officer: "ACO1", description:"Emergency and evacuation procedures breached, no EXIT signs and assembly points available"},
    {id: 2, locationName: 'wentworthville',officer: "ACO2", description:"Children not to be alone with visitors - sited visitors talking to children and not attended by any educators.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true}
  ];
  moreLawRegList = [
    {inaid:'INA-00001926',id: 170, priority: 'high',lawid: "Law 170(2)", description:"Offence relating to unauthorised persons on education and care service premises",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001923',id: 164, priority: 'high', lawid: "Regulation 164(1a)", description:"Requirement for notice of new persons at residence",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001926',id: 166, priority: 'high', lawid: "Regulation 166(1)", description:"Children not to be alone with visitors",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001926',id: 100, priority: 'medium',lawid: "Regulation 100(1)", description:"Risk assessment must be conducted before excursion",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001924',id: 97, priority: 'low',lawid: "Regulation 97(1), (2), (3)(ab) and (4)", description:"Emergency and evacuation procedures",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001924',id: 175, priority: 'medium',lawid: "Law 175(1) (2) and (3) ", description:"Offence relating to requirement to keep enrolment ",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true}
    
   
  ];

  subLawRegList = [
    {inaid:'INA-00001926',id: 164,priority: 'high', lawid: "Regulation 164(1a)", description:"Requirement for notice of new persons at residence. The approved provider of the family day care service failed to ensure that each family day care educator notifies the provider of –(a) any new person aged 18 years or over who resides, or intends to reside, at the educator’s family day care residence.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001923',id: 166,priority: 'high', lawid: "Regulation 166(1)", description:"Children not to be alone with visitors. The approved provider failed to take all reasonable steps to ensure that a child being educated and cared for at a family day care residence or approved family day care venue as part of the service is not left alone with a visitor to the residence or venue.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001923',id: 100, priority: 'medium',lawid: "Regulation 100(1)", description:"Risk assessment must be conducted before excursion. The approved provider of the education and care service failed to ensure a risk assessment is carried out in accordance with regulation 101 before an authorisation is sought under regulation 102 for an excursion.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001924',id: 170, priority: 'high', lawid: "Law 170(2)", description:"Offence relating to unauthorised persons on education and care service premises, The approved provider of the education and care service failed to ensure that a person does not remain at the education and care service premises while children are being educated and cared for at the premises, unless – (a)  the person is an authorised person, or(b)  the person is under the direct supervision of an educator or other staff member of the service.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001924',id: 97, priority: 'low',lawid: "Regulation 97(1)", description:"The approved provider failed to ensure that, for the purposes of preparing the emergency and evacuation procedures, educators had appropriate emergency and evacuation instructions and floor plans.'\n'The emergency and evacuation procedures required under regulation 168 must set out—(a)  instructions for what must be done in the event of an emergency; and(b)  an emergency and evacuation floor planAuthorised officers attended the premises of the following educator and identified the issues outlined below:",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001924',id: 97,priority: 'low', lawid: "Regulation 97(2)", description:"The approved provider failed to ensure that, for the purposes of preparing the emergency and evacuation procedures, the approved provider of the education and care service must ensure that a risk assessment is conducted to identify potential emergencies that are relevant to the service.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001926',id: 97,priority: 'low', lawid: "Regulation 97(3)ab", description:"The approved provider of the education and care service failed to ensure that:(ab) in the case of a family day care service, the emergency and evacuation procedures are rehearsed every 3 months by each family day care educator and children being educated and cared for by the family day care educator on that day; and (b)  the rehearsals of the emergency and evacuation procedures are documented. ",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001926',id: 97, priority: 'low',lawid: "Regulation 97(4)", description:"The approved provider of the education and care service failed to ensure that a copy of the emergency and evacuation floor plan and instructions are displayed in a prominent position near each exit at the education and care service premises, including a family day care residence and approved family day care venue.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true},
    {inaid:'INA-00001926',id: 175, priority: 'medium', lawid: "Law 175(1)", description:"The approved provider of the education and care service failed to ensure that each family day care educator who educates and cares for a child at a residence or approved family day care venue, as part of a family day care service, must keep the prescribed documents available for inspection by an authorised officer at that residence or venue.Regulation 177(1) provides that, for the purposes of section 175(1) of the Law, a record of assessments of family day care residences and approved family day care venues conducted under regulation 116, is a prescribed document in relation to each education and care service operated by the approved provider.",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true}
    
  ];

  sortedLawRegList : any[];

  ACOs = [
    {id: 1, name: "ACO1"},
    {id: 2, name: "ACO2"},
    {id: 3, name: "ACO3"},
    {id: 4, name: "ACO4"},
    {id: 5, name: "ACO5"},
    {id: 6, name: "mahinder"},
    {id: 7, name: "newACO"}
  ];
  lawRegList = [
    {evidence:'Emergency and evacuation procedures breached, no EXIT signs and assembly points available',attachment:'image1.gif,image2.png',locationName:'chatswood',officer:'ACO1',inaid:'INA-00001926',id: 167, priority: 'high',lawid: "Law 167(1)", description:"Offence relating to protection of children from harm and hazards The approed provider of the education and care service failed to ensure that every reasonable precaution is taken to protect children being educated and cared for as part of the family day care service from harm and from any hazard likely to cause injury. Authorised officers attended the premises of the following educators and identified potential poison, electrocution, strangulation, crushing, cutting, entrapment harms and hazards.",complianceStatus:'Pending',evidenceFlag:true,showEvidenceFlag:true},
    {evidence:'Children not to be alone with visitors - sited visitors talking to children and not attended by any educators.',attachment:'image1.gif,image2.png',locationName:'chatswood',officer:'ACO1',inaid:'INA-00001926',id: 165, priority: 'medium', lawid: "Law 165(1)", description:"Offence to inadequately supervise children. The approved provider of the education and care service failed to ensure that all children being educated and cared for by the service are adequately supervised at all times that the children are in the care of that service.",complianceStatus:'non Compliant',evidenceFlag:true,showEvidenceFlag:true},
    {evidence:'Emergency and evacuation procedures breached, no EXIT signs and assembly points available',attachment:'image1.gif,image2.png',locationName:'wentworthville',officer:'ACO2',inaid:'INA-00001924',id: 166, priority: 'high',lawid: "Law 166(1)", description:"Offence to use inappropriate discipline. '\n' The approved provider of the education and care service failed to ensure that no child being educated and cared for by the service is subjected to –(a)	any form of corporal punishment; or(b)	any discipline that is unreasonable in the circumstances1.	At the residence of educator xxxx, located at 3540 North Rocks Road, Carlingford, there were five school aged children in attendance and authorised officers observed on three occasions, Ms xxxx using inappropriate discipline/behaviour guidance strategies with the children. This included Ms xxxx pulling children by the wrist in a rough manner and grabbing a child by the upper arm and stating, \"Do not do that, say sorry\". Authorised officers spoke to Ms xxxx and advised this was inappropriate behaviour management and that she had already been spoken to about this earlier during the visit. Ms xxxxx was unaware that this was inappropriate.During the visit, children advised the authorised officers that when they misbehave, Ms xxxx places them in time-out for 10 minutes where they cannot do anything.",complianceStatus:'Compliant',evidenceFlag:true,showEvidenceFlag:true},
    {evidence:'Children not to be alone with visitors - sited visitors talking to children and not attended by any educators.',attachment:'image1.gif,image2.png',locationName:'wentworthville',officer:'ACO2',inaid:'INA-00001923',id: 81, priority: 'low',lawid: "Regulation  81(1)", description:"Sleep and rest. The approved provider of the education and care service failed to take reasonable steps to ensure that the needs for sleep and rest of children being educated and cared for by the service are met, having regard to the ages, development stages and individual needs of the children.",complianceStatus:'non Compliant',evidenceFlag:true,showEvidenceFlag:true}
    
  ];
  locationList: any[] = [
    {id:1,name:"EducatorName Loc1", address:"south wentworthville",officer:["Paul","Jhon"],showtab:true, selected:true},
    {id:2,name:"EducatorName Loc2", address:"carlingford",officer:["Paul","Jhon"],showtab:false}
  ];

 
  sublawfiltelist : any[][];
 
 selectedValue = null;

 emptyLocations : false;
 editField: string;
 passeddata:any;
 role:string;
 filenames:any[] =[];
constructor(private dataservice : DataService) {
  this. bntStyle = 'btn-default';
  
 }

/*

@HostListener('input') oninput() {
  this.mdbTablePagination.searchText = this.searchText;
}

*/

  ngOnInit() {
    this.sortedLawRegList = [...this.moreLawRegList];
    
    
    this.passeddata =  this.dataservice.getOption();
    this.role=this.dataservice.getRole();

   if(this.locationList.length > 0)
   {
    this.showlocTable=false;
   }
   
    
  }

  addACO(aconame : string, loc:any ) {
   
    const index: number = this.locationList.indexOf(loc);
    if (index !== -1) {
      const index2 = this.locationList[index].officer.indexOf(aconame);
      {
        if(index2!== -1)
        {
          this.locationList[index].officer.push(aconame);
        }
      }
        
    } 
    
  }

  deleteACO(location:any, aconame : string) {
    const index: number = this.locationList.indexOf(location);
    if (index !== -1) {
      const index2 = this.locationList[index].officer.indexOf(aconame);
      {
        if(index2!== -1)
        {
          this.locationList[index].officer.splice(index2,1);
        }
      }
        
    } 

  }

  askEvidence(obj:any) {
   
    obj.evidenceFlag = !obj.evidenceFlag;


  }

  opentab(loc:any)
  {
    this.locationList.map(el=> {
      if(el==loc)
        el.showtab = true;
      else
        el.showtab = false;
    });
   
   
  }

  showEvidence(obj:any) {
   
    obj.showEvidenceFlag = !obj.showEvidenceFlag;


  }

  updatestatus(item: any, compstatus: string)
  {
    item.complianceStatus = compstatus;
  }

  addMoreLawsandRegs(item:any)
  {
    
    item.selectedflag = !item.selectedflag;

    if(item.selectedflag)
    {
      this.lawRegList.push(item);
    }
    else{
      const index: number = this.lawRegList.indexOf(item);
      if (index !== -1) {
        this.lawRegList.splice(index, 1);
    } 

    }
    

  }

  getsublaws(lawid : number)
  {
   
    
   return this.subLawRegList.filter(el => (el.id==lawid ));
   
  
  }

  addLocations()
  {
    this.showLocation = !this.showLocation;
  }

  saveLocation(name:string,address:string,officer:string)
  {
   
  
    let temploc = new Location();
    temploc.id=this.locCounter++;
    temploc.name = name;
    temploc.address=address;
    temploc.officer.push(officer);


    this.locationList.push(temploc);
    if(this.locationList.length > 0)
    {
     this.showlocTable=false;
    }



    
  }

  ngAfterViewInit() {

       
  }

 onseraching(searchValue:string)
 {
  
  this.sortedLawRegList = this.moreLawRegList.filter(el => (el.lawid.toLowerCase().includes(searchValue.toLowerCase())==true || el.description.toLowerCase().includes(searchValue.toLowerCase())==true));
  
  
 }

 preventClose(event: MouseEvent) {
  event.stopImmediatePropagation();
  }
  
 refreshlist()
 {
  this.sortedLawRegList = [...this.moreLawRegList];
 }

 updateSatus(enddate:string)
 {
  
  if(enddate.length!=0)
  {
    this.passeddata.VisitStatus = 'completed';
    this.dataservice.setOption(this.passeddata);
  }else{
    this.passeddata.VisitStatus = 'Visit Pending';
    this.dataservice.setOption(this.passeddata);
  }
 }
  
 getFiles(filename:string)
 {
  
  this.filenames.push(filename);
  
 }
 bntStyle: string;

 tabclick(loc:any){

  this.selectedtab = loc.name;

  this.locationList.map(el=> {
    if(el==loc)
      el.selected = true;
    else
      el.selected = false;
  });
 
 }

}

export class Location{
  id: number; 
  name: string;
  address: string;
  officer:string[] = [];
  selected:boolean;
}
