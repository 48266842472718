<hr>
<table mdbTable id="chooseitems" 
class="z-depth-1 px-4 py-4 doe-table-body mt-2" hover="true" *ngIf="accessList.indexOf('ChooseOutcome')>-1">
<tbody>
    <tr class="w-100">
        <td class="w-50">
<div class=" mt-2" mdbDropdown *ngIf="accessList.indexOf('ChooseOutcome')>-1">
    <!--<a class="trigger dropdown-toggle waves-light headertexts p-0 ml-1 buttoncolor text-white font-weight-bold" placement="top"  mdbDropdownToggle>
       
        Choose Outcome Type </a> -->

        <button mdbBtn class="trigger dropdown-toggle waves-light  buttoncolor rounded text-white font-weight-bold ml-2" placement="top"  mdbDropdownToggle size="sm"
        >
        Choose Outcome 
    </button>
  
      <div class="dropdown-menu dropdown-primary p-4 colselection" >
          <!-- Default unchecked -->
     

         <div  *ngFor="let status of outcomeStatus; let i = index" >
                     <mdb-checkbox  [default]="false" (change)="addtoOutcomeTable(status,$event.checked);"  >{{status}}</mdb-checkbox> 
               
                  </div>
  
       </div>
  
    
  <!--<div class="col-md-4 py-4 ml-1">
       
        <label class="headertexts">Outcome Type: </label>
        
          <select class="browser-default custom-select" (change)="onCaseStatusChange($event.target.value);" name="sel2">
                        
            <option *ngFor="let status of outcomeStatus" [value]="status"  [selected]="status==passeddata.CaseStatus">
                <mdb-checkbox [default]="false" (change)="addOutcometoView(status,$event.checked)">{{status}}</mdb-checkbox>
                                                  
            </option>
          </select>


    </div> -->
  

</div>
</td>
<td class=""> 
    <div class="custom-file  w-50  ">

        <div class="input-group p-0">

            <div class="custom-file">
                <input type="file"
                    class="custom-file-input "
                    accept=".jpg,.pdf"
                    id="inputGroupFile01"
                    multiple
                    (change)="onFileChanged($event,item)"
                   
                   >
                <label class="custom-file-label"
                    for="inputGroupFile01">{{filename}}</label>
            </div>
        </div>

    </div>

    <button type="button" mdbBtn size="sm"
    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mb-0"
    mdbWavesEffect (click)="onUpload(item);">
    <div *ngIf="uploading" >
        <div class="spinner-border text-primary" role="status">
        </div>
    </div>
    <span *ngIf="!uploading"> Upload </span>
    </button>


    <div>
        <label class="mt-4">( file can be of
            type
            .jpg,.pdf
            only and cannot be more than 10 MB)</label> 
    </div>
   
</td>
</tr>
</tbody>
</table>
<hr>
<div><label class="headertexts mt-1">Documents List:</label> </div>

<div>

<table mdbTable id="documentsTable" 
class="z-depth-1 px-4 py-4 doe-table-body" hover="true">

<!--Table head-->
<thead class="lawReg-table-header tableheadercolor">
    <tr class="table-responsive">
        <th *ngFor="let head of headElementsDocuments; let i = index"
            aria-controls="documentsTable" [mdbTableSort]="outcomeDocumentsList"
            [sortBy]="headElementsDocumentsSort[i]" scope="col">
            {{head}} <mdb-icon fas icon="sort"></mdb-icon>
        </th>
    </tr>

</thead>
 
<!--Table body-->
<tbody>


    <tr *ngFor="let doc of outcomeDocumentsList | paginate: config"> 
        <td class="doe-table-body">{{doc.outcomeType}}  <a [routerLink]="['/emailTemplates/emergencyAction/showtemplate']"  
            routerLinkActive="active"> <mdb-icon fas icon="pencil-alt" class="prefix text-danger"  *ngIf="passeddata.CaseStatus!==Approval && accessList.indexOf('ChooseOutcome')>-1 && doc.status!=='Approved' && doc.status!=='Attached Document' && doc.outcomeType !=='No further'" (click)="setValues(doc.outcomeType);"></mdb-icon></a>
</td>

<td class="doe-table-body">
    <a *ngIf="doc.trimId!==null && doc.trimId.trim()!=='' &&  doc.trimId!=='undefined' && doc.trimId!=='null'" class="text-primary" (click)=loadDoc(doc.trimId);>{{doc.outcomeType}}</a>
    <a *ngIf="(doc.outcomeType !=='No further') && doc.trimId===null || doc.trimId.trim()==='' || doc.trimId==='undefined' ||  doc.trimId==='null'" (click)="generateAttachmentPdf(doc.outcomeType);" class="text-primary" ><span class="text-danger">Draft</span> {{doc.outcomeType}}</a>
</td>
        <td class="doe-table-body">{{doc.status}}</td>
        <td class="doe-table-body">{{doc.draftedBy}}</td>
        <td class="doe-table-body">{{doc.draftedDate | date: 'dd-MM-yyyy'}}</td>
        <td class="doe-table-body">{{doc.approvedBy}}</td>
        <td class="doe-table-body">{{doc.approvedDate | date: 'dd-MM-yyyy'}}</td>
        <td class="doe-table-body">{{doc.reviewedBy}}</td>
        <td class="doe-table-body">{{doc.reviewedDate | date: 'dd-MM-yyyy'}}</td>
        <td><mdb-icon fas icon="window-close" *ngIf="(doc.status==='In Progress' && accessList.indexOf('ChooseOutcome')>-1) || (doc.status==='Attached Document' && accessList.indexOf('deleteHistoryDoc')>-1) " class="ml-1 text-danger cursor" (click)="removeDoc(doc);"></mdb-icon></td>
     
  
    </tr>
    

</tbody>


</table>

<div class="mt-2 text-center">
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
</div>

</div>