<div class="dark-font bc-icons-2" mt-2>
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" mdbWavesEffect class="headertexts">
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> Home
            </a>

        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item>
            <a routerLink="/details/pages/detail1/case" routerLinkActive="active" mdbWavesEffect class="headertexts">
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Details</span>

            </a>

        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item>
            <a class="headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span
                    class="headertexts">{{outcomeType}}</span>
            </a>

        </mdb-breadcrumb-item>

    </mdb-breadcrumb>
</div>

<!--<div class="text-right mt-0">

     <button type="button" mdbBtn size="sm" 
     class="relative waves-light buttoncolor rounded text-white font-weight-bold  trigger" mdbWavesEffect [routerLink]="['/details/pages/detail1/case']" 
     routerLinkActive="active">Go Back</button>
  
 </div> -->
<div class="row col-md-12">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <fieldset class="scheduler-border">
            <legend class="scheduler-border" icon="ui-icon-check"> {{outcomeType}}</legend>
            <div class="text-right">
                <button type="button" mdbBtn size="sm"
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0"
                    mdbWavesEffect (click)="generateInitialAttachmentPdf(outcomeType)"> View Template</button>
            </div>
            <div class="form-row">
                <label for="approvalName">Approved Provider Name</label>
                <input type="text" id="approvalName" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="approvalName"  />
            </div>
            <div class="form-row">
                <label for="pmcNameWithTitle">Primary Contact</label>
                <input type="text" id="pmcNameWithTitle" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="pmcNameWithTitle" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Decision not to Cancel Provider Approval'||outcomeType === 'Decision not to Cancel Service Approval'">
                <label for="showCauseIssueDateToServiceProvider">Show Cause Issued Date</label>
                <input type="text" id="showCauseIssueDateToServiceProvider" class="form-control" rows="3"
                    maxlength="1000" [(ngModel)]="showCauseIssueDateToServiceProvider" />
            </div>

            <div class="form-row"
                *ngIf="outcomeType === 'Decision not to Cancel Provider Approval'||outcomeType === 'Decision not to Cancel Service Approval'">
                <label for="revisitDate">Revisit Date</label>
                <input type="text" id="revisitDate" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="revisitDate" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Decision not to Cancel Provider Approval'||outcomeType === 'Decision not to Cancel Service Approval'||outcomeType === 'Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval'">
                <label for="responseReceivedDate">Response Received Date</label>
                <input type="date" id="responseReceivedDate" name="responseReceivedDate" class="form-control"
                    [ngModel]="responseReceivedDate | date:'yyyy-MM-dd'"
                    (ngModelChange)="responseReceivedDate = $event" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Decision not to Cancel Provider Approval'||outcomeType === 'Decision not to Cancel Service Approval'">
                <label for="initialVistDate">Initial Visit Date</label>
                <input type="text" id="initialVisitDate" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="initialVisitDate" />
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Decision not to Cancel Service Approval'">
                <label for="dateToBeImposed">Date To Be Imposed</label>
                <input type="date" id="dateToBeImposed" name="dateToBeImposed" class="form-control"
                    [ngModel]="dateToBeImposed | date:'yyyy-MM-dd'" (ngModelChange)="dateToBeImposed = $event" />
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Decision not to Cancel Service Approval'">
                <p><b>Assessment Conditions</b></p>
                <label for="emailTemplateTextAreaPart1" class="headertexts"></label>
                <!--<editor apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" id="assessmentConditions"
                    [initialValue]="(assessmentConditions)?assessmentConditions:''" [(ngModel)]="assessmentConditions"
                    [init]="{
                      width:500,
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print',
                        'preview anchor searchreplace visualblocks code',
                        'fullscreen insertdatetime media table paste',
                        'help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | help'
                    }">
                </editor>-->

                <p-editor name="assessmentConditions" 
                [(ngModel)]="assessmentConditions"
                 #editor [style]="{'height':'320px'}">
                 <p-header>
                    <span class="ql-formats">
                      <select class="ql-size">
                        <option value="small">Small</option>
                        <option selected></option>
                        <option value="large">Large</option>
                        <option value="huge">Huge</option>
                      </select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button class="ql-italic" aria-label="Italic"></button>
                      <button class="ql-underline" aria-label="Underline"></button>
                      <button class="ql-strike" aria-label="Strike"></button>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <select title="Text Alignment" class="ql-align" >
                          <option selected>Gauche</option>
                          <option value="center" label="Center"></option>
                          <option value="right" label="Right"></option>
                          <option value="justify" label="Justify"></option>
                        </select>            
                      </span> 
                    </p-header>
              
                </p-editor> 

                
            </div>
            
            <div class="form-row"
                *ngIf="outcomeType === 'Admin Letter' || outcomeType === 'Emergency Action' ||  outcomeType === 'Compliance Notice'
                || outcomeType === 'Caution Letter'|| outcomeType ==='Suspension of Provider Approval'|| outcomeType === 'Suspension of Service Approval'
                ||outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'">
                <label for="visitDates">Visit Date</label>
                <input type="text" id="visitDates" class="form-control" rows="3" maxlength="400"
                    [(ngModel)]="visitDates" />
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Admin Letter'">
                <label for="personNamePresentOnVisit">Responsible Person Present</label>
                <input type="text" id="personNamePresentOnVisit" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="personNamePresentOnVisit" />
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Compliance Notice'">
                <label for="daysAfterSent">Weeks From Sent Date</label>
                <input type="text" id="daysAfterSent" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="daysAfterSent" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Compliance Direction'|| outcomeType === 'Suspension of Service Approval'">
                <label for="visitSummary">Visit Summary (including the purpose and date)</label>
                <textarea class="form-control evidenceText border border-info" id="visitSummary" rows="10"
                    maxlength="200" id="visitSummary" name="visitSummary" class="form-control"
                    [(ngModel)]="visitSummary"></textarea>
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Compliance Notice'">
                <label for="reasonForVisit">Reason for Visit</label>
                <textarea class="form-control evidenceText border border-info" id="reasonForVisit" rows="10"
                    maxlength="200" id="visitSummary" name="reasonForVisit" class="form-control"
                    [(ngModel)]="visitSummary"></textarea>
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Compliance Direction'">
                <label for="considerRiskAndWorkRquiredDate">Date that considers risk and the work required</label>
                <input type="text" id="considerRiskAndWorkRquiredDate"
                    maxlength="200" name="considerRiskAndWorkRquiredDate" class="form-control"
                    [(ngModel)]="considerRiskAndWorkRquiredDate"/>
            </div>

            <div class="form-row" *ngIf="outcomeType === 'Compliance Notice'||outcomeType === 'Compliance Direction'">
                <label for="officerName">Officers Name</label>
                <input type="text" id="officerName" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="officerName" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Compliance Notice'||outcomeType === 'Admin Letter'||outcomeType === 'Compliance Direction'">
                <label for="hubEmailAddress">Hub Email Address</label>
                <input type="text" id="hubEmailAddress" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="hubEmailAddress" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Emergency Action' || outcomeType === 'Suspension of Service Approval' ">
                <label for="incidentOccurred">Service History and Department Action or Responses</label>
                <textarea class="form-control evidenceText border border-info" id="incidentOccurred" rows="10"
                    maxlength="400" [(ngModel)]="incidentOccurred"></textarea>

            </div>
            <div class="form-row" *ngIf="outcomeType === 'Admin Letter'||outcomeType === 'Emergency Action'">
                <label for="responseDueDate">Service Response Due Date</label>
                <input type="text" id="responseDueDate" class="form-control" rows="3" maxlength="100"
                    [(ngModel)]="responseDueDate" />
            </div>
          
          
            <div class="form-row" *ngIf="outcomeType === 'Caution Letter'">
                <label for="issuesAndSteps">Service History and Department Action or Responses</label>
                <textarea class="form-control evidenceText border border-info" id="issuesAndSteps" rows="10"
                    maxlength="4000" [(ngModel)]="issuesAndSteps"></textarea>

            </div>

            <!-- <div class="form-row"
                *ngIf="outcomeType === 'Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval'">
                <label for="cancellationReasons">Identify and justify that for each of the cancellation reasons </label>
                <textarea class="form-control evidenceText border border-info" id="cancellationReasons" rows="10"
                    [(ngModel)]="suspensionReasons"></textarea>
            </div> -->
            <div class="form-row"
            *ngIf="outcomeType === 'Suspension of Provider Approval'|| outcomeType === 'Suspension of Provider Approval'|| 
                   outcomeType === 'Show Cause Notice to Cancellation of Service Approval' || outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'">
            <label  for="groundForSuspension"  *ngIf="outcomeType === 'Suspension of Provider Approval'">Ground For Suspension</label>
            <label   for="groundForSuspension" *ngIf="outcomeType === 'Show Cause Notice to Cancellation of Service Approval' || outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'">
            Ground for proposed cancellation</label>
        
          <!-- <editor apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" id="groundForSuspension" 
                [initialValue]="(groundForSuspension)?groundForSuspension:''" [(ngModel)]="groundForSuspension"
                [init]="{
            
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print',
                'preview anchor searchreplace visualblocks code',
                'fullscreen insertdatetime media table paste',
                'help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | help'
            }">
            </editor> -->


            <p-editor name="groundForSuspension" 
            [(ngModel)]="groundForSuspension"
             #editor [style]="{'height':'320px'}">
             <p-header>
                <span class="ql-formats">
                  <select class="ql-size">
                    <option value="small">Small</option>
                    <option selected></option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold" aria-label="Bold"></button>
                  <button class="ql-italic" aria-label="Italic"></button>
                  <button class="ql-underline" aria-label="Underline"></button>
                  <button class="ql-strike" aria-label="Strike"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select title="Text Alignment" class="ql-align" >
                      <option selected>Gauche</option>
                      <option value="center" label="Center"></option>
                      <option value="right" label="Right"></option>
                      <option value="justify" label="Justify"></option>
                    </select>            
                  </span> 
                </p-header>
          
            </p-editor> 
        </div>
        
            <div class="form-row"
            *ngIf="outcomeType === 'Suspension of Provider Approval'|| outcomeType === 'Suspension of Provider Approval'|| 
            outcomeType === 'Cancellation of Provider Approval'||      outcomeType === 'Cancellation of Service Approval'||
            outcomeType === 'Show Cause Notice to Cancellation of Service Approval' || outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'">
            <label for="suspensionReasons" *ngIf="outcomeType === 'Suspension of Provider Approval'">Suspension Reasons</label>
            <label for="suspensionReasons" *ngIf="outcomeType === 'Show Cause Notice to Cancellation of Service Approval' || outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'
            || outcomeType === 'Cancellation of Provider Approval'|| outcomeType === 'Cancellation of Service Approval'">
            Identify and justify that for each of the proposed cancellation</label>
            
            <!--    <editor apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" id="suspensionReasons"
                    [initialValue]="(suspensionReasons)?suspensionReasons:''" [(ngModel)]="suspensionReasons" [init]="{
                 
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print',
                    'preview anchor searchreplace visualblocks code',
                    'fullscreen insertdatetime media table paste',
                    'help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | help'
                }">
             </editor> -->

             <p-editor name="suspensionReasons" 
             [(ngModel)]="suspensionReasons"
              #editor [style]="{'height':'320px'}">
              <p-header>
                <span class="ql-formats">
                  <select class="ql-size">
                    <option value="small">Small</option>
                    <option selected></option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold" aria-label="Bold"></button>
                  <button class="ql-italic" aria-label="Italic"></button>
                  <button class="ql-underline" aria-label="Underline"></button>
                  <button class="ql-strike" aria-label="Strike"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select title="Text Alignment" class="ql-align" >
                      <option selected>Gauche</option>
                      <option value="center" label="Center"></option>
                      <option value="right" label="Right"></option>
                      <option value="justify" label="Justify"></option>
                    </select>            
                  </span> 
                </p-header>
           
             </p-editor>


            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval'">
                <label for="summaryOfOngoingIssues">Summary of ongoing issues</label>
                <textarea class="form-control evidenceText border border-info" id="summaryOfOngoingIssues" rows="10"
                    [(ngModel)]="issuesAndSteps"></textarea>
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval'">
                <label for="showCauseIssueDate">Show Cause Notice Date</label>
                <input type="text" id="showCauseIssueDate" class="form-control" rows="3" maxlength="1000"
                    [(ngModel)]="showCauseIssueDateToServiceProvider" />
            </div>
            <div class="form-row"
                *ngIf="outcomeType === 'Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval'">
                <label for="cancellationEffectiveDate">Date Cancellation Take Effect</label>
                <input type="text" id="cancellationEffectiveDate" class="form-control" rows="3" maxlength="1000"
                    [(ngModel)]="responseDueDate" />
            </div>
            <div class="form-row"
            *ngIf="outcomeType === 'Show Cause Notice to Cancellation of Provider Approval'||outcomeType === 'Cancellation of Service Approval' || outcomeType === 'Cancellation of Provider Approval'">
            <label for="attachmentNames">Attachment Names</label>
            <input type="text" id="attachmentName" class="form-control" rows="3" maxlength="1000"
                [(ngModel)]="attachmentName" />
        </div>
        <div class="form-row"
        *ngIf="outcomeType === 'Compliance Notice'">
        <label for="summaryOfOngoingIssues">Summary of ongoing issues</label>
        <textarea class="form-control evidenceText border border-info" id="summaryOfOngoingIssues" rows="10"
            [(ngModel)]="issuesAndSteps"></textarea>
    </div>

            <!-- Added by Francis -->
            <div class="form-row" *ngIf="outcomeType === 'Admin Letter'|| outcomeType === 'Compliance Notice'|| outcomeType === 'Compliance Direction'||outcomeType === 'Emergency Action'||outcomeType === 'Caution Letter'"> 
                <label for="breachAreas">Breach Areas</label>
            </div>
            <div class="form-row" *ngIf="outcomeType === 'Admin Letter'|| outcomeType === 'Compliance Notice'|| outcomeType === 'Compliance Direction'||outcomeType === 'Emergency Action'||outcomeType === 'Caution Letter'">
                <p-multiSelect id="breachAreas" defaultLabel="Choose confirmed breach areas" 
                    selectedItemsLabel="{0} selected" maxSelectedLabels="1" [options]="breachAreaSelectItem"
                    [(ngModel)]="selectedBreachAreaStringArr"></p-multiSelect>
            </div>
            <!-- <div class="form-row"> 
                    <label for ="breachAreas">Breach Areas2</label>
                    <p-multiSelect id="breachAreas1" selectedItemsLabel = "{0} selected" maxSelectedLabels = "2" [options]="lawRegList"  optionLabel="lawid" [(ngModel)]="selectedBreachAreaStringArr"></p-multiSelect>
                </div>  -->


            <!-- end of francis code change-->
            <div class="form-row">
                <label for="signedBy">Signed By</label>
                <input type="text" id="signedBy" name="signedBy" class="form-control"  [(ngModel)]="signedBy"/>
            </div>
            <div class="form-row">
                <label for="signatureDate">Signature Date</label>
                <input type="date" id="signatureDate" placeholder="yyyy-MM-dd" name="signatureDate" class="form-control"
                    [ngModel]="signatureDate | date:'yyyy-MM-dd'" (ngModelChange)="signatureDate = $event">
            </div>
           
            <div class="text-right">
                <button type="button" mdbBtn size="sm"
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0"
                    mdbWavesEffect (click)="saveOutcomeTemplate1();">
                    Save</button>
                <button type="button" mdbBtn size="sm"
                    *ngIf="outcomeType==='Admin Letter'||outcomeType==='Emergency Action' || outcomeType==='Compliance Notice' || outcomeType==='Compliance Direction'"
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0"
                    mdbWavesEffect (click)="generateBreachTableTempletePdf(outcomeType);">
                    Table Preview</button>

                <button type="button" mdbBtn size="sm"
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0"
                    mdbWavesEffect (click)="PreviewConfirmationModal.show()">
                    Preview</button>
                
                <button type="button" mdbBtn size="sm"
                    class="relative waves-light buttoncolor rounded text-white font-weight-bold  trigger" mdbWavesEffect
                    [routerLink]="['/details/pages/detail1/case']" routerLinkActive="active">Go Back</button>
            </div>
        </fieldset>
    </div>
    <div class="col-md-3"></div>
</div>
<div mdbModal #PreviewConfirmationModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Header-->
            <div class="modal-header d-flex justify-content-center">
                <p class="heading">Have you saved your content before preview?</p>
            </div>

            <!--Body-->
            <div class="modal-body">
                <mdb-icon fas icon="save" size="4x" class="animated rotateIn"></mdb-icon>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a mdbBtn color="info" outline="true" class="waves-effect"
                    (click)="generateAttachmentPdf(outcomeType);PreviewConfirmationModal.hide();" mdbWavesEffect>Yes</a>
                <a type="button" mdbBtn color="info" class="waves-effect" mdbWavesEffect data-dismiss="modal"
                    (click)="PreviewConfirmationModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<div mdbModal #ReadyForReviewConfirmationModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Header-->
            <div class="modal-header d-flex justify-content-center">
                <p class="heading">Have you all your your content before submit for review?</p>
            </div>

            <!--Body-->
            <div class="modal-body">
                <mdb-icon fas icon="save" size="4x" class="animated rotateIn"></mdb-icon>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a mdbBtn color="info" outline="true" class="waves-effect"
                    (click)="saveOutcomeTemplateStatus(outcomeType);ReadyForReviewConfirmationModal.hide();" mdbWavesEffect>Yes</a>
                <a type="button" mdbBtn color="info" class="waves-effect" mdbWavesEffect data-dismiss="modal"
                    (click)="ReadyForReviewConfirmationModal.hide() "  [routerLink]="['/details/pages/detail1/case']" routerLinkActive="active">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>