import { Component, Input, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';


@Component({
  selector: 'app-summaryGraph',
  templateUrl: './summaryGraph.component.html',
  styleUrls: ['./summaryGraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
  

})
export class SummaryGraphComponent implements  OnChanges {

  
  
  @Input()
  lawRegList: any[] = [];
 
   @Input()
   statusUpdateFlag:boolean=false;


   public tempchartDatasets: Array<any> = [
    { data: [], label: 'Case Status' }
  ];
  ngOnChanges()
  {
        
       this.tempchartDatasets[0].data=[];
       this.tempchartDatasets[0].data.push(this.lawRegList.filter(param =>param.complianceStatus!==null &&  param.complianceStatus.toLowerCase() === 'Confirmed breach'.toLowerCase()).length);
       this.tempchartDatasets[0].data.push(this.lawRegList.filter(param =>param.complianceStatus!==null &&  param.complianceStatus.toLowerCase()  === 'Not reviewed'.toLowerCase()).length);
       this.tempchartDatasets[0].data.push(this.lawRegList.filter(param => param.complianceStatus!==null && param.complianceStatus.toLowerCase()  === 'Breach not confirmed'.toLowerCase()).length);
       this.tempchartDatasets[0].data.push(this.lawRegList.filter(param =>param.complianceStatus!==null &&  param.complianceStatus === 'N/A').length);
      
       this.chartDatasets=[...this.tempchartDatasets];
       this.ref.detectChanges();
     
  
   
  }

 

  constructor(private ref : ChangeDetectorRef) {
    ref.detach();
    setInterval(() => {
        this.ref.detectChanges();
    }, 500);

  }
  
  public chartType: string = 'pie';

  public chartDatasets: Array<any> = [
    { data: [], label: 'Case Status' }
  ];

  public chartLabels: Array<any> = ['Confirmed Breach', 'Not Reviewed', 'Breach Not Confirmed','N/A'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#F7464A',  '#FDB45C', '#46BFBD','#949FB1'],
      hoverBackgroundColor: ['#FF5A5E',  '#FFC870', '#5AD3D1','#A8B3C5'],
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void {console.log(e); }
  public chartHovered(e: any): void { console.log(e);}

  
}
