<div>
    <a><mdb-icon fas icon="pencil-alt" class="prefix text-danger" (click)="changeAddress($event);changeAddressModal.show();"></mdb-icon></a>
</div>    
<div mdbModal #changeAddressModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="changeAddressModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="changeAddressModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Update Address</p>
            </div>
            <div class="modal-body">
                         
                
                <form class="doe-table-body">
                   <div class="text-danger headertexts">
                      {{errormsg}}
                    </div>
                    <div class="form-group">
                      <input mdbInput type="text" class="form-control"  name="educatorName" [(ngModel)]="educatorName">
                      <label for="educatorName">Educator Name</label>
                    </div>
                    <!-- Default input -->
                    <div class="form-group">
                      <input mdbInput type="text" class="form-control"  name="inputAddress1" [(ngModel)]="inputAddress1">
                      <label for="inputAddress1"><span class="text-danger">*</span>Address Line 1</label>
                    </div>
                    <!-- Default input -->
                    <div class="form-group">
                      <input mdbInput type="text" class="form-control"  name="inputAddress2" [(ngModel)]="inputAddress2">
                      <label for="inputAddress2">Address Line 2</label>
                    </div>
                    <!-- Grid row -->
                    <div class="form-row">
                      <!-- Default input -->
                      <div class="form-group col-md-6">
                        <input mdbInput type="text" class="form-control"  name="inputSuburb" [(ngModel)]="inputSuburb">
                        <label for="inputSuburb"><span class="text-danger">*</span>Suburb</label>
                      </div>
                      <!-- Default input -->
                      <div class="form-group col-md-6">
                        <input mdbInput type="text" class="form-control"  name="inputPostCode" [(ngModel)]="inputPostCode">
                        <label for="inputZip"><span class="text-danger">*</span>Postcode</label>
                      </div>
                    </div>
                    <div class="text-right">
                    <!-- Grid row -->
                    <button type="button" mdbBtn size="sm"
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                    mdbWavesEffect (click)="saveAddress(); ">Save</button>
                    </div>
                    
                  </form>
               
            
            
            </div>

        </div>
    </div>
</div>