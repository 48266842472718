import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from './AppConfigService';

const uploadUrl='/generatedEmail/updateEmailDoc';
const updateStatusUrl='/generatedEmail/updateEmailDocStatus';
const getUrl='/generatedEmail/getEmailDoc';
const deleteUrl='/generatedEmail/deleteEmailDoc';
@Injectable({
  providedIn: 'root'
})
export class GeneratedEmailDocService {
  baseUrl:any;

  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

 
    saveGeneratedDoc(caseId:string,docName:string, docBody1:string, docBody2 :string, revisitDate:string,responseReceivedDate:Date,
        initialVisitDate:string,dateToBeImposed:Date, personNamePresentOnVisit:string,issuesAndSteps:string,responseDueDate:string,
        incidentOccurred:string,visitDates: string,daysAfterSent:string,lawApplicable:string,ragulationsApplicable:string,
        visitSummary :string,officerName:string,hubEmailAddress:string,pmcNameWithTitle:string,showCauseIssueDateToServiceProvider:string,
        signatureDate:Date,considerRiskAndWorkRquiredDate:string,headerPart1:string,headerPart2:string,headerPart3:string,
        assessmentConditions:string , confirmedBreachAreas:string,approvalName:string,endPart:string,breachTableHeader:string,groundForSuspension:string,
        suspensionReasons:string,attachmentName:string,signedBy:string,outcomeType:string): Observable<any> {
          
    var myjson = {caseId: caseId, docName: docName, docBody1: docBody1,docBody2: docBody2,revisitDate:revisitDate,responseReceivedDate:responseReceivedDate,
      initialVisitDate:initialVisitDate,dateToBeImposed:dateToBeImposed,
      personNamePresentOnVisit:personNamePresentOnVisit,issuesAndSteps:issuesAndSteps,responseDueDate:responseDueDate,
      incidentOccurred:incidentOccurred,visitDates:visitDates,daysAfterSent:daysAfterSent,
      lawApplicable:lawApplicable,ragulationsApplicable:ragulationsApplicable,visitSummary :visitSummary,
      officerName:officerName,hubEmailAddress:hubEmailAddress,pmcNameWithTitle:pmcNameWithTitle,
      showCauseIssueDateToServiceProvider:showCauseIssueDateToServiceProvider,signatureDate:signatureDate,
      considerRiskAndWorkRquiredDate:considerRiskAndWorkRquiredDate,headerPart1:headerPart1,headerPart2:headerPart2,headerPart3:headerPart3,
      assessmentConditions:assessmentConditions,confirmedBreachAreas:confirmedBreachAreas,approvalName:approvalName,endPart:endPart,
      breachTableHeader:breachTableHeader,groundForSuspension:groundForSuspension,
      suspensionReasons:suspensionReasons,attachmentName:attachmentName,signedBy:signedBy,outcomeType:outcomeType};
     // alert ("saveGeneratedDoc"+confirmedBreachAreas);
    return this.http.post<any>(this.baseUrl+uploadUrl, myjson)
      .pipe(
        catchError(this.handleError('saveGeneratedDoc', myjson))
      );
  }
  saveGeneratedDocStatus(caseId: string, docName: string, status: string): Observable<any> {

    var myjson = { caseId: caseId, docName: docName, status: status };
    // alert ("saveGeneratedDoc"+confirmedBreachAreas);
    return this.http.post<any>(this.baseUrl + updateStatusUrl, myjson)
      .pipe(
        catchError(this.handleError('saveGeneratedDocStatus', myjson))
      );
  }


  getExistingGeneratedDoc(caseId:any,docName:any): Observable<any> {
   
   
    return this.http.get<any>(this.baseUrl+getUrl+"?caseId="+caseId+"&outcome="+docName)
      .pipe(
        catchError(this.handleError('getExistingGeneratedDoc', caseId))
      );
  }

  
  deleteGeneratedDoc(caseId:any,docName:any): Observable<any> {
   
   
    return this.http.post<any>(this.baseUrl+deleteUrl+"?caseId="+caseId+"&outcome="+docName,null)
      .pipe(
        catchError(this.handleError('deleteGeneratedDoc', caseId))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
    //  alert("error message"+error.message);
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
