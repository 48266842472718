
import { Map1Component } from './views/maps/map1/map1.component';
import { ModalsComponent } from './views/modals/modals.component';
import { BasicTableComponent } from './views/visits/visit1/visit1.component';
import { Profile1Component } from './views/details/detail1/centerbased/centerbased.component';
import { FDCDetailsComponent } from './views/details/detail1/fdc/fdc.component';
import { RouterModule, Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { NotFoundComponent } from './views/errors/not-found/not-found.component';
import { Dashboard1Component } from './views/dashboards/dashboard1/dashboard1.component';
import { LandingComponent } from './views/landingpage/landingpage1/landingpage1.component';
import { CaseComponent } from './views/details/detail1/case/case.component';
import { AddRegulationsComponent } from './views/Admin/AddRegulations/addRegulations.component';
import { ManageEmailComponent } from './views/Admin/manageEmailTemplates/manageEmail.component';
import { OAuth2ComponentComponent } from './main-layout/oauth2-component/oauth2-component.component';
import { EmergencyActionComponent } from './views/emailTemplates/emergencyAction/emergencyAction.component';
import { UpdateBreachActionsComponent } from './views/details/detail1/case/updateBreachActions/updateBreachActions.component';
import { ManageLOVComponent } from './views/Admin/manageLOV/manageLOV.component';
import { ManageUserDetailsComponent } from './views/Admin/manageUserDetails/manageUserDetails.component';
import { InvalidPageComponent } from './views/errors/invalidpage/invalidpage.component';



const routes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'landingpage/v1' },
  {path: 'secure/aad', component: OAuth2ComponentComponent},
  {path: 'oauth2/logout', redirectTo: 'landingpage/v1'},
 

  { path: 'landingpage', children:
    [
      { path: 'v1', component: LandingComponent },
     
    ]
  },
  { path: 'dashboards', children:
    [
      { path: 'v1', component: Dashboard1Component },
    ]
  },
  { path: 'details', children:
    [
      { path: 'pages/detail1/centerbased', component: Profile1Component },
    ]
  },
  { path: 'details', children:
    [
      { path: 'pages/detail1/case', component: CaseComponent },
    ]
  },
  { path: 'emailTemplates', children:
    [
      { path: 'emergencyAction/showtemplate', component: EmergencyActionComponent },
    ]
  },
  { path: 'updateBreachActions', children:
    [
      { path: 'pages/detail1/updateBreachActions', component: UpdateBreachActionsComponent },
    ]
  },
  { path: 'Admin', children:
    [
      { path: 'pages/admin/addRegulations', component: AddRegulationsComponent }, 
    
    ]
  },
  { path: 'Admin', children:
  [
   
    { path: 'pages/admin/manageUsers', component: ManageUserDetailsComponent}
  ]
},
  { path: 'Admin', children:
    [
      { path: 'pages/admin/manageLOV', component: ManageLOVComponent }, 
    ]
  },
  { path: 'Admin', children:
    [
      { path: 'pages/admin/manageEmail', component: ManageEmailComponent }, 
    ]
  },
  { path: 'details', children:
  [
    { path: 'pages/detail1/fdc', component: FDCDetailsComponent },
  ]
},
  { path: 'visits', children:
    [
      { path: 'pages/visit1', component: BasicTableComponent },
    ]
  },
  { path: 'maps', children:
    [
      { path: 'map1', component: Map1Component},
    ]
  },
  { path: 'error', children:
  [
    { path: 'errorpage', component: InvalidPageComponent},
  ]
},

  { path: 'modals', component: ModalsComponent},
  { path: '**', component: NotFoundComponent },

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
