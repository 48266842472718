import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { CaseService } from 'src/app/service/case.service';
import { EmailService } from 'src/app/service/email.service';
import { NotesService } from 'src/app/service/notes.service';
import { DocumentService } from 'src/app/service/document.service';
import { CaseOutcomeService } from 'src/app/service/caseOutcome.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';
import { LoginDataService } from 'src/app/service/loginData.service';
import { EmailAdminService } from 'src/app/service/emailAdmin.service';
import { GeneratedEmailDocService } from 'src/app/service/generatedEmailDoc.service';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-caseOutcomeOfficer',
  templateUrl: './caseOutcomeOfficer.component.html',
  styleUrls: ['./caseOutcomeOfficer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class CaseOutcomeOfficerComponent implements OnInit {
  @ViewChild('notesModal', { static: true }) notesModal: ModalContainerComponent;
 
  ​tempEmailTemplate="";
  headElementsDocuments=["select","Outcome Type","PDF Link","Status","Drafted By","Drafted Date","Reviewer","Reviewed Date","Approver","Approved Date","Email Sent Date",""];
  headElementsDocumentsSort=["select","outcomeType","outcomeType","status","draftedBy","draftedDate","reviewedBy","reviewedDate","ApprovedBy","approvedDate","emailSentDate","Delete"];

  headElementsNotesSort = ['notes', 'type', 'createdDate', 'user'];
  headElementsNotes = ['notes', 'type', 'created Date', 'created By'];
  passeddata:any;
  outcomeStatus = ["Admin Letter", "Caution Letter","Emergency Action","Compliance Notice", "Compliance Direction", 
  "Suspension of Provider Approval","Suspension of Service Approval",
  "Cancellation of Provider Approval","Cancellation of Service Approval",
  "Decision not to Cancel Provider Approval", "Decision not to Cancel Service Approval",
  "Show Cause Notice to Cancellation of Provider Approval","Show Cause Notice to Cancellation of Service Approval"
  ,"No further Action"];
  noteType =["choose Note Type","Note","Approval","status Change","Feedback","other"];
  notesList:any[]=[];
  usersList:any[]=[];
  refreshOutcomeTableReset=true;
  reviewer:string;
  emailtoaddress:string='';
  showmanagerreviewer:boolean=false;
  @Input()
  summaryreport:any;
  @Output() 
  summaryReport = new EventEmitter<boolean>();
  @Output() 
  message = new EventEmitter<any>();
  @Input()
  accessList: string[] = [];
  @Input()
  visitList:any[]=[];
  @Input()
  lawRegList:any[]=[];
  @Input()
  completeVisitsList:any[]=[];
  @Input()
  recordNumber:string='';
  user:string='';
  loggedInuser:string='';
  userListACOs:any[]=[];
  userListMgrs:any[]=[];
  sendClicked : boolean = false;
  readyForApproval:boolean=false;
  readyForReview:boolean=false;
  roleId:string='';

  emailFrom: string;
  emailTo: string;
  emailCc: string;
  emailSubject: string;
  emailContent: string;
  emailAttachments:string;
  ngOnInit() {

    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId'),
       uid:localStorage.getItem('uid')
      
       //roleId: localStorage.getItem('roleId')
 
     });
 
    
    }

    this.user = (this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname:'';
    this.roleId=(this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().roleId:'';
    this.loggedInuser=(this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().uid:'';
    
    
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }

    this.passeddata = this.dataservice.getOption();

    this.tempEmailTemplate="Dear "+this.passeddata.PrimaryContact+",<br/>Please find attached breaches identified<br/> If you have any questions please contact us on 1800 619 113.<br/><br/>Regards,<br/>Hub Coordinator| Statewide Operations Network<br/>Early Childhood Education<br/>1800 619 113 <br/>"+this.passeddata.Approver+"<br/>www.det.nsw.edu.au<br/>";
   
    this.configoutcome = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.outcomeDocumentsList.length
    };
   
    this.getNotesfromDB();
    this.getAllpendingDocs();
    this.statusChange=false;

    this.getAllUsers();

        
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.notesList.length
    };
 
  }
  getCaseStatus()
  {
    var flag = false;
    var tempvisitlist = this.completeVisitsList.filter(el =>(el.visitstatus==='Scheduled' || el.visitstatus==='scheduled') );
    if(tempvisitlist && tempvisitlist!==null && tempvisitlist.length >0)
    {
      flag=!flag;
      return flag;
    }
    /*this.visitList.forEach(el => {
     
      if(el.visitstatus.trim()==='scheduled' || el.visitstatus.trim()==='Scheduled')
      {
        flag=!flag;
        return flag;
      }
    });*/

    return flag;
   

  }
  resetNotes()
  {
    this.notesText="";
    this.notesType="";
  }
  getAllUsers()
  {
    this.userDetailsService.getAllUsers().subscribe(data => {
      for (const d of (data as any)) {


        this.usersList.push({
          userId: d.intUserId,
          email: d.email,
          roleId:d.roleId
        }
        );

      }
        
      this.userListACOs=this.usersList.filter(el => 
        (el.roleId === 'ACO' && el.userId.toUpperCase()!==(this.loginDataService.getData().uid).toUpperCase())
       
      );
    
    
      this.userListMgrs=this.usersList.filter(el => 
        (el.roleId === 'READ_ONLY' && el.userId.toUpperCase()!==(this.loginDataService.getData().uid).toUpperCase())
       
      );
     
    //  console.log(this.outcomeDocumentsList);
          
    });
  }
  
  getACOsforReviewer()
  {
   
    this.completeVisitsList.forEach(visit => {
     
      var userobj = this.userListACOs.find(el => (visit.officer1 === el.userId || (visit.officer2!==null && visit.officer2 === el.userId) ));
         
      if(userobj!==null)
       {
       
         var index = this.userListACOs.indexOf(userobj);
    
         if(index!==-1)
         {
          this.userListACOs.splice(index,1);
         }
        
       }
      
      });
   return this.userListACOs;
  }

  getApproversList()
  {


    return this.usersList.filter(el => 
      (el.roleId === 'ROM' && el.userId.toUpperCase()!==(this.loggedInuser.toUpperCase()))
     
    );
   
   
  }



  updateCaseStatus(status:string)
  {
    if(status==='Recommendations')
    {
      this.passeddata.CaseStatus = 'Recommendations';
    }
    if(status==='Review')
    {
      this.passeddata.CaseStatus = 'Peer Review Sent';
    }
    if(status==='Review Complete')
    {
      this.passeddata.CaseStatus = 'Peer Review Modifications';
    }
    if(status==='Decision Pending')
    {
      this.passeddata.CaseStatus = 'Decision Pending';
    }
    if(status==='Approval')
    {
      this.passeddata.CaseStatus = 'Approval';
      
    }
    if(status==='Closed')
    {
      this.passeddata.CaseStatus = 'Closed';
           
    }
    this.dataservice.setOption(this.passeddata);
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
      if(status === 'Closed')
      {
        this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        this.router.navigate(['/visits/pages/visit1']);
      }
      return resp;
    }
    );

  }
  loadDoc(docId: any,docName:string) {


    
    this.documentService.getTrimDoc(docId).subscribe((response) => {
    var file:any;

    if(docName.indexOf(".jpg")!== -1)
    {


      file = new Blob([response], { type: 'data:image/png;base64,' });

      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);


    }else 
    {

      file = new Blob([response], { type: 'application/pdf' });

     var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
   
    
  });
}

  updateCase()
  {
    this.caseService.updateCase(this.passeddata)
    .subscribe(res => {
      console.log(res);
      this.message.emit({'status':'success','messageTxt':'save successful'});
        }
        ,err =>{
          console.log(err);
          this.message.emit({'status':'error','messageTxt':'save failed'});
        });
  }

  constructor(private router:Router, private location:Location,private ref: ChangeDetectorRef,private documentService:DocumentService,private loginDataService:LoginDataService,private userDetailsService:UserDetailsService,private caseOutcomeService:CaseOutcomeService,private documentServce:DocumentService,private notesService :NotesService,private dataservice: DataService, private caseService: CaseService,private emailService:EmailService,private emailAdminService: EmailAdminService,private generatedEmailDocService: GeneratedEmailDocService) {
  
  }

  helpText(message:any)
  {
      if(message.status==='success')
      this.message.emit({'status':'success','messageTxt':message.messageTxt});
     
      else if(message.status==='error')
      this.message.emit({'status':'error','messageTxt':message.messageTxt});
     
  }

  /*disableReviewWhen()
  {
    this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'In Progress').subscribe(data => {
      if(data && data!==null && data.length==0)
      {
        return true;
      }
      else if(data && data!==null && data.length>0){
        return false;
      }
      else{
        return true;
      }
     
    });
  }

  disableApproverWhen()
  {
    this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'Review Complete').subscribe(data => {
      if(data && data!==null && data.length==0)
      {
        return true;
      }
      else if(data && data!==null && data.length>0){
        return false;
      }
      else{
        return true;
      }
    });
  }*/

  updateReviewer()
  {

   /* if(this.pendingOutcomesList.length === 0)
    {
      this.message.emit({'status':'error','messageTxt':'choose outcome type to proceed'});
    }*/
  //else{

if(this.passeddata.Reviewer===null || this.passeddata.Reviewer===undefined || this.passeddata.Reviewer==='')
{
  this.message.emit({'status':'error','messageTxt':'choose reviewer to proceed'});
}
else
{
  var tempList = this.outcomeDocumentsList.filter(el=>(el.status==='In Progress'));

   if(tempList!==null && tempList.length>0)
   {
 
    tempList.forEach(el =>{
      this.caseOutcomeService.updateCaseOutcome(el.outcomeId,"sent for Review",'',this.passeddata.Reviewer,'').subscribe(res=>
        {
            el.status='sent for Review';
            el.reviewedBy=this.passeddata.Reviewer;
           console.log("sent draft for review"+res);
        });
    });
   
//send email to reviewer
    
   this.usersList.find(el=>{
    if(el.userId===this.passeddata.Reviewer)
    {
     this.emailtoaddress = el.email;
     this.passeddata.CaseStatus = 'Peer Review Sent';
     this.dataservice.setOption(this.passeddata);
     this.caseService.updateCase(this.passeddata).subscribe(res =>{
       console.log(res);
       this.sendEmailtoUser('email sent for Review',this.emailtoaddress);
     });
   
    }
  });

  }
  else{
    this.message.emit({'status':'error','messageTxt':'choose outcome type to proceed'});
  }


  
   }


 
  }


  updateOutcomeStates(fromstatus:string,toStatus:string)
  {

    this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,fromstatus).subscribe(data => {
   
      if(data.length>0)
      {
       for (const d of (data as any)) {

         this.caseOutcomeService.updateCaseOutcome(d.outcomeId,toStatus,(d.approvedBy!==null)?d.approvedBy:'',d.reviewedBy,d.trimId).subscribe(res=>
           {
               console.log("sent draft with status changed "+toStatus+res);
               this.refreshOutcomeTable=true;
           });
   
       }
   
      
   
      }
    });
  }

   reviewComplete(fromstatus:string,toStatus:string)
  {
     
    
   
   // await this.updateOutcomeStates(fromstatus,toStatus);

   var tempList = this.outcomeDocumentsList.filter(el=>(el.status===fromstatus));
  
   if(tempList!==null && tempList.length>0)
   {
 
    tempList.forEach(el =>{
      this.caseOutcomeService.updateCaseOutcome(el.outcomeId,"Review Complete",'',this.passeddata.Reviewer,'').subscribe(res=>
        {
          el.status='Review Complete';
          el.reviewedBy=this.passeddata.Reviewer;
          var myDate = new Date();
         
          el.reviewedDate = myDate;
          console.log("Review complete"+res);
        });
    });
    var tolist = '';
    this.visitList.forEach(visit => {
      tolist=tolist+(visit.officer1!==null)?(visit.officer1+','):''+(visit.officer2!==null)?(visit.officer2+','):'';
    } );
      this.sendEmailtoUser(toStatus,tolist);
      this.updateCaseStatus(toStatus);
      
   //this.getAllpendingDocs();
   //this.ref.markForCheck();
  }


   
  }
  validateAndSendforApproval()
  {

    if(this.passeddata.Approver===null || this.passeddata.Approver===undefined || this.passeddata.Approver==='')
    {
      this.message.emit({'status':'error','messageTxt':'choose Approver to proceed'});
    }

    else
    {
    var tempList = this.outcomeDocumentsList.filter(el=>(el.status==='In Progress' || el.status==='Review Complete'));
  
    if(tempList!==null && tempList.length>0)
    {
  
     tempList.forEach(el =>{
      this.caseOutcomeService.updateCaseOutcome(el.outcomeId,"sent for Approval",this.passeddata.Approver,el.reviewedBy,el.trimId).subscribe(res=>
        {
           el.status='sent for Approval';
           el.approvedBy=this.passeddata.Approver;
          
           console.log("sent for Approval"+res);
         });
     });

     
        this.usersList.find(el=>{
        if(el.userId===this.passeddata.Approver)
        {
          this.emailtoaddress = el.email;
          this.passeddata.CaseStatus = 'Recommendations';
          this.dataservice.setOption(this.passeddata);
          this.caseService.updateCase(this.passeddata).subscribe(res =>{
            console.log(res);
            this.sendEmailtoUser('email sent for Approval',this.emailtoaddress);
          });
        
        }
        });
     }
  else
    {
      this.message.emit({'status':'error','messageTxt':' please get all documents reviewed to proceed'});
    }
   
  }
}

outcomeDocumentsList:any[]=[];
 
   getAllpendingDocs()
  {
    this.outcomeDocumentsList=[];
    this.ref.markForCheck();
   
      this.caseOutcomeService.getAllCaseOutcome(this.passeddata.CaseId,'').subscribe(data => {
       
      
      for (const d of (data as any)) {


        this.outcomeDocumentsList.push({
          outcomeId: d.outcomeId,
          outcomeType: d.outcomeType,
          draftedBy:d.draftBy,
          status:d.status,
          approvedDate:d.approvedDate,
          approvedBy:d.approvedBy,
          draftedDate:d.draftedDate,
          trimId:d.trimId,
          reviewedDate:d.reviewedDate,
          emailSentDate:d.emailSentDate,
          reviewedBy:d.reviewedBy

            }
        );
       

      }
    
      this.outcomeDocumentsList =  this.outcomeDocumentsList.sort((a,b) => (a.draftedDate < b.draftedDate) ? 1 : ((b.draftedDate < a.draftedDate) ? -1 : 0)); 
      this.ref.markForCheck();
      console.log(this.outcomeDocumentsList);
     
    });
  }
  getReadyForApproval()
  {
    
   var templist = this.outcomeDocumentsList.filter(el=>(el.status==='In Progress' || el.status==='Review Complete'));
   if(templist!==null && templist!==undefined && templist.length>0)
   {
     return false;
   }
   else{
     return true;
   }
  }
  
  getReadyForReview()
  {
   var templist = this.outcomeDocumentsList.filter(el=>(el.status==='In Progress'));
   if(templist!==null && templist!==undefined && templist.length>0)
   {
     return false;
   }
   else{
     return true;
   }
  }
  setValues(outcome:any)
  {
   // this.passeddata.SelectedOutcome=outcome;
   localStorage.setItem("SelectedOutcome",outcome);
    //this.passeddata.lawRegList=this.lawRegList;
   
    this.passeddata.breachVisitDates='';

    this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.passeddata.breachVisitDates= this.passeddata.breachVisitDates+","+el.startDt;
      }
    });

  }
  addOutcometoView(outcome:string,event:boolean)
  {
    var temp={outcomeType: outcome};
    if(event===true)
    {
      var i = this.outcomeDocumentsList.indexOf(temp);
      if(i<0)
      {
        this.outcomeDocumentsList.push(temp)
      }
      
  
     
    }
    else
    {
      var i = this.outcomeDocumentsList.indexOf(outcome);
      this.outcomeDocumentsList.splice(i,1);

    }
   
  }
  refreshOutcomeTable=false;
  addtoOutcomeTable(outcome:string,event:boolean)
  {
    if(event=== true)
    {
      if(this.outcomeDocumentsList!==null && this.outcomeDocumentsList!== undefined)
      {
       var templist = this.outcomeDocumentsList.filter(el => (el.outcomeType===outcome && el.status!=='Approved'));
       if(templist!==null && templist.length<=0)
       {


      this.caseOutcomeService.saveCaseOutcomeStatus(outcome,this.passeddata.CaseId,this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname,'','In Progress','')
      .subscribe(resp => {
               console.log(resp);
               this.getAllpendingDocs();
              
               
              // this.refreshOutcomeTable = true;
               this.message.emit({'status':'success','messageTxt':'case Outcome added as In Progress '+ outcome});
               
          },
          err => {
           
            console.log('Error Occured during save outcome ' + err);
            this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
          }
          );
       
       }
    else{
      this.message.emit({'status':'error','messageTxt':'cannot add as document is already created and not yet approved '});
      console.log('cannot add as document is already created and not yet approved ');
    }
  }
  }
  else{

    if(this.outcomeDocumentsList!==null && this.outcomeDocumentsList!== undefined)
    {
      var inpregressList =   this.outcomeDocumentsList.filter(el => (el.outcomeType===outcome && el.status==='In Progress'));
      
        inpregressList.forEach(a =>{
          this.removeDoc(a);
        })
      
    }
  }
    
  
   
  }
 
  isInProgress(outcome : any)
  {
    var templist = this.outcomeDocumentsList.filter(el => (el.outcomeType===outcome && el.status==='In Progress'));
    if(templist!==null && templist!==undefined &&  templist.length>0)
    {
      return true;

    }
    else{
      return false;
    }

  }
  resetRefreshTable(refreshtable: boolean)
  {
    if(refreshtable===false)
    {
      this.refreshOutcomeTable=false;
    }
  }

  getNotesfromDB()
  {
    this.notesService.getAllNotes(this.passeddata.CaseId)
    .subscribe(data => {
      for (const d of (data as any)) {


        this.notesList.push({
          notes: d.comments,
          user: d.createdBy,
          createdDate: d.createdDate,
          type: d.action
        }
        );

      }
      this.notesList =  this.notesList.sort((a,b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0)); 

     
      console.log(this.notesList);
    });
  }
  showsummary() {
    this.summaryreport=!this.summaryreport;
    this.summaryReport.emit(this.summaryreport);
    if(this.summaryreport)
    {
      this.message.emit({'status':'info','messageTxt':'Scroll down to see the Summary Report'});
   
    }
    else{
      this.message.emit({'status':'info','messageTxt':'Summary Report collapsed'});
   
    }
  
  }

  sendEmail(message:string,status:string,to:string)
  {
    
      console.log("send email for approval status"+status);
    this.emailService.sendEmailtoUser(message,to).subscribe((response) => {

      console.log(response);   

      //save case
      this.saveCase();
      this.outcomeDocumentsList.forEach(el =>
        this.saveOutcomes(el.outcomeType));
    //  this.getAllpendingDocs();  //refresh list
      this.message.emit({'status':'success','messageTxt':'Email Sent for Approval'});
      
    },
    err => {
     
      console.log('Error Occured duringng send email for approval ' + err);
      this.message.emit({'status':'error','messageTxt':'Email Sent for Approval failed'});
    }

    );
   
     
  }


  sendEmailtoUser(message:string,to:string)
  {
   
 
   
    this.emailService.sendEmailtoUser(message +" for caseId "+this.passeddata.CaseId,to).subscribe((response) => {
    
      console.log(response);   

        //save case
      //  this.saveCase();
        //this.outcomeDocumentsList.forEach(el =>
         // this.saveOutcomes(el.outcomeType));
       
      this.message.emit({'status':'success','messageTxt':'Mail sent to User '+to});

    },
    err => {
     
      console.log('Error Occured duringng send email ' + err);
      this.message.emit({'status':'error','messageTxt':'Email Sent to User failed'});
    });
   // this.showSuccess('Email Sent');

   
  }

  statusChange:boolean;
  onCaseStatusChange(newValue:string) {
    
    this.passeddata.CaseStatus = newValue;
    this.statusChange=true;
   
  }

  notesText:string="";
  notesType:string="";
  saveCase()
  {
   
    this.passeddata.ApprovalStatus="Pending Approval";
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
             console.log(resp);
             this.message.emit({'status':'success','messageTxt':'case Outcome updated to '+ this.passeddata.ApprovalStatus});
        },
        err => {
         
          console.log('Error Occured duringng save case' + err);
          this.message.emit({'status':'error','messageTxt':'Error saving case'});
        }
        );
  }

  config: any;
  configoutcome:any;
  pageChanged(event : any){
    this.config.currentPage = event;
  }
  pageChangedDoc(event : any){
    this.configoutcome.currentPage = event;
  }

  saveOutcomes(caseOutcome:string)
  {
    
    this.caseOutcomeService.saveCaseOutcome(caseOutcome,this.passeddata.CaseId,"username",'')
    .subscribe(resp => {
             console.log(resp);
             //this.message.emit({'status':'success','messageTxt':'case Outcome updated to '+ this.passeddata.ApprovalStatus});
        },
        err => {
         
          console.log('Error Occured duringng save outcome ' + err);
          this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
        }
        );
  }
  errormessage : string = "";

  saveNotes()
  {
      if(this.notesType!=="" && this.notesType!=="choose Note Type" && this.notesText!=="")
      {
        this.errormessage="";
      this.notesService.saveNotes(this.notesType,this.notesText,this.loginDataService.getData().firstName+this.loginDataService.getData().surname,this.passeddata.CaseId)
      .subscribe(  res => {
        console.log(res);
        this.notesList=[];
        this.getNotesfromDB();
        this.message.emit({'status':'success','messageTxt':'Notes added'});
        this.notesModal.hide();
      },
      err => {
       
        console.log('Error Occured duringng generate Pdf ' + err);
        this.message.emit({'status':'error','messageTxt':'Error adding notes'});
      }
      );
    }
    else{
      this.errormessage = "Choose Note Type and and Summary before save"
    }
  
  }

  //get input() { return this.validatingForm.get('required'); }

  //get childCountSize() { return this.validationForm.get('childCountSize'); }
 // get serviceNameLength() { return this.validationForm.get('serviceNameLength'); }
 // get serviceAddressLength() { return this.validationForm.get('serviceAddressLength'); }

 //emailtoaddress:string='serviceemail@detnsw.edu.au';
 updateManagerReviewer()
 {
   
 if(this.passeddata.ManagerReviewer==null || this.passeddata.ManagerReviewer==undefined || this.passeddata.ManagerReviewer==='')
 {
  this.message.emit({'status':'error','messageTxt':'choose manager to proceed'});
 }
 else{

 
  this.usersList.find(el=>{
    if(el.userId===this.passeddata.ManagerReviewer)
    {
     this.emailtoaddress = el.email;
            
    }
  });

  this.sendEmailtoUser('email sent for Manager Review',this.emailtoaddress);
  this.updateCaseStatus('Decision Pending');
  }
  
 }
 isSelectedDocs()
 {
  var tempList = this.outcomeDocumentsList.filter(el=>(el.selected!==null && el.selected!==undefined && el.selected==='true'));
  if(tempList!==null && tempList.length>0)
  {
    return false;
  }
  else{
    return true;
  }
 }
  emailNotificationFunction: string = '';
  selectedOutcomeType: string = '';
  visitDates: string = '';
  officerName: string = '';
  hubEmailAddress: string = '';
  pmcNameWithTitle: string = '';
  approvalName: string = '';
  responseDueDate: string = '';
  outcomeTypeLetterList: string = '';

  prepareEmailForSelectedOutCome() {
    var selectedOutComeList = this.outcomeDocumentsList.filter(el => (el.selected !== null && el.selected !== undefined && el.selected === 'true'));
    var uploadedDocumentList: any[] = [];
    var selectedOutcomeDocList: any[] = [];
    this.emailFrom = '';
    this.emailTo = '';
    this.emailCc = '';
    this.emailSubject = '';
    this.emailContent = '';
    this.emailAttachments = '';

    if (selectedOutComeList !== null && selectedOutComeList.length > 0) {
      //alert('coming here' + selectedOutComeList.length);
      for (let i = 0; i < selectedOutComeList.length; i++) {
        if (i === 0) {
          this.emailAttachments = selectedOutComeList[i].outcomeType;
        } else {
          this.emailAttachments = this.emailAttachments + ", " + selectedOutComeList[i].outcomeType;
        }

        if (selectedOutComeList[i].status === "Attached Document") {
          uploadedDocumentList.push(selectedOutComeList[i]);
        } else {
          selectedOutcomeDocList.push(selectedOutComeList[i]);
          if (selectedOutcomeDocList.length === 1) {
            this.outcomeTypeLetterList = " -"+ selectedOutcomeDocList[0].outcomeType;
          } else {
            this.outcomeTypeLetterList = this.outcomeTypeLetterList + "\r\n -" + selectedOutcomeDocList[i].outcomeType;
          }
        }
      }//end for loop
      //  alert("selectedOutcomeDocList" + selectedOutcomeDocList.length);
      if (selectedOutcomeDocList.length > 1) {
        this.emailNotificationFunction = "Multiple Letters";
        this.updateEmailTemplateForSelectedOutCome();
      } else {
        this.selectedOutcomeType = selectedOutcomeDocList[0].outcomeType;

        if (this.selectedOutcomeType === 'Admin Letter') {
          this.emailNotificationFunction = "ADMIN LETTER";
        }
        if (this.selectedOutcomeType === 'Emergency Action') {
          this.emailNotificationFunction = "EMERGENCY ACTION NOTICE";
        }
        if (this.selectedOutcomeType === 'Compliance Direction') {
          this.emailNotificationFunction = "COMPLIANCE DIRECTION";
        }
        if (this.selectedOutcomeType === 'Compliance Notice') {
          this.emailNotificationFunction = "COMPLIANCE NOTICE";
        }
        if (this.selectedOutcomeType === 'Caution Letter') {
          this.emailNotificationFunction = "CAUTION LETTER";
        }

        if (this.selectedOutcomeType === 'Show Cause Notice to Cancellation of Provider Approval') {
          this.emailNotificationFunction = "SHOW CAUSE NOTICE:CANCELLATION OF PROVIDER APPROVAL";
        }
        if (this.selectedOutcomeType === 'Show Cause Notice to Cancellation of Service Approval') {
          this.emailNotificationFunction = "SHOW CAUSE NOTICE:CANCELLATION OF SERVICE APPROVAL";
        }
        if (this.selectedOutcomeType === 'Cancellation of Provider Approval') {
          this.emailNotificationFunction = "CANCELLATION OF PROVIDER APPROVAL";
        }
        if (this.selectedOutcomeType === 'Cancellation of Service Approval') {
          this.emailNotificationFunction = "CANCELLATION OF SERVICE APPROVAL";
        }
        if (this.selectedOutcomeType === 'Suspension of Provider Approval') {
          this.emailNotificationFunction = "SUSPENSION OF PROVIDER APPROVAL";
        }
        if (this.selectedOutcomeType === 'Suspension of Service Approval') {
          this.emailNotificationFunction = "SUSPENSION OF SERVICE APPROVAL";
        }

        if (this.selectedOutcomeType === 'Decision not to Cancel Provider Approval') {
          this.emailNotificationFunction = "NOTICE OF DECISION TO CANCEL PROVIDER APPROVAL";
        }
        if (this.selectedOutcomeType === 'Decision not to Cancel Service Approval') {
          this.emailNotificationFunction = "NOTICE OF DECISION TO CANCEL SERVICE APPROVAL";
        }
        if (this.selectedOutcomeType === 'No further Action') {
          this.emailNotificationFunction = "No further Action";
        }


        // alert("emailNotificationFunction" + emailNotificationFunction);
        // alert(" this.passeddata.CaseId" + this.passeddata.CaseId + selectedOutcomeType);
     
        if (this.selectedOutcomeType === 'No further Action') {
          this.updateEmailTemplateForSelectedOutCome();
        }
        else{
          this.generatedEmailDocService.getExistingGeneratedDoc(this.passeddata.CaseId, this.selectedOutcomeType).subscribe(data => {
         
            if (data !== undefined && data !== null) {
              this.visitDates = data.visitDates;
              this.officerName = data.officerName;
              this.hubEmailAddress = data.hubEmailAddress;
              this.pmcNameWithTitle = data.pmcNameWithTitle;
              this.approvalName = data.approvalName;
              this.responseDueDate = data.responseDueDate;
            }
          
            this.updateEmailTemplateForSelectedOutCome();
           // alert("after call"+this.emailContent);
          },
            err => {
              console.log('Error Occured duringng getting admin list ' + err);
              this.message.emit({ 'status': 'error', 'messageTxt': 'Error Occured duringng getting generated document for outcome type' + this.selectedOutcomeType });
              return;
            }

          );//end of generatedEmailDocService service call
        }
      } //end of else for selectedOutcomeDocList.length > 1

    }
  }
  updateEmailTemplateForSelectedOutCome() {
 
    this.emailAdminService.getEmailAdminByEmailNotificationFunction(this.emailNotificationFunction)
      .subscribe(emaildata => {
       
        if (emaildata !== undefined && emaildata !== null) {
          this.emailFrom = emaildata.emailFrom;
          this.emailTo = emaildata.emailTo;
          this.emailCc = emaildata.emailCc;
          this.emailSubject = emaildata.emailSubject;
          this.emailContent = emaildata.emailContent;

          this.emailSubject = this.emailSubject.replace(/#Service Number#/g, this.passeddata.SENumber);
          this.emailSubject = this.emailSubject.replace(/#Service Name#/g, this.passeddata.ServiceName);
          this.emailSubject = this.emailSubject.replace(/#Case Id#/g, this.passeddata.CaseId);
          this.emailSubject = this.emailSubject.replace(/#Approved Provider Name#/g, this.approvalName);

          this.emailContent = this.emailContent.replace(/#Primary Contact#/g, this.pmcNameWithTitle);
          this.emailContent = this.emailContent.replace(/#Service Name#/g, this.passeddata.ServiceName);
          this.emailContent = this.emailContent.replace(/#Approved Provider Name#/g, this.approvalName);
          this.emailContent = this.emailContent.replace(/#Visit Date#/g, this.visitDates);
          this.emailContent = this.emailContent.replace(/#Service Response Due Date#/g, this.responseDueDate);
          this.emailContent = this.emailContent.replace(/#Provider Name#/g, this.passeddata.ProviderName);
          this.emailContent = this.emailContent.replace(/#Officer Name#/g, this.officerName);
          if (this.hubEmailAddress === null) {
            this.hubEmailAddress = this.passeddata.ServiceEmail;
          }
          this.emailContent = this.emailContent.replace(/#Hub Email Address#/g, this.hubEmailAddress);
          this.emailContent = this.emailContent.replace(/#Signed By#/g, this.user);
          this.emailContent = this.emailContent.replace(/#Outcome Type Letter List#/g, this.outcomeTypeLetterList);
          this.emailContent = this.emailContent.replace(/#AP Name#/g, this.passeddata.PrimaryContact);
          this.emailContent = this.emailContent.replace(/#Case Id#/g, this.passeddata.CaseId);
        }
        
      },
        err => {
          console.log('Error Occured duringng getting admin list ' + err);
          this.message.emit({ 'status': 'error', 'messageTxt': 'Error Occured duringng getting email template for ' + this.emailNotificationFunction });
          return;
        }
      );
  }

    //this.emailSubject =  this.emailSubject.replace(/#Provider Approval Number#/g,this.passeddata.ProviderId);
    // this.emailSubject = this.emailSubject.replace(/#Case Number#/g,this.passeddata.CaseId);
    // // alert(" visitDates before replace" + outcomeTypeLetterList);
    // if (this.emailSubject !== undefined && this.emailSubject !== null) {

    //   this.emailSubject = this.emailSubject.replace(/#Service Number#/g, this.passeddata.SENumber);

    //   this.emailSubject = this.emailSubject.replace(/#Service Name#/g, this.passeddata.ServiceName);
    //   this.emailSubject = this.emailSubject.replace(/#Case Id#/g, this.passeddata.CaseId);


    //   this.emailSubject = this.emailSubject.replace(/#Approved Provider Name#/g, approvalName);

    //   alert("emailSubject" + this.emailSubject);
    //   // alert("his.passeddata.ServiceEmail" + this.passeddata.ServiceEmail);
    // }
    // if (this.emailContent !== undefined && this.emailContent !== null) {

    //   this.emailContent = this.emailContent.replace(/#Primary Contact#/g, pmcNameWithTitle);
    //   this.emailContent = this.emailContent.replace(/#Service Name#/g, this.passeddata.ServiceName);
    //   this.emailContent = this.emailContent.replace(/#Approved Provider Name#/g, approvalName);
    //   this.emailContent = this.emailContent.replace(/#Visit Date#/g, visitDates);
    //   this.emailContent = this.emailContent.replace(/#Service Response Due Date#/g, responseDueDate);
    //   this.emailContent = this.emailContent.replace(/#Provider Name#/g, this.passeddata.ProviderName);
    //   this.emailContent = this.emailContent.replace(/#Officer Name#/g, officerName);
    //   if (hubEmailAddress === null) {
    //     hubEmailAddress = this.passeddata.ServiceEmail;
    //   }
    //   this.emailContent = this.emailContent.replace(/#Hub Email Address#/g, hubEmailAddress);
    //   this.emailContent = this.emailContent.replace(/#Signed By#/g, this.user);
    //   this.emailContent = this.emailContent.replace(/#Outcome Type Letter List#/g, outcomeTypeLetterList);
    //   this.emailContent = this.emailContent.replace(/#AP Name#/g, this.passeddata.PrimaryContact);
    // }
    // //end else
    // alert("emailContent" + this.emailContent);
    // alert("emailAttachments" + this.emailAttachments);
   // }
//  }
 loadingApprove:boolean=false;
 async sendEmailtoService(message:string,to:string,subject:string)
 {
 
  
  //this.saveCase();

  this.loadingApprove = true;
   //var tempList = this.outcomeDocumentsList.filter(el=>(el.status==='sent for Approval'));
  
   var tempList = this.outcomeDocumentsList.filter(el=>(el.selected!==null && el.selected!==undefined && el.selected==='true'));

   if(tempList!==null && tempList.length>0)
     {
          var tempListWithoutDocument = this.outcomeDocumentsList.filter(el=>(el.selected!==null && el.selected!==undefined && el.selected==='true' && el.outcomeType !=='No further Action'));
           await this.emailService.sendEmailSelected(message,tempListWithoutDocument,to,subject,"Approved",this.passeddata.CaseId,this.passeddata.SENumber,this.passeddata.ProviderId,this.recordNumber,this.user).subscribe((response) => {
   
         console.log(response);   
          
         this.message.emit({'status':'success','messageTxt':'Mail sent to Service'});
         

         this.updateCaseStatus('Approval');

         
        /* tempList.forEach(el =>{

            if(el.status!=='Attached Document')
            {
              el.status='Approved';
              el.approvedBy=this.passeddata.Approver;
              var mydate = new Date();
              el.approvedDate=mydate;
            
            }
               
           
        });*/

        this.getAllpendingDocs();
        this.ref.detectChanges();
         
        this.loadingApprove=false;

       },err=>{
         console.log(err);
         this.loadingApprove=false;
         this.sendClicked = false;
         tempList=[];
         this.outcomeDocumentsList.forEach(a=>{
           if(a.selected!==null && a.selected!==undefined && a.selected==='true')
           {
              a.selected=null;
           }

         });
        this.message.emit({'status':'error','messageTxt':'error sending documents '+err});
       });


       






     }
    
 



  
 }

 selectedFile:any;
filename:string="choose one";

public onFileChanged(event: any) {
  console.log(event);
  this.selectedFile = event.target.files[0];
  if(this.selectedFile!== null && this.selectedFile!==undefined)
  {
    this.filename=this.selectedFile.name;

  }
  

 }

 onUpload()
  {
    if(this.selectedFile!==null && this.selectedFile.type !== 'application/pdf' && this.selectedFile.type !== 'image/jpeg')
    {
       this.message.emit({'status':'error','messageTxt':'File TYpe not valid'});
      return;
    }
    if((this.selectedFile!==null && (this.selectedFile.size/1000)>20000))
    {
   
      this.message.emit({'status':'error','messageTxt':'File size too big'});
      return;
    }
    this.uploadtoTrim();
  }
  

  fileURL: any;
  uploading:boolean=false;
 async uploadtoTrim() {


  
  this.uploading = true;
  const uploadData = new FormData();
  uploadData.append('myFile', this.selectedFile, this.selectedFile.name);

  this.documentService.uploadEvidencetoTrim(uploadData, null,this.passeddata,this.recordNumber)
    .subscribe(
      res => {
      
        console.log(res);
        this.uploading = false;
        this.message.emit({'status':'success','messageTxt':'File uploaded to trim'});
       
       
        this.message.emit({'status':'success','messageTxt':'pdf upload to Trim for '+this.filename});   

        this.caseOutcomeService.saveCaseOutcomeStatus(this.filename,this.passeddata.CaseId,this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname,'','Attached Document',res.trimId)
        .subscribe(resp => {
                 console.log(resp);
                 this.getAllpendingDocs();
                 //this.ref.detectChanges();
             
                this.filename="choose one";
                this.message.emit({'status':'success','messageTxt':'pdf addedd to caseoutcome '});   
                 
            },
            err => {
             
              console.log('Error Occured during save outcome ' + err);
              this.message.emit({'status':'error','messageTxt':'Error saving case outcome'});
            }
            );


      },
      err => {
        
        this.uploading = false;
        this.message.emit({'status':'success','messageTxt':'upload failed'});
        console.log('Error Occured duringng saving: ' + err);
      });



  }

  updateOutcomeSelection(doc:any)
  {
      if(doc.selected==='true')
      {
        doc.selected='false';
      }
      else{
        doc.selected="true";
      }
  }
  generateAttachmentPdf(outcome:string)
  {
    
    this.documentServce.getOutcomeStatusPdf(outcome,this.passeddata.CaseId,null,this.passeddata.responseType)
    .subscribe(  res => {
      console.log(res);
      
      var file:any;
     /* file = new Blob([res], { type: 'application/pdf' });
      
      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);*/


       file = new Blob([res], { type: 'application/pdf' });
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
   
    },
    err => {
     
      console.log('Error Occured duringng generate Pdf ' + err);
      this.message.emit({'status':'error','messageTxt':'Error generating Pdf'});
    }
    );

  }

 

  removeDoc(doc:any)
  {
    if(confirm("are you sure you want to delete?"))
    {
    this.caseOutcomeService.deleteCaseOutcome(doc.outcomeId).subscribe(data => {

      this.generatedEmailDocService.deleteGeneratedDoc(this.passeddata.CaseId,doc.outcomeType).subscribe(data2 =>{

        console.log("deleted doc"+data + data2);
        var i = this.outcomeDocumentsList.indexOf(doc);
        this.outcomeDocumentsList.splice(i,1);
        this.ref.detectChanges();
      },err=>{
        console.log("failed to delete doc"+err);
      });
     
    },err => {
      console.log("failed to delete doc"+err);
    });
  }
  }


}
