import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';





@Component({
  selector: 'app-changeCaseStatus',
  templateUrl: './changeCaseStatus.component.html',
  styleUrls: ['./changeCaseStatus.component.scss']

})
export class ChangeCaseStatusComponent implements OnInit {

  caseStatusList=['Case Assessment','Evidence Collection and Analysis','Peer Review Sent','Peer Review Modifications','Recommendations','Decision Pending','Approval','Closed','Immediate Risk to Children'];

  @Input()
  currentStatus:string;
  @Output()
  changedStatus = new EventEmitter<String>();
 
  selectedStatus:string='';
 
  ngOnInit() {
 // alert("in init.. content"+ this.modalRef.content);
   
  }

  constructor() {
   
  }

  updateStatus()
{
  if(this.selectedStatus!==null && this.selectedStatus!==undefined && this.selectedStatus!=='')
  {
 
    this.changedStatus.emit(this.selectedStatus);
  }

}



}
