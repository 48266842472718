import { Component, OnInit} from '@angular/core';





@Component({
  selector: 'app-evidenceComments',
  templateUrl: './evidenceComments.component.html',
  styleUrls: ['./evidenceComments.component.scss']

})
export class EvidenceCommentsComponent implements OnInit {
 
  
  question:string;
  answer:string='';
  
  ngOnInit() {
   
   
  }

  constructor() {}
  
  addQuestion()
  {
    
    this.answer=this.answer+ "</br>" + "<li><span class='username'>" +this.question + "</span></li></br>" ;
   
  }





}
