
<fieldset class="scheduler-border">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-clipboard" style="color:#041e42;padding-right: 4px;" ></i>Case Outcome</legend>
      
<mdb-card class="mb-4 mt-4"  >
    <mdb-card-header class="p-0 cardheadercolor" (click)="finalize.toggle()">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Outcome {{passeddata.CaseId}}</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="finalize.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="doe-table-body" style="padding-right: 10px;padding-left: 20px;">
        <div mdbCollapse [isCollapsed]="false" #finalize="bs-collapse">

                      <!-- Form -->
            <form class="">
               
             

           

                <div class="row d-flex">
                    <button type="button" mdbBtn size="sm" *ngIf="passeddata.CaseStatus!=='Approval' && passeddata.CaseStatus!=='Closed'"
                    class="relative waves-light notesolor rounded text-white font-weight-bold ml-3 mt-0"
                    mdbWavesEffect (click)="resetNotes();notesModal.show()">Add Notes</button>


                </div>

                <div class="mb-3">
                    <table mdbTable id="notesTable" 
                            class="z-depth-1 px-4 py-4 doe-table-body" hover="true">
                         
                            <!--Table head-->
                            <thead class="lawReg-table-header tableheadercolor">
                                <tr class="">
                                    <th *ngFor="let head of headElementsNotes; let i = index"
                                        aria-controls="notesTable" [mdbTableSort]="notesList"
                                        [sortBy]="headElementsNotesSort[i]" scope="col" style="white-space: nowrap;">
                                        {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                                    </th>
                                </tr>

                            </thead>
                            <!--Table body-->
                            <tbody>


                                <tr *ngFor="let note of notesList | paginate: config">
                                    <td class="doe-table-body" title="{{note.notes}}">{{(note.notes!==null && note.notes!==undefined)?(note.notes.substring(1,25)+(note.notes.length>25?'...':'')):''}}</td>
                                    <td class="doe-table-body">{{note.type}}</td>
                                    <td class="doe-table-body">{{note.createdDate | date: 'dd-MM-yyyy'}}</td>
                                    <td class="doe-table-body">{{note.user}}</td>
                                    
                                

                                </tr>
 

                            </tbody>
                        </table>
                        <div class="mt-2 text-center">
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
                        </div>
                      
                    </div>
                   <br>
              <!--     <app-chooseRecievedResponse  (message)="helpText($event)" *ngIf="accessList.indexOf('ChooseOutcome')>-1 && (((roleId==='ROM' ||  roleId==='ADMIN') && this.passeddata.CaseStatus==='Recommendations') || ((roleId==='ACO' || roleId==='ADMIN' || roleId==='LEADACO') && (this.passeddata.CaseStatus==='Evidence Collection and Analysis' || this.passeddata.CaseStatus==='Peer Review Modifications')))"></app-chooseRecievedResponse> 
-->
<hr> 
<button type="button" mdbBtn size="sm" *ngIf="accessList.indexOf('ChooseOutcome')>-1 && (passeddata.CaseStatus==='Immediate Risk to Children' || (((roleId==='ROM' || roleId==='ADMIN' ) && (this.passeddata.CaseStatus==='Recommendations' || this.passeddata.CaseStatus==='Decision Pending')) || ((roleId==='ACO' || roleId==='ADMIN' || roleId==='LEADACO') && (this.passeddata.CaseStatus==='Evidence Collection and Analysis' || this.passeddata.CaseStatus==='Peer Review Modifications'))))"
class="relative waves-light breachactioncolor rounded white-text font-weight-bold"
mdbWavesEffect  [routerLink]="['/updateBreachActions/pages/detail1/updateBreachActions']"  >  Update Breach Actions</button>

<!-- start of documents list-->
            <hr>
<table mdbTable id="chooseitems" 
class="z-depth-1 px-4 py-4 doe-table-body mt-2" hover="true" *ngIf="accessList.indexOf('ChooseOutcome')>-1 && (passeddata.CaseStatus==='Immediate Risk to Children' || (((roleId==='ROM' || roleId==='ADMIN' ) && (this.passeddata.CaseStatus==='Recommendations' || this.passeddata.CaseStatus==='Decision Pending')) || ((roleId==='ACO' || roleId==='ADMIN' || roleId==='LEADACO') && (this.passeddata.CaseStatus==='Evidence Collection and Analysis' || this.passeddata.CaseStatus==='Peer Review Modifications'))))">
<tbody>
    <tr class="w-100">
        <td >
<div class=" mt-2" mdbDropdown *ngIf="accessList.indexOf('ChooseOutcome')>-1" >
    <!--<a class="trigger dropdown-toggle waves-light headertexts p-0 ml-1 buttoncolor text-white font-weight-bold" placement="top"  mdbDropdownToggle>
       
        Choose Outcome Type </a> -->

        <button mdbBtn class="trigger dropdown-toggle waves-light chooseoutcomecolor rounded text-white font-weight-bold ml-2"  placement="top"  mdbDropdownToggle size="sm"
        >
        Choose Draft Outcome 
    </button>
  
      <div class="dropdown-menu dropdown-primary p-4 colselection" >
          <!-- Default unchecked -->
     

         <div  class="hovercolor" *ngFor="let status of outcomeStatus; let i = index" >
                     <mdb-checkbox  [checked]="isInProgress(status)===true?true:false" (change)="addtoOutcomeTable(status,$event.checked);"  >{{status}}</mdb-checkbox> 
               
                  </div>
  
       </div>
  
    
  <!--<div class="col-md-4 py-4 ml-1">
       
        <label class="headertexts">Outcome Type: </label>
        
          <select class="browser-default custom-select" (change)="onCaseStatusChange($event.target.value);" name="sel2">
                        
            <option *ngFor="let status of outcomeStatus" [value]="status"  [selected]="status==passeddata.CaseStatus">
                <mdb-checkbox [default]="false" (change)="addOutcometoView(status,$event.checked)">{{status}}</mdb-checkbox>
                                                  
            </option>
          </select>


    </div> -->
  

</div>
</td>
<td class=""> 
    <div class="custom-file  w-50  ">

        <div class="input-group p-0">

            <div class="custom-file">
                <input type="file"
                    class="custom-file-input "
                    accept=".jpg,.pdf"
                    id="inputGroupFile01"
                    multiple
                    (change)="onFileChanged($event,item)"
                   
                   >
                <label class="custom-file-label"
                    for="inputGroupFile01">{{filename}}</label>
            </div>
        </div>

    </div>

    <button type="button" mdbBtn size="sm" [ngClass]="{'disabled':(filename==='choose one')}"
    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mb-0"
    mdbWavesEffect (click)="onUpload(item);">
    <div *ngIf="uploading" >
        <div class="spinner-border text-primary" role="status">
        </div>
    </div>
    <span *ngIf="!uploading"> Upload </span>
    </button>


    <div>
        <label class="mt-4">( file can be of
            type
            .jpg,.pdf
            only and cannot be more than 20 MB)</label> 
    </div>
   
</td>
</tr>
</tbody>
</table>
<hr>
<div><label class="headertexts mt-1">Documents List:</label> </div>

<div>

<table mdbTable id="documentsTable" 
class="z-depth-1 px-4 py-4 doe-table-body" hover="true">

<!--Table head-->
<thead class="lawReg-table-header tableheadercolor">
    <tr  class="">
        <th *ngFor="let head of headElementsDocuments; let i = index" [ngClass]="{'d-none': head==='select' && accessList.indexOf('ChooseOutcomeDocs')===-1}"
            aria-controls="documentsTable" [mdbTableSort]="outcomeDocumentsList"
            [sortBy]="headElementsDocumentsSort[i]" scope="col">
            {{head}} <mdb-icon fas icon="sort"></mdb-icon>
        </th>
    </tr>

</thead>
 
<!--Table body-->
<tbody>


    <tr *ngFor="let doc of outcomeDocumentsList | paginate: configoutcome"> 

        <td class="doe-table-body text-center" *ngIf="accessList.indexOf('ChooseOutcomeDocs')>-1">
            <mdb-checkbox [checked]="doc.selected==='true'" (change)="updateOutcomeSelection(doc)"></mdb-checkbox>
           
        </td>
        <td class="doe-table-body" title="{{doc.outcomeType}}">
            {{(doc.outcomeType!==null && doc.outcomeType!==undefined)?(doc.outcomeType.substring(0,25)+(doc.outcomeType.length>25?'...':'')):''}}
            <a [routerLink]="['/emailTemplates/emergencyAction/showtemplate']"  
            routerLinkActive="active"> 
            <mdb-icon fas icon="pencil-alt" class="prefix text-danger"  *ngIf="accessList.indexOf('ChooseOutcome')>-1 && doc.status!=='Attached Document' && doc.status!=='Approved' && doc.status!=='sent for Review' && doc.outcomeType!=='No further Action' && ((((roleId==='ROM' || roleId==='ADMIN') && (this.passeddata.CaseStatus==='Recommendations' || this.passeddata.CaseStatus==='Immediate Risk to Children' )) || ((roleId==='ACO' || roleId==='ADMIN' || roleId==='LEADACO') && (this.passeddata.CaseStatus==='Evidence Collection and Analysis' || this.passeddata.CaseStatus==='Peer Review Modifications'))))" (click)="setValues(doc.outcomeType);"></mdb-icon>
            <!--<mdb-icon fas icon="pencil-alt" class="prefix text-danger"  *ngIf="passeddata.CaseStatus!==Approval && accessList.indexOf('ChooseOutcome')>-1 && doc.status!=='Approved' && doc.status!=='History Document'" (click)="setValues(doc.outcomeType);"></mdb-icon> --></a>
</td>

        <td class="doe-table-body">
            <a *ngIf="(doc.trimId!==null && doc.trimId.trim()!=='' &&  doc.trimId!=='undefined' && doc.trimId!=='null' ) && doc.outcomeType!=='No further Action' "
                class="text-primary" (click)=loadDoc(doc.trimId,doc.outcomeType); title="{{doc.outcomeType}}">
                {{(doc.outcomeType!==null && doc.outcomeType!==undefined)?(doc.outcomeType.substring(0,25)+(doc.outcomeType.length>25?'...':'')):''}}
            </a>
            <a *ngIf="(doc.trimId===null || doc.trimId.trim()==='' || doc.trimId==='undefined' ||  doc.trimId==='null' ) && doc.outcomeType!=='No further Action' " 
                (click)="generateAttachmentPdf(doc.outcomeType);" class="text-primary" title="{{doc.outcomeType}}"><span
                    class="text-danger">Draft</span>
                {{(doc.outcomeType!==null && doc.outcomeType!==undefined)?(doc.outcomeType.substring(0,25)+(doc.outcomeType.length>25?'...':'')):''}}
            </a>
         </td>
        <td class="doe-table-body">{{doc.status}}</td>
        <td class="doe-table-body">{{doc.draftedBy}}</td>
        <td class="doe-table-body">{{doc.draftedDate | date: 'dd-MM-yyyy'}}</td>
        <td class="doe-table-body">
        {{(doc.reviewedBy && doc.reviewedBy!== null && doc.reviewedBy!==undefined)?(doc.reviewedBy.replace('.',' ')):""}}
    </td>
        <td class="doe-table-body">{{doc.reviewedDate | date: 'dd-MM-yyyy'}}</td>
        <td class="doe-table-body">{{doc.approvedBy}}</td>
        <td class="doe-table-body">{{doc.approvedDate | date: 'dd-MM-yyyy'}}</td>
       
        <td class="doe-table-body">{{doc.emailSentDate | date: 'dd-MM-yyyy'}}</td>
        <td><mdb-icon fas icon="window-close" *ngIf="(doc.status==='In Progress' && accessList.indexOf('ChooseOutcome')>-1) || (doc.status==='Attached Document' && accessList.indexOf('deleteHistoryDoc')>-1) " class="ml-1 text-danger cursor" (click)="removeDoc(doc);"></mdb-icon></td>
     </tr>
    

</tbody>


</table>

<div class="mt-2 text-center">
    <pagination-controls (pageChange)="pageChangedDoc($event)"></pagination-controls> 
</div>

</div>

<div class="row">
    <div  class="col-md-4" *ngIf="accessList.indexOf('officerComments')>-1">
        <label class="headertexts"><mdb-icon fas icon="pencil-alt">
        </mdb-icon>Officer's Comments </label>
        <textarea style="font-size:12px;" class="form-control"  rows="3" maxlength="1500"  name="OfficersComments" [(ngModel)]="passeddata.OfficerComments" [value]="passeddata.OfficerComments"  [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}"></textarea>
        <span class="mt-0 reminingText py-0">{{(passeddata.OfficerComments!==null && passeddata.OfficerComments!==undefined)?(1500-passeddata.OfficerComments.length):1500}} characters left...</span>
                    
        <div class="text-right">
        <button type="button" mdbBtn size="sm" 
        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();">Save</button>
    </div>
</div>
    <div  class="col-md-4" *ngIf="accessList.indexOf('officerComments')===-1">
        <label class="headertexts">
            Officer's Comments </label>
         <!--    <textarea class="form-control"  rows="3" maxlength="100"  name="OfficersComments" disabled >{{passeddata.OfficerComments}}</textarea>
         -->
         <div class="overflow:auto h-25 w-100 doe-table-body"> 
           {{passeddata.OfficerComments}}
         </div>
    </div>
    <div  class="col-md-4" *ngIf="accessList.indexOf('reviewComments')>-1">
        <label class="headertexts"><mdb-icon fas icon="pencil-alt">
        </mdb-icon>Reviewer's Comments </label>
        <textarea style="font-size: 12px;" class="form-control"  rows="3" maxlength="1500"  name="ReviewerComments" [(ngModel)]="passeddata.ReviewerComments" [value]="passeddata.ReviewerComments" [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Peer Review Sent')}"></textarea>
        <span class="mt-0 reminingText py-0">{{(passeddata.ReviewerComments!==null && passeddata.ReviewerComments!==undefined)?(1500-passeddata.ReviewerComments.length):1500}} characters left...</span>
                  
        <div class="text-right">
          <button type="button" mdbBtn size="sm" 
          class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();">Save</button>
      </div>
    </div>
    <div class="col-md-4" *ngIf="accessList.indexOf('reviewComments')===-1">
               
        <label class="headertexts">Reviewer's Comments </label>
        <div class="overflow:auto h-25 w-100 doe-table-body"> 
          {{passeddata.ReviewerComments}}
        </div>
     
        
    </div>
    <div  class="col-md-4" *ngIf="accessList.indexOf('approverComments')>-1">
        <label class="headertexts"><mdb-icon fas icon="pencil-alt">
        </mdb-icon>Approver's Comments </label>
        <textarea style="font-size: 12px;" class="form-control"  rows="3" maxlength="1500"  name="ApproverComments" [(ngModel)]="passeddata.ApproverComments" [value]="passeddata.ApproverComments"  [ngClass]="{'disabled': passeddata.CaseStatus!=='Recommendations' && passeddata.CaseStatus!=='Decision Pending'}"></textarea>
        <span class="mt-0 reminingText py-0">{{(passeddata.ApproverComments!==null && passeddata.ApproverComments!==undefined)?(1500-passeddata.ApproverComments.length):1500}} characters left...</span>
      
        <div class="text-right">
          <button type="button" mdbBtn size="sm" 
          class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();">Save</button>
      </div>
    </div>
    <div class="col-md-4" *ngIf="accessList.indexOf('approverComments')===-1">
               
        <label class="headertexts">Approver's Comments </label>
        <div class="overflow:auto h-25 w-100 doe-table-body"> 
          {{passeddata.ApproverComments}}
        </div>
     
        
    </div>

</div>

            <!-- end of documents list-->
              <!--      <app-documentsList [recordNumber]="recordNumber" [refreshOutcomeTable]="refreshOutcomeTable" (refreshOutcomeTableReset)="resetRefreshTable($event)" [visitList]="visitList" [lawRegList]="lawRegList" [accessList]="accessList" (message)="helpText($event)" (readyForReview)="readyForReview" (readyForApproval)="readyForApproval"></app-documentsList>
           -->
                 <!--  <div><label class="headertexts mt-1">Documents Pending Approval :</label> </div>
                    <div class="row d-flex mt-0"  *ngFor="let outcome  of pendingOutcomesList">
                   
                        <a (click)="generateAttachmentPdf(outcome.outcomeType);" class="text-primary ml-4 headertexts" >view PDF for {{outcome.outcomeType}}</a>
                      
                        <a [routerLink]="['/emailTemplates/emergencyAction/showtemplate']"  
                        routerLinkActive="active"> <mdb-icon fas icon="pencil-alt" class="prefix text-danger"  (click)="setValues(outcome.outcomeType);"></mdb-icon></a>
                     --> 
                      
                      <!--  <app-emergencyActionTemplate  *ngIf="accessList.indexOf('EditPdf')>-1" (message)="helpText($event)" [outcomeType]="outcome.outcomeType" [visitList]="visitList" [lawRegList]="lawRegList"></app-emergencyActionTemplate>
                      -->         
                 <!-- </div>  -->  

                    
                 <!--   <div class="row d-flex mt-0"  *ngIf="statusChange || (passeddata.ApprovalStatus==='Pending Approval')">
                   
                        <a (click)="generateAttachmentPdf();" class="text-primary ml-4 headertexts" *ngIf="passeddata.CaseStatus!='Scheduled'">view PDF for {{passeddata.CaseStatus}}</a>
                        <label class="doe-table-body text-danger py-1 ml-1" *ngIf="passeddata.ApprovalStatus==='Pending Approval'">{{passeddata.ApprovalStatus}}</label>
                    </div>-->
               <!--    
                <div class="mt-4" *ngIf="accessList.indexOf('officerComments')>-1">-->
                  <!--  <mdb-icon fas icon="pencil-alt" class="prefix"></mdb-icon>
                    <textarea type="text" id="form8" class="md-textarea form-control" mdbInput  name="OfficersComments" [(ngModel)]="passeddata.OfficerComments" [ngValue]="passeddata.OfficerComments" ></textarea>
                    <label for="form8">Officers's Comments</label> -->


                   <!-- <label class="headertexts"><mdb-icon fas icon="pencil-alt">
                    </mdb-icon>Officers's Comments </label>
                    <textarea class="form-control"  rows="3" maxlength="150"  name="OfficersComments" [(ngModel)]="passeddata.OfficerComments" [value]="passeddata.OfficerComments"  [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}"></textarea>
                    <span class="mt-0 reminingText py-0">{{(passeddata.OfficerComments!==null && passeddata.OfficerComments!==undefined)?(150-passeddata.OfficerComments.length):150}} characters left...</span>
                  
                    <div class="text-right">
                    <button type="button" mdbBtn size="sm" 
                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();"  [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}">Save</button>
                </div>
                    
                </div>

            

                <div class="mt-4" 
                *ngIf="accessList.indexOf('officerComments')===-1">
                      
  
                      <label class="headertexts">
                     Officers's Comments </label>-->
                  <!--    <textarea class="form-control"  rows="3" maxlength="100"  name="OfficersComments" disabled >{{passeddata.OfficerComments}}</textarea>
                  -->
                 <!--  <div class="overflow:auto h-25 w-100 doe-table-body"> 
                    {{passeddata.OfficerComments}}
                  </div>
                      
                  </div>


                  <div class="mt-4" *ngIf="accessList.indexOf('reviewComments')>-1">
                    
  
                      <label class="headertexts"><mdb-icon fas icon="pencil-alt">
                      </mdb-icon>Reviewer's Comments </label>
                      <textarea class="form-control"  rows="3" maxlength="150"  name="ReviewerComments" [(ngModel)]="passeddata.ReviewerComments" [value]="passeddata.ReviewerComments" [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Peer Review Sent')}"></textarea>
                      <span class="mt-0 reminingText py-0">{{(passeddata.ReviewerComments!==null && passeddata.ReviewerComments!==undefined)?(150-passeddata.ReviewerComments.length):150}} characters left...</span>
              
                      <div class="text-right">
                        <button type="button" mdbBtn size="sm" 
                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();">Save</button>
                    </div>
                      
                  </div>
                  <div class="mt-4" *ngIf="accessList.indexOf('reviewComments')===-1">
               
                      <label class="headertexts">Reviewer's Comments </label>
                      <div class="overflow:auto h-25 w-100 doe-table-body"> 
                        {{passeddata.ReviewerComments}}
                      </div>
                   
                      
                  </div>


                  
                  <div class="mt-4" *ngIf="accessList.indexOf('approverComments')>-1">
                 
  
                      <label class="headertexts"><mdb-icon fas icon="pencil-alt">
                      </mdb-icon>Approver's Comments </label>
                      <textarea class="form-control"  rows="3" maxlength="150"  name="ApproverComments" [(ngModel)]="passeddata.ApproverComments" [value]="passeddata.ApproverComments"  [ngClass]="{'disabled':(this.passeddata.CaseStatus==='Recommendations')}"></textarea>
                      <span class="mt-0 reminingText py-0">{{(passeddata.ApproverComments!==null && passeddata.ApproverComments!==undefined)?(150-passeddata.ApproverComments.length):150}} characters left...</span>
              
                      <div class="text-right">
                        <button type="button" mdbBtn size="sm" 
                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="updateCase();">Save</button>
                    </div>
                      
                  </div>
                  <div class="mt-4" *ngIf="accessList.indexOf('approverComments')===-1">
               
                      <label class="headertexts">Approver's Comments </label>
                      <div class="overflow:auto h-25 w-100 doe-table-body"> 
                        {{passeddata.ApproverComments}}
                      </div>
                   
                      
                  </div>
                -->
    

                  <div *ngIf="accessList.indexOf('editCaseOutcomeOfficer')>-1 && accessList.indexOf('ChooseOutcome')>-1" class="mt-3">
                    <label class="headertexts"> Choose Reviewer: </label>   
                    <div><select  style="font-size: 12px;" class="browser-default custom-select w-25"   [(ngModel)]="passeddata.Reviewer" name="selectReviewer" [ngClass]="{'disabled':(getCaseStatus()||passeddata.CaseStatus!=='Evidence Collection and Analysis'|| getReadyForReview())}" >
                                        
                        <option *ngFor="let user of getACOsforReviewer()" [value]="user.userId"   >{{user.userId}}
                        </option>
                      </select>
                  
                        <button type="button" mdbBtn size="sm" *ngIf="accessList.indexOf('ChooseOutcome')>-1" 
                        class="relative waves-light draftreviewcolor rounded text-white font-weight-bold " [ngClass]="{'disabled':(getCaseStatus()||passeddata.CaseStatus!=='Evidence Collection and Analysis'|| getReadyForReview())}" 
                        mdbWavesEffect (click)="updateReviewer();" >Send Draft for Review</button>

                      <!--
                            <button type="button" mdbBtn size="sm" *ngIf="accessList.indexOf('ChooseOutcome')>-1" 
                            class="relative waves-light btn-deep-purple rounded text-white font-weight-bold " [ngClass]="{'disabled': (getCaseStatus() || (passeddata.CaseStatus!=='Peer Review Modifications' && passeddata.CaseStatus!=='Evidence Collection and Analysis') || getReadyForApproval())}" 
                            mdbWavesEffect (click)="validateAndSendforApproval();">Send Draft for Approval</button>
                        --> 
    
                    </div>

                  </div>

                  <div *ngIf="accessList.indexOf('editCaseOutcomeOfficer')>-1 && accessList.indexOf('ChooseOutcome')>-1" class="mt-3">
                    <label class="headertexts"> Choose Approver: </label>   
                    <div><select  style="font-size: 12px;" class="browser-default custom-select w-25"   [(ngModel)]="passeddata.Approver" name="selectApprover"  [ngClass]="{'disabled': (getCaseStatus() || (passeddata.CaseStatus!=='Peer Review Modifications' && passeddata.CaseStatus!=='Evidence Collection and Analysis') || getReadyForApproval())}"  >
                                        
                        <option *ngFor="let user of getApproversList()" [value]="user.userId"   >{{user.userId}}
                        </option>
                      </select>
                  
                                   
                            <button type="button" mdbBtn size="sm" *ngIf="accessList.indexOf('ChooseOutcome')>-1" 
                            class="relative waves-light draftapprovalcolor rounded text-white font-weight-bold " [ngClass]="{'disabled': (getCaseStatus() || (passeddata.CaseStatus!=='Peer Review Modifications' && passeddata.CaseStatus!=='Evidence Collection and Analysis') || getReadyForApproval())}" 
                            mdbWavesEffect (click)="validateAndSendforApproval();">Send Draft for Approval</button>
                        
    
                    </div>

                  </div>

                  <div  *ngIf="accessList.indexOf('ChooseManagerReviewer')>-1" class="mt-3">
                   
                
                    <div class="ml-3">
                    <mdb-checkbox
                        class="headertexts"
                         (change)="showmanagerreviewer=!showmanagerreviewer" 
                        [ngClass]="{'disabled': (passeddata.CaseStatus !== 'Recommendations')?true:false}" >
                        Do you want to send the draft for Manager Review?
  
                    </mdb-checkbox> 
                    </div>
                    <div  *ngIf="showmanagerreviewer==true">
                   

                     <label class="headertexts"> Choose Manager: </label>   
                     <div>
                        
                        <select style="font-size: 12px;" class="browser-default custom-select w-25"   [(ngModel)]="passeddata.ManagerReviewer" name="selectManagerReviewer">
                                        
                        <option *ngFor="let user of userListMgrs" [value]="user.userId"  >{{user.userId}}
                        </option>
                      </select>

                    
                  
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light managerreviewcolor rounded text-white font-weight-bold " [ngClass]="{'disabled': (passeddata.CaseStatus !== 'Recommendations')?true:false}" 
                        mdbWavesEffect (click)="updateManagerReviewer();">Send Draft for Manager Review</button>
                    </div>                  
                      
            
                    </div>
                   

                    <div  *ngIf="accessList.indexOf('ChooseManagerReviewer')>-1" class="mt-3">
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light sendemailcolor rounded text-white rounded  font-weight-bold" [ngClass]="{'disabled': ((passeddata.CaseStatus !== 'Recommendations' && passeddata.CaseStatus !== 'Decision Pending' && passeddata.CaseStatus !== 'Immediate Risk to Children') || (isSelectedDocs()===true?true:false) || sendClicked===true)?true:false}"
                        mdbWavesEffect (click)="prepareEmailForSelectedOutCome();emailModal.show();this.sendClicked = true;">
                    
                        <div *ngIf="loadingApprove" mt-3>
                            <div class="spinner-border text-primary" role="status">
                            </div>
                        </div>
                        <span *ngIf="!loadingApprove">  Approve and Send Email to Service </span>
                    
                        </button>
                    </div>

                  </div>

            
               
                <div class="row d-flex mt-5 p-0">
                   
                    <div class="ml-0">
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light summaryreportcolor rounded white-text font-weight-bold"
                        mdbWavesEffect (click)="showsummary();">View Summary Report</button>
                     </div> 
                  
                       
                     <button type="button" mdbBtn size="sm" *ngIf="accessList.indexOf('CloseCase')>-1"
                     class="relative waves-light closecolor rounded  text-white font-weight-bold " 
                     mdbWavesEffect (click)="updateCaseStatus('Closed');">Close Case</button>

                       
                       <div class="col-md-3 ml-0" *ngIf="accessList.indexOf('reviewComments')>-1">
                        <button type="button" mdbBtn size="sm" [ngClass]="{'disabled': passeddata.CaseStatus!=='Peer Review Sent'}" 
                        class="relative waves-light reviewcolor rounded text-white font-weight-bold "
                        mdbWavesEffect (click)="reviewComplete('sent for Review','Review Complete');">Review Complete</button>
                       </div> 
                    
                     
                     
                   </div>

            </form>
            <!-- Form -->
        </div>
    </mdb-card-body>
</mdb-card>

<div mdbModal #emailModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="emailModal.hide();this.sendClicked = false;">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Non Compliance draft email</p>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="emailTo">To:</span>
                    </div>
                    <input type="text" class="form-control emailLabel" [(ngModel)] ="emailTo" aria-label="Type" aria-describedby="basic-addon1" disabled>
                </div>
                  
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="emailFrom">From:</span>
                    </div>
                    <input type="text" class="form-control emailLabel" [(ngModel)] ="emailFrom"  aria-label="From" aria-describedby="basic-addon2">
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">CC:</span>
                    </div>
                  <!--  <input type="text" class="form-control emailLabel" placeholder="{{passeddata.ServiceEmail}}" aria-label="To" aria-describedby="basic-addon3" > -->
                  <input type="text" class="form-control emailLabel"  [(ngModel)] ="emailCc"  aria-label="To" aria-describedby="basic-addon3" >
                </div>
               
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="emailSubject">Subject:</span>
                    </div>
                    <input type="text" class="form-control emailLabel"  [(ngModel)]="emailSubject" aria-label="cc" aria-describedby="basic-addon4">
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">Attachements:</span>
                    </div>
                    <input type="text" class="form-control emailLabel"  [(ngModel)]="emailAttachments" aria-label="cc" aria-describedby="basic-addon4">
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts">Message</span>
                    </div>
                   
                    <textarea class="form-control" rows="8" maxlength="4000" name="emailBody"
                    [(ngModel)]="emailContent"></textarea>
                   
                  </div>
              
              
                <div>
                    <table mdbTable class="p-0 mt-0 table-fixed noborder">
                        <tbody class="p-0">
                            <tr class="p-0">
                                <td class="noborder p-0">
                                    <button type="button" mdbBtn size="sm"
                                    class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                                    mdbWavesEffect (click)="sendEmailtoService(emailContent,emailTo,emailSubject);emailModal.hide(); ">Send</button>
                                
                                 
                                  </td>
                                <td
                                    class="mt-0 reminingText p-0 text-right noborder">
                                   
                                        <button type="button" mdbBtn size="sm"
                                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger justify-content-right"
                                        mdbWavesEffect (click)="emailModal.hide();this.sendClicked = false; ">Cancel</button>

                                </td>
                            </tr>
                        </tbody>
                    </table>


           
                </div>
            </div>

        </div>
    </div>
</div>




<div mdbModal #notesModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="notesModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="notesModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Add Notes</p>
            </div>
            <div class="modal-body">
               <div class="text-danger headertexts">
                   {{errormessage}}
               </div>
              
                <label class="headertexts"> Note Type: </label>   
                    <select class="browser-default custom-select"   [(ngModel)]="notesType" name="selectType">
                                        
                        <option *ngFor="let type of noteType" [value]="type" >{{type}}
                        </option>
                      </select>
                    
                                   
               
               
        
                    <div class="form-group green-border-focus mb-4 mt-3">
                        <label for="notestextarea" class="headertexts"> Summary:</label>
                    <textarea class="form-control doe-table-body" id="notestextarea" maxlength="1000" [(ngModel)]="notesText" rows="3" ></textarea>
                        <span class="mt-0 reminingText py-0">{{(notesText!==null && notesText!==undefined)?(1000-notesText.length):1000}} characters left...</span>
                   
                  </div>
              
              
                  <div class="text-right">

                
                <button type="button" mdbBtn size="sm"
                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                mdbWavesEffect (click)="saveNotes();">Save</button>
                
            </div>
            
            
            </div>

        </div>
    </div>
</div>

</fieldset>

