import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'invalidpage',
  templateUrl: './invalidpage.component.html',
  styleUrls: ['./invalidpage.component.scss']
})
export class InvalidPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('404');
  }

}
