<div class="full">
<div class="row " >
  <div class="col-md-8">
    <img src="../../../../assets/img/angular-mdb.png" alt="Error 404" class="img-fluid" width="200px" height="20px">
    <h2 class="h2-responsive mt-3 mb-2">Invalid Access - As user is not Configured in ecompliance</h2>
   
  </div>
 
</div>
</div>

