import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent, ModalContainerComponent, ModalDirective } from 'angular-bootstrap-md';
import { DatePipe, formatDate } from '@angular/common'
import { DataService } from '../../../visits/visit1/visit1.service';
import { VisitService } from '../../../../service/visit.service';
import { INAService } from '../../../../service/ina.service';
import { EvidenceService } from 'src/app/service/evidence.service';
//import { EvidenceDocService } from 'src/app/service/evidenceDoc.service';

import { CaseService } from 'src/app/service/case.service';
import { LawRegService } from 'src/app/service/lawReg.service';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/service/document.service';
import { LoginDataService } from 'src/app/service/loginData.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';
import { timer } from 'rxjs';
import { AppConfigService } from 'src/app/service/AppConfigService';
//import { interval  } from 'rxjs';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class CaseComponent implements OnInit , OnDestroy{

  @ViewChild('ConfirmationModal') confirmationModal: ModalDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild('row', { static: true }) row: ElementRef;
  @ViewChild('RevCommentsModal', { static: true }) RevCommentsModal: ModalContainerComponent;
  @ViewChild('visitsection') visitsection: ElementRef;
  accessList: string[] = [];
  address: any;
  additionalRegs: any[] = [];
  additionalRegsTemp: any[] = [];
  evidenceText1: string;
  summaryreport = false;
  previous: any = [];
  headElements = ['id', 'name', 'address', 'officer'];
  selectedtab: string;
  searchText: string = '';
  searchValue: string;
  maxVisibleItems: number = 2;
  questionairre: string;
  headElementsLawRegSort = ['inaid', 'priority', 'description', 'dateOfAllegedOffence', 'complianceStatus'];
  headElementsLawReg = ['INA', 'Priority', 'Area of Breach', 'Date of Alleged Offence', 'Area of breach outcome'];
  headEvidenceList = ['VisitId', 'location Name', 'Officer', 'Evidence Description', "Attachments"];

  showLocation = true;
  showlocTable = true;
  locCounter = 0;
  modalImagepath: any;
  assignedACOs = ["mahinder", "newACO"];
  headElementsSummarySort = ['inaid', 'Priority', 'lawid', 'complianceStatus', 'evidence', 'Attachments', 'reviewComments']
  headElementsSummary = ['INA', 'Priority', 'Area of Breach', 'Area of Breach Outcome', 'Evidence', 'Attachments', 'Review Comments']
  statusCompliance = ["choose one", "Confirmed Breach", "Breach Not Confirmed", "Not Reviewed"];
  //tempEmailTemplate="Dear "+(this.dataservice && this.dataservice.getOption() && this.dataservice.getOption()!==null && this.dataservice.getOption()!=='undefided')?this.dataservice.getOption().PrimaryContact:""+",<br/><br/>The approved provider has now submitted their Service Context Details and Self Assessment form for <span style='font-weight: bold;'>"+this.dataservice.getOption().ServiceName+" :# "+this.dataservice.getOption().SENumber+"</span> <br><br>Please login to abd site provide explanation <span style='font-weight: bold;'>$ASR_NO.</span><br><br>Yours sincerely, <br><br><br>Network Coordination Unit<br>Statewide Operations<br>Early Childhood Education Directorate<br>Department of Education<br>T: 1800 619 113<br>E: ECnetworkcoordination@det.nsw.edu.au<br>";
  tempEmailTemplate = "";
  outcomeStatus = ["Choose Final Outcome", "Admin Letter", "Compliance Notice", "Compliance Direction", "Suspension of Provider Approval", "Show Cause Notice: Cancellation of Provider Approval", "Notice of Decision to Cancel Provider Approval"];
  reviewerComments = "reviewer comments here";
  approverComments = "approver comments here";

  evidenceTemplate = '';



  moreLawRegList: any[] = [];
  sortedLawRegList: any[] = [];
  visitheadElementsSort = ['visitId', 'location', 'visitstatus', 'startDt', 'endDt', 'officer1', 'officer2', "visitNotDone"];
  visitheadElements = ['Visit ID', 'Location', 'Status', 'Start Date', 'Completion Date', 'Officer 1', 'Officer 2', "Visit Not Conducted"];

  visitList: any[] = [];

  visitselected = false;



  lawRegList: any[] = [];
  /*locationList: any[] = [
    { id: 1, name: "EducatorName Loc1", address: "south wentworthville", officer: ["Paul", "Jhon"], showtab: true, selected: true },
    { id: 2, name: "EducatorName Loc2", address: "carlingford", officer: ["Paul", "Jhon"], showtab: false }
  ];*/
  sublawfiltelist: any[][];

  public map: any;

  emptyLocations: false;
  editField: string;
  passeddata: any;
  role: string = '';
  autosavetimer: number;
  //address:String;
  filenames: any[] = [];
  constructor(private loginService: LoginDataService,
    private appconfig: AppConfigService,
    private userDetailsService: UserDetailsService, private documentService: DocumentService, private toastr: ToastrService, private caseService: CaseService, private datePipe: DatePipe, private lawRegService: LawRegService, private ref: ChangeDetectorRef, private dataservice: DataService, private visitservice: VisitService, private inaService: INAService, private evidenceService: EvidenceService) {
    this.bntStyle = 'btn-default';
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
    this.autosavetimer = +this.appconfig.getConfig().autosavetimer;


  }
  ngOnDestroy(): void {

    this.lawRegList.forEach(item=>{
      if (item.subscribtion !== null && item.subscribtion !== undefined) {

        item.subscribtion.unsubscribe();
      }
    });
    
  }

  /*

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  */

  CompletedOn = '';
  CaseSummary = '';
  recordNumber = '';
  userId: string;
  loggedinUser: string

  async ngOnInit() {

    if (this.loginService.getData() == null || this.loginService.getData() == undefined) {
      console.log(localStorage.getItem('displayLoginName'));
      this.loginService.setAccessList([]);
      this.loginService.setData({
        firstName: localStorage.getItem('firstName'),
        surname: localStorage.getItem('surname'),
        displayLoginName: localStorage.getItem('displayLoginName'),
        email: localStorage.getItem('email'),
        status: localStorage.getItem('status'),
        roleId: localStorage.getItem('roleId'),
        uid: localStorage.getItem('uid')

        //roleId: localStorage.getItem('roleId')

      });

      this.userId = this.loginService.getData().firstName + "." + this.loginService.getData().surname;
      this.loggedinUser = this.loginService.getData().uid;
      //this.role = this.loginService.getData().roleId;

    } else {

      this.userId = this.loginService.getData().firstName + "." + this.loginService.getData().surname;
      this.loggedinUser = this.loginService.getData().uid;
      //this.role = this.loginService.getData().roleId;
    }
    if (this.dataservice.getRole() == null || this.dataservice.getRole() == undefined) {
      var role = localStorage.getItem("selectedRole");
      (role !== null) ? this.dataservice.setRole(role) : this.dataservice.setRole('');


    }
    this.role = this.dataservice.getRole();

    //this.accessList = [...this.loginDataService.getAccessList()];

    await this.setAccess();

    this.accessList = [...this.loginService.getAccessList()];


    if (this.dataservice.getOption() == null || this.dataservice.getOption() == undefined) {

      var temp = localStorage.getItem("selectedCase");
      (temp != null) ? this.dataservice.setOption(JSON.parse(temp)) : this.dataservice.setOption('');

      this.passeddata = this.dataservice.getOption();

    } else {
      this.passeddata = this.dataservice.getOption();
    }
    this.caseService.getCase(this.dataservice.getOption().CaseId).subscribe(data => {
      this.passeddata.CaseStatus = data.status;

    });


    if (this.passeddata) {

      this.tempEmailTemplate = "Dear " + this.passeddata.PrimaryContact + ",<br/><br/>The approved provider has now submitted their Service Context Details and Self Assessment form for <span style='font-weight: bold;'>" + this.dataservice.getOption().ServiceName + " :# " + this.dataservice.getOption().SENumber + "</span> <br><br>Please login to abd site provide explanation <span style='font-weight: bold;'>$ASR_NO.</span><br><br>Yours sincerely, <br><br><br>Network Coordination Unit<br>Statewide Operations<br>Early Childhood Education Directorate<br>Department of Education<br>T: 1800 619 113<br>E: ECnetworkcoordination@det.nsw.edu.au<br>";

      this.getVisitsfromDB(this.passeddata.CaseId);
      this.getINAforVisitsfromDB(this.passeddata.CaseId);
      this.map = { lat: this.passeddata.Latitude, lng: this.passeddata.Longitude };


      this.documentService.createRecordinTrim(this.passeddata, '', this.userId)
        .subscribe(
          res => {
            console.log(res);

            this.recordNumber = res.recordNumber;

            //  this.showSuccess('create Record successful');

          },
          err => {
            this.showError('create record Failed !');
            console.log('Error Occured during create record: ' + err);
          });

    }


    /*  if (this.locationList && this.locationList!==null && this.locationList.length > 0) {
        this.showlocTable = false;
      }*/



    /*interval(600000).subscribe(() => {
      
      this.autoSave(this.selectedItem)});*/

  }

  getreviewcomments(comments: any) {

    var tmp = document.createElement("DIV");
    tmp.innerHTML = comments;
    return tmp.textContent || tmp.innerText || "";
  }

  selectedItem: any;
  selectedVisit: any;

  setLoadReg(item: any) {


    this.lawRegList.forEach(el => {
      if (el === item) {

        el.loadReg = !el.loadReg;
        if (el.loadReg === false) {
          this.additionalRegsPop = [];
          /// this.additionalRegsPop.splice(0,this.additionalRegsPop.length);
        }
        else {

          this.additionalRegsPop = [...this.additionalRegs];
        }
      } else {
        el.loadReg = false;
      }
    });

  }

  setVisitEditable(visit: any) {
    visit.editable = true;
  }
  backtoReport(visit: any) {
    var count = this.getOutstandingINAs(visit.visitId);
    if (count > 0) {
      this.showError("INAs are outstanding - with no Area of breach selected :" + count);
    }
    visit.editable = false;
  }
  validateStatus(item: any) {

    if (item !== null && (item.complianceStatus === null || (item.complianceStatus !== null && (item.complianceStatus.toLowerCase() === 'choose one' || item.complianceStatus.toLowerCase() === 'n/a')))) {
      this.showInfo("please choose Area of breach outcome to continue ");
    }
  }



  addevent(event: any) {
    event.stopPropagation();
  }
  closeDropdown() {

    /* this.additionalRegs.forEach(el =>{
       el.loadReg=false;
     });*/

    this.lawRegList.forEach(el => {
      el.loadReg = false;
    });
  }
  getList(item: any) {
    if (item !== null && item !== undefined && item.loadReg === true) {

      return this.additionalRegs;
    }
    else {
      return [];
    }

  }
  async setAccess() {

    //  var i =   this.accessList.indexOf('editVisitOutcome');
    //  this.accessList.splice(i,1);
    //  this.accessList.push('reviewer');
    this.loginService.setAccessList([]);  //empty the list as it is a reviewer role
    await this.userDetailsService.getAccessDetails("ECOMP_" + this.role.toUpperCase()).toPromise().then((data) => {

      for (const d of (data as any)) {


        this.loginService.getAccessList().push(d.pageName);


      }



    });




  }



  reviewModalEvidence: any;
  setCurrentEvidence(evidence: any) {
    this.reviewModalEvidence = evidence;
  }
  reviewComments: string;
  errormessage: string = "";
  addINAReviewComments(comments: string) {
    // this.reviewModalEvidence.reviewComments = this.reviewModalEvidence.reviewComments + this.userId + ":" +  comments;
    if (comments) {
      this.errormessage = "";
      var jstoday = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-au');
      this.lawRegList.find(el => {

        if (el === this.reviewModalEvidence) {
          if (el.reviewComments === null) {
            el.reviewComments = '';
          }
          if (this.role === 'READ_ONLY') {
            el.reviewComments = el.reviewComments + "<span class='usernameMgr'>" + this.userId + "@" + jstoday + ": </span>" + comments + "<br/>";

          }
          else {
            el.reviewComments = el.reviewComments + "<span class='username'>" + this.userId + "@" + jstoday + ": </span>" + comments + "<br/>";

          }
          // this.reviewModalEvidence.reviewComments = this.reviewModalEvidence.reviewComments + this.userId + ":" +  comments;
          el.updatedBy = this.userId;

          this.evidenceService.addEvidence(el).subscribe(res => {
            console.log(res);
            this.showSuccess("review comments added");
            this.reviewComments = '';
            //    el.reviewComments="";
            this.RevCommentsModal.hide();

          });
        }

      });

    } else {
      this.errormessage = "enter comments before save";
    }
  }

  additionalRegsPop: any[] = [];
  onserachingLaws(searchValue: string) {

    if (searchValue === '') {
      this.additionalRegsPop = [...this.additionalRegs];
    }
    else {
      this.additionalRegsPop = this.additionalRegs.filter(el => (el.regId.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }

  }

  showSuccess(message: string) {

    this.toastr.success(message, 'Success!',
      { timeOut: 2000 });;
  }
  showError(message: string) {

    this.toastr.error(message, 'Error!',
      { timeOut: 2000 });;
  }



  showInfo(message: string) {

    this.toastr.info(message, 'Info!',
      { timeOut: 2000 });;
  }

  getTemplate(location: any) {
    var primaryContact = (this.dataservice && this.dataservice.getOption() && this.dataservice.getOption() !== null) ? this.dataservice.getOption().PrimaryContact : '';
    this.evidenceTemplate = "At the residence of educator " + primaryContact + ", located at " + location + ", there were (insert number) children in attendance at the time of the visit.";
    return true;
    // alert(this.evidenceTemplate);
    //return this.evidenceTemplate;
  }
  removeINA(item: any, visit: any) {


    /*  var i = this.lawRegList.indexOf(item);
       this.lawRegList.splice(i,1);
  this.ref.detectChanges();*/
    item.discard = 'true';

    this.inaService.deleteINA(item.inaid)
      .subscribe(resp => {
        console.log(resp);
        this.evidenceService.deleteEvidence(item.inaid)
          .subscribe(resp => {
            this.showSuccess('delete successful');
            console.log(resp);
            this.onChange("Not Reviewed", item, visit)
          }, err => {
            this.showError('deletion of INA failed');
            console.log(err);
          });

      }, err => {
        console.log(err);
      }
      );

  }
  onSelectINA(selectedReg: any, selectedDesc: any, lawregIndicator: any, visit: any) {

    var existing = this.lawRegList.find(el => (el.lawid === selectedReg && el.visitId === visit.visitId && (el.discard == null || el.discard == '' || el.discard === 'null')));

    if (existing !== null && existing !== undefined) {
      this.showError("INA with this Law/Reg already exists");
    }
    else {

      var match = this.additionalRegs.find(a => (a.regId === selectedReg))
      var priority = (match !== null && match !== undefined && match.priority !== null && match.priority !== '') ? match.priority.toLowerCase() : 'low';
      var questionlist = match.questionlist;

      var issubreg = selectedReg.indexOf("(");
      //set the priority to base regulation  
      if (issubreg !== -1) {
        var subregtemp = selectedReg.substring(0, issubreg);

        match = this.additionalRegs.find(a => (a.regId === subregtemp));

        priority = (match !== null && match !== undefined && match.priority !== null && match.priority !== '') ? match.priority.toLowerCase() : priority;

      }
      var temp: any;
      var tempINA = {
        inaid: 'temp_INA' + visit.visitId + "_" + selectedReg,
        id: 1,
        priority: priority,
        priorityCode: (priority !== null && priority !== undefined && priority.toLowerCase() == 'high') ? 1 : (priority !== null && priority !== undefined && priority.toLowerCase() == 'medium') ? 2 : 3,
        questionlist: questionlist,
        lawid: selectedReg,
        description: selectedDesc,
        lawregIndicator: lawregIndicator,
        complianceStatus: 'choose one',
        dateOfAllegedOffence: '',
        loadReg: false,
        evidenceFlag: true,
        showEvidenceFlag: false,
        visitId: visit.visitId,
        visitNotDone: "false",
        serviceId: this.passeddata.SENumber,
        evidence: '',
        discard: '',
        questionairre: questionlist,
        reviewComments: '',
        officerComments: '',
        selectedFile: temp,
        additionalRegsList: [],
        imgURL: [],
        receivedImageData: [],
        convertedImage: [],
        filename: "choose one",
        updatedBy: this.userId
      };

      this.inaService.addINAs(tempINA, this.passeddata.CaseId).subscribe(res => {
        console.log(res);
        this.evidenceService.addEvidence(tempINA)
          .subscribe(resp => {
            this.showSuccess('save successful');
            console.log(resp);
            this.lawRegList.push(tempINA); // add the temp ina
          }, err => {
            this.showError('addition of INA failed in add evidence');
            console.log(err);
          });



      }, err => {
        console.log(err);
        this.showError('addition of INA failed in add ina');

      });
    }

    this.addINA = false;


  }
  nqaInaId: string = '';
  selectedINA: any;
  updateTempINA() {
    if (this.selectedINA) {

      this.inaService.updateINAforTempID(this.selectedINA.inaid, this.nqaInaId)
        .subscribe(resp => {
          console.log(resp);
          this.showSuccess(this.selectedINA.inaid + " successfully updated to " + this.nqaInaId);
          this.lawRegList.forEach(el => {
            if (el.inaid === this.selectedINA.inaid) {
              el.inaid = this.nqaInaId;
            }
          });
        }, err => {
          this.showError(err.error.message);
          //alert(JSON.stringify(err.error.message));
        }

        );
    }

  }

  setSelectedINA(item: any) {
    this.selectedINA = item;
  }

  // {id:1,visitId:"VISIT-0000123", visitstatus:"pending",startDt:"17/12/2019",endDt:"",officer:"ACO1,ACO2",selected:false},
  completeVisitsList: any[] = [];
  getVisitsfromDB(caseId: string) {

    this.visitservice.getVisits(caseId)
      .subscribe(data => {
        for (const d of (data as any)) {


          this.visitList.push({
            id: 1,
            visitId: d.visitId,
            visitstatus: d.visitStatus,
            visitNotDone: d.notReviewedFlag,
            startDt: this.datePipe.transform(d.visitActualStartDate, 'dd-MM-yyyy'),
            calstartDt: (d.visitActualStartDate !== null) ? this.datePipe.transform(d.visitActualStartDate, 'yyyy-MM-dd') : '',
            endDt: this.datePipe.transform(d.visitActualEndDate, 'dd-MM-yyyy'),
            calEndDate: this.datePipe.transform(d.visitActualEndDate, 'yyyy-MM-dd'),
            officer1: d.officer1,
            officer2: d.officer2,
            selected: false,
            location: d.summary,
            officerComments: d.officerComments,
            reviewerComments: d.reviewerComments,
            approverComments: d.approverComments
          }
          );

          this.updateCaseStatus();

        }

        this.completeVisitsList = [...this.visitList];
        console.log(this.visitList);
        //const loggedinUser = this.userId;

        if ((this.loginService.getData().roleId === 'ACO') && (this.dataservice.getRole() !== 'Reviewer') && (this.dataservice.getRole() !== 'LEADACO')) {

          this.visitList = this.visitList.filter(el => {

            if ((el.officer1 !== null && el.officer1.toLowerCase() === this.loggedinUser.toLowerCase()) || (el.officer2 !== null && el.officer2.toLowerCase() === this.loggedinUser.toLowerCase()))
              return true;
            else
              false;

          }



          );

        }



      });


  }


  getVisitsForId(visitId: string) {

    return this.visitList.find(el => el.visitId === visitId);


  }

  //  {inaid:'INA-00001926',id: 170, priority: 'high',lawid: "Law 170(2)", description:"INA-00001934",complianceStatus:'none',evidenceFlag:true,selectedflag:false,showEvidenceFlag:true,evidences:this.EvidenceList1},
  // {evidence:'Emergency and evacuation procedures breached, no EXIT signs and assembly points available',attachment:'image1.gif,image2.png',locationName:'chatswood',officer:'ACO1',inaid:'INA-00001926',id: 167, priority: 'high',lawid: "Law 167(1)", description:"Offence relating to protection of children from harm and hazards The approed provider of the education and care service failed to ensure that every reasonable precaution is taken to protect children being educated and cared for as part of the family day care service from harm and from any hazard likely to cause injury. Authorised officers attended the premises of the following educators and identified potential poison, electrocution, strangulation, crushing, cutting, entrapment harms and hazards.",complianceStatus:'Pending',evidenceFlag:true,showEvidenceFlag:true,evidences:this.EvidenceList1},
  //selectedValue = 'Confirmed breach';
  //when the compliance status is changed
  statusUpdateFlag: boolean = false;
  tempList: any[] = [];
  onChange(newValue: string, item: any, visit: any) {
    this.tempList = [...this.lawRegList];

    this.tempList.map(el => {
      if (el == item) {

        el.complianceStatus = newValue;
     
      //  if (item.myeditor !== null && item.myeditor !== undefined && item.myeditor.plugins.wordcount.body.getWordCount() === 0 && newValue === 'Confirmed Breach' && this.passeddata.ServiceType === 'Family Day Care') {
        if (this.findLength(item.evidence)==0 && newValue === 'Confirmed Breach' && this.passeddata.ServiceType === 'Family Day Care') {

          var primaryContact = (this.dataservice && this.dataservice.getOption() && this.dataservice.getOption() !== null) ? this.dataservice.getOption().PrimaryContact : '';
          this.evidenceTemplate = "At the residence of educator " + primaryContact + ", located at " + visit.location + ", there were (insert number) children in attendance at the time of the visit.";
          el.evidence = this.evidenceTemplate;
       
          this.ref.detectChanges;
        }

      }

    });

    this.lawRegList = this.tempList;
    item.complianceStatus = newValue;
    this.statusUpdateFlag = !this.statusUpdateFlag;
    //this.ref.markForCheck();

    this.inaService.updateINAs(item.inaid, item)
      .subscribe(resp => {
        this.showSuccess('Area of Breach Outcome Updated ' + newValue);
        return resp;
      }, err => {
        console.log(err);
        this.showError('Area of Breach Outcome update failed' + item.inaid);
      }

      );



  }

  onChangevisitStartDate(datestring: any, visit: any) {
    if (datestring !== undefined && datestring !== null) {

      let newDate = new Date(datestring);
      newDate.setHours(0, 0, 0, 0);

      var now = new Date();
      now.setHours(0, 0, 0, 0);


      if (newDate > now) {
        visit.calstartDt = "";
        this.showError('date cannot be more than today');
        return "";
      }


      visit.calstartDt = datestring;
      visit.startDt = this.datePipe.transform(visit.calstartDt, 'dd-MM-yyyy');


      this.visitservice.updateVisits(visit)
        .subscribe(resp => {
          console.log(resp);
          this.showSuccess('Visit Start Date Updated for' + visit.visitId + ' to ' + visit.calstartDt);

        }, err => {
          console.log("err Start Date failed to update" + err);
          this.showError("Start Date failed to update");
        }

        );

      return;

    }

  }

  onChangeDateofOffense(datestring: any, item: any, visit: any) {

    console.log(visit.visitId);
    if (datestring !== undefined && datestring !== null) {

      let newDate = new Date(datestring);
      newDate.setHours(0, 0, 0, 0);

      var now = new Date();
      now.setHours(0, 0, 0, 0);


   /*   if (visit.startDt !== null) {
        var visitstartDate = new Date(visit.startDt.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        visitstartDate.setHours(0, 0, 0, 0);


        if (newDate < visitstartDate) {
          item.caldateOfAllegedOffence = "";
          this.showError('date cannot be less than visit start date');
          return "";
        }
      }*/


      if (newDate > now) {
        item.caldateOfAllegedOffence = "";
        this.showError('date cannot be more than today');
        return "";
      }




      item.caldateOfAllegedOffence = datestring;
      item.dateOfAllegedOffence = this.datePipe.transform(item.caldateOfAllegedOffence, 'dd-MM-yyyy');
      this.inaService.updateINAs(item.inaid, item)
        .subscribe(resp => {
          console.log(resp);
          this.showSuccess('Date of alleged offense Updated for' + item.inaid + ' to ' + item.caldateOfAllegedOffence);

        }

        );

      return;

    }


  }

  onCaseStatusChange(newValue: string) {

    this.passeddata.CaseStatus = newValue;


  }

  finalizeCase() {


    this.caseService.updateCase(this.passeddata)
      .subscribe(resp => {
        return resp;
      }
      );
  }

  updateVisitNotDone(visit: any) {

    // visit.visitNotDone=!visit.visitNotDone;
    if (visit.visitNotDone === 'true') {
      visit.visitNotDone = 'false';
    }
    else {
      visit.visitNotDone = 'true';
    }
    if (visit.visitNotDone === "true") {


      this.lawRegList.map(el => {
        if (el.visitId == visit.visitId) {

          this.onChange("Not Reviewed", el, visit);
        }

      });


      visit.visitstatus = "Cancelled";
      var myDate = new Date();
      visit.endDt = this.datePipe.transform(myDate, 'dd-MM-yyyy');
      visit.calEndDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
      this.visitList.map(v => {
        if (v.visitId === visit.visitId) {
          v.visitstatus = "Cancelled";
          //  v.endDt =this.datePipe.transform(myDate, 'dd-MM-yyyy');
        }
      });

      this.visitservice.updateVisits(visit)
        .subscribe(res => {
          this.showSuccess('Cancelled ' + visit.visitId);
          return res;
        });

    }
    else {

      visit.visitstatus = "Scheduled";
      visit.endDt = "";
      visit.calEndDate = "";
      this.lawRegList.map(el => {
        if (el.visitId === visit.visitId) {

          this.onChange("choose one", el, visit);
        }

      });

      this.visitservice.updateVisits(visit)
        .subscribe(res => {
          this.showSuccess('Re-Scheduled ' + visit.visitId);
          return res;
        });
    }




    this.updateCaseStatus();

  }

  setGraph: boolean = false;

  getINAforVisitsfromDB(caseId: string) {

    var temp: any;
    this.lawRegService.getAllRegs()
      .subscribe(data => {

        for (const d of (data as any)) {


          this.additionalRegs.push({
            lawregIndicator: d.lawregIndicator,
            regId: d.reg_num,
            description: d.reg_description,
            priority: d.flag,
            questionlist: d.questionlist

          }
          );

        }

        this.inaService.getINAs(caseId)
          .subscribe(data => {
            for (const d of (data as any)) {

              var temparray: any[] = [];
              if (d.linkedRegs !== null) {
                var regList = d.linkedRegs.split(',');

                this.additionalRegs.forEach(el => {

                  if (regList.indexOf(el.regId) !== -1) {
                    var jsonstr = { 'lawregIndicator': el.lawregIndicator, 'regId': el.regId, 'description': el.description };
                    temparray.push(jsonstr);
                  }
                });
              }
              var match = this.additionalRegs.find(a => (a.regId === d.regNumber));
              var desc = "";
              var indicator = "";
              var questionlist = "";
              var priority = (match !== null && match !== undefined && match.priority !== null && match.priority !== '') ? match.priority.toLowerCase() : 'low';
              if (match !== null && match !== undefined) {
                desc = match.description;
                indicator = match.lawregIndicator;
                questionlist = match.questionlist;
              }
              var issubreg = d.regNumber.indexOf("(");
              //set the priority to base regulation  
              if (issubreg !== -1) {
                var subregtemp = d.regNumber.substring(0, issubreg);

                match = this.additionalRegs.find(a => (a.regId === subregtemp));

                priority = (match !== null && match !== undefined && match.priority !== null && match.priority !== '') ? match.priority.toLowerCase() : priority;

              }

              this.lawRegList.push({
                inaid: d.breachId,
                id: 1,
                priority: priority,
                questionlist: questionlist,
                priorityCode: (priority !== null && priority !== undefined && priority.toLowerCase() == 'high') ? 1 : (priority !== null && priority !== undefined && priority.toLowerCase() == 'medium') ? 2 : 3,
                lawid: d.regNumber,
                description: desc,
                lawregIndicator: indicator,
                complianceStatus: d.breachConfim,
                dateOfAllegedOffence: (d.dateOfAllegedOffence !== null) ? this.datePipe.transform(d.dateOfAllegedOffence, 'dd-MM-yyyy') : '',
                caldateOfAllegedOffence: (d.dateOfAllegedOffence !== null) ? this.datePipe.transform(d.dateOfAllegedOffence, 'yyyy-MM-dd') : '',
                loadReg: false,
                evidenceFlag: true,
                showEvidenceFlag: false,
                visitId: d.visitId,
                visitNotDone: d.notReviewedFlag,
                serviceId: this.passeddata.SENumber,
                evidence: '',
                discard: d.discard,
                questionairre: questionlist,
                reviewComments: '',
                officerComments: '',
                selectedFile: temp,
                additionalRegsList: temparray,
                imgURL: [],
                receivedImageData: [],
                convertedImage: [],
                filename: "choose one",
                updatedBy: d.updatedBy


              }


              );



            }

            console.log(this.lawRegList);
            this.getEvidenceforVisitfromDB();
            this.getEvidenceDocsforVisitfromDB();
            this.setGraph = true;

            //this.sortedLawRegList = [...this.moreLawRegList];
          });



      });


      

  }

  


  getEvidenceforVisitfromDB() {


    this.lawRegList.forEach(el => {
    
      this.evidenceService.getEvidence(el.inaid)
        .subscribe(data => {
          for (const d of (data as any)) {
            if (d !== null) {
              if(d.questionairre!=null && this.findLength(d.questionairre)>0)
              {
                el.questionairre =d.questionairre;
              }
            
        
              el.reviewComments = d.reviewComments;
              el.complianceDueDate = d.complianceDueDate;
              el.correctionSteps = d.correctionSteps;
              el.updatedBy = d.updatedBy;
            }
            if (d !== null && d.evidenceDetails !== null && d.evidenceDetails != '') {

              el.evidence = d.evidenceDetails;

              el.remaininglength = (d.evidenceDetails !== null) ? (2500 - d.evidenceDetails.length) : 2500;
              el.showEvidenceFlag = true;
              el.evidenceFlag = false;

            }

          }
        });


    });



  }


  attachRegulations(reg: any, event: boolean, item: any) {

    if (event == true) {

      if (item.additionalRegsList.find((r: { regId: any; }) => r.regId === reg.regId) != null || item.lawid === reg.regId) {

        this.showError('duplicate already exists!');
      }
      else {
        item.additionalRegsList.push(reg);
        this.inaService.updateINAs(item.inaid, item)
          .subscribe(res => {
            console.log(res);
            this.showSuccess('attached successfully');
            this.getEvidenceDocsforVisitfromDB();


          },
            err => {
              this.showError('attach Failed !');
              console.log('Error Occured duringng saving: ' + err);
            }


          );

        this.ref.detectChanges();
      }

    }







  }
  selectedaddreg: any;
  deleteFunction: any;
  removeLink(addreg: any, item: any) {
    // if(confirm("are you sure you want to delete?"))
    //  {
    var i = item.additionalRegsList.indexOf(addreg);
    item.additionalRegsList.splice(i, 1);
    this.inaService.updateINAs(item.inaid, item)
      .subscribe(resp => {
        this.showSuccess("deleted " + addreg.regId);
        return resp;
      }
      );


    this.ref.detectChanges();
    //}
  }

  deleteFunctionality() {

    if (this.deleteFunction !== null && this.deleteFunction !== undefined && this.deleteFunction === "removeLink" && this.selectedaddreg !== null && this.selectedItem !== null && this.selectedaddreg !== undefined && this.selectedItem !== undefined) {
      this.removeLink(this.selectedaddreg, this.selectedItem);
    }
    if (this.deleteFunction !== null && this.deleteFunction !== undefined && this.deleteFunction === "deletePdf" && this.deleteimagedata !== null && this.deleteimagedata !== undefined) {
      this.deletePdf(this.deleteimagedata);
    }
    if (this.deleteFunction !== null && this.deleteFunction !== undefined && this.deleteFunction === "removeINA" && this.selectedItem !== null && this.selectedItem !== undefined && this.selectedVisit !== null && this.selectedVisit !== undefined) {
      this.removeINA(this.selectedItem, this.selectedVisit);
    }

  }
  getAdditionalRegsfromDB() {


    this.lawRegService.getAllRegs()
      .subscribe(data => {

        for (const d of (data as any)) {


          this.additionalRegs.push({
            lawregIndicator: d.lawregIndicator,
            regId: d.reg_num,
            description: d.reg_description,
            priority: d.flag,
            questionlist: d.questionlist

          }
          );

        }


      });


  }


  getEvidenceDocsforVisitfromDB() {


    this.lawRegList.forEach(el => {

      //this.evidenceDocService.getAllDocs(el.inaid)
      this.documentService.getAllDocs(el.inaid)
        .subscribe(data => {

          el.receivedImageData = data;

        });

    }


    );





  }

  deleteimagedata: any;
  deletePdf(imagedata: any) {

    this.documentService.deleteDoc(imagedata)
      .subscribe(data => {
        console.log(data);
        imagedata.docName = "";
        imagedata.docType = "";

        this.showSuccess('deleted doc' + imagedata.docId);

      });


  }

  validateDate(visit: any) {
    let newDate = new Date(visit.calEndDate);
    newDate.setHours(0, 0, 0, 0);

    var now = new Date();
    now.setHours(0, 0, 0, 0);

    if (visit.startDt !== null) {
      var visitstartDate = new Date(visit.startDt.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      visitstartDate.setHours(0, 0, 0, 0);
      if (newDate < visitstartDate) {

        this.showError('date cannot be less than visit start date');
        visit.calEndDate = "";
        return;
      }
    }




    if (newDate > now) {

      this.showError('date cannot be more than today');
      visit.calEndDate = "";
      return;
    }
    var inaList = this.getINAsforVisit(visit.visitId);
    var inatemplist = inaList.filter(ina => (ina.complianceStatus === 'choose one' || ina.complianceStatus === 'N/A'));
    if (inatemplist && inatemplist != null && inatemplist.length > 0) {
      this.showError('update all INA status before marking the end date');
      visit.calEndDate = "";
      return;
    }
    /* inaList.forEach(ina =>{
    
        if(ina.complianceStatus === 'choose one' || ina.complianceStatus === 'N/A')
        {
        
          this.showError('update all INA status before marking the end date');
          visit.calEndDate="";
          return; 
        }
      });*/
  }
  getNonDiscardedList() {

    return this.lawRegList.filter(el => (el.discard == null || el.discard == '' || el.discard === 'null'));
  }

  getCaseStatus() {
    if (this.passeddata !== null && this.passeddata !== undefined && this.passeddata.CaseStatus === 'Immediate Risk to Children') {
      return true;
    }
    var tempvisitlist = this.completeVisitsList.filter(el => (el.visitstatus === 'Scheduled' || el.visitstatus === 'scheduled'));

    if (tempvisitlist && tempvisitlist !== null && tempvisitlist.length > 0) {

      return false;
    }
    else {
      var tempvisitlist2 = this.completeVisitsList.filter(el => (el.visitstatus === 'Cancelled' || el.visitstatus === 'cancelled'));
      if (tempvisitlist2 && tempvisitlist2 !== null && tempvisitlist2.length === this.completeVisitsList.length) {

        return false;
      }
      else {
        return true;
      }
    }
    /*this.visitList.forEach(el => {
     
      if(el.visitstatus.trim()==='scheduled' || el.visitstatus.trim()==='Scheduled')
      {
        flag=!flag;
        return flag;
      }
    });*/



  }
  addINA: boolean = false;
  getAdditionalRegs() {
    if (this.addINA) {
      return this.additionalRegs;
    }
    else {
      return [];
    }

  }
  setAddINA() {
    this.addINA = true;

  }
  validateAllVisitsCompleted(visit: any) {

    var inaList = this.getINAsforVisit(visit.visitId);

    var inatemplist = inaList.filter(ina => (ina.complianceStatus === 'choose one' || ina.complianceStatus === 'N/A'));

    if (inatemplist && inatemplist != null && inatemplist.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  saveVisit(visit: any) {

    if (visit.calEndDate == null || visit.calEndDate == undefined || visit.calEndDate === "") {
      visit.endDt = "";
      visit.visitNotDone = false;
      visit.visitstatus = "Scheduled";

    }
    else {
      visit.endDt = this.datePipe.transform(visit.calEndDate, 'dd-MM-yyyy');
      if (visit.visitstatus === 'Scheduled') {
        visit.visitstatus = "Completed";
      }

    }

    this.visitservice.updateVisits(visit)
      .subscribe(res => {

        return res;
      });

    this.showSuccess('saved visit' + visit.visitId);
  }

  saveEvidences(item: any) {

    /*   if(item.myeditor!==null && item.myeditor.plugins.wordcount.body.getWordCount()===0)
       {
         this.showError("Evidences/Comments cannot be blank");
          
           this.evidenceService.getEvidence(item.inaid)
           .subscribe(data => {
             for (const d of (data as any)) {
           
               if (d!==null && d.evidenceDetails != '') {
                 item.evidence = d.evidenceDetails;
               
              
               }
   
             }
           });
                  
          
       }*/


    this.lawRegList.map(el => {
      if (el == item) {

        el.showEvidenceFlag = true;


      }

    });
    item.updatedBy = this.userId;
    this.evidenceService.addEvidence(item).subscribe(resp => {
      this.showSuccess('evidence saved successfully');
      return resp;
    });



  }


  autoSave(item: any) {


    this.lawRegList.map(el => {
      if (el == item) {

        el.showEvidenceFlag = true;


      }

    });
    item.updatedBy = this.userId;
    if(item.questionairre==null)
    {
      item.questionairre='';
    }
    this.evidenceService.addEvidence(item).subscribe(resp => {
      this.showSuccess('evidence section saved successfully '+item.inaid);
      return resp;
    });

  }

  getINAsforVisit(visitId: string) {



    return this.lawRegList.filter(el => (el.visitId === visitId))


  }

  getOutstandingINAs(visitId: string) {

    var templist = this.lawRegList.filter(el => (el.visitId === visitId && (el.complianceStatus === 'choose one' || el.complianceStatus == 'N/A')));
    if (templist !== null) {
      return templist.length;
    }
    else {
      return 0;
    }

  }
  getINAsforVisitNotDiscarded(visitId: string) {

    this.lawRegList = this.lawRegList.sort((a, b) => (a.priorityCode > b.priorityCode) ? 1 : ((b.priorityCode > a.priorityCode) ? -1 : 0));

    return this.lawRegList.filter(el => (el.visitId === visitId && (el.discard == null || el.discard == '' || el.discard === 'null')));


  }

  enablevisit(visit: any) {
    this.visitselected = true;
    this.visitList.map(el => {
      if (el == visit)
        el.selected = true;
      else
        el.selected = false;
    });
    //this.showInfo("Selected "+visit.visitId);


  }
  public scroll(element: any) {
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, document.body.scrollHeight);
  }
  showsummary() {
    this.summaryreport = true;


  }

  /* addACO(aconame: string, loc: any) {
 
     const index: number = this.locationList.indexOf(loc);
     if (index !== -1) {
       const index2 = this.locationList[index].officer.indexOf(aconame);
       {
         if (index2 !== -1) {
           this.locationList[index].officer.push(aconame);
         }
       }
 
     }
 
   }
 
   deleteACO(location: any, aconame: string) {
     const index: number = this.locationList.indexOf(location);
     if (index !== -1) {
       const index2 = this.locationList[index].officer.indexOf(aconame);
       {
         if (index2 !== -1) {
           this.locationList[index].officer.splice(index2, 1);
         }
       }
 
     }
 
   }*/

  askEvidence(obj: any) {

    obj.evidenceFlag = !obj.evidenceFlag;


  }

  /*opentab(loc: any) {
    this.locationList.map(el => {
      if (el == loc)
        el.showtab = true;
      else
        el.showtab = false;
    });


  }*/

  /* showEvidence(obj:any) {

     this.lawRegList.map(el=> {
       if(el==obj)
         el.showEvidenceFlag = !el.showEvidenceFlag;

     });



   }*/

  setremainingLength(text: string, item: any) {

    item.remaininglength = 2500 - text.length;
  }
  updateItemEvidence(evidenceText: string, item: any) {


    this.lawRegList.map(el => {
      if (el == item) {

        el.evidence = evidenceText;

      }

    });
    item.evidence = evidenceText;

  }



  addMoreLawsandRegs(item: any) {

    item.selectedflag = !item.selectedflag;

    if (item.selectedflag) {
      this.lawRegList.push(item);
    }
    else {
      const index: number = this.lawRegList.indexOf(item);
      if (index !== -1) {
        this.lawRegList.splice(index, 1);
      }

    }


  }

  getselectedvisit() {
    return this.visitList.filter(el => (el.selected == true));

  }
  /* getsublaws(lawid: number) {
 
 
     return this.subLawRegList.filter(el => (el.id == lawid));
 
 
   }*/

  addLocations() {
    this.showLocation = !this.showLocation;
  }

  /* saveLocation(name: string, address: string, officer: string) {
 
 
     let temploc = new Location();
     temploc.id = this.locCounter++;
     temploc.name = name;
     temploc.address = address;
     temploc.officer.push(officer);
 
 
     this.locationList.push(temploc);
     if (this.locationList!=null  && this.locationList.length > 0) {
       this.showlocTable = false;
     }
 
 
 
 
   }*/

  ngAfterViewInit() {


  }

  onseraching(searchValue: string) {

    this.sortedLawRegList = this.moreLawRegList.filter(el => (el.lawid.toLowerCase().includes(searchValue.toLowerCase()) == true || el.description.toLowerCase().includes(searchValue.toLowerCase()) == true));


  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  refreshlist() {
    this.sortedLawRegList = [...this.moreLawRegList];
  }

  updateSatus(enddate: string, visit: any) {

    if (enddate.length != 0) {

      this.visitList.map(el => {
        if (el == visit) {
          el.visitstatus = "Completed";
          el.endDt = this.datePipe.transform(enddate, 'dd-MM-yyyy');

        }
      });

    } else {
      this.visitList.map(el => {
        if (el == visit) {
          el.visitstatus = "Scheduled";
          el.endDt = "";
        }
      });

    }

    this.updateCaseStatus();

  }


  updateCaseStatus() {
    var templist = this.visitList.filter(el =>
      (el.visitstatus.toUpperCase('scheduled'.toUpperCase))
    );
    /*var templist2 =  this.visitList.filter(el =>
       (el.visitstatus === 'Completed' || el.visitstatus === 'Cancelled')
      )*/

    if (templist != null && templist.length > 0 && this.passeddata.CaseStatus == 'Case Assessment')   // if all visits are scheduled
    {
      this.passeddata.CaseStatus = "Evidence Collection and Analysis";
    }
    /* else if(templist2!=null && templist2.length === this.visitList.length)   // if all visits are scheduled
      {
          this.passeddata.CaseStatus = "Evidence Collection and Analysis";
      }*/
    this.dataservice.setOption(this.passeddata);
    this.caseService.updateCase(this.passeddata)
      .subscribe(resp => {
        return resp;
      }
      );

  }

  getFiles(filename: string, item: any) {

    this.filenames.push(filename);


    this.lawRegList.map(el => {
      if (el == item) {
        el.attachment = el.attachment + "," + filename;

      }

    });


  }
  bntStyle: string;

  /* tabclick(loc: any) {
 
     this.selectedtab = loc.name;
 
     this.locationList.map(el => {
       if (el == loc)
         el.selected = true;
       else
         el.selected = false;
     });
 
   }*/


  public onFileChanged(event: any, item: any) {
    console.log(event);
    this.lawRegList.map(el => {
      if (el === item) {
        el.selectedFile = event.target.files[0];
        el.filename = el.selectedFile.name;

      }

    });



    //Below part is used to display the selected image
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.lawRegList.map(el => {
        if (el === item) {
          el.imgURL = reader.result;

        }

      });

    };

  }

  // This part is for uploading
  fileURL: any;
  uploading: boolean = false;
  onUpload(item: any) {

    if (item.selectedFile) {
      item.filename = item.selectedFile.name;
      if (item.selectedFile.type !== 'application/pdf' && item.selectedFile.type !== 'image/jpeg') {
        this.showError('file type not valid!');
        return;
      }
      if ((item.selectedFile.size / 1000) > 20000) {
        this.showError('file size too big');
        return;
      }
      this.uploading = true;
      const uploadData = new FormData();
      uploadData.append('myFile', item.selectedFile, item.selectedFile.name);

      this.documentService.uploadEvidencetoTrim(uploadData, item, this.passeddata, this.recordNumber)
        .subscribe(
          res => {

            console.log(res);
            this.uploading = false;
            this.showSuccess('upload successful');
            this.getEvidenceDocsforVisitfromDB();
            item.filename = "choose one"

          },
          err => {

            this.uploading = false;
            item.filename = "choose one"
            this.showError('upload Failed !');
            console.log('Error Occured duringng saving: ' + err);
          });
    }
    else {
      this.showError('choose file to upload !');
    }



    /* this.evidenceDocService.uploadEvidenceDoc(uploadData, item)
       .subscribe(
         res => {
           console.log(res);
           this.showSuccess('upload successful');
           this.getEvidenceDocsforVisitfromDB();
 
         },
         err => {
           this.showError('upload Failed !');
           console.log('Error Occured duringng saving: ' + err);}
 
 
       );*/

  }

  onEditAddress(event: any, visit: any) {


    visit.location = event.educatorName + "," + event.inputAddress1 + "," + event.inputAddress2 + "," + event.inputSuburb + "," + event.inputPostCode;

    this.visitservice.updateVisits(visit)
      .subscribe(res => {
        this.showSuccess('saved visit' + visit.visitId);
        return res;
      });



  }

  updateQuestion(event: any, ina: any) {

    ina.questionairre = event;

    ina.updatedBy = this.userId;
    this.evidenceService.addEvidence(ina)
      .subscribe(resp => {
        return resp;
      });

    this.showSuccess('Questionnaire saved successful');
  }



  loadDoc(docId: any, docName: any) {


    // this.evidenceDocService.getDoc(docId).subscribe((response) => {
    this.documentService.getTrimDoc(docId).subscribe((response) => {
      var file: any;

      /* file = new Blob([response], { type: 'application/pdf' });
  
       var fileURL = URL.createObjectURL(file);
          window.open(fileURL); */

      if (docName.indexOf(".jpg") !== -1) {


        file = new Blob([response], { type: 'data:image/png;base64,' });

        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);


      } else if (docName.indexOf(".pdf") !== -1) {

        file = new Blob([response], { type: 'application/pdf' });

        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }


    },
      err => {
        this.showError('download failed !');
        console.log('Error Occured during dowload document' + err);
      });
  }


  onSummaryReport(summary: boolean) {
    if (summary) {
      this.summaryreport = true;
    }
    else {
      this.summaryreport = false;
    }
  }
  helpText(message: any) {
    if (message.status === 'success')
      this.showSuccess(message.messageTxt);
    else if (message.status === 'error')
      this.showError(message.messageTxt);
    else if (message.status === 'info')
      this.showInfo(message.messageTxt);
  }
  //myeditor:any;
  handleEditorInit(item: any, event: any) {
    item.myeditor = event.editor;



  }
  maxlength = 2500;
  tempval: any;

  checkSizeLimit(event: any, item: any) {
    this.selectedItem = item;
    if (item.myeditor !== undefined) {

      if (item.myeditor.plugins.wordcount.body.getWordCount() <= this.maxlength) {

        item.evidence = event;


      }
      else {
        //  item.evidence=item.evidence;

        alert('max length reached  2500 words');
      }





    }



  }



  checkSizeLimitQuestion(event:any,item:any)
  {
    
    if(item.myeditorquestion!==undefined)
    {
      if(item.myeditorquestion.plugins.wordcount.body.getWordCount()<=this.maxlengthquestion)
      {
        item.questionairre = event;
              
      }
      else{
           
         alert('max length reached  600 words');
      }
    }
   
    
  }
 
 maxlengthquestion:number=600
 handleEditorInitQuestion(item: any, event: any) {
  item.myeditorquestion = event.editor;


}

  removetimer(item: any) {
    if (item.subscribtion !== null && item.subscribtion !== undefined) {

      item.subscribtion.unsubscribe();
    }
  }
  settimer(item: any) {

    item.subscribtion = timer(this.autosavetimer, this.autosavetimer).subscribe(x => {
      console.log(x);
      this.autoSave(item);

    });
  }

  settimernew(item: any) {

    if (item.editflag != undefined) {
    
      item.editflag = !item.editflag;

    }
    else {
      item.editflag = true;

    }

    if (item.editflag == true) {

     

      item.subscribtion = timer(this.autosavetimer, this.autosavetimer).subscribe(x => {

        console.log(x);

        this.autoSave(item);

      });
    }
    else {

    
      item.subscribtion.unsubscribe();


    }


  }

  validateLengthEvidence(strval: string) {
    if (strval && strval != null) {
      var cont = strval.replace(/<[^>]*>/g, " ");
      cont = cont.replace(/\s+/g, ' ');
      cont = cont.trim();
      var n = cont.split(" ").length;
      if (n > 2500) {
        alert("maximum length reached - 2500 words");
      }
    }

  }
  validateLengthQuestion(strval: string) {
    if (strval && strval != null) {
      var cont = strval.replace(/<[^>]*>/g, " ");
      cont = cont.replace(/\s+/g, ' ');
      cont = cont.trim();
      var n = cont.split(" ").length;
      if (n > 600) {
        alert("maximum length reached - 600 words");
      }
    }

  }
  findLength(strval: string) {
    if (strval && strval != null) {
      var cont = strval.replace(/<[^>]*>/g, " ");
      cont = cont.replace(/\s+/g, ' ');
      cont = cont.trim();
      var n = cont.split(" ").length;
      return n;
   
    }else{
      return 0;
    }

  }



}

export class Location {
  id: number;
  name: string;
  address: string;
  officer: string[] = [];
  selected: boolean;
}
