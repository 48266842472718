import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from 'src/app/service/AppConfigService';
import { LoginDataService } from 'src/app/service/loginData.service';
import { UserDetailsService } from 'src/app/service/userDetails.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) sidenav: ElementRef;

  clicked: boolean;
  show:boolean = false;
  role:string;
  userId:string;
  loggedinUser:string;
  logoutURL:string;

  accessList:string[]=[];
  constructor(private userDetailsService:UserDetailsService,private config: AppConfigService , private cookieService: CookieService, private loginDataService:LoginDataService,private r: Router,private activatedRoute: ActivatedRoute,private loginService:LoginDataService) {


    this.clicked = this.clicked === undefined ? false : true;
    this.logoutURL = this.config.getConfig().logoutURL;

  }
  isExpired:any="false";
  async ngOnInit() {

    this.isExpired=localStorage.getItem('isExpired');

    if(this.loginService.getData() == null || this.loginService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
      this.loginService.setAccessList([]);
     this.loginService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId'),
       uid:localStorage.getItem('uid')
      
       //roleId: localStorage.getItem('roleId')
  
     });
  
     this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
     this.loggedinUser=this.loginService.getData().uid;
     this.role = this.loginService.getData().roleId;

         
    }else{
  
     this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
     this.loggedinUser=this.loginService.getData().uid;
     this.role = this.loginService.getData().roleId;


    }   
  
   //await this.setAccess();
  }

  async setAccess()
    {
     
      //  var i =   this.accessList.indexOf('editVisitOutcome');
      //  this.accessList.splice(i,1);
      //  this.accessList.push('reviewer');
      this.loginService.setAccessList([]);  //empty the list as it is a reviewer role
        await this.userDetailsService.getAccessDetails("ECOMP_"+this.role.toUpperCase()).toPromise().then( (data) => {
       
          for (const d of (data as any)) {
  
            
            this.loginService.getAccessList().push(d.pageName);
  
  
          }

         
          
        });
       
       
     
      
    }


  firstName:string;
  ngDoCheck()
  {
    //this.firstName = this.loginService.getData().firstName;
    if(this.loginService.getData()!=null)
    {
      this.firstName=this.loginService.getData().firstName;
      this.role=this.loginService.getData().roleId;
    }
    if(this.loginService.getAccessList()!=null)
    {
      this.accessList = [...this.loginDataService.getAccessList()];
    }
      
  }

  setClicked(val: boolean): void {
    this.clicked = val;
  }
  toggleCollapse() {
    this.show = !this.show
  }
  getlink()
  {
      if(this.r.url.includes('landingpage') || this.r.url.includes('errorpage'))
        return false;
      else
      return true;
  }
  getLogout() {
   localStorage.clear();
   this.cookieService.deleteAll();
 window.location.href=this.logoutURL+"&client_id=2f1f980b-fa57-426d-a3e8-d15477c5416f"

   /* let logouthint = localStorage.getItem("login_hint");
    this.userDetailsService.doLogout(logouthint).subscribe(res=>{
      if(res!=null)
      {
        console.log("res"+res)
        localStorage.clear();
        window.location.href= "https://ssoaddonsext.test.det.nsw.edu.au/ssoaddon/logout"
      }
    },err=>{
      console.log(err)
    })*/
   // this.ssologonservice.getLogout();
    // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    // console.log('COOKIE' + this.cookieValue);
  }

 
  ngAfterContentInit()
  {
   // this.role=this.dataservice.getRole();
  }

  getRole()
  {

    this.activatedRoute.queryParams.subscribe(params => {
           this.role = params['role'];

    });

    return this.role ;
  }

}
