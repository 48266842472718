import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from './AppConfigService';
//import { DatePipe } from '@angular/common';

//import { environment } from '../../environments/environment';
const getUrl = '/ina/all';
const addUrl='/ina/add';
const deleteUrl='/ina/discard';
const updateUrl = '/ina/update';
const updateINAIdUrl = '/ina/updateINAId';
@Injectable({
  providedIn: 'root'
})
export class INAService {
  baseUrl :any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }
  updateINAforTempID(oldbreachId:string,newbreachId:string)
  {
   
    return this.http.put(this.baseUrl+updateINAIdUrl+"?oldbreachId="+oldbreachId+"&newbreachId="+newbreachId,null);
  }
  
  getINAs(caseId:string) {
    return this.http.get(this.baseUrl+getUrl+"?caseId="+caseId);
  }

  updateINAs(breachId:string,item:any) {
   
    var myjson = {status: item.complianceStatus, linkedRegs: item.additionalRegsList,allegedOffenseDate:item.dateOfAllegedOffence};
  
    return this.http.put(this.baseUrl+updateUrl+"/"+breachId,myjson);
  }
  addINAs(item:any,caseId:string) {
   
    var myjson = {breachId: item.inaid, visitId:item.visitId,caseId:caseId,lawregIndicator:item.lawregIndicator,regId:item.lawid,regDescription:item.description,createdBy:item.createdBy};
  
    return this.http.put(this.baseUrl+addUrl,myjson);
  }
  deleteINA(inaid:any) {
      
    return this.http.put(this.baseUrl+deleteUrl+'/'+inaid,null);
  }
}
