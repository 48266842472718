import { Component, OnInit} from '@angular/core';

export class User {
  public name: string;
  public address: string;
  public count: any;
 
}

@Component({
  selector: 'app-serviceDetailsInput',
  templateUrl: './serviceDetailsInput.component.html',
  styleUrls: ['./serviceDetailsInput.component.scss']

})
export class ServiceInputDetailsComponent implements OnInit {
  
  
  model = new User();

  ngOnInit() {

   
  }

  constructor() {

    

  }
  //get input() { return this.validatingForm.get('required'); }

  //get childCountSize() { return this.validationForm.get('childCountSize'); }
 // get serviceNameLength() { return this.validationForm.get('serviceNameLength'); }
 // get serviceAddressLength() { return this.validationForm.get('serviceAddressLength'); }


 onSubmit(form:any) {
  console.log(form.value)
}


}
