
<mdb-card  class="col-md-12 mb-3 p-0" >
    <mdb-card-header class="p-0">
       
    </mdb-card-header>
    <mdb-card-body class="p-0">
      
        <table mdbTable class="p-0" >
            <tbody>
                <tr>
                    <td class="mb-3"> 
                        <br/>
                        <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><img src="../../../../assets/animat-rocket-color.gif" style="width:60px;"class="img-fluid" alt="">  Welcome to eCompliance</button>
                   
                     <!--   <h4><span class="font-weight-bold ">Welcome to eCompliance </span></h4>-->
                     </td>
                  </tr>
                   
               
                <tr>
                   <td class="mb-3"> 
                    <a  class="nav-link font-weight-bold text-black waves-light white-hover lighten-2-hover " mdbWavesEffect  (click)="getLogin()">
                    <img src="../../../../assets/ecompliance-landing.jpg" class="img-fluid" alt="">  </a>
                    </td>
                 </tr>
               <tr>
                <td class="mb-3"> 
                <!--  <a routerLink="../../visits/pages/visit1" routerLinkActive="active"   class="nav-link font-weight-bold text-black waves-light white-hover lighten-2-hover " mdbWavesEffect  >
                        E-Compliance</a>  -->


                        
                <a  class="nav-link font-weight-bold text-black waves-light white-hover lighten-2-hover " mdbWavesEffect  (click)="getLogin()">
                    <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><img src="../../../../assets/animat-lightbulb-color.gif" style="width:60px;"class="img-fluid" alt=""> Sign in to eCompliance</button>
                        
                        </a>

                           
                </td>
               
               </tr>
            </tbody>
        </table>
            
        
    </mdb-card-body>
</mdb-card>

<app-footer></app-footer>

                    



