import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfigService } from './AppConfigService';
const getUrl = '/emailTemplates/get';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  baseUrl:any;

  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  


  getEmailTemplate(outcome: any) {
    
   
    return this.http.get<any>(this.baseUrl+getUrl+"/"+outcome) .pipe(
      catchError(this.handleError('getTemplate', outcome))
    );

  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
     // alert("error message"+error.message);
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
