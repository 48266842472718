import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { AppConfigService } from './AppConfigService';
const getUrl = '/lawreg/all';
const updateUrl = '/lawreg/update';
const deleteUrl = '/lawreg/delete';
const addUrl = '/lawreg/add';
@Injectable({
  providedIn: 'root'
})
export class LawRegService {
  baseUrl :any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  
  getAllRegs(): Observable<any> {
    return this.http.get(this.baseUrl+getUrl);
     
  }

  addLawReg(inputrequestobj:any):Observable<any> {
   
   // var inputrequest=JSON.stringify(inputrequestobj);
      return this.http.post(this.baseUrl+addUrl,inputrequestobj);
      
  }

  updateLawReg(inputrequestobj:any):Observable<any> {
   
      return this.http.post(this.baseUrl+updateUrl,inputrequestobj);
      
  }

  deleteLawReg(lawregId:any):Observable<any> {
   
    return this.http.post(this.baseUrl+deleteUrl+'/'+lawregId,null);
    
}

 
}
