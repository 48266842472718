import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { CaseService } from 'src/app/service/case.service';





@Component({
  selector: 'app-chooseRecievedResponse',
  templateUrl: './chooseRecievedResponse.component.html',
  styleUrls: ['./chooseRecievedResponse.component.scss']

})
export class ChooseRecievedResponseComponent implements OnInit {
 

  passeddata:any;
  @Output() 
  message = new EventEmitter<any>();

  ngOnInit() {
 


      if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
      
        var temp =localStorage.getItem("selectedCase");
        (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');



      }

      this.passeddata = this.dataservice.getOption(); 
  }

  constructor(private dataservice: DataService, private caseService: CaseService) {
   
  }

  saveResponseRecievedData()
  {
    
    this.caseService.updateCase(this.passeddata)
    .subscribe(resp => {
      this.message.emit({'status':'success','messageTxt':'save successful'});
      return resp;
        },  err => {
     
          console.log('Error Occured duringng save successful' + err);
          this.message.emit({'status':'error','messageTxt':'correction steps save failed'});
        }
        );
  }


}
