import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';

const localUrl = '/evidence/save';
const deleteUrl = '/evidence/delete';
const getUrl = '/evidence/all';
@Injectable({
  providedIn: 'root'
})
export class EvidenceService {
  baseUrl:any;
  constructor( private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  addEvidence(eve: any): Observable<any> {
    var myjson = {inaid: eve.inaid, visitId: eve.visitId,evidence:eve.evidence,complianceDueDate:eve.complianceDueDate,correctionSteps:eve.correctionSteps,complianceStatus:eve.complianceStatus,serviceId:eve.serviceId,reviewComments:eve.reviewComments,questionairre:eve.questionairre,updatedBy:eve.updatedBy};
    return this.http.post<any>(this.baseUrl+localUrl, myjson)
      .pipe(
        catchError(this.handleError('addEvidence', myjson))
      );
  }


 deleteEvidence(evidenceId: any): Observable<any> {
   
    return this.http.post<any>(this.baseUrl+deleteUrl+'/'+evidenceId,null)
      .pipe(
        catchError(this.handleError('deleteEvidence', evidenceId))
      );
  }

  getEvidence(inaId:string): Observable<any> {
    return this.http.get<any>(this.baseUrl+getUrl+"?breachId="+inaId)
      .pipe(
        catchError(this.handleError('getEvidence', inaId))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}




