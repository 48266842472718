import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EmailAdminService } from 'src/app/service/emailAdmin.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/service/loginData.service';

@Component({
  selector: 'app-manageEmail',
  templateUrl: './manageEmail.component.html',
  styleUrls: ['./manageEmail.component.scss']

})
export class ManageEmailComponent implements OnInit {
  //outcomeStatus = ["Choose Final Outcome","Admin Letter", "Compliance Notice", "Compliance Direction", "Suspension of Provider Approval","Show Cause Notice: Cancellation of Provider Approval","Notice of Decision to Cancel Provider Approval"];
  headElementsEmailTemplate = ['Template Name', 'From', 'To', 'CC', 'Subject', 'Email Body'];
  headElementsEmailTemplateSort = ['Template Name', 'From', 'To', 'Cc', 'Subject', 'Email Body'];
  tempEmailTemplate = "Dear <username>,<br/><br/>The approved provider has now submitted their Service Context Details and Self Assessment form for <span style='font-weight: bold;'> <ServiceName> this.dataservice.getOption().SENumber </span> <br><br>Please login to abd site provide explanation <span style='font-weight: bold;'>$ASR_NO.</span><br><br>Yours sincerely, <br><br><br>Network Coordination Unit<br>Statewide Operations<br>Early Childhood Education Directorate<br>Department of Education<br>T: 1800 619 113<br>E: ECnetworkcoordination@det.nsw.edu.au<br>";

  user: any;

  emailFunctId: any;
  emailNotificationFunction: string;

  emailFrom: string;
  emailTo: string;
  emailCc: string;
  emailSubject: string;
  emailContent: string;
  selectedEditObj: any;
  editEmailFunctId: any;
  editEmailNotificationFunction: string;
  editDescription: string;
  editEmailFrom: string;
  editEmailTo: string;
  editEmailCc: string;
  editEmailSubject: string;
  editEmailContent: string;


  ngOnInit() {
    if (this.loginDataService.getData() == null || this.loginDataService.getData() == undefined) {
      console.log(localStorage.getItem('displayLoginName'));

      this.loginDataService.setData({
        firstName: localStorage.getItem('firstName'),
        surname: localStorage.getItem('surname'),
        displayLoginName: localStorage.getItem('displayLoginName'),
        email: localStorage.getItem('email'),
        status: localStorage.getItem('status'),
        roleId: localStorage.getItem('roleId'),
        uid: localStorage.getItem('uid'),
        //roleId: localStorage.getItem('roleId')

      });



    }

    this.user = localStorage.getItem('firstName') + ' ' + localStorage.getItem('surname');
    this.getListOfEmailAdminfromDB();

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: (this.listOfEmailAdmin == undefined || this.listOfEmailAdmin == null) ? 0 : this.listOfEmailAdmin.length
    };


  }
  changeCaseStatus(event: any) {
    event.stopPropagation();
  }
  clickcreate: boolean = false;
  clickedit: boolean = false;
  onAddEmailAdmin() {


      if(this.emailNotificationFunction==undefined || 
        this.emailNotificationFunction==null || this.emailNotificationFunction.trim() === ""){
          this.showError("all fields are required");
         
       return;
      }
      var obj = this.listOfEmailAdmin.find(el => (el.emailNotificationFunction===this.emailNotificationFunction));
    
      if(obj!=undefined && obj !==null){
  
 
      this.showError("duplicate function");
  
      return;
     }
   
    var tempObj = {
      emailFunctId: this.emailFunctId, emailNotificationFunction: this.emailNotificationFunction,
      emailFrom: this.emailFrom,
      emailTo: this.emailTo, emailCc: this.emailCc,
      emailSubject: this.emailSubject, emailContent: this.emailContent
    }
   
    this.emailAdminService.addEmailAdmin(tempObj).subscribe(resp => {
      //this.showSuccess("deleted "+addreg.regId);
      console.log(resp);  
  // alert(tempObj)
      // this.listOfEmailAdmin.push({
      //   emailFunctId: tempObj.emailFunctId,
      //   emailNotificationFunction: tempObj.emailNotificationFunction,
      //   emailFrom: tempObj.emailFrom,
      //   emailTo: tempObj.emailTo,
      //   emailCc: tempObj.emailCc,
      //   emailSubject: tempObj.emailSubject,
      //   emailContent: tempObj.emailContent
      // }
      // );
      this.getListOfEmailAdminfromDB();
   //     this.listOfEmailAdmin = this.listOfEmailAdmin.sort((a,b) => (a.emailFunctId < b.emailFunctId) ? 1 : ((b.emailFunctId < a.emailFunctId) ? -1 : 0)); 
      this.ref.detectChanges();
     
    //  this.backupList = [...this.listOfEmailAdmin];
      this.showSuccess("saved successfully Email Template " + this.emailNotificationFunction);

      return resp;
    }, err => {
      this.showError("save failed" + err.error.message);
    }
    );
    this.clickcreate=false;
  }

  listOfEmailAdmin: any[] = [];
  config: any;
  getListOfEmailAdminfromDB() {

    this.listOfEmailAdmin = [];
    this.emailAdminService.getAllAdminEmail()
      .subscribe(data => {

        for (const d of (data as any)) {


          this.listOfEmailAdmin.push({
            emailFunctId: d.emailFunctId,
            emailNotificationFunction: d.emailNotificationFunction,
            emailFrom: d.emailFrom,
            emailTo: d.emailTo,
            emailCc: d.emailCc,
            emailSubject: d.emailSubject,
            emailContent: d.emailContent
          }

          );//push

        }//for
        this.backupList = [...this.listOfEmailAdmin];
        this.listOfEmailAdmin = this.listOfEmailAdmin.sort((a,b) => (a.emailFunctId < b.emailFunctId) ? 1 : ((b.emailFunctId < a.emailFunctId) ? -1 : 0)); 
        },
        err => {
          console.log('Error Occured duringng getting admin list ' + err);
      
        }
      );


  }

  setselected(selectedEmailAdmin: any) {
   // alert("coming here" + selectedEmailAdmin.emailFunctId);
    this.editEmailFunctId = selectedEmailAdmin.emailFunctId;
    this.editEmailNotificationFunction = selectedEmailAdmin.emailNotificationFunction;
    this.editEmailFrom = selectedEmailAdmin.emailFrom;
    this.editEmailTo = selectedEmailAdmin.emailTo;
    this.editEmailCc = selectedEmailAdmin.emailCc;
    this.editEmailSubject = selectedEmailAdmin.emailSubject;
    this.editEmailContent = selectedEmailAdmin.emailContent;
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  onSelectEmailAdmin(emailFunctId: any) {
  //  alert("on selected");
    var selectedEmailAdmin = this.listOfEmailAdmin.find(el => (el.emailFunctId === emailFunctId));

    if (selectedEmailAdmin !== null) {
      this.editEmailFunctId = selectedEmailAdmin.emailFunctId;
      this.editEmailNotificationFunction = selectedEmailAdmin.emailNotificationFunction;

      this.editEmailFrom = selectedEmailAdmin.emailFrom;
      this.editEmailTo = selectedEmailAdmin.emailTo;
      this.editEmailCc = selectedEmailAdmin.emailCc;
      this.editEmailSubject = selectedEmailAdmin.emailSubject;
      this.editEmailContent = selectedEmailAdmin.emailContent;
    }

  }
  onEditEmailAdmin() {

    var tempObj = {
      emailFunctId: this.editEmailFunctId, emailNotificationFunction: this.editEmailNotificationFunction,
      emailFrom: this.editEmailFrom,
      emailTo: this.editEmailTo, emailCc: this.editEmailCc,
      emailSubject: this.editEmailSubject, emailContent: this.editEmailContent
    }
    this.emailAdminService.updateEmailAdmin(tempObj).subscribe(resp => {
      //this.showSuccess("deleted "+addreg.regId);
      console.log(resp);

      // this.listOfEmailAdmin.push({
      //   emailFunctId: tempObj.emailFunctId,
      //   emailNotificationFunction: tempObj.emailNotificationFunction,

      //   emailFrom: tempObj.emailFrom,
      //   emailTo: tempObj.emailTo,
      //   emailCc: tempObj.emailCc,
      //   emailSubject: tempObj.emailSubject,
      //   emailContent: tempObj.emailContent
      // }
      // );

      //   this.listOfEmailAdmin = this.listOfEmailAdmin.sort((a,b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0)); 
      this.getListOfEmailAdminfromDB();
      this.ref.detectChanges();

      this.backupList = [...this.listOfEmailAdmin];
      this.showSuccess(" Successfully Updated Email Template " + this.editEmailNotificationFunction);

      return resp;
    }, err => {
      this.showError("save failed" + err.error.message);
    }
    );
  }

  validateEdit() {
    if (this.editEmailFunctId !== null && this.editEmailFunctId !== undefined) {
      this.clickcreate = false;
      this.clickedit = true;
    }
    else {
      this.showError("select a email function to continue");
    }
  }

  backupList: any[] = [];
  onFilter(searchValue: string, fieldName: string) {

    if (searchValue === '') {
      this.listOfEmailAdmin = [...this.backupList];
    }
    else {
   
      if (fieldName !== null && fieldName === 'Template Name') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailNotificationFunction !== null && el.emailNotificationFunction !== undefined && el.emailNotificationFunction.toLowerCase().includes(searchValue.toLowerCase()) == true));
      }
      
      
      if (fieldName !== null && fieldName === 'From') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailFrom !== null && el.emailFrom !== undefined && el.emailFrom.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if (fieldName !== null && fieldName === 'To') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailTo !== null && el.emailTo !== undefined && el.emailTo.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if (fieldName !== null && fieldName === 'CC') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailCc !== null && el.emailCc !== undefined && el.emailCc.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if (fieldName !== null && fieldName === 'Subject') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailSubject !== null && el.emailSubject !== undefined && el.emailSubject.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }
      if (fieldName !== null && fieldName === 'Email Body') {
        this.listOfEmailAdmin = this.backupList.filter(el => (el.emailContent !== null && el.emailContent !== undefined && el.emailContent.toLowerCase().includes(searchValue.toLowerCase()) == true));

      }

    }

  }
  //  onDeleteLawReg()
  //  {

  //    if(this.editLawId!==null && this.editLawId!==undefined)
  //  {
  //  this.emailAdminService.deleteLawReg(this.editLawId).subscribe(resp => {

  //    //this.showSuccess("deleted "+addreg.regId);
  //    console.log(resp);
  //    var obj = this.listOfLawRegs.find(el => (el.regId===this.editLawId));

  //    var index = this.listOfLawRegs.indexOf(obj);

  //    this.listOfEmailAdmin.splice(index,1);
  //    this.editLawRegIndicator="Choose Law/Reg";
  //    this.editPriority="Choose Priority";
  //    this.editlawregDesc="";
  //    this.editLawId=null;
  //    this.showSuccess("deleted successfully Law/Reg"+this.editLawId);

  //    return resp;
  //      },err=>{
  //        this.showError("delete failed"+err);
  //      }
  //    );
  //    }
  //    else{
  //      this.showError("select a law reg to continue");
  //    }

  //  }

  showSuccess(message: string) {

    this.toastr.success(message, 'Success!',
      { timeOut: 2000 });;
  }
  showError(message: string) {

    this.toastr.error(message, 'Error!',
      { timeOut: 2000 });;
  }



  showInfo(message: string) {

    this.toastr.info(message, 'Info!',
      { timeOut: 2000 });;
  }

  constructor(private ref: ChangeDetectorRef, private emailAdminService: EmailAdminService, private toastr: ToastrService, private loginDataService: LoginDataService) { }





}
