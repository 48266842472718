import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { EmailService } from 'src/app/service/email.service';





@Component({
  selector: 'app-chooseOfficer',
  templateUrl: './chooseOfficer.component.html',
  styleUrls: ['./chooseOfficer.component.scss']

})
export class ChooseOfficerComponent implements OnInit {
 @Input()
  usersList:any[]=[];
  @Input()
  caseId:string='';
  @Input()
  userval:string;
  @Output()
  modaluser = new EventEmitter<String>();
  @Output() 
  message = new EventEmitter<any>();
 
  ngOnInit() {
 // alert("in init.. content"+ this.modalRef.content);
   
  }

  constructor(private emailService:EmailService) {
   
  }

updateUser()
{
  if(this.userval!==null && this.userval!==undefined && this.userval!=='')
  {
 
    var user = this.usersList.find(el => (el.userId===this.userval))
    if(user!==null && user!==undefined && user.email!==null && user.email!=='')
    {
         this.sendEmailtoUser("you are assigned a new case",user.email);
    }
    this.modaluser.emit(this.userval);
  }

}


sendEmailtoUser(message:string,to:string)
  {
   
 
   if(this.caseId!=null && this.caseId!==undefined && this.caseId!=='')
   {

   
    this.emailService.sendEmailtoUser(message +" for caseId "+this.caseId,to).subscribe((response) => {
    
      console.log(response);   
           
      this.message.emit({'status':'success','messageTxt':'Mail sent to User '+to});

    },
    err => {
     
      console.log('Error Occured duringng send email ' + err);
      this.message.emit({'status':'error','messageTxt':'Email Sent to User failed'});
    });
   // this.showSuccess('Email Sent');

   
  } 
}

}
