<!--<mdb-card class="mb-3 mt-3" >
  <mdb-card-header class="p-0 subcardheadercolor">
      <table mdbTable class="table-hover table-condensed mb-0">
          <tbody class="p-0">
              <tr class="p-0">
                  <td class="subcardheader py-1 font-weight-bold ">Case Status</td>
                 
              </tr>
          </tbody>
      </table>

  </mdb-card-header>
  <mdb-card-body class="p-4" id="casestatus"> -->

<div class="modal-dialog" role="document">
  <div class="modal-content" style= "height: 200px;width:370px;">
  <!--    <div class="modal-header">-->
         <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="showSummarygraph.hide()">
              <span aria-hidden="true" class="text-black">×</span>
          </button>  -->
       
      <!--</div>-->
      <div class="modal-body">
        <div style="display: block; height:max-content">
          <canvas  style= "height: 100px;" mdbChart
              [chartType]="chartType"
              [datasets]="chartDatasets"
              [labels]="chartLabels"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
          </canvas>
        </div>    
         
         
      
      
      </div>

  </div>
</div>
<!--<mdb-card-body> </mdb-card>-->