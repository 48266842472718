import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { timer } from 'rxjs';
import { AppConfigService } from 'src/app/service/AppConfigService';

@Component({
  selector: 'app-evidenceChecklist',
  templateUrl: './evidenceChecklist.component.html',   
  styleUrls: ['./evidenceChecklist.component.scss']

})
export class EvidenceChecklistComponent implements OnInit {

  question:string="";
  @Input()
  answer:string='';
  answer2:string='';
 @Input()
 CaseStatus:string='';
  @Output()
  questionairre = new EventEmitter<string>();
  maxlength = 600;
  @Output() 
  message = new EventEmitter<any>();
  totallenth=0;
  subscribtion:any;
  autosavetimer:number;
  ngOnInit() {

   
  }

  checkSizeLimit(event:any)
  {
    
    if(this.myeditor!==undefined)
    {
      if(this.myeditor.plugins.wordcount.body.getWordCount()<=this.maxlength)
      {
        this.answer = event;
        this.totallenth=this.answer.length;
        
      }
      else{
        this.answer=this.answer;
      
         alert('max length reached  600 words');
      }
    }
   
    
  }
 myeditor:any;
  handleEditorInit(event : any)
  {
    this.myeditor=event.editor;
    
  }

  constructor(private appconfig: AppConfigService) {
    this.autosavetimer = +this.appconfig.getConfig().autosavetimer;
       
  }
  
  addQuestion()
  {
    if(this.question!=="")
    {
    this.answer=this.answer+ "<br>" + "<li><span class='username'>" +this.question + "</span></li><div> <br> </div>" ;
    this.question='';
    }
  }
  updateAnswer(value:any)
  {
    
    console.log(value);
    //this.answer=value;
  }
  saveQuestionaire()
  {
   
    this.questionairre.emit(this.answer);
  }

  removetimer()
 {

  if(this.subscribtion!==null && this.subscribtion!==undefined)
  {
 
    this.subscribtion.unsubscribe();
  }
 }
 settimer()
 {
 
  this.subscribtion = timer( this.autosavetimer, this.autosavetimer).subscribe(x => {
    console.log(x);
    this.saveQuestionaire();

  });
 }

  
}
