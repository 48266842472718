


<div class="dark-font bc-icons-2" mt-2 >
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" mdbWavesEffect>
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> <span class="headertexts">Home</span>
            </a>

        </mdb-breadcrumb-item>
     
        <mdb-breadcrumb-item>
            <a routerLink="/details/pages/detail1/case" routerLinkActive="active" 
                class="headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Details</span>
            </a>

        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item>
            <a class="headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> <span class="headertexts">Table of Breaches</span>
            </a>

        </mdb-breadcrumb-item>

    </mdb-breadcrumb>
</div> 


<fieldset class="scheduler-border">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-briefcase"
            style="color:#041e42;padding-right: 6px;"></i>Breaches List  ({{passeddata.CaseId}})</legend>



        <div mdbCollapse [isCollapsed]="false" #datatable="bs-collapse" class="" >
            <div id="print-section">
            <table mdbTable id="complianceTable" 
            class=".table-sm .table-fixed .table-hover doe-table-body tabborder" bordered="true">

            <!--Table head-->
            <thead class="lawReg-table-header border tabborder tableheadercolor">
                <tr >
                   <!-- <th *ngFor="let head of headElements; let i = index"
                        aria-controls="lawregTable1" 
                        scope="col" class="border tabborder" >
                        {{head }} 
                       
                    </th>-->
                    <th *ngFor="let head of headElements; let i = index"
                    aria-controls="lawregTable1" [mdbTableSort]="lawRegList"
                    [sortBy]="headElementsLawRegSort[i]" scope="col" style="white-space: nowrap;">
                    {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                
                <div (click)="changeCaseStatus($event)">
                    <input style="font-size:12px;" class="form-control form-control-sm text-black p-0 border border-dark" type="search"
                    placeholder="" mdbInput id="input1" aria-label="Search"
                    (search)="onFilter($event.target.value,head)" (keyup)="onFilter($event.target.value,head)">
                  <!--  <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i> -->
                </div>
                </th>
                </tr>
             
            </thead>
            <!--Table body-->
            <tbody *ngFor="let item of lawRegList" class="border tabbodyborder">
                <tr>
              
                        
                    <td>
                      {{item.lawregIndicator}} {{item.lawid}}:{{item.description}}
                    </td>
                    <td >
                        <div [innerHTML]="item.evidence">
                        </div>
                    </td>
                    <td class="w-25">
                                                  
                        <textarea class="form-control evidenceText border border-info" id="stepstoTake" rows="5" maxlength="4000"   [(ngModel)]="item.correctionSteps" [value]="item.correctionSteps">{{item.correctionSteps}}</textarea>
                       <div class="text-right">
                        <button type="button" mdbBtn size="sm" 
                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="saveCorrectionSteps(item);">Save</button>
                    </div>
                    </td>
                    <td >
                  
                      <input mdbInput
                      type="date" id="responseDt" class="form-control"  name="responseDt" [(ngModel)]="item.complianceDueDate" [value]="item.complianceDueDate | date:'yyyy-MM-dd'">
                      <div class="text-right">
                        <button type="button" mdbBtn size="sm" 
                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0" mdbWavesEffect (click)="saveComplianceDueDate(item);">Save</button>
                     </div>
                    </td>
                 
                </tr>
            </tbody>
</table>
                
        </div>
      </div>              

</fieldset>

<div class="text-right">

    <button type="button" mdbBtn size="sm" 
    class="relative waves-light buttoncolor rounded text-white font-weight-bold  trigger" mdbWavesEffect [routerLink]="['/details/pages/detail1/case']" 
    routerLinkActive="active">Go Back</button>
 
</div>