
<!-- <button printSectionId="print-section" [useExistingCss]="true" (click)="onPrint();">Print me</button> -->

<mdb-card class="mb-3 mt-3" >
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Questionnaire</td>
                   
                </tr>
            </tbody>
        </table>
  
    </mdb-card-header>
    <mdb-card-body class="p-4" id="print-section">
      <div class="headertexts">
            <mdb-icon fas  icon="question-circle" size="2x" class="text-danger"></mdb-icon><span class="ml-2">{{currentquestion.question}} </span>
            

        </div>
        <hr>
        <div class="evidenceText">
           
            <label class="headertexts">Your Comments :</label>
            <textarea class="form-control p-2"  rows="3" maxlength="100"  name="QuestionComments" [(ngModel)]="currentquestion.comment">{{currentquestion.comment}}</textarea>
  

        </div>
        <div>
                    
        <table mdbTable class="p-0 table-fixed" > 
            <tbody class="p-0">
            <tr class="p-0">
            <td class="mt-0 reminingText"><button type="button" mdbBtn size="sm"
                class="relative waves-light buttoncolor rounded text-white font-weight-bold trigger text-left"
                mdbWavesEffect (click)="prevQuestion()"> <mdb-icon fas  icon="arrow-left" size="2x" class="text-white"></mdb-icon></button>
               
            </td>
            <td  class="mt-0 reminingText text-right"><button type="button" mdbBtn size="sm"
                class="relative waves-light buttoncolor rounded text-white font-weight-bold trigger text-right"
                mdbWavesEffect (click)="nextQuestion()"> <mdb-icon fas  icon="arrow-right" size="2x" class="text-white"></mdb-icon></button>
     

            </td>
            </tr>
            </tbody>
        </table>
    </div> 

    </mdb-card-body>
</mdb-card>

