import { Injectable } from '@angular/core';  

  
@Injectable()  
export class LoginDataService {  
  
  private data : any;
  //private role :string;
  private acessList:string[]=[];
  private token:any;
  setToken(token:any)
  {
    this.token = token;
  }
  getToken() {  
    return this.token;  
  }  
 setData(input : any) {      
    this.data = input; 
  }  
  
  getData() {  
    return this.data;  
  }  

  setAccessList(input : any)
  {
    this.acessList = input; 
  }

  getAccessList()
  {
    return this.acessList; 
  }
  
  
}