import { Component, OnInit} from '@angular/core';






@Component({
  selector: 'app-evidenceQuestions',
  templateUrl: './evidenceQuestions.component.html',
  styleUrls: ['./evidenceQuestions.component.scss']

})
export class EvidenceQuestionsComponent implements OnInit {
 
  //questionsList:any[]=[];
  counter:number;
  currentquestion:any;
  
  answer:any;
  questionsList = [
    { id:1, question: 'here is Question 1 ?', comment: "" },
    { id:2, question: 'here is Question 2 ?', comment: "" },
    { id:3, question: 'here is Question 3 ?', comment: "" },
    { id:4, question: 'here is Question 4 ?', comment: "" }
  ];
  ngOnInit() {
   this.counter=0;
   this.currentquestion=this.questionsList[this.counter];
   
  }

  nextQuestion()
  {
   // this.questionsList[this.counter].comment = this.answer;
    //this.answer='';
    this.counter = this.counter+1;
    if(this.questionsList.length <= this.counter)
    {
      this.counter = 0;
     
    }
    this.currentquestion = this.questionsList[this.counter];
  }
  prevQuestion()
  {

    this.counter = this.counter-1;
    if( this.counter < 0 )
    {
      this.counter = this.questionsList.length  ;
     
    }
    this.currentquestion = this.questionsList[this.counter];

  }
  constructor() {}




}
