import { Component, OnInit, Input} from '@angular/core';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { DocumentService } from 'src/app/service/document.service';





@Component({
  selector: 'app-casesummary',
  templateUrl: './caseSummary.component.html',
  styleUrls: ['./caseSummary.component.scss']

})
export class CaseSummaryComponent implements OnInit  {
  passeddata:any;
  headSummaryList = ['Law/Reg', 'Priority', 'Area of Breach'];
  unqiueLaws: any[] = [];
  onlyBreachConfirmed:boolean=true;
  selectedVisits:string[]=[];
  headEvidenceListSort=['Visit ID','INA','Location Name', 'Officer', 'Evidence Description', "Attachments",'Area of Breach Outcome'];
  headEvidenceList = ['Visit ID','INA','Location Name', 'Officer', 'Evidence Description', "Attachments",'Area of Breach Outcome'];
  @Input()
  lawRegList: any[] = [];

  @Input()
  visitList: any[] = [];
  @Input()
 accessList: string[] = [];

 summaryList:any[]=[];
 
  ngOnInit() {

    
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }

    this.passeddata = this.dataservice.getOption();
    

  }

  isEmpty(item:any)
  {
      if(item && item!=null)
      {
        var templst = this.summaryList.filter(el => el.lawid===item.lawid);
        if(templst && templst!=null && templst.length>0)
        {
          return false;
        }
         else{
           return true;
         }
      }
      else{
        true;
      }
  }

  constructor(private dataservice: DataService , private documentServce: DocumentService) {
 
  }



  loadDoc(docId: any,docName: any) {

    
     // this.evidenceDocService.getDoc(docId).subscribe((response) => {
      this.documentServce.getTrimDoc(docId).subscribe((response) => {
        var file:any;
  
      /* file = new Blob([response], { type: 'application/pdf' });
  
       var fileURL = URL.createObjectURL(file);
          window.open(fileURL); */
      if(docName.indexOf(".jpg")!== -1)
        {
  
  
          file = new Blob([response], { type: 'data:image/png;base64,' });
  
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
  
  
        }else if(docName.indexOf(".pdf")!== -1)
        {
  
          file = new Blob([response], { type: 'application/pdf' });
  
         var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
  
  
      });
    }
    getAdditionalRegs(lawId:string)
    {
      return this.summaryList.filter(el=> (el.lawid===lawId));
    }
 
  getuniqueLaws() {

      
    this.summaryList = [...this.lawRegList];
    this.lawRegList.forEach(el => {

      if (!this.unqiueLaws.find(param => param.lawid === el.lawid)) {
        
        this.unqiueLaws.push(el);
      }


    });

    if(this.onlyBreachConfirmed && this.selectedVisits.length===0)
    {
     
      this.summaryList = this.lawRegList.filter(param => param.complianceStatus !==null && param.complianceStatus.toLowerCase() === 'Confirmed breach'.toLowerCase());
    }
    else if(this.selectedVisits.length!==0 && !this.onlyBreachConfirmed )
    {
   
      this.summaryList = this.lawRegList.filter(param =>   
    
        this.selectedVisits.indexOf(param.visitId)!== -1
        );
       
    }
    else if(this.selectedVisits.length!==0 && this.onlyBreachConfirmed )
    {
    
      this.summaryList = this.lawRegList.filter(param => param.complianceStatus!==null && param.complianceStatus.toLowerCase() === 'Confirmed breach'.toLowerCase()).filter(param =>   
    
        this.selectedVisits.indexOf(param.visitId)!== -1
        );
    }

    return this.unqiueLaws;
    //return this.unqiueLaws;
  }

  getVisitsLocation(visitId: string) {

    var templist = this.visitList.find(el => el.visitId === visitId);
    if(templist)
    {
      return templist.location;
    }


  }


  addtoSelectedVisitList(visitId:string,event:boolean)
  {
   
    //var temp={visitId: visitId};
    if(event===true)
    {
      var i = this.selectedVisits.indexOf(visitId);
      if(i<0)
      {
        this.selectedVisits.push(visitId)
      }
      
  
     
    }
    else
    {
      var i = this.selectedVisits.indexOf(visitId);
      this.selectedVisits.splice(i,1);

    }

  
   
  }

  onPrint() {
    const printContent = document.getElementById("print-section");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');

    if(printContent!=null && WindowPrt!=null)
    {
    // WindowPrt.document.write('<link rel="stylesheet" type="text/css" href="./caseSummary.component.scss">');
    WindowPrt.document.write(printContent.innerHTML);

      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    }


  }


  generatePdf()
  {


    this.documentServce.getPdf(this.passeddata.CaseId)
    .subscribe(  res => {
      console.log(res);
      var file:any;
      file = new Blob([res], { type: 'application/pdf' });

      var fileURL = URL.createObjectURL(file);
       window.open(fileURL);

    },
    err => {

      console.log('Error Occured duringng generate Pdf ' + err);}


    );

  }


  /*private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string
{
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);
    for (let idx = 0; idx < elements.length; idx++)
    {
        htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
}*/


}
