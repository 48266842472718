import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { LawRegService } from 'src/app/service/lawReg.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/service/loginData.service';






@Component({
  selector: 'app-addRegulations',
  templateUrl: './addRegulations.component.html',
  styleUrls: ['./addRegulations.component.scss']

})
export class AddRegulationsComponent implements OnInit {
 
  lawregIndicator:any="Choose Law/Reg";
  lawregNumber:any;
  lawregDesc:any;
  priority:any="Choose Priority";
  questionlist:any="";
  headElementsLawRegSort = ['regId', 'description', 'priority', 'lawregIndicator','createdDate'];
  headElementsLawReg = ['Law/Reg Id', 'Description', 'Priority', 'Law/Reg','Created Date'];
  user:any;
  selectedEditObj:any;
  editLawId:any;
  editLawRegIndicator:any;
  editlawregDesc:any;
  editPriority:any;
editquestionlist:any;
  ngOnInit() {
    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId'),
       uid:localStorage.getItem('uid'),
       //roleId: localStorage.getItem('roleId')
 
     });
 
   

    }
    
    this.user=localStorage.getItem('firstName')+ ' '+localStorage.getItem('surname');
    this.getListOfLawRegsfromDB();

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: (this.listOfLawRegs==undefined || this.listOfLawRegs==null)?0:this.listOfLawRegs.length  }; 
 
   
  }
  changeCaseStatus(event:any)
  {
    event.stopPropagation();
  }
 clickcreate:boolean=false;
 clickedit:boolean=false;
  onAddLawReg()
  {

   
    if(this.lawregNumber==undefined || this.lawregNumber==null 
      || this.lawregDesc==undefined || this.lawregDesc==null 
      || this.priority==null || this.priority==='Choose Priority' 
    || this.lawregIndicator==null || this.lawregIndicator=='Choose Law/Reg')
    {
      this.showError("all fields are required");
      return;
    }
   
    var tempObj = {user:this.user,lawregIndicator:this.lawregIndicator,lawregNumber:this.lawregNumber,lawregDesc:this.lawregDesc,priority:this.priority,questionlist:this.questionlist}
    this.lawregService.addLawReg(tempObj).subscribe(resp => {
      //this.showSuccess("deleted "+addreg.regId);
      console.log(resp);
      this.listOfLawRegs.push({
        lawregIndicator: tempObj.lawregIndicator,
        regId: tempObj.lawregNumber,
        description: tempObj.lawregDesc,
        priority:tempObj.priority,
        createdDate:new Date(),
        questionlist:tempObj.questionlist

      }
      );
     
     this.listOfLawRegs = this.listOfLawRegs.sort((a,b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0)); 
     this.ref.detectChanges();
     this.backupList = [...this.listOfLawRegs];
     this.showSuccess("saved successfully Law/Reg"+this.editLawId);
     
      return resp;
        },err=>{
         this.showError("save failed"+err);
        }
   );
  }

  listOfLawRegs:any[]=[];
  config:any;
  getListOfLawRegsfromDB() {


    this.lawregService.getAllRegs()
      .subscribe(data => {

        for (const d of (data as any)) {


          this.listOfLawRegs.push({
            lawregIndicator: d.lawregIndicator,
            regId: d.reg_num,
            description: d.reg_description,
            priority:d.flag,
            createdDate:(d.createdDate==null || d.createdDate=='')?new Date():d.createdDate,
            questionlist:d.questionlist

          }
         
          );
         
      }
      this.backupList = [...this.listOfLawRegs];
    this.listOfLawRegs = this.listOfLawRegs.sort((a,b) => (a.createdDate > b.createdDate) ? 1 : ((b.createdDate > a.createdDate) ? -1 : 0)); 
   
    //  alert(this.listOfLawRegs.length);
    });

    
}

setselected(lawreg:any)
{
 
  this.editLawRegIndicator=lawreg.lawregIndicator;
  this.editlawregDesc=lawreg.description;
  this.editPriority=lawreg.priority;
  this.editLawId=lawreg.regId;
  this.editquestionlist=lawreg.questionlist;
  
}

pageChanged(event : any){
  this.config.currentPage = event;
}

onSelectRegs(lawId:any)
{
 
  var obj = this.listOfLawRegs.find(el => (el.regId===lawId));

  if(obj!==null )
  {
    this.editLawRegIndicator=obj.lawregIndicator;
    this.editlawregDesc=obj.description;
    this.editPriority=obj.priority;
   this.editLawId=obj.regId;
  this.editquestionlist=obj.questionlist;

  }
  
}
onEditLawReg()
{

  this.selectedEditObj = {user:this.user,lawregIndicator:this.editLawRegIndicator,lawregNumber:this.editLawId,lawregDesc:this.editlawregDesc,priority:this.editPriority,questionlist:this.editquestionlist}
  
  if(this.editLawId!==null && this.editLawId!==undefined)
  {
  this.lawregService.updateLawReg(this.selectedEditObj).subscribe(resp => {
    //this.showSuccess("deleted "+addreg.regId);
    console.log(resp);
    this.listOfLawRegs.forEach(el =>{
      if(el.regId===this.editLawId){
        el.lawregIndicator=this.editLawRegIndicator;
        el.priority=this.editPriority;
        el.description=this.editlawregDesc;
        el.questionlist=this.editquestionlist;

      }
    });

    this.showSuccess("edited successfully Law/Reg"+this.editLawId);
   
    return resp;
      },err =>{
        this.showError("edit failed"+err);
      }
    );
    }
    else{
      this.showError("select a law reg to continue");
    }
}

validateEdit()
{
  if(this.editLawId!==null && this.editLawId!==undefined)
  {
  this.clickcreate=false;
  this.clickedit=true;
  }
  else{
    this.showError("select a law reg to continue");
  }
}

backupList:any[]=[];
onFilter(searchValue:string,fieldName:string) {
    
  if(searchValue==='')
  {
    this.listOfLawRegs =[...this.backupList];
  }
  else
  {
    
    if(fieldName!==null && fieldName==='Law/Reg Id')
    {
      this.listOfLawRegs = this.backupList.filter(el => (el.regId!==null && el.regId!==undefined && el.regId.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }
    if(fieldName!==null && fieldName==='Description')
    {
      this.listOfLawRegs = this.backupList.filter(el => (el.description!==null && el.description!==undefined && el.description.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }
    if(fieldName!==null && fieldName==='Priority')
    {
      this.listOfLawRegs = this.backupList.filter(el => (el.priority!==null && el.priority!==undefined && el.priority.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }
    if(fieldName!==null && fieldName==='Law/Reg')
    {
      this.listOfLawRegs = this.backupList.filter(el => (el.lawregIndicator!==null && el.lawregIndicator!==undefined && el.lawregIndicator.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }
    if(fieldName!==null && fieldName==='Created Date')
    {
      this.listOfLawRegs = this.backupList.filter(el => (el.createdDate!==null && el.createdDate!==undefined && el.lawregIndicator.toLowerCase().includes(searchValue.toLowerCase()) == true));

    }
   
  }

}
  onDeleteLawReg()
  {
   
    if(this.editLawId!==null && this.editLawId!==undefined)
  {
  this.lawregService.deleteLawReg(this.editLawId).subscribe(resp => {
    
    //this.showSuccess("deleted "+addreg.regId);
    console.log(resp);
    var obj = this.listOfLawRegs.find(el => (el.regId===this.editLawId));
   
    var index = this.listOfLawRegs.indexOf(obj);
    
    this.listOfLawRegs.splice(index,1);
    this.editLawRegIndicator="Choose Law/Reg";
    this.editPriority="Choose Priority";
    this.editlawregDesc="";
    this.editLawId=null;
    this.editquestionlist="";
    this.showSuccess("deleted successfully Law/Reg"+this.editLawId);
   
    return resp;
      },err=>{
        this.showError("delete failed"+err);
      }
    );
    }
    else{
      this.showError("select a law reg to continue");
    }
   
  }

  showSuccess(message:string)
  {
   
    this.toastr.success(message, 'Success!',
    {timeOut: 2000});;
  }
  showError(message:string)
  {

    this.toastr.error(message, 'Error!',
    {timeOut: 2000});;
  }

  

  showInfo(message:string)
  {

    this.toastr.info(message, 'Info!',
    {timeOut: 2000});;
  }

  myeditor:any;
  handleEditorInit(event : any)
  {
    this.myeditor=event.editor;
    
    
  }
  maxlength=2500;
  tempval:any;
  
  checkSizeLimit(questions:any,event:any)
  {
   console.log(questions);
    if(this.myeditor!==undefined)
    {
     
      if(this.myeditor.plugins.wordcount.body.getWordCount()<=this.maxlength)
      {
        
        questions = event;
       
                    
      }
      else{
      //  item.evidence=item.evidence;
     
         alert('max length reached  2500 words');
      }

     

    }
   
    
  }

  constructor(private ref: ChangeDetectorRef,private lawregService:LawRegService,private toastr:ToastrService,private loginDataService: LoginDataService) {}


}
