
<a (click)="basicModal.show()"><img src="../../../assets/help_5.png" class="img-fluid" alt=""> </a>
<div mdbModal #basicModal="mdbModal" class="modal fade right doe-table-body " tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-full-height modal-right modal-lg" role="document">
    <div class="modal-content ">
      <div class="modal-header cardheadercolor">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 cardheader text-center" id="myModalLabel">Help Text</h4>
      </div>
      <div class="modal-body scrollbar helpdropdowns">

        <mdb-card class="mb-3 mt-2">
            <mdb-card-header class="p-0  #b2ebf2 cyan lighten-4">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="headertexts py-1 font-weight-bold ">Download a Case</td>
                            <td class="text-right p-0 text-danger">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="downloadhelp.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </mdb-card-header>
            <mdb-card-body class="p-0 helpdropdowns">
                <div mdbCollapse [isCollapsed]="true" #downloadhelp="bs-collapse">
                  
                       <ul>
                            <li>
                                Prepare a .xls file in the format as shown here:
                                
                            </li>
                            <img src="../../../assets/downloadxls.PNG" class="img-fluid" alt="">
                            <br>
                            <br>
                            <li>
                                Browse for the xls and click on download button and wait for the download to complete. 
                                
                            </li>
                            <img src="../../../assets/downloadCase.PNG" class="img-fluid" alt="">
                            <br>
                            <br>
                            <li>
                                downloaded case will be visible in the case list table
                            </li>     
                            
                  
                        </ul>
                  
                    
                </div>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-3 mt-2">
            <mdb-card-header class="p-0 #b2ebf2 cyan lighten-4">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="headertexts py-1 font-weight-bold ">Browse and select a Case</td>
                            <td class="text-right p-0 text-danger">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="browseCase.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </mdb-card-header>
            <mdb-card-body class="p-0 helpdropdowns">
                <div mdbCollapse [isCollapsed]="true" #browseCase="bs-collapse">
                    <ul>
                        <li>
                           Look for the case you need with Case ID in the case list table. 
                         
                        </li>
                        <li>
                            You can also search for a case ID in the search box. 
                        </li>
                        <br>
                        <img src="../../../assets/CaseListTableHelp.PNG" class="img-fluid" alt="">
                        <br>
                        <br>
                        <li>
                            To add or remove the columns use the add columns drop down and check/uncheck the column you need.
                        </li>     
                        <img src="../../../assets/AddColumnsHelp.PNG" class="img-fluid" alt="">
                        <br>
                        <br>
                        <li>
                            click the table row to to look at more details about the Case. 
                            You will be navigated to a new page with Case details 
                         </li>  
              
                    </ul>
        
                </div>
            </mdb-card-body>
        </mdb-card>

        <mdb-card class="mb-3 mt-2">
            <mdb-card-header class="p-0 #b2ebf2 cyan lighten-4">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="headertexts py-1 font-weight-bold ">Select a Visit</td>
                            <td class="text-right p-0 text-danger">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="selectVisithelp.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </mdb-card-header>
            <mdb-card-body class="p-0 helpdropdowns">
                <div mdbCollapse [isCollapsed]="true" #selectVisithelp="bs-collapse">
                    <ul>
                        <li>
                          On the Details Page, all the case details can be seen.
                         
                        </li>
                        <li>
                         You can go back to Home page by clicking on the Home Link 
                        </li>
                        <br>
                        <img src="../../../assets/visitDetailsPage2.PNG" class="img-fluid" alt="">
                        <br>
                        <br>
                        <li>
                            click to select the visit in the table to upload evidences.
                        </li>    
                        <br> 
                        <img src="../../../assets/VisitListHelp.PNG" class="img-fluid" alt="">
                        <br>
                        
              
                    </ul>
        
                </div>
            </mdb-card-body>
        </mdb-card>

        <mdb-card class="mb-3 mt-2">
            <mdb-card-header class="p-0 #b2ebf2 cyan lighten-4">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="headertexts py-1 font-weight-bold ">Upload Evidences</td>
                            <td class="text-right p-0 text-danger">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="uploadEvidenceHelp.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </mdb-card-header>
            <mdb-card-body class="p-0 helpdropdowns">
                <div mdbCollapse [isCollapsed]="true" #uploadEvidenceHelp="bs-collapse">
                    <ul>
                        <li>
                           Each visit has a set of INAs, evidence needs to be captured for each of this.
                           
                          </li>
                          <br>
                          <img src="../../../assets/INAList.PNG" class="img-fluid" alt="">
                          <br>
                          <br>
                        <li>
                          If selected visit is Completed, evidences captured and documents uploaded along with INA status s displayed in a small report 
                         
                        </li>
                        <li>
                            If selected visit is Scheduled, questionaire for each INA can be recorded. Click on more/add evidence button to proceed
                           
                          </li>
                          <br>
                          <img src="../../../assets/INAList.PNG" class="img-fluid" alt="">
                          <br>
                          <br>
                        <li>
                            If selected visit is Scheduled, you can upload evidences and add evidence details. <br>
                            Click on more/add evidence button to proceed.<br>
                            click on browse button to select the document to upload. This document is uploaded to Trim.<br>
                            Uploaded document can also be deleted by clicking on the "X" button next to teh uploaded document.
                            To add evidence text, enter details in evidence/comments textarea and save. <br>
                            Update the status of each INA by selecting "Area of Breach Outcome" drop down.<br>

                        </li>
                        <br>
                        <img src="../../../assets/uploadEvidence.PNG" class="img-fluid" alt="">
                       
                        <br>
                        <br>

                        <li>
                            To mark a Visit to completed, update the "Visit End Date" and Save <br>
                            Officer's comments can also be recorded by adding details in Officer's comments text area and save.<br>

                        </li>
                        <br>
                        <img src="../../../assets/saveVisitDateHelp.PNG" class="img-fluid" alt="">
                       
                        <br>

                  
              
                    </ul>
        
                </div>
            </mdb-card-body>
        </mdb-card>

        <mdb-card class="mb-3 mt-2">
            <mdb-card-header class="p-0 #b2ebf2 cyan lighten-4">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="headertexts py-1 font-weight-bold ">Review and Approval</td>
                            <td class="text-right p-0 text-danger">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="reviewHelp.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </mdb-card-header>
            <mdb-card-body class="p-0 helpdropdowns">
                <div mdbCollapse [isCollapsed]="true" #reviewHelp="bs-collapse">
                    <ul>
                        <li>
                           To send a Case for Review: Go to the Case outcoe Section and choose reviewer and click on "Send Draft for Review"<br>
                           email will be sent to the reviewer.
                           
                          </li>
                          <br>
                          <br>
                          <img src="../../../assets/ReviewHelp.PNG" class="img-fluid" alt="">
                          <li>
                            To send a Case for Approval: Go to the Case outcoe Section and choose and click on "Send Draft for Approval"<br>
                            email will be sent to the hub manager/approver. 
                           </li>
                           <br>

                           <img src="../../../assets/ApprovalHelp.PNG" class="img-fluid" alt="">
                           <br>
                           <br>
                         <li>
                            To send a Case for Manager Review: Login as Manager and choose the reviewer and click on "send Draft for Manager Review".
                           </li>
                           <br>
                           <img src="../../../assets/ManagerReview.PNG" class="img-fluid" alt="">
                           <br>
                           <br>
                           <li>
                            To send a Case for Manager Review: Login as Manager and choose the reviewer and click on "send Draft for Manager Review".
                            <br> email will be sent to the reviewer.
                        </li>
                           <br>
                           <img src="../../../assets/ServiceEmailHelp.PNG" class="img-fluid" alt="">
                      
                      

                  
              
                    </ul>
        
                </div>
            </mdb-card-body>
        </mdb-card>
        

        
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn class="waves-light backcolor text-white" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
       
      </div>
    </div>
  </div>
</div>