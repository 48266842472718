<mdb-card class="mb-4 mt-4" *ngIf="accessList.indexOf('editCaseOutcomeApprover')>-1">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Outcome {{passeddata.CaseId}}</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="finalize.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="doe-table-body">
        <div mdbCollapse [isCollapsed]="false" #finalize="bs-collapse">

                      <!-- Form -->
            <form class="">
               
            
                <div class="row d-flex">
                    <button type="button" mdbBtn size="sm"
                    class="relative waves-light btn-yellow lighten-2 rounded black-text font-weight-bold ml-3 mt-0"
                    mdbWavesEffect (click)="resetNotes();notesModal.show()">Add Notes</button>


                </div>

                <div>
                    <table mdbTable id="notesTable" 
                            class="z-depth-1 px-4 py-4 doe-table-body" hover="true">
                         
                            <!--Table head-->
                            <thead class="lawReg-table-header tableheadercolor">
                                <tr class="">
                                    <th *ngFor="let head of headElementsNotes; let i = index"
                                        aria-controls="notesTable" [mdbTableSort]="notesList"
                                        [sortBy]="headElementsNotesSort[i]" scope="col" style="white-space: nowrap;">
                                        {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                                    </th>
                                </tr>

                            </thead>
                            <!--Table body-->
                            <tbody>


                                <tr *ngFor="let note of notesList | paginate:config">
                                    <td class="doe-table-body">{{note.notes}}</td>
                                    <td class="doe-table-body">{{note.type}}</td>
                                    <td class="doe-table-body">{{note.createdDate | date: 'dd-MM-yyyy'}}</td>
                                    <td class="doe-table-body">{{note.user}}</td>
                                   
                                

                                </tr>
 

                            </tbody>
                         
                        </table>
                      
                            <div class="mt-2 text-center">
                                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
                            </div>

                       
                    </div>

                <div class="mt-4">
                                                                       
                    <label for="officersComments" class="headertexts"> 
                    Officer's Comments </label>
                    <textarea class="form-control evidenceText" id="officersComments" rows="3" disabled  >{{passeddata.OfficerComments}}</textarea>
            

                </div>


              <!-- <div class="row d-flex mt-2">
                   
                   
                    <div class="col-md-5 py-4 ml-1 doe-table-body">
                      
                          <label class="headertexts">Documents Pending Approval:</label>  
                          <div *ngFor="let outcome  of pendingOutcomesList">
                                  <a (click)="generateAttachmentPdf(outcome.outcomeType);" class="text-primary ml-4 headertexts"  >view PDF for {{outcome.outcomeType}}</a>
                         
                                <a [routerLink]="['/emailTemplates/emergencyAction/showtemplate']"  
                        routerLinkActive="active"> <mdb-icon fas icon="pencil-alt" class="prefix text-danger"  (click)="setValues(outcome.outcomeType);"></mdb-icon></a>
   
                        
                          </div>

                           
                    </div>


                    <div class="col-md-5 py-4 ml-1 doe-table-body">
                     
                          <label class="headertexts">History Documents:</label>  
                         <div class="custom-file text-center mt-2 ">

                            <div class="input-group w-50">
        
                                <div class="custom-file ">
                                    <input type="file" class="custom-file-input " accept=".pdf" id="inputGroupFile01"
                                        (change)="onFileChanged($event)">
                                    <label class="custom-file-label text-left" for="inputGroupFile01">{{filename}}</label>
                                </div>
                                <button mdbBtn class="buttoncolor rounded text-white font-weight-bold white-hover mt-0" size="sm"
                                type="submit" (click)="onUpload();">
                                 Upload 
                                 </button>
                            </div> 
                           
        
                        </div>  
        
                      


                        <div *ngFor="let history of historyList">
                            <a class="text-primary ml-4 headertexts" (click)=loadDoc(history.docTrimId);>{{history.attachmentName}}</a>
                         
                       
                        </div>
                        
                         
                        
                    </div>

                  
    
                </div> -->

                <hr>
            
                <app-documentsList (message)="helpText($event)" [refreshOutcomeTable]="refreshOutcomeTable" (refreshOutcomeTableReset)="resetRefreshTable($event)" [visitList]="visitList" [lawRegList]="lawRegList" [accessList]="accessList"></app-documentsList>
           
                <div>
             

                    
                    <label class="headertexts"><mdb-icon fas icon="pencil-alt">
                    </mdb-icon>Approver's Comments </label>
                    <textarea class="form-control"  rows="3" maxlength="100"  name="ApproverComments" [(ngModel)]="passeddata.ApproverComments" [ngValue]="passeddata.ApproverComments"></textarea>
                </div>

                <div  *ngIf="accessList.indexOf('ChooseManagerReviewer')>-1" class="mt-3">
                    <label class="headertexts"> Choose Manager: </label>   
                    <div><select class="browser-default custom-select w-25"   [(ngModel)]="this.passeddata.ManagerReviewer" name="selectType">
                                        
                        <option *ngFor="let user of usersList" [value]="user.userId" >{{user.userId}}
                        </option>
                      </select>
                  
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light btn-light-green rounded black-text font-weight-bold " [ngClass]="{'disabled': (passeddata.CaseStatus !== 'Recommendations')?true:false}" 
                        mdbWavesEffect (click)="updateManagerReviewer();sendEmailtoUser('email sent for Review','shilpa.asuthkar@det.nsw.edu.au');updateCaseStatus('Decision Pending');">Send Draft for Manager Review</button>
                   
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light btn-deep-orange rounded text-white rounded black-text font-weight-bold" [ngClass]="{'disabled': ((passeddata.CaseStatus !== 'Recommendations' && passeddata.CaseStatus !== 'Decision Pending') || sendClicked===true)?true:false}"
                        mdbWavesEffect (click)="emailModal.show();updateCaseStatus('Approval');">
                    
                        <div *ngIf="loadingApprove" mt-3>
                            <div class="spinner-border text-primary" role="status">
                            </div>
                        </div>
                        <span *ngIf="!loadingApprove">  Approve and Send Email to Service </span>
                    
                        </button>
       
                   
                    </div>

                  </div>
    
            
                <div class="row d-flex mt-2 ml-2 p-0 mb-0 doe-table-body">
                    <label class="text-danger">(Please review all the pending approval docs and when sure send Email to service)</label>
                </div>
            <div class="row d-flex p-0 mt-1">
              
                             
           <div class="col-md-3 ">
                <button type="button" mdbBtn size="sm"
                class="relative waves-light btn-unique rounded white-text font-weight-bold "
                mdbWavesEffect (click)="showsummary();">View Summary Report</button>
             </div>
            
                 
              
            </div>

            </form>
            <!-- Form -->
        </div>
    </mdb-card-body>
</mdb-card>







<div mdbModal #emailModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="emailModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Non Compliance draft email</p>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">Type:</span>
                    </div>
                    <input type="text" class="form-control emailLabel" placeholder="{{passeddata.CaseStatus}}" aria-label="Type" aria-describedby="basic-addon1" disabled>
                </div>
                  
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">From:</span>
                    </div>
                    <input type="text" class="form-control emailLabel" placeholder="username" aria-label="From" aria-describedby="basic-addon2">
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">To:</span>
                    </div>
                  <!--  <input type="text" class="form-control emailLabel" placeholder="{{passeddata.ServiceEmail}}" aria-label="To" aria-describedby="basic-addon3" > -->
                  <input type="text" class="form-control emailLabel" [placeholder]="this.emailtoaddress"  aria-label="To" aria-describedby="basic-addon3" >
                </div>
               
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts" id="basic-addon1">CC:</span>
                    </div>
                    <input type="text" class="form-control emailLabel" placeholder="cc" aria-label="cc" aria-describedby="basic-addon4">
                </div>

                <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text headertexts">Message</span>
                    </div>
                    <div contenteditable [innerHTML]="this.tempEmailTemplate" class="my-custom-scrollbar emailLabel border" >
                     
                     </div>
                   
                  </div>
              
              

                <button type="button" mdbBtn size="sm"
                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                mdbWavesEffect (click)="sendEmail(tempEmailTemplate,passeddata.ServiceEmail);emailModal.hide(); ">Send</button>
            
            
            </div>

        </div>
    </div>
</div>



<div mdbModal #notesModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="notesModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="notesModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">Add Notes</p>
            </div>
            <div class="modal-body">
                <div class="text-danger headertexts">
                    {{errormessage}}
                </div>
                <label class="headertexts"> Note Type: </label>    
                    <select class="browser-default custom-select"   [(ngModel)]="notesType" name="selectType">
                                
                        <option *ngFor="let type of noteType" [value]="type" [selected]="type === 'choose Note Type'">{{type}}
                        </option>
                      </select>
                    
                                   
               
               
        
                    <div class="form-group green-border-focus mb-4 mt-3">
                        <label for="notestextarea" class="headertexts"> Summary: </label>
                    <textarea class="form-control doe-table-body" id="notestextarea"  [(ngModel)]="notesText" rows="3" maxlength="100"  ></textarea>

                   
                  </div>
              
              <div class="text-right">

                <button type="button" mdbBtn size="sm"
                class="relative waves-light backcolor rounded text-white font-weight-bold trigger "
                mdbWavesEffect (click)="saveNotes();">Save</button>
            
            </div>
            </div>

        </div>
    </div>
</div>




<div mdbModal #editPdfModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="editPdfModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="editPdfModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
               <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
               <p class="text-center w-responsive mx-auto mb-3 cardheader">EDIT PDF</p>
            </div>
            <div class="modal-body">
               
              
            <!--  <ejs-richtexteditor #toolsRTE id='alltoolRTE' [(ngModel)]="outcomeTemplate"  [maxLength]='4000'   style="overflow:scroll; max-height:300px">
                    <ng-template>
                      {{outcomeTemplate}}
                    </ng-template>
          
          
                  </ejs-richtexteditor> -->  
                  <editor
                  apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" 
                  [initialValue]="outcomeTemplate"
                  [(ngModel)]="outcomeTemplate" 
                  [init]="{
                    height: 400,
                    menubar: false,
                    plugins: [],
                    toolbar:
                      'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | help'
                  }"
                >
                </editor>

                <button type="button" mdbBtn size="sm"
                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                mdbWavesEffect (click)="saveText();notesModal.hide(); ">Update</button>
            
            
            </div>

        </div>
    </div>
</div>

