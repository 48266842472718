import { Injectable } from '@angular/core';  

  
@Injectable()  
export class DataService {  
  
  private data : any;
  private role :string;
  private address: string;
  private providerName: string;
  
 setOption(input : any) {      
    this.data = input; 
  }  
  
  getOption() {  
    return this.data;  
  }  


  setRole(input : string) {      
    this.role = input; 
  }  
  
  getRole() {  
    return this.role;  
  }  

  setAddress(input : string) {      
    this.address = input; 
  }  
  
  getAddress() {  
    return this.address;  
  }  

  setProviderName(input : string) {      
    this.providerName = input; 
  }  
  
  getProviderName() {  
    return this.providerName;  
  }  
}