import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';
;
const getUrl = '/outcomes/get';
const saveUrl='/outcomes/add';
const deleteUrl='/outcomes/delete';
const updateUrl='/outcomes/update';



@Injectable({
  providedIn: 'root'
})
export class CaseOutcomeService {
  baseUrl :any;
  constructor( private http: HttpClient,private config: AppConfigService) { 

    this.baseUrl = this.config.getConfig().baseUrl;
  }
  
  deleteCaseOutcome(outcomeId:string): Observable<any> {
   
    return this.http.post<any>(this.baseUrl+deleteUrl+"/"+outcomeId,null)
      .pipe(
        catchError(this.handleError('delete outcome', outcomeId))
      );
  }
 
  saveCaseOutcome(outcome: string, caseId: string,draftedBy: string,approvedBy:string): Observable<any> {
   
    var myjson = {caseId: caseId, outcomeType: outcome, draftedBy: draftedBy,approvedBy:approvedBy};
   
    return this.http.post<any>(this.baseUrl+saveUrl, myjson)
      .pipe(
        catchError(this.handleError('save outcome', myjson))
      );
  }

  saveCaseOutcomeStatus(outcome: string, caseId: string,draftedBy: string,approvedBy:string,status:string,trimId:string): Observable<any> {
   
   var myjson = {caseId: caseId, outcomeType: outcome, draftedBy: draftedBy,approvedBy:approvedBy,status:status,trimId:trimId};
    
    return this.http.post<any>(this.baseUrl+saveUrl, myjson)
      .pipe(
        catchError(this.handleError('save outcome', myjson))
      );
  }


  updateCaseOutcome(outcomeId: any, status:string,approvedBy:string,reviewedBy:string,trimId:string): Observable<any> {
   
   
    return this.http.post<any>(this.baseUrl+updateUrl+'/'+outcomeId+"?status="+status+"&trimId="+trimId+"&approvedBy="+approvedBy+"&reviewedBy="+reviewedBy,null )
      .pipe(
        catchError(this.handleError('update outcome', outcomeId))
      );
  }

  getAllCaseOutcome(caseId: any,status:any) {
    
       
    return this.http.get<any>(this.baseUrl+getUrl+"?caseId="+caseId+"&status="+status) .pipe(
      catchError(this.handleError('get all case outcomes', caseId))
    );

  }





  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead
      alert("error message"+JSON.stringify(error));
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
