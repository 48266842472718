import { Component, OnInit, Input} from '@angular/core';
import { DataService } from '../../visits/visit1/visit1.service';
import { EvidenceService } from 'src/app/service/evidence.service';

@Component({
  selector: 'app-complianceNoticeTemplate',
  templateUrl: './complianceNotice.component.html',
  styleUrls: ['./complianceNotice.component.scss']

})
export class ComplianceNoticeComponent implements OnInit {
  passeddata:any;
  date:Date;
  todaysDate:Date
  headElements = ['Provision of the National Regulations', 'Description of non-compliance', 'Steps to be taken by the Approved Provider'];
  @Input()
  lawRegList: any[] = [];

  @Input()
  visitList: any[] = [];
  visitdates:string='';
  ngOnInit() {
    this.passeddata = this.dataservice.getOption();
    this.date = new Date();
    this.date.setDate( this.date.getDate() + 14 );
    this.todaysDate=new Date();
    this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.visitdates=this.visitdates+","+el.startDt;
      }
    })
    };
    saveComplianceNotice(item:any)
    {
      this.evidenceService.addEvidence(item)
      .subscribe(resp => {
        return resp;
      });
      

    }

 
  constructor(private dataservice: DataService, private evidenceService: EvidenceService)
  {

  }


}
