<fieldset class="scheduler-border">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-briefcase"
            style="color:#041e42;padding-right: 6px;"></i>Law/Reg List</legend>

<div class="mb-3 ">
    <table mdbTable id="lawregTable" 
            class="z-depth-1 doe-table-body border border-1" hover="true">
         
            <!--Table head-->
            <thead class="lawReg-table-header tableheadercolor">
                <tr class="">
                    <th *ngFor="let head of headElementsLawReg; let i = index"
                        aria-controls="lawregTable" [mdbTableSort]="listOfLawRegs"
                        [sortBy]="headElementsLawRegSort[i]" scope="col" style="white-space: nowrap;">
                        {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                    
                    <div (click)="changeCaseStatus($event)">
                        <input style="font-size:12px;" class="form-control form-control-sm text-black p-0 border border-dark" type="search"
                        placeholder="" mdbInput id="input1" aria-label="Search"
                        (search)="onFilter($event.target.value,head)" (keyup)="onFilter($event.target.value,head)">
                      <!--  <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i> -->
                    </div>
                    </th>
                </tr>
                
            </thead>
            <!--Table body-->
            <tbody>


                <tr class="showpointer" *ngFor="let lawreg of listOfLawRegs | paginate: config" (click)="setselected(lawreg);" [ngClass]="{'selectedcolor': (this.editLawId===lawreg.regId)?true:false}">
                    <td class="doe-table-body">{{lawreg.regId}}</td>
                    <td class="doe-table-body">{{lawreg.description}}</td>
                    <td class="doe-table-body">{{lawreg.priority}}</td>
                    <td class="doe-table-body">{{lawreg.lawregIndicator}}</td>
                    <td class="doe-table-body">{{lawreg.createdDate | date : 'dd-MM-yyyy'}}</td>
                    
                

                </tr>


            </tbody>
        </table>
        <div class="mt-2 text-center doe-table-body">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
        </div>
      
    </div>

      <div class=" text-right">
        <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
        size="sm" type="submit" (click)="clickcreate=true;clickedit=false;">Create
     </button>
                   
                    <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                     size="sm" type="submit" (click)="validateEdit();">Edit
                  </button>
                  <button mdbBtn class=" buttoncolor rounded text-white font-weight-bold px-5"
                  size="sm" type="submit" (click)="(editLawId!==null && editLawId!==undefined)?ConfirmationDeleteModal.show():validateEdit()">Delete
               </button>
                </div>    
    
</fieldset>


<fieldset class="scheduler-border" *ngIf="this.clickcreate===true">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-plus"
            style="color:#041e42;padding-right: 6px;"></i>Create Law/Reg</legend>

        <div >
            <!-- Form -->
            <form class="">
               
               <!-- <div class="col-md-4 py-4">
                 

               <select class="browser-default custom-select" name="Hub">
                                    
                        <option selected>Choose Hub</option>
                        <option value="1">Hub1</option>
                        <option value="2">Hub2</option>
                        <option value="3">Hub3</option>
                    </select>
                </div>  -->
                <div class=" py-2">
                 
                    <!-- Small input --><label class="headertexts">Law/Reg Number</label>
                      <input class="form-control" type="text" name="lawregNumber" placeholder="Enter Law/Regulation Number"  [(ngModel)]="lawregNumber">
  
                   
  
                  </div>
                <div class=" py-2">
                 
                    <label class="headertexts">Law/Reg indicator</label>
                    <select class="browser-default custom-select" name="LawRegIndicator" [(ngModel)]="lawregIndicator">
                                         
                             <option selected>Choose Law/Reg</option>
                             <option value="Law">Law</option>
                             <option value="Regulation">Regulation</option>
                            
                         </select>
                 </div> 
              
             

                <div class=" py-2">
                    <label class="headertexts">Law/Regulation Description</label>
                    <textarea class="form-control"  rows="3" maxlength="100" name="lawregDesc" [(ngModel)]="lawregDesc"></textarea>
  
             

                </div>

                
                <div class="py-2">
                    <label class="headertexts">Priority</label>

                    <select class="browser-default custom-select" name="Priority" [(ngModel)]="priority">
                        <option selected>Choose Priority</option>       
                             <option value="High">High</option>
                             <option value="Medium">Medium</option>
                             <option value="Low">Low</option>
                         </select>
                 </div> 
                 <div class="py-2">
                 <!-- <label class="headertexts">Questions List</label>
              <editor
                 apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" 
                 name="questionlist"
                 [(ngModel)]="questionlist" 
                 [init]="{
                   height: 200,
                   charLimit : 10,
                   branding:false,
                   menubar: false,
                   plugins: 'wordcount lists advlist',
                   toolbar:
                     'undo redo | formatselect | bold italic | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | help'
                 }"
                
           
               >
               </editor> -->


            

               <p-editor name="questionlist" 
               [(ngModel)]="questionlist"
                #editor [style]="{'height':'320px'}">
                <p-header>
                  <span class="ql-formats">
                    <select class="ql-size">
                      <option value="small">Small</option>
                      <option selected></option>
                      <option value="large">Large</option>
                      <option value="huge">Huge</option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                    <button class="ql-strike" aria-label="Strike"></button>
                  </span>
                  <span class="ql-formats">
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <select title="Text Alignment" class="ql-align" >
                        <option selected>Gauche</option>
                        <option value="center" label="Center"></option>
                        <option value="right" label="Right"></option>
                        <option value="justify" label="Justify"></option>
                      </select>            
                    </span> 
                  </p-header>
             
               </p-editor>  
               
                 </div>

                   <div class=" text-right">

               
                <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                 size="sm" type="submit" (click)="onAddLawReg()">Save
              </button>
            </div>    

            </form>
            <!-- Form -->
        </div>
   </fieldset>
   <fieldset class="scheduler-border" *ngIf="this.clickedit===true">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-edit"
            style="color:#041e42;padding-right: 6px;"></i>Edit Law/Reg</legend>
  
            <div >
                <!-- Form -->
                <form class="">
                   
                  
                    <div class=" py-2">
                        <label class="headertexts">Law/Reg Number</label>
                        <input class="form-control" type="text" name="editLawId" value="{{editLawId}}" disabled>
  
                   
                      <!--  <select class="browser-default custom-select" name="editLawId" [(ngModel)]="editLawId" (change)="onSelectRegs(editLawId)">
                                      <option selected>choose Law/Reg</option>     
                                      <option (value)="regs.regId" *ngFor="let regs of listOfLawRegs">  
                                          {{regs.regId}}</option>
                                
                        </select> -->

                    </div>
                    <div class=" py-2" >
                     
                        <label class="headertexts">Law/Reg indicator</label>
                        <select class="browser-default custom-select" name="editLawRegIndicator" [(ngModel)]="editLawRegIndicator">
                                             
                                 <option selected>Choose Law/Reg</option>
                                 <option value="Law">Law</option>
                                 <option value="Regulation">Regulation</option>
                                
                             </select>
                     </div> 
                  
                   
    
                    <div class=" py-2" >
                        <label class="headertexts">Law/Regulation Description</label>
                        <textarea class="form-control"  rows="3" maxlength="100" name="editlawregDesc" [(ngModel)]="editlawregDesc"></textarea>
      
                 
    
                    </div>
    
                    
                    <div class="py-2">
                        <label class="headertexts">Priority</label>
    
                        <select class="browser-default custom-select" name="editPriority" [(ngModel)]="editPriority">
                            <option selected>Choose Priority</option>       
                                 <option value="High">High</option>
                                 <option value="Medium">Medium</option>
                                 <option value="Low">Low</option>
                             </select>
                     </div> 

                     <div class="py-2">
                      <label class="headertexts">Questions List</label>

                   <!--   <editor
                      apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg" 
                      name="editquestionlist" 
                      [(ngModel)]="editquestionlist" 
                      [init]="{
                        height: 200,
                        charLimit : 10,
                        branding:false,
                        menubar: false,
                        plugins: 'wordcount lists advlist',
                        toolbar:
                          'undo redo | formatselect | bold italic | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | help'
                      }"
                     
                
                    >
                    </editor> -->

                    <p-editor name="editquestionlist" 
                    [(ngModel)]="editquestionlist"
                     #editor [style]="{'height':'320px'}">
                     <p-header>
                      <span class="ql-formats">
                        <select class="ql-size">
                          <option value="small">Small</option>
                      <option selected></option>
                      <option value="large">Large</option>
                      <option value="huge">Huge</option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-bold" aria-label="Bold"></button>
                        <button class="ql-italic" aria-label="Italic"></button>
                        <button class="ql-underline" aria-label="Underline"></button>
                        <button class="ql-strike" aria-label="Strike"></button>
                      </span>
                      <span class="ql-formats">
                          <button class="ql-list" value="ordered"></button>
                          <button class="ql-list" value="bullet"></button>
                          <select title="Text Alignment" class="ql-align" >
                            <option selected>Gauche</option>
                            <option value="center" label="Center"></option>
                            <option value="right" label="Right"></option>
                            <option value="justify" label="Justify"></option>
                          </select>            
                        </span> 
                      </p-header>
                     </p-editor>
                  

                     </div>
    
                       <div class=" text-right">

    
                   
                    <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                     size="sm" type="submit" (click)="onEditLawReg()">Save
                  </button>
                
                </div>    
    
                </form>
                <!-- Form -->
            </div>
     </fieldset>



     
<div mdbModal #ConfirmationDeleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm modal-notify modal-danger" role="document">
  <!--Content-->
  <div class="modal-content text-center">
    <!--Header-->
    <div class="modal-header d-flex justify-content-center">
      <p class="heading">Are you sure? Do you want to delete {{editLawId}}?</p>
    </div>

    <!--Body-->
    <div class="modal-body">
      <mdb-icon fas icon="times" size="4x" class="animated rotateIn"></mdb-icon>
    </div>

    <!--Footer-->
    <div class="modal-footer justify-content-center">
      <a mdbBtn color="danger" outline="true" class="waves-effect" (click)="onDeleteLawReg();ConfirmationDeleteModal.hide();"
        mdbWavesEffect>Yes</a>
      <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="ConfirmationDeleteModal.hide()">No</a>
    </div>
  </div>
  <!--/.Content-->
</div>
</div>