   
                    <div class="p-0 mt-3">
                        <table mdbTable class="table-hover table-condensed mb-0">
                            <tbody class="p-0">
                                <tr class="p-0">
                                    <td class="w-50 p-0" >
                                        <label class="headertexts">Choose Response Recieved Type:</label>
                                        <select class="browser-default custom-select" name="responseType" [(ngModel)]="passeddata.responseType" (change)="saveResponseRecievedData()">
                                                <option value="0">Choose One</option>          
                                                <option value="1">Response Recieved From Service</option>
                                                <option value="2">Response Not Recieved From Service</option>
                                                <option value="3">Response Recieved to Cancel</option>
                                        </select>
                                    </td>
                                    <td class="p-0" >
                                        <label class="headertexts">Date of Response Recieved</label>
                                        <input mdbInput
                                    type="date" id="responseDt" class="form-control"  name="responseDt" [(ngModel)]="passeddata.responseDate" (change)="saveResponseRecievedData()">
                         
                                    </td>
<!--
                                    <td>
                                        <button type="button" mdbBtn size="sm" 
                      class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-4" (click)="saveResponseRecievedData()" mdbWavesEffect>Save</button>
                
                 
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                 
                      
                    </div>