import { Component, OnInit, OnChanges,OnDestroy} from '@angular/core';
import { EmailTemplateService } from 'src/app/service/emailTemplate.service';
import { GeneratedEmailDocService } from 'src/app/service/generatedEmailDoc.service';

import { EvidenceService } from 'src/app/service/evidence.service';
import {ToastrService} from 'ngx-toastr';
import { LoginDataService } from 'src/app/service/loginData.service';
import { INAService } from 'src/app/service/ina.service';
import { DataService } from 'src/app/views/visits/visit1/visit1.service';
import { LawRegService } from 'src/app/service/lawReg.service';

@Component({
  selector: 'app-updateBreachActions',
  templateUrl: './updateBreachActions.component.html',
  styleUrls: ['./updateBreachActions.component.scss']

})
export class UpdateBreachActionsComponent implements OnInit,OnChanges,OnDestroy {
  passeddata:any;
  date:Date;
  todaysDate:Date
  headElements = ['Provision of the National Laws and Regulations', 'Description of risk posed', 'Steps to be taken to remove or reduce the risk ', 'Due date for compliance'];
  headElementsLawRegSort=['lawid','evidence','correctionSteps','complianceDueDate'];
  user:string='';

 /* @Input()
 
  @Input()
  
  @Input()
  visitList: any[] = [];
  
  dueDate:string='';
  emergencyActionText:string;*/
  lawRegList: any[] = [];
  backuplist:any[]=[];
  visitdates:string='';
  outcomeTemplate : string =""; //pretable template
  outcomeType:string="";
  postTableTemplate: string=""; // after the table - penalities template

  async ngOnInit() {

    if(this.loginDataService.getData() == null || this.loginDataService.getData() == undefined){
      console.log(localStorage.getItem('displayLoginName'));
     
     this.loginDataService.setData({firstName:localStorage.getItem('firstName'),
       surname:localStorage.getItem('surname'),
       displayLoginName:localStorage.getItem('displayLoginName'),
       email:localStorage.getItem('email'),
       status:localStorage.getItem('status'),
       roleId:localStorage.getItem('roleId'),
       uid:localStorage.getItem('uid'),
       //roleId: localStorage.getItem('roleId')
 
     });
 
    
    }

  
    
    if(this.dataservice.getOption() == null || this.dataservice.getOption() == undefined){
     
      var temp =localStorage.getItem("selectedCase");
      (temp!=null)?this.dataservice.setOption(JSON.parse(temp)):this.dataservice.setOption('');
    

   
    }
    
    this.user = (this.loginDataService && this.loginDataService.getData() && this.loginDataService.getData!=null)?this.loginDataService.getData().firstName+' '+this.loginDataService.getData().surname:'';
    this.passeddata = this.dataservice.getOption();
   
    var outcome =localStorage.getItem("SelectedOutcome");
    if(outcome!==null)
    {
      this.outcomeType=outcome;
    }
    
    this.date = new Date();
    this.date.setDate( this.date.getDate() + 14 );
    this.todaysDate=new Date();
   
    //this.outcomeType=(localStorage.getItem("SelectedOutcome")!==null)?localStorage.getItem("SelectedOutcome"):'';
   
   //this.lawRegList = (this.passeddata && this.passeddata!=null)?this.passeddata.lawRegList:[];
   
 // this.lawRegList =JSON.parse(localStorage.getItem('lawRegList') || '{}');
  //this.lawRegList = this.lawRegList.filter(el=>(el.discard==null || el.discard==undefined || el.discard!==true))
 
  this.lawRegService.getAllRegs()
  .subscribe(data => {

    for (const d of (data as any)) {


      this.additionalRegs.push({
        lawregIndicator: d.lawregIndicator,
        regId: d.reg_num,
        description: d.reg_description,
        priority:d.flag

      }
      );

  }


  this.inaService.getINAs( this.passeddata.CaseId)
  .subscribe( data => {
  
   
    for (const d of (data as any)) {
     
      if(d!==null && (d.discard==null || d.discard==undefined || d.discard=='null' || d.discard!=='true') && d.breachConfim!==null && d.breachConfim.toLowerCase()==='Confirmed breach'.toLowerCase())
      {
        var evidenceobj="";
        var correctionStepsobj="";
        var complianceDueDateobj="";
      
        var match =  this.additionalRegs.find(a => (a.regId===d.regNumber));
        var desc ="";
        var indicator="";
        if(match!==null && match!==undefined)
        {
          
          desc=match.description;
          indicator=match.lawregIndicator;
         
        }
      
      this.evidenceService.getEvidence(d.breachId).subscribe(data2 => {
       
         if(data2!==null && data2.length>0 && data2[0]!==null)
         {
           evidenceobj = data2[0].evidenceDetails;
           correctionStepsobj=data2[0].correctionSteps;
           complianceDueDateobj=data2[0].complianceDueDate;
         }else{
          evidenceobj = 'N/A';
          correctionStepsobj='N/A';
          complianceDueDateobj='';
         }
       this.lawRegList.push({
         inaid: d.breachId,
         priority: 'high',
         lawid: d.regNumber,
         description: desc,
         complianceStatus:d.breachConfim,
         evidenceFlag: true,
         showEvidenceFlag: false,
         visitId: d.visitId,
         visitNotDone:d.notReviewedFlag,
         serviceId: this.passeddata.SENumber,
         complianceDueDate:complianceDueDateobj,
         evidence: evidenceobj,
         correctionSteps:correctionStepsobj,
         discard:d.discard,
         questionairre:'',
         reviewComments:'',
         officerComments:'',
         selectedFile: temp,
         updatedBy:d.updatedBy,
         lawregIndicator:indicator
     
     });


     this.backuplist=[...this.lawRegList];
   
   
      });
     }
    }
   
  
   });




});



 


   

   
    

    this.visitdates=(this.passeddata && this.passeddata!=null)?this.passeddata.breachVisitDates:'';

   /* this.visitList.forEach(el =>{
      if(el.visitstatus === 'Completed')
      {
        this.visitdates=this.visitdates+","+el.startDt;
      }
    });*/
  


    //this.getTemplate();
    }


    additionalRegs:any[]=[];
     async getAdditionalRegsfromDB() {

  
    await this.lawRegService.getAllRegs()
        .subscribe(data => {

          for (const d of (data as any)) {


            this.additionalRegs.push({
              lawregIndicator: d.lawregIndicator,
              regId: d.reg_num,
              description: d.reg_description,
              priority:d.flag

            }
            );

        }
     
      });

    }


    ngOnChanges()
    {
           
    }

    changeCaseStatus(event:any)
  {
    event.stopPropagation();
  }

  onFilter(searchValue:string,fieldName:string) {
  
    if(searchValue==='')
    {
      this.lawRegList =[...this.backuplist];
    }
    else
    {
      
      if(fieldName!==null && fieldName==='Provision of the National Regulations')
      {
        this.lawRegList = this.backuplist.filter(el => (el.lawid!==null && el.lawid!==undefined && el.lawid.toLowerCase().includes(searchValue.toLowerCase()) == true));
  
      }
      if(fieldName!==null && fieldName==='Description of risk posed')
      {
        this.lawRegList = this.backuplist.filter(el => (el.evidence!==null && el.evidence!==undefined && el.evidence.toLowerCase().includes(searchValue.toLowerCase()) == true));
  
      }
      if(fieldName!==null && fieldName==='Steps to be taken to remove or reduce the risk ')
      {
        this.lawRegList = this.backuplist.filter(el => (el.correctionSteps!==null && el.correctionSteps!==undefined && el.correctionSteps.toLowerCase().includes(searchValue.toLowerCase()) == true));
  
      }
      if(fieldName!==null && fieldName==='Due date for compliance')
      {
        this.lawRegList = this.backuplist.filter(el => (el.complianceDueDate!==null && el.complianceDueDate!==undefined && el.complianceDueDate.toLowerCase().includes(searchValue.toLowerCase()) == true));
  
      }
   
     
    }
  
  }


    getTemplate()
    {
    
      this.generatedEmailDocService.getExistingGeneratedDoc(this.passeddata.CaseId,this.outcomeType).subscribe(data => {
        if(data!==null)
        {
          this.outcomeTemplate = data.docBody1;
         
          if(this.passeddata.responseType === '1')
          {
            this.outcomeTemplate =this.outcomeTemplate +"<br>"+  data.responseRecieved;
          }
          if(this.passeddata.responseType === '2')
          {
            this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data.responseNotRecieved;
          }
          if(this.passeddata.responseType === '3')
          {
            this.outcomeTemplate =this.outcomeTemplate  +"<br>"+  data.responseRecievedToCancel;
          }
          this.postTableTemplate = data.docBody2;
          this.outcomeTemplate = this.outcomeTemplate.replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/null/g,'').replace(/\$VisitDates/g,this.visitdates).replace(/\$ServiceName/g,this.passeddata.ServiceName).replace(/\$ServiceAddress/g,this.passeddata.Address).replace(/\$ServiceId/g,this.passeddata.SENumber).replace(/\$ProviderAddress/g,this.passeddata.ProviderAddress).replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/\$ProviderNumber/g,this.passeddata.ProviderId).replace(/\$responseDate/g,this.passeddata.responseDate);
          console.log(this.outcomeTemplate);
        }
        else{
           this.emailTemplateService.getEmailTemplate(this.outcomeType).subscribe(data2 => {
          if(data2!== null && data2!==undefined)
          {
            this.outcomeTemplate = data2.templateBody;
           
           if(this.passeddata.responseType === '1')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data2.responseRecieved;
           }
           if(this.passeddata.responseType === '2')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+  data2.responseNotRecieved;
           }
           if(this.passeddata.responseType === '3')
           {
             this.outcomeTemplate =this.outcomeTemplate +"<br>"+   data2.responseRecievedToCancel;
           }

           this.postTableTemplate = data2.postTableTemplate;
           this.outcomeTemplate = this.outcomeTemplate.replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/null/g,'').replace(/\$VisitDates/g,this.visitdates).replace(/\$ServiceName/g,this.passeddata.ServiceName).replace(/\$ServiceAddress/g,this.passeddata.Address).replace(/\$ServiceId/g,this.passeddata.SENumber).replace(/\$ProviderAddress/g,this.passeddata.ProviderAddress).replace(/\$ProviderName/g,this.passeddata.ProviderName).replace(/\$ProviderNumber/g,this.passeddata.ProviderId).replace(/\$responseDate/g,this.passeddata.responseDate);
           console.log(this.outcomeTemplate);
               
         }
        });
        }
        

       });
    }

    
    deleteBreach(item : any)
    {
      
       var index= this.backuplist.indexOf(item);
       
       if(index!== -1)
       {
        this.backuplist.splice(index,1);
       }
     
           
    }

    saveCorrectionSteps(item:any)
    {
    
      item.updatedBy=this.user;
      item.questionairre=null;
      item.reviewComments=null;
      this.evidenceService.addEvidence(item).subscribe(resp => {
        //this.message.emit({'status':'success','messageTxt':'correction steps saved'});
        this.showSuccess("correction steps saved'");
        return resp;
      },  err => {
        this.showError("Error Occured at save email templates'");
        console.log('Error Occured duringng save email templates' + err);
        //this.message.emit({'status':'error','messageTxt':'correction steps save failed'});
      });
    }

    saveComplianceDueDate(item:any)
    {

      item.questionairre=null;
      item.reviewComments=null;
      let newDate = new Date(item.complianceDueDate);
      newDate.setHours(0,0,0,0);
      var now = new Date();
      now.setHours(0,0,0,0);
      if(newDate<now)
      {
    
        this.showError('date cannot be today or less than today');
        return; 
      }

      item.updatedBy=this.user;
      this.evidenceService.addEvidence(item)
      .subscribe(resp => {
       // this.message.emit({'status':'success','messageTxt':'changes to compliance due date saved saved'});
       this.showSuccess("changes to compliance due date saved'");
       return resp;
      },  err => {
        this.showError("Error Occured at save email templates'");
        console.log('Error Occured duringng save email templates' + err);
        //this.message.emit({'status':'error','messageTxt':'compliance due date save failed'});
      });
    }

   
    saveOutcomeTemplate2()
    {
      
    }

    showSuccess(message:string)
  {

    this.toastr.success(message, 'Success!',
    {timeOut: 2000});;
  }
  showError(message:string)
  {

    this.toastr.error(message, 'Error!',
    {timeOut: 2000});;
  }

  

  showInfo(message:string)
  {

    this.toastr.info(message, 'Info!',
    {timeOut: 2000});;
  }

 
  constructor(private lawRegService:LawRegService,private inaService: INAService,private loginDataService:LoginDataService,private toastr:ToastrService,private evidenceService: EvidenceService,private dataservice:DataService,private emailTemplateService: EmailTemplateService,private generatedEmailDocService: GeneratedEmailDocService)
  {

  }
  ngOnDestroy(): void {
  
  }


}
