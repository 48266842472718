<mdb-card class="mb-3 mt-3">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Input Service Details</td>


                    <td class="text-right p-0">

                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="serviceDetails.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="p-0" id="print-section">
        <div mdbCollapse [isCollapsed]="false" #serviceDetails="bs-collapse">
            <form #userForm="ngForm" (ngSubmit)="userForm.form.valid">
                <div class="form-row px-2">
                    <div class="col-md-4 mb-3">


                        <label class="headertexts">Service Name</label>
                        <input type="text" name="name" placeholder="Enter Service Name" class="form-control"
                            [(ngModel)]="model.name" #name="ngModel" [ngClass]="{ 'is-invalid': name.invalid }"
                            maxlength="40">

                        <div class="invalid-feedback" *ngIf="name.invalid">
                            <p *ngIf="name.errors.maxlength">maxlength is 30</p>
                        </div>
                    </div>
                 
                    <div class="col-md-2 mb-3">
                        <label class="headertexts">Child Count</label>
                        <input type="number" name="count" placeholder="Enter Child Count" class="form-control" 
                        [(ngModel)]="model.count" #count="ngModel"
                          [ngClass]="{ 'is-invalid': count.invalid }" max="30">
                        <div class="invalid-feedback" *ngIf="count.invalid">
                          <p *ngIf="count.errors.maxlength">count can be max 30</p>
                        </div>
                    </div>


                </div>
                <div class="form-row px-2">
                  
                    <div class="col-md-4 mb-3">
                        <label class="headertexts">Address</label>
                        <textarea class="form-control evidenceText" id="address" name="address" rows="2" maxlength="50" 
                        [(ngModel)]="model.address" #address="ngModel"  [ngClass]="{ 'is-invalid': address.invalid }"></textarea>
                                                                      
                        <div class="invalid-feedback" *ngIf="address.invalid">
                          <p *ngIf="address.errors.maxlength">maxlength is 100</p>
                        </div>
                    </div>

                    <div class="col-md-2 mb-3 align-text-bottom py-5">
                        <button type="button" mdbBtn size="sm"
                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0 align-text-bottom"
                        mdbWavesEffect>Save</button>
                    </div>


                </div>
                 
                
            </form>
        </div>
    </mdb-card-body>
</mdb-card>