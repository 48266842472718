<div class="dark-font bc-icons-2" mt-2>
    <mdb-breadcrumb class="headertexts">
        <mdb-breadcrumb-item>
            <a routerLink="/visits/pages/visit1" routerLinkActive="active" [queryParams]="{ role:role}" mdbWavesEffect>
                <mdb-icon fas icon="home" class="mr-1"></mdb-icon> <span class="headertexts">Home</span>
            </a>

        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item>
            <a class="font-weight-bold text-black headertexts" mdbWavesEffect>
                <mdb-icon fas icon="angle-right" class="mr-1"></mdb-icon> Details
            </a>


        </mdb-breadcrumb-item>
    </mdb-breadcrumb>


    <fieldset class="scheduler-border">
        <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-clipboard"
                style="color:#041e42;padding-right: 4px;"></i>Case details</legend>

        <div class="row mt-90">
            <div class="col-md-8 col-sm-12 col-12">
                <div class="serv-section-2">
                    <div class="serv-section-2-icon"> <i class="fas fa-gem"></i> </div>
                    <div class="serv-section-desc mt-4">
                        <h4>Case details</h4>
                        <h5>CASE ID : {{(passeddata)?passeddata.CaseId:""}} | CASE STATUS :
                            {{(passeddata)?passeddata.CaseStatus:""}}</h5>
                    </div>
                    <div class="section-heading-line-left">
                        <br />
                        <table mdbTable bordered="true">

                            <!--Table head-->
                            <!--Table body-->
                            <tbody>

                                <tr>
                                    <td style="font-weight:bold">Case ID:</td>
                                    <td>{{(passeddata)?passeddata.CaseId:""}}</td>
                                    <td style="font-weight:bold">Service Name:</td>
                                    <td>{{(passeddata)?passeddata.ServiceName:""}}</td>
                                </tr>

                                <tr>

                                    <td style="font-weight:bold">Service ID:</td>
                                    <td>{{(passeddata)?passeddata.SENumber:""}}</td>
                                    <td style="font-weight:bold">Service Type:</td>
                                    <td>{{(passeddata)?passeddata.ServiceType:""}}</td>



                                </tr>


                                <tr>
                                    <td style="font-weight:bold">Provider ID:</td>
                                    <td>{{(passeddata)?passeddata.ProviderId:""}}</td>
                                    <td style="font-weight:bold">Approved Provider:</td>
                                    <td>{{(passeddata)?passeddata.ProviderName:""}}</td>


                                </tr>
                                <tr>
                                    <td style="font-weight:bold">Nominated Supervisor:</td>
                                    <td>{{(passeddata)?passeddata.NominatedSupervisor:""}}</td>
                                    <td style="font-weight:bold">Primary Contact:</td>
                                    <td>{{(passeddata)?passeddata.PrimaryContact:""}}</td>

                                </tr>
                                <tr>
                                    <td style="font-weight:bold">Service Address:
                                        <!--<mdb-icon fas icon="map-marker-alt" class="mr-3 text-danger fa-lg"  type="button" (click)="mapModal.show()">
                                    </mdb-icon>-->
                                    </td>
                                    <td>{{(passeddata)?passeddata.Address:""}}</td>
                                    <td style="font-weight:bold">Owner:</td>
                                    <td>{{(passeddata && passeddata.Officer!== null && passeddata.Officer!==undefined)?(passeddata.Officer.replace('.',' ')):""}}
                                    </td>


                                </tr>

                            </tbody>


                            <!--Table body-->
                        </table>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12 col-12">
                <div class="serv-section-2 serv-section-2-act">
                    <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="fas fa-signature"></i> </div>
                    <div class="serv-section-desc mt-4">
                        <h4>Case Overview</h4>
                        <h5>Pie Chart</h5>
                    </div>
                    <div class="section-heading-line-left"></div>
                    <app-summaryGraph *ngIf="setGraph" [lawRegList]="lawRegList" [statusUpdateFlag]="statusUpdateFlag">
                    </app-summaryGraph>


                </div>
            </div>

        </div>

    </fieldset>





    <!--<br/>


<mdb-card class="mb-3 p-0">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold">Case Details</td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="test.toggle()"></mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body>
        <div mdbCollapse [isCollapsed]="false" #test="bs-collapse">
            <mdb-card-title>
                <span class="font-weight-bolder">CASE ID :</span> {{(passeddata)?passeddata.CaseId:""}} | <span
                    class="font-weight-bolder">CASE STATUS </span>: {{(passeddata)?passeddata.CaseStatus:""}}
            </mdb-card-title>
           
                        <table mdbTable bordered="true" class="table-condensed">-->

    <!--Table head-->
    <!--Table body-->
    <!--     <tbody class="doe-table-body">

                                <tr >
                                    <th scope="row">Case ID:</th>
                                    <td>{{(passeddata)?passeddata.CaseId:""}}</td>
                                    <th scope="row">Service Name:</th>
                                    <td>{{(passeddata)?passeddata.ServiceName:""}}</td>
                                </tr>

                                <tr >
                                
                                    <th scope="row">Service ID:</th>
                                    <td>{{(passeddata)?passeddata.SENumber:""}}</td>
                                    <th scope="row">Service Type:</th>
                                    <td>{{(passeddata)?passeddata.ServiceType:""}}</td>
                                    
                                

                                </tr>
                              

                                <tr >
                                    <th scope="row">Provider ID:</th>
                                    <td>{{(passeddata)?passeddata.ProviderId:""}}</td>
                                    <th scope="row">Approved Provider:</th>
                                    <td>{{(passeddata)?passeddata.ProviderName:""}}</td>

                                
                                </tr>
                                <tr >
                                    <th scope="row">Nominated Supervisor:</th>
                                    <td>{{(passeddata)?passeddata.NominatedSupervisor:""}}</td>
                                    <th scope="row">Primary Contact:</th>
                                    <td>{{(passeddata)?passeddata.PrimaryContact:""}}</td>
                               
                                </tr>
                                <tr>
                                    <th scope="row">Service Address:-->
    <!--<mdb-icon fas icon="map-marker-alt" class="mr-3 text-danger fa-lg"  type="button" (click)="mapModal.show()">
                                    </mdb-icon>-->
    <!--</th>
                                    <td>{{(passeddata)?passeddata.Address:""}}</td>
                                    <th scope="row">Owner:</th>
                                    <td>{{(passeddata && passeddata.Officer!== null && passeddata.Officer!==undefined)?(passeddata.Officer.replace('.',' ')):""}}</td>
                                
                                </tr>

                            </tbody>

                        
                        
                        </table>
                    

                <app-summaryGraph *ngIf="setGraph" [lawRegList]="lawRegList" [statusUpdateFlag]="statusUpdateFlag" class="p-0"></app-summaryGraph>


        </div>

 
    </mdb-card-body>
</mdb-card> -->



    <!--
<app-summaryGraph *ngIf="setGraph" [lawRegList]="lawRegList" [statusUpdateFlag]="statusUpdateFlag"></app-summaryGraph>
-->

    <!--<app-serviceDetailsInput ></app-serviceDetailsInput> -->

    <div mdbModal #mapModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mapModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="mapModal.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <app-map [map]="map"></app-map>

                </div>

            </div>
        </div>
    </div>
    <!-- FLB code-->

    <fieldset class="scheduler-border">
        <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-briefcase"
                style="color:#041e42;padding-right: 6px;"></i>Visit List</legend>
        <span class="ml-2"
            style="font-weight:100;font-size: 12px;color: black;">{{(completeVisitsList!=null && completeVisitsList!=undefined)?('(Total visits: '+completeVisitsList.length+')'):''}}</span>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-12">
                <table mdbTable id="visitdetailstable" hover="true">
                    <thead class="tableheadercolor">
                        <tr class="headertexts">
                            <th *ngFor="let head of visitheadElements; let i = index" aria-controls="visitdetailstable"
                                [mdbTableSort]="visitList" [sortBy]="visitheadElementsSort[i]" scope="col"
                                style="white-space: nowrap;">{{head}}
                                <mdb-icon fas icon="sort"></mdb-icon>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr mdbTableCol *ngFor="let el of visitList; let i = index" [routerLink]=""
                            (click)="enablevisit(el)" routerLinkActive="active" class="doe-table-body"
                            [ngClass]="{'cyan lighten-5 font-weight-bold':(el.selected==true)}">

                            <td>{{el.visitId}}</td>
                            <td>{{el.location}}
                                <app-changeAddress
                                    *ngIf="(el.visitstatus==='scheduled' || el.visitstatus==='Scheduled')  && passeddata.ServiceType==='Family Day Care'"
                                    (address)="onEditAddress($event,el);"></app-changeAddress>

                            </td>
                            <td>{{el.visitstatus }}</td>
                            <td><!--{{el.startDt}} -->

                                <span *ngIf="el.visitstatus!=='Scheduled'">
                                    {{el.startDt}}
                                </span>
                              <input mdbInput style="font-size:12px;color: black;" type="date" *ngIf="el.visitstatus==='Scheduled' || el.visitstatus==='scheduled'" 
                                class="form-control" name="visitStartDate" (change)="onChangevisitStartDate($event.target.value,el)" 
                               [value]="el.calstartDt" 
                               [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && role!=='ADMIN') || (passeddata.CaseStatus==='Immediate Risk to Children' )}">
                               


                            </td>
                            <td>
                                {{el.endDt}}

                            </td>
                            <td>
                                {{el.officer1}}

                            </td>
                            <td>
                                {{el.officer2}}

                            </td>

                            <td class="text-center">
                                <mdb-checkbox
                                    *ngIf="el.visitstatus!=='Completed' && accessList.indexOf('cancelVisit')>-1"
                                    [checked]="el.visitNotDone==='true'" (change)="updateVisitNotDone(el)"
                                    [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && role!=='ADMIN') || (passeddata.CaseStatus==='Immediate Risk to Children' )}">

                                </mdb-checkbox>

                            </td>

                         


                        </tr>
                    </tbody>

                </table>

            </div>
            <!--  <div class="col-md-3 col-sm-12 col-12">
                <div class="serv-section-2 serv-section-2-act">
                    <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="fas fa-edit"></i> </div>
                    <div class="serv-section-desc">
                        <h4>Notes details</h4>
                        <h5>All Notes List</h5> </div>
                    <div class="section-heading-line-left"></div>
                    <app-summaryGraph *ngIf="setGraph" [lawRegList]="lawRegList" [statusUpdateFlag]="statusUpdateFlag" ></app-summaryGraph>

                   
                </div>
            </div>-->
            <!-- <div class="col-md-4 col-sm-12 col-12">
                <div class="serv-section-2">
                    <div class="serv-section-2-icon"> <i class="fas fa-signature"></i> </div>
                    <div class="serv-section-desc">
                        <h4>Accumulation</h4>
                        <h5>Support and Sell</h5> </div>
                    <div class="section-heading-line-left"></div>
                    
                </div>
            </div>-->
        </div>

    </fieldset>
    <!--<mdb-card class="mb-3 mt-2" [ngClass]="{'d-none': (summaryreport==true)}"> -->
    <!--<mdb-card class="mb-3 mt-2">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Visits {{(passeddata)?passeddata.CaseId:""}} <span class="ml-2" style="font-weight:100;font-size: 12px;color: black;">{{(visitList!=null && visitList!=undefined)?('(Total visits: '+visitList.length+')'):''}}</span></td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="visitdetailsdialog.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>

    </mdb-card-header>
    <mdb-card-body class="p-0">
        <div mdbCollapse [isCollapsed]="false" #visitdetailsdialog="bs-collapse">
            <table mdbTable id="visitdetailstable" hover="true" class="z-depth-1 p-0">
                <thead class="tableheadercolor">
                    <tr class="headertexts">
                        <th *ngFor="let head of visitheadElements; let i = index" aria-controls="visitdetailstable"
                            [mdbTableSort]="visitList" [sortBy]="visitheadElementsSort[i]" scope="col" style="white-space: nowrap;">{{head}}
                            <mdb-icon fas icon="sort"></mdb-icon>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    <tr mdbTableCol *ngFor="let el of visitList; let i = index" [routerLink]=""
                        (click)="enablevisit(el)"  routerLinkActive="active"
                        class="doe-table-body" [ngClass]="{'cyan lighten-5 font-weight-bold':(el.selected==true)}">
                        
                        <td>{{el.visitId}}</td>
                        <td>{{el.location}}
                        <app-changeAddress *ngIf="(el.visitstatus==='scheduled' || el.visitstatus==='Scheduled')  && passeddata.ServiceType==='Family Day Care'" (address)="onEditAddress($event,el);" ></app-changeAddress>

                        </td>
                        <td>{{el.visitstatus }}</td>
                        <td>{{el.startDt}}</td>
                        <td>
                            {{el.endDt}}

                        </td>
                        <td>
                            {{el.officer1}}

                        </td>
                        <td>
                            {{el.officer2}}

                        </td>

                        <td class="text-center">
                            <mdb-checkbox *ngIf="el.visitstatus!=='Completed'" [checked]="el.visitNotDone==='true'" (change)="updateVisitNotDone(el)">
                                            
                             </mdb-checkbox>

                        </td>

                        

                       

                    </tr>
                </tbody>

            </table>

        </div>
    </mdb-card-body>
</mdb-card>-->

    <!--<mdb-card id="visitsection" class="light-blue lighten-4" [ngClass]="{'d-none': (visitselected==false || summaryreport==true) }" *ngFor="let visit of getselectedvisit()"> -->
    <fieldset class="scheduler-border" [ngClass]="{'d-none': (visitselected==false),'disabled': (passeddata.CaseStatus==='Immediate Risk to Children' ) }"
        *ngFor="let visit of getselectedvisit()">
        <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-edit"
                style="color:#041e42;padding-right: 6px;"></i>Visit INA List  <span class="ml-2"
                style="font-weight:100;font-size: 12px;color: black;">{{visit.visitId}}</span> </legend>

        <!--<mdb-card id="visitsection" class="cyan lighten-5" [ngClass]="{'d-none': (visitselected==false) }" *ngFor="let visit of getselectedvisit()">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Details {{visit.visitId}} - {{visit.visitstatus}} </td>
                    <td class="text-right p-0">
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="visitdetails.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table> 

    </mdb-card-header>
    <mdb-card-body class="px-3 py-3">
        <div mdbCollapse [isCollapsed]="false" #visitdetails="bs-collapse">-->
            <table mdbTable class="p-0 mt-0 ml-0 table-fixed">
                <tbody class="p-0">
                    <tr class="p-0">
                        <td class="noborder">
                            <span class="ml-2" *ngIf="getOutstandingINAs(visit.visitId)>0"  
                            style="font-weight:100;font-size: 12px;color: red;">(Total outstanding INAs with no Area of Breach outcome selected: {{getOutstandingINAs(visit.visitId)}})</span>
                          
                          </td>
                          
                        <td
                            class="mt-0 reminingText pr-5 text-right noborder" 
                            *ngIf="accessList.indexOf('EditCompletedEvidences')>-1 && visit.visitstatus==='Completed' && (passeddata.CaseStatus==='Peer Review Modifications'|| passeddata.CaseStatus==='Recommendations'|| passeddata.CaseStatus==='Decision Pending')">
                               <a><mdb-icon fas icon="edit" size="2x" class="text-danger" title="edit evidence" (click)="setVisitEditable(visit)"></mdb-icon></a>
 
                                <a class="ml-3"><mdb-icon fas icon="check-circle" size="2x" title="Show Visit Report" class="text-success" (click)="backtoReport(visit)"></mdb-icon></a>
 

                        </td>
                    </tr>
                </tbody>
            </table>   
        <mdb-card class="mb-3 mt-0" *ngIf="accessList.indexOf('editVisit')>-1"
            [ngClass]="{'d-none': ((visit.visitstatus=='Completed' && (visit.editable===undefined || visit.editable!==true)) || visit.visitstatus=='Cancelled')}">
            <mdb-card-header class="p-0 cardheadercolor">
                <table mdbTable class=" table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="cardheader py-1 font-weight-bold ">Law and Regulations</td>
                            <td class="p-0" mdbDropdown>

                                <!-- <a class="trigger dropdown-toggle waves-light headertexts p-0 ml-1"  mdbTooltip="Attach Regulations" placement="top"  mdbDropdownToggle ><mdb-icon fas icon="plus-circle" class="text-primary" ></mdb-icon> </a> -->
                                <button mdbBtn
                                    class="trigger dropdown-toggle waves-light  addinacolor rounded font-weight-bold  mt-0 pt-1 pb-1 pl-3 pr-3 text-white "
                                    size="sm" placement="top" mdbDropdownToggle size="sm"
                                    *ngIf="accessList.indexOf('editTempINA')>-1 && visit.visitstatus!=='Completed' && (passeddata.CaseStatus==='Peer Review Modifications' || passeddata.CaseStatus==='Evidence Collection and Analysis')"
                                    (click)="setAddINA();"> <mdb-icon fas icon="plus-square" size="md" class="mr-1"></mdb-icon>
                                    Add INA
                                </button>
                                <div class="dropdown-menu dropdown-primary colselection force-scroll">

                                    <div *ngFor="let addina of getAdditionalRegs(); let i = index" class="hovercolor">
                                        <!--
                                               <input type="radio" id="{{addina.regId}}" name="addina" (value)="addina.regId" (change)="onSelectINA($event.target.value)"> -->
                                        <span title="{{addina.description}}"
                                            (click)="onSelectINA(addina.regId,addina.description,addina.lawregIndicator,visit);"
                                            class="p-2 showpointer">
                                            <mdb-icon fas icon="angle-right"></mdb-icon>{{addina.regId}}:
                                            {{(addina.description!==null && addina.description!==undefined)?((addina.description).substring(1,25)+'...'):''}}
                                        </span>
                                    </div>

                                </div>


                                <!-- <select class="browser-default custom-select"   name="selectType" >
                                    
                                    <option *ngFor="let addina of additionalRegs" [value]="status" title="{{addina.description}}">{{addina.regId}}: {{(addina.description!==null && addina.description!==undefined)?((addina.description).substring(1,25)+'...'):''}}
                                    </option>
                                </select> -->

                            </td>
                            <td class="text-right p-0">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="lawreg.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </mdb-card-header>
            <mdb-card-body class="p-0">
                <div mdbCollapse [isCollapsed]="false" #lawreg="bs-collapse">


                    <form class="p-0">
                        <!-- Subject -->
                        <table mdbTable id="lawregTable" class="z-depth-1 p-0 doe-table-body" hover="true">

                            <!--Table head-->
                            <thead class="lawReg-table-header tableheadercolor">
                                <tr class="">
                                    <th *ngFor="let head of headElementsLawReg; let i = index"
                                        aria-controls="lawregTable" [mdbTableSort]="lawRegList"
                                        [sortBy]="headElementsLawRegSort[i]" scope="col" style="white-space: nowrap;">
                                        {{head }} <mdb-icon fas icon="sort"></mdb-icon>
                                    </th>
                                    <th>

                                    </th>
                                </tr>

                            </thead>
                            <!--Table body-->
                            <tbody *ngFor="let item of getINAsforVisit(visit.visitId)">

                                <!-- <tr >
                <th scope="row">Law/Regulations</th>
                <th scope="row">Description</th>
                <th scope="row">Compliance</th>
                
          
               
            </tr>      -->

                                <tr (click)="closeDropdown()">
                                    <td class="font-weight-bold"> <span class=""
                                            [ngClass]="{'tempINA':((item.inaid!=null && item.inaid!==undefined && item.inaid.indexOf('temp')!==-1)?true:false)}">{{item.inaid}}  
                                        </span>
                                       <span *ngIf="((item.inaid!=null && item.inaid!==undefined && (item.inaid.indexOf('temp')!==-1)?true:false ) && (item.discard==null || item.discard==undefined || item.discard!=='true'))"><a><mdb-icon fas icon="pencil-alt" class="prefix text-danger" (click)="setSelectedINA(item);changeTempINA.show();"></mdb-icon></a> </span>
                                    </td>

                                    <td class="">
                                        <mdb-icon fas icon="flag" class="mr-3 red-text" *ngIf="item.priority=='high'">
                                        </mdb-icon>
                                        <mdb-icon fas icon="flag" class="mr-3 green-text"
                                            *ngIf="item.priority=='low'">
                                        </mdb-icon>
                                        <mdb-icon fas icon="flag" class="mr-3 blue-text" *ngIf="item.priority=='medium'">
                                        </mdb-icon>

                                    </td>

                                    <td class="" #dropdwn mdbDropdown (click)="addevent($event);">

                                        <a class="trigger dropdown-toggle waves-light text-black p-0 ml-1"  [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true')}" 
                                        mdbTooltip="Attach Regulations" placement="top" mdbDropdownToggle>
                                        <mdb-icon fas icon="plus-circle" class="text-primary"
                                            (click)="setLoadReg(item)"></mdb-icon>
                                    </a>
                                    <span class="font-weight-bold"> {{item.lawregIndicator}}:{{item.lawid}}:{{item.description}}</span>

                                    <div  class="dropdown-menu dropdown-primary p-0 colselection force-scroll" >
                                   
                                      <div class="text-right"> 
                                           <a class="text-primary  justify-content-right"  style="font-size:12px;margin-right: 2em;" (click)="closeDropdown();dropdwn.hide();">Close</a>
                                     </div>
                                    <div class="pl-4 ml-2 ">
                                         <form
                                        class="form-inline d-flex md-form form-sm active-white active-white-2 mb-0 ml-0 mt-0 p-0 text-right">
            
                                        
                                    <input style="font-size:12px;" class="form-control form-control-sm ml-0  mt-1 text-black p-0" type="search"
                                            placeholder="Search Law/Reg Id" mdbInput aria-label="Search"
                                            (search)="onserachingLaws($event.target.value)" (keyup)="onserachingLaws($event.target.value)">

                                            <i class="fas fa-search text-primary text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i>
                                      
                                          </form> 
                                        <!-- Default unchecked -->
                                        <div *ngFor="let regs of additionalRegsPop; let i = index" class="hovercolor">
                                            <mdb-checkbox [default]="false"  
                                                (change)="attachRegulations(regs,$event.checked,item)">
                                                {{regs.regId}}:{{regs.description}}</mdb-checkbox>

                                        </div> 
                                    </div>

                                    </div>
                                    <div *ngFor="let addreg of item.additionalRegsList; let i = index">
                                        <mdb-icon fas icon="check" class="ml-2 text-success"></mdb-icon>
                                       {{addreg.lawregIndicator}}:{{addreg.regId}}:{{addreg.description}} <mdb-icon fas icon="window-close"
                                            class="ml-1 text-danger cursor" (click)="selectedaddreg=addreg; selectedItem=item;deleteFunction='removeLink'; ConfirmationDeleteModal.show()">
                                        </mdb-icon>

                                    </div>



                                    </td>
                                    <td class="">
                                   <!--   <input type="date" #offenseDate name="offenseDate" [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true')}" [(ngModel)]="item.dateOfAllegedOffence" [value]="item.dateOfAllegedOffence | date:'yyyy-MM-dd'" (input)="onChangeDateofOffense($event.target.value,item,visit)" />
                                       -->
                                       <input mdbInput style="font-size:12px;color: black;" type="date"  [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true') || (visit.visitstatus==='Completed')}" 
                                        class="form-control" name="offenseDate" (change)="onChangeDateofOffense($event.target.value,item,visit)" 
                                       [value]="item.caldateOfAllegedOffence"> 

                                     
                                    </td>
                                    <td>

                                        <!-- <a class="trigger" (click)="askEvidence(item)"><span class="white rounded text-dark font-weight-bold border-color mb-2 ">Add Evidence </span> <i class="fas fa-plus ml-1"></i></a> -->
                                        <!--   <select class="browser-default custom-select " 
                                             *ngIf="role!='caseOfficer' " [(ngModel)]="item.complianceStatus" >
                                      
                                        <option *ngFor="let comp of statusCompliance" [ngValue]="comp" [selected]="comp===item.complianceStatus">{{comp}}
                                        </option>
                                    </select>  -->

                                        <!-- <mdb-select (ngModelChange)="getSelectedValue($event)" [(ngModel)]="selectedValue" [options]="statusCompliance"
                                    placeholder="Choose Breach Outcome"></mdb-select> -->

                                        <select style="font-size:12px;" class="browser-default custom-select"  [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true')}" 
                                            (change)="onChange($event.target.value,item,visit)" name="sel2">

                                            <option *ngFor="let comp of statusCompliance" [value]="comp"
                                                [selected]="comp!==null && item.complianceStatus!==null && comp.toLowerCase()==item.complianceStatus.toLowerCase()">{{comp}}
                                            </option>
                                        </select>


                                    </td>
                                   

                                    <td>
                                           
                                       
                                      
                                           <span *ngIf="item.discard==='true'" class="text-danger">discarded</span> 
                                       
                                           <span *ngIf="item.discard==null || item.discard==undefined || item.discard!=='true'" class="text-danger">
                                           <table class="p-0 noborder">
                                               <tbody class="noborder">
                                               <tr class="p-0 noborder">
                                                   <td class=" p-0 noborder">
                                            <button type="button" mdbBtn size="sm" *ngIf="item.showEvidenceFlag==false"
                                            id="{{'addevidence-'+item.inaid}}"
                                            class="relative waves-light addevidencecolor rounded  font-weight-bold  trigger px-1 text-white"
                                            (click)="askEvidence(item);evidence.toggle();settimernew(item);" mdbWavesEffect
                                            style="white-space: nowrap;">Add Evidence</button>
                                            </td>
                                            <td class=" p-0 mt-1 noborder">
                                                    <mdb-icon fas icon="window-close"
                                                    *ngIf="(item.inaid!==null && item.inaid.indexOf('temp_INA')!==-1 && accessList.indexOf('editTempINA')>-1 && (passeddata.CaseStatus==='Peer Review Modifications' || passeddata.CaseStatus==='Evidence Collection and Analysis' ))"
                                                    class="ml-1 text-danger cursor showpointer" (click)="selectedItem=item;selectedVisit=visit;deleteFunction='removeINA'; ConfirmationDeleteModal.show();">
                                                </mdb-icon>
                                            </td>
                                            </tr>
                                        </tbody>
                                            </table>
                                           </span> 
                                        
                                        <!--      <mdb-icon fas icon="save" class="mr-3 fa-lg"
                                            type="button" (click)="updateStatustoDB(item);">
                                        </mdb-icon>  

                                        <span class="row headertexts mt-2"> <a class="text-primary" *ngIf="item.showEvidenceFlag==false" (click)="askEvidence(item);evidence.toggle();" >
                                            <mdb-icon fas icon="plus-circle"></mdb-icon>Evidence</a></span> -->

                                    </td>


                                </tr>
                                <tr class="bottomrownoside" [ngClass]="{'d-none': item.evidenceFlag && item.discard!=='true'}" 
                                    (click)="closeDropdown()">
                                    <td colspan="7" class="p-0">
                                        <mdb-card class="mb-4">
                                            <mdb-card-header class="p-0">
                                                <table mdbTable class="mb-0">
                                                    <tbody>

                                                        <tr class="p-0" >
                                                            <td class=" py-1 px-3">
                                                                <span class="badge badge-success"
                                                                    style="color:black;font-size:12px;background-color: lightgreen;">Evidence:
                                                                    ({{item.receivedImageData.length}} attachments)
                                                                    <!-- <span class="d-inline-block text-truncate headertexts text-center" style="background-color: rgb(85, 184, 154); max-width: 600px;">
                                                                    Evidence:  ({{item.receivedImageData.length}} attachments) -->
                                                                 </span>
                                                               <!--  <span class="text-primary showpointer" [mdbTooltip]="getreviewcomments(item.reviewComments)"
                                                                 placement="right justify-content-right" *ngIf="item.reviewComments!=null && item.reviewComments!=undefined" >review comments</span>
                                                               -->
                                                             <!--  <a  class="text-primary ml-2"
                                                                    [mdbPopover]="getreviewcomments(item.reviewComments)"
                                                                    placement="right"
                                                                    mdbPopoverHeader="Review Comments">
                                                                  review comments
                                                                    </a> -->  
                                                              

                                                            </td>
                                                            <td class="text-right">
                                                                <a class="text-primary"
                                                                    (click)="evidence.toggle();settimernew(item)">more...</a>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </mdb-card-header>
                                            <mdb-card-body class="p-0">
                                                <div mdbCollapse [isCollapsed]="true" #evidence="bs-collapse">

                                                  <!--  <div class="p-4">
                                                       <app-evidenceChecklist id="{{'evidence'+item.inaid}}" [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true')}"
                                                            (questionairre)="updateQuestion($event,item)"
                                                            [answer]="(item && item.questionairre!==undefined && item.questionairre!==null && item.questionairre!=='')?item.questionairre:item.questionlist"
                                                            [CaseStatus]="this.passeddata.CaseStatus"
                                                            (message)="helpText($event)"></app-evidenceChecklist> 

                                                    </div>-->
                                                    <form class="p-0 mt-1">
                                                    <div class="form-group green-border-focus mb-1 p-4" >
                                                        <label for="questionarea"
                                                            class="headertexts">
                                                            <mdb-icon fas icon="pencil-alt">
                                                            </mdb-icon>Questions 
                                                        </label>   
                                                
                                                        <p-editor name="questionarea" (keydown)="validateLengthQuestion(item.questionairre)"
                                                        [(ngModel)]="item.questionairre"
                                                         #editor [style]="{'height':'320px'}">

                                                         <p-header>
                                                            <span class="ql-formats">
                                                              <select class="ql-size">
                                                                <option value="small">Small</option>
                                                                <option selected></option>
                                                                <option value="large">Large</option>
                                                                <option value="huge">Huge</option>
                                                              </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                              <button class="ql-bold" aria-label="Bold"></button>
                                                              <button class="ql-italic" aria-label="Italic"></button>
                                                              <button class="ql-underline" aria-label="Underline"></button>
                                                              <button class="ql-strike" aria-label="Strike"></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button class="ql-list" value="ordered"></button>
                                                                <button class="ql-list" value="bullet"></button>
                                                                <select title="Text Alignment" class="ql-align" >
                                                                  <option selected>Gauche</option>
                                                                  <option value="center" label="Center"></option>
                                                                  <option value="right" label="Right"></option>
                                                                  <option value="justify" label="Justify"></option>
                                                                </select>            
                                                              </span> 
                                                            </p-header>
                                                          
                                                      
                                                        </p-editor>          
                                                      <!-- <textarea maxlength="2500" 
                                                        class="form-control evidenceText border border-info"  
                                                        name="questionarea" rows="10" [(ngModel)]="item.questionairre">{{item.questionairre}}</textarea>
                                                        --> 
                                                        <table mdbTable class="p-0 mt-0 table-fixed">
                                                            <tbody class="p-0">
                                                                <tr class="p-0">
                                                                   <!-- <td>
                                                                        <span class="mt-0 reminingText py-0">maximum word count is 600</span>
                                                                     
                                                                      </td>-->
                                                                     
                                                                        <td>
                                                                            <span class="mt-0 reminingText py-0 text-right">words remaining... {{600-findLength(item.questionairre)}}</span>
                                                                         
                                                                          </td>
                                                                   
                                                                   
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                    </div>
                                                   
                                                    </form>
                                                   
                                                    <label for="revcomm" class="headertexts ml-4" *ngIf="item.reviewComments!==undefined && item.reviewComments!==null && item.reviewComments!==''">
                                                          Reviewer's Comments
                                                    </label>
                                                    <div #revcomm [innerHTML]="item.reviewComments" class="ml-4" *ngIf="item.reviewComments!==undefined && item.reviewComments!==null && item.reviewComments!==''"></div>

                                                    <form class="p-3 mt-1">
                                                        <table mdbTable class="p-0 table-fixed border border-0">
                                                            <tbody class="p-0">

                                                                <tr class="p-0" [ngClass]="{'disabled':(item.discard!==null && item.discard!==undefined && item.discard==='true')}">
                                                                    <td class="w-50">

                                                                        <div class="form-group green-border-focus mb-1">
                                                                            <label for="evidencetextarea"
                                                                                class="headertexts">
                                                                                <mdb-icon fas icon="pencil-alt">
                                                                                </mdb-icon>Evidence/Comments
                                                                            </label>
                                                                            <!--    <textarea id="{{'evidencedet-'+item.inaid}}" maxlength="2500" class="form-control evidenceText border border-info" id="evidencetextarea" rows="10" (keydown)="setremainingLength($event.target.value,item)" (change)="updateItemEvidence($event.target.value,item)" *ngIf="!(item.evidence==='' && item.complianceStatus==='Confirmed breach')" [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}">{{item.evidence}}</textarea>
                                                                            <textarea id="{{'evidencedet1-'+item.inaid}}" maxlength="2500" class="form-control evidenceText border border-info" id="evidencetextarea" rows="10" (keydown)="setremainingLength($event.target.value,item)" (change)="updateItemEvidence($event.target.value,item)" *ngIf="item.evidence==='' && item.complianceStatus==='Confirmed breach'?getTemplate(visit.location):false" [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}">{{evidenceTemplate}}</textarea>
                                                                        -->
                                                                      <!--  <editor
                                                                                apiKey="gre7sqvn58tjloy73e3jllkmiced4ujzfyhi62620g9i8rvg"
                                                                                [initialValue]="(item.evidence)?item.evidence:''"
                                                                                name="evidenceComments"
                                                                                [(ngModel)]="item.evidence"
                                                                                (onInit)="handleEditorInit(item,$event)"
                                                                                (onBlur)="selectedItem=item;ConfirmationModal.show()"
                                                                               
                                                                                (ngModelChange)="checkSizeLimit($event,item)"
                                                                                [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications') || (item.complianceStatus=='choose one' || item.complianceStatus=='N/A')}"
                                                                                [init]="{
                                                                              height: 200,
                                                                              branding:false,
                                                                              charLimit : 10,
                                                                              menubar: false,
                                                                              plugins: 'wordcount lists advlist',
                                                                              toolbar:
                                                                                'undo redo | formatselect | bold italic | \
                                                                                alignleft aligncenter alignright alignjustify | \
                                                                                outdent indent bullist numlist| help'
                                                                            }">
                                                                            </editor> -->


                                                                            <p-editor name="evidencearea" (keydown)="validateLengthEvidence(item.evidence)"
                                                                            [(ngModel)]="item.evidence"
                                                                             #editor [style]="{'height':'320px'}">
                                                                             <p-header>
                                                                                <span class="ql-formats">
                                                                                  <select class="ql-size">
                                                                                    <option value="small">Small</option>
                                                                                    <option selected></option>
                                                                                    <option value="large">Large</option>
                                                                                    <option value="huge">Huge</option>
                                                                                  </select>
                                                                                </span>
                                                                                <span class="ql-formats">
                                                                                  <button class="ql-bold" aria-label="Bold"></button>
                                                                                  <button class="ql-italic" aria-label="Italic"></button>
                                                                                  <button class="ql-underline" aria-label="Underline"></button>
                                                                                  <button class="ql-strike" aria-label="Strike"></button>
                                                                                </span>
                                                                                <span class="ql-formats">
                                                                                    <button class="ql-list" value="ordered"></button>
                                                                                    <button class="ql-list" value="bullet"></button>
                                                                                    <select title="Text Alignment" class="ql-align" >
                                                                                      <option selected>Gauche</option>
                                                                                      <option value="center" label="Center"></option>
                                                                                      <option value="right" label="Right"></option>
                                                                                      <option value="justify" label="Justify"></option>
                                                                                    </select>            
                                                                                  </span> 
                                                                                </p-header>
                                                                          
                                                                            </p-editor> 


                                                                        </div>
                                                                        <table mdbTable class="p-0 mt-0 table-fixed">
                                                                            <tbody class="p-0">
                                                                                <tr class="p-0">
                                                                                <!--   <td>
                                                                                        <span class="mt-0 reminingText py-0">maximum word count is 2500</span>
                                                                                     
                                                                                      </td> --> 
                                                                                      <td>
                                                                                        <span class="mt-0 reminingText py-0 text-right">words remaining... {{2500-findLength(item.evidence)}}</span>
                                                                                     
                                                                                      </td>
                                                                                   
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </td>



                                                                    <td class="pl-1">
                                                                        <div class="custom-file  w-50  ">

                                                                            <div class="input-group p-0">

                                                                                <div class="custom-file mt-5">
                                                                                    <input type="file"
                                                                                        class="custom-file-input "
                                                                                        accept=".jpg,.pdf"
                                                                                        id="{{'inputGroupFile01'+item.inaid}}"
                                                                                        multiple
                                                                                        (change)="onFileChanged($event,item)">
                                                                                    <label class="custom-file-label"
                                                                                        for="{{'inputGroupFile01'+item.inaid}}">{{item.filename}}</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <button type="button" mdbBtn size="sm"
                                                                            [ngClass]="{'disabled':(item!==null && item.filename==='choose one')}"
                                                                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mb-0"
                                                                            mdbWavesEffect (click)="onUpload(item);">
                                                                            <div *ngIf="uploading">
                                                                                <div class="spinner-border text-primary"
                                                                                    role="status">
                                                                                </div>
                                                                            </div>
                                                                            <span *ngIf="!uploading"> Upload </span>
                                                                        </button>


                                                                        <div>
                                                                            <label class="mt-4">( file can be of
                                                                                type
                                                                                .jpg,.pdf
                                                                                only and cannot be more than 20
                                                                                MB)</label>
                                                                        </div>
                                                                        <div
                                                                            *ngFor="let imagedata of item.receivedImageData">
                                                                            <mdb-icon far icon="file-pdf"
                                                                                class="text-danger"
                                                                                *ngIf="imagedata.docType==='.pdf'">
                                                                            </mdb-icon>
                                                                            <mdb-icon far icon="file-image"
                                                                                class="text-danger"
                                                                                *ngIf="imagedata.docType==='.jpg'">
                                                                            </mdb-icon> <a
                                                                                (click)="loadDoc(imagedata.evidenceId,imagedata.docName);"
                                                                                class="text-primary ml-1">{{ imagedata.docName}}
                                                                            </a>
                                                                            <mdb-icon fas icon="window-close"
                                                                                class="ml-1 text-danger cursor"
                                                                                *ngIf="imagedata.docName!==''"
                                                                                (click)="deleteimagedata=imagedata;deleteFunction='deletePdf';ConfirmationDeleteModal.show()">
                                                                            </mdb-icon>

                                                                            <!--  <a href="item.convertedImage" >{{ item.receivedImageData['docName'] | json}} </a> -->

                                                                            <!-- <ul class="list-group list-group-flush p-0 "
                                                                                *ngFor="let name of filenames; let i = index">
                                                                                <li class="list-group-item">
                                                                                    {{name}}
                                                                                </li>
                                                                            </ul> -->

                                                                        </div>

                                                                        <!-- <div class="md-form">
                                                                            <button type="button" mdbBtn size="sm"
                                                                                class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                                                                                mdbWavesEffect (click)=saveEvidences(item,passeddata.SENumber)>Save</button>
                                                                        </div> -->
                                                                    </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </form>
                                                    <!-- Default form contact -->

                                                    <div
                                                    class="mt-0 reminingText p-0 text-right">
                                                    <button type="button" mdbBtn
                                                        size="sm"
                                                        class="relative waves-light backcolor rounded text-white font-weight-bold trigger ml-3 mt-0"
                                                        [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}"
                                                        mdbWavesEffect
                                                        (click)="selectedItem=item;ConfirmationModal.show()">Save</button>

                                                </div>
                                                </div>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </form>
                </div>
            </mdb-card-body>
        </mdb-card>



        <div mdbModal #imageModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="imageModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close pull-right" aria-label="Close" (click)="imageModal.hide()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <img [src]="modalImagepath">

                        <pdf-viewer [src]="modalImagepath" [render-text]="true" style="display: block;"></pdf-viewer>

                    </div>

                </div>
            </div>
        </div>


       <mdb-card class="mb-3 mt-2" [ngClass]="{'d-none': visit.visitstatus!='Completed' || (visit.visitstatus=='Completed' && visit.editable==true)}">
            <mdb-card-header class="p-0 cardheadercolor">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="cardheader py-1 font-weight-bold ">Visit Report</td>
                            <td class="text-right p-0">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="summary.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </mdb-card-header>
            <mdb-card-body class="p-0">
                <div mdbCollapse [isCollapsed]="false" #summary="bs-collapse">


                    <form class="p-0">
                        <!-- Subject -->
                        <table mdbTable mdbTableScroll scrollX="true" id="summaryTable" bordered="true"
                            class=" .justify-content-start .table-sm .table-fixed .table-hover w-auto doe-table-body">

                            <!--Table head-->
                            <thead class="lawReg-table-header tableheadercolor">
                                <tr>
                                    <th *ngFor="let head of headElementsSummary; let i = index" class="headerTransform"
                                        aria-controls="summaryTable" [mdbTableSort]="lawRegList"
                                        [sortBy]="headElementsSummarySort[i]" scope="col" style="white-space: nowrap;">
                                        {{head}} <mdb-icon fas icon="sort"></mdb-icon>
                                    </th>
                                </tr>

                            </thead>
                            <!--Table body-->
                            <tbody *ngFor="let item of getINAsforVisitNotDiscarded(visit.visitId)">

                                <tr>

                                    <td style="width:15%">{{item.inaid}} </td>


                                    <td>
                                        <mdb-icon fas icon="flag" class="mr-3 red-text" *ngIf="item.priority=='high'">
                                        </mdb-icon>
                                        <mdb-icon fas icon="flag" class="mr-3 green-text"
                                            *ngIf="item.priority=='low'">
                                        </mdb-icon>
                                        <mdb-icon fas icon="flag" class="mr-3 blue-text" *ngIf="item.priority=='medium'">
                                        </mdb-icon>

                                    </td>

                                    <td><span class="font-weight-bold">{{item.lawregIndicator}}:{{item.lawid}}:{{item.description}}</span>

                                        <div *ngFor="let addreg of item.additionalRegsList; let i = index">
                                            <mdb-icon fas icon="chevron-circle-right" class="ml-2"></mdb-icon>
                                             {{addreg.lawregIndicator}}:{{addreg.regId}}:{{addreg.description}}

                                        </div>

                                    </td>
                                    <td style="width:5%">
                                        <div>{{item.complianceStatus}}</div>


                                    </td>

                                    <td style="width:60%">
                                        <div [innerHTML]="item.evidence">
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngFor="let imagedata of item.receivedImageData">
                                            <a (click)="loadDoc(imagedata.evidenceId,imagedata.docName);"
                                                class="text-primary">{{ imagedata.docName}} </a>


                                        </div>
                                    </td>
                                    <td style="width:10%">
                                        <div [innerHTML]="item.reviewComments"></div>

                                        <div *ngIf="accessList.indexOf('reviewComments')>-1 || accessList.indexOf('MgrReviewComments')>-1"><button type="button"
                                                mdbBtn size="sm"
                                                class="relative waves-light commentscolor rounded text-white font-weight-bold ml-3 mt-0 pt-2 pb-2 pl-2 pr-2"
                                                [ngClass]="{'disabled': (this.passeddata.CaseStatus!=='Peer Review Sent' && role==='Reviewer') || (this.passeddata.CaseStatus!=='Decision Pending' && role==='READ_ONLY')  }"
                                                mdbWavesEffect
                                                (click)="setCurrentEvidence(item);RevCommentsModal.show()">Add Comments</button>
                                        </div>
                                    </td>

                                </tr>



                            </tbody>
                        </table>
                    </form>
                </div>
            </mdb-card-body>
        </mdb-card>


        <mdb-card class="mb-4 mt-4" *ngIf="validateAllVisitsCompleted(visit)?true:false">
            <mdb-card-header class="p-0 cardheadercolor">
                <table mdbTable class="table-hover table-condensed mb-0">
                    <tbody class="p-0">
                        <tr class="p-0">
                            <td class="cardheader py-1 font-weight-bold ">Visit Outcome</td>
                            <td class="text-right p-0">
                                <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="finalize.toggle()">
                                </mdb-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </mdb-card-header>
            <mdb-card-body>
                <div mdbCollapse [isCollapsed]="false" #finalize="bs-collapse">
                    <!-- Form -->
                    <form class="">
                        <div class="row d-flex px-3">
                            <!-- <div class="col-md-3 headertexts">
                                <span class="font-weight-bolder">VISIT NUMBER: </span> {{visit.visitId}}
                            </div> -->
                            <div class="col-md-4 ">
                                <span style="font-size:12px;color: black;" class="font-weight-bolder">Visit Status:
                                    {{visit.visitstatus}}</span>
                            </div>
                            <div class="col-md-4 ">
                                <span style="font-size:12px;color: black;" class="font-weight-bolder">Visit Start Date:
                                    {{visit.startDt}}</span>
                            </div>
                            <div class="col-md-4 " *ngIf="accessList.indexOf('editVisitOutcome')>-1"
                                [ngClass]="{'disabled': (this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && role!=='ADMIN')}">
                                <!--<mdb-icon fas icon="calendar-alt" size="sm" class="prefix"></mdb-icon> -->
                                <span style="font-size:12px;color: black;" class="font-weight-bolder"> <label
                                        for="endDt">Visit End Date:</label>

                                    <input mdbInput style="font-size:12px;color: black;" type="date" id="endDt"
                                        class="form-control" name="endDt" (change)="validateDate(visit)"
                                        [(ngModel)]="visit.calEndDate" [value]="visit.calEndDate">
                                </span>
                            </div>

                            <div class="col-md-4 " *ngIf="accessList.indexOf('editVisitOutcome')==-1">
                                <span class="font-weight-bolder">VISIT END DATE: </span> {{visit.endDt }}
                            </div>

                        </div>



                        <!--    <div class="row d-flex mt-4 px-3 ml-1" *ngIf="accessList.indexOf('editVisitOutcome')>-1">
                          
                           <div class="md-form">-->
                        <!--<mdb-icon fas icon="calendar-alt" size="sm" class="prefix"></mdb-icon> -->

                        <!--   <input mdbInput
                                    type="date" id="endDt" class="form-control"  name="endDt" (change)="validateDate(visit)" [(ngModel)]="visit.calEndDate" [value]="visit.calEndDate">

                          <input mdbInput
                                    type="date" id="endDt" class="form-control"  name="endDt" (change)="validateDate(visit)" [(ngModel)]="visit.calEndDate" [value]="visit.calEndDate" [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}">

                                
                                    <label for="endDt" class="">Visit End Date:</label>
                            </div> 
                              
                         

        
                        </div>


                        <div class="row d-flex mt-4 px-3" *ngIf="accessList.indexOf('editVisitOutcome')==-1">
                            <div class="col-md-3 headertexts">
                                <span class="font-weight-bolder">VISIT END DATE: </span> {{visit.endDt }}
                            </div>
                                             
                         
        
                        </div>-->


                        <div class="row" style="padding-top: 10px;">
                            <div class="col-md-4" *ngIf="accessList.indexOf('editVisitOutcome')>-1">
                                <label class="headertexts">
                                    <mdb-icon fas icon="pencil-alt"></mdb-icon>
                                    Officer's Comments
                                </label>

                                <!-- <textarea style="font-size: 12px;" class="form-control"  rows="3" maxlength="100" name="officerComments"  [(ngModel)]="visit.officerComments" 
                                    [value]="visit.officerComments" ></textarea>-->


                                <textarea style="font-size: 12px;" class="form-control" rows="3" maxlength="1500"
                                    name="officerComments" [(ngModel)]="visit.officerComments"
                                    [value]="visit.officerComments"
                                    [ngClass]="{'disabled':(this.passeddata.CaseStatus!=='Evidence Collection and Analysis' && this.passeddata.CaseStatus!=='Peer Review Modifications')}"></textarea>
                                <span
                                    class="mt-0 reminingText py-0">{{(visit.officerComments!==null && visit.officerComments!==undefined)?(1500-visit.officerComments.length):1500}}
                                    characters left...</span>



                            </div>


                            <div class="col-md-4" *ngIf="accessList.indexOf('editVisitOutcome')==-1">

                                <label class="headertexts">
                                    Officers's Comments </label>

                                <div class="overflow:auto h-25 w-100 doe-table-body">
                                    {{visit.officerComments}}
                                </div>
                                <!--     <textarea class="form-control"  rows="3" maxlength="100"  name="OfficersComments" disabled>{{visit.officerComments}}</textarea>
                           -->
                            </div>

                            <div class="col-md-4" *ngIf="accessList.indexOf('reviewComments')>-1">

                                <label class="headertexts">
                                    <mdb-icon fas icon="pencil-alt"></mdb-icon>
                                    Reviewer's Comments 
                                </label>

                                <!--  <textarea style="font-size: 12px;" class="form-control"  rows="3" maxlength="100" name="reviewersComments"  [(ngModel)]="visit.reviewerComments" -->

                                <textarea style="font-size: 12px;" class="form-control" rows="3" maxlength="1500"
                                    name="reviewersComments" [(ngModel)]="visit.reviewerComments"
                                    [ngClass]="{'disabled': passeddata.CaseStatus!=='Peer Review Sent'}"
                                    [value]="visit.reviewerComments"></textarea>
                                <span
                                    class="mt-0 reminingText py-0">{{(visit.reviewerComments!==null && visit.reviewerComments!==undefined)?(1500-visit.reviewerComments.length):1500}}
                                    characters left...</span>




                            </div>
                            <div class="col-md-4" *ngIf="accessList.indexOf('reviewComments')==-1">

                                <label class="headertexts">
                                    Reviewer's Comments </label>
                                <div class="overflow:auto h-25 w-100 doe-table-body">
                                    {{visit.reviewerComments}}
                                </div>

                            </div>

                            <div class="col-md-4" *ngIf="accessList.indexOf('MgrVisitComments')>-1">
                                <label class="headertexts">
                                    <mdb-icon fas icon="pencil-alt"></mdb-icon>
                                    Approver's Comments
                                </label>

                                <!--   <textarea style="font-size: 12px;" class="form-control"  rows="3" maxlength="100" name="approversComments"  [(ngModel)]="visit.approverComments" -->

                                <textarea style="font-size: 12px;" class="form-control" rows="3" maxlength="1500"
                                    name="approversComments" [(ngModel)]="visit.approverComments"
                                    [ngClass]="{'disabled': passeddata.CaseStatus!=='Recommendations'}"
                                    [value]="visit.approverComments"></textarea>
                                <span
                                    class="mt-0 reminingText py-0">{{(visit.approverComments!==null && visit.approverComments!==undefined)?(1500-visit.approverComments.length):1500}}
                                    characters left...</span>




                            </div>
                            <div class="col-md-4" *ngIf="accessList.indexOf('MgrVisitComments')==-1">

                                <label class="headertexts">
                                    Approver's Comments </label>
                                <div class="overflow:auto h-25 w-100 doe-table-body">
                                    {{visit.approverComments}}
                                </div>

                            </div>

                        </div>


                        <div class="text-right">
                            <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5" size="sm"
                                type="submit" (click)="saveVisit(visit);"
                                *ngIf="accessList.indexOf('editVisitOutcome')>-1 || accessList.indexOf('MgrVisitComments')>-1 ||accessList.indexOf('reviewComments')>-1">Save
                            </button>
                        </div>
                    </form>
                    <!-- Form -->
                </div>
            </mdb-card-body>
        </mdb-card>
        <!--</div>
    </mdb-card-body>
</mdb-card>-->
    </fieldset>


    <div mdbModal #RevCommentsModal="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog"
        aria-labelledby="RevCommentsModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="RevCommentsModal.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <!-- <h2 class="modal-title w-100" id="myModalLabel">Send Non Compliance Information</h2> -->
                    <p class="text-center w-responsive mx-auto mb-3 cardheader">Add Review Comments</p>
                </div>
                <div class="modal-body">


                    <div class="text-danger">
                        {{errormessage}}
                    </div>


                    <div class="form-group green-border-focus mb-4 mt-3">
                        <label for="revCommentstextarea" class="headertexts"> Review Comments </label>
                        <textarea class="form-control doe-table-body" name="revCommentstextarea" rows="3" maxlength="1000"
                            [(ngModel)]="reviewComments"></textarea>
                        <span
                            class="mt-0 reminingText py-0">{{(reviewComments!==null && reviewComments!==undefined)?(1000-reviewComments.length):1000}}
                            characters left...</span>


                    </div>

                    <div class="text-right">

                        <button type="button" mdbBtn size="sm"
                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger"
                            mdbWavesEffect (click)="addINAReviewComments(reviewComments);">Save</button>
                    </div>

                </div>

            </div>
        </div>
    </div>




    <app-caseOutcomeOfficer *ngIf="getCaseStatus()?true:false" [summaryreport]="summaryreport"
        (summaryReport)="onSummaryReport($event)" [accessList]="accessList" [recordNumber]="recordNumber"
        [visitList]="visitList" [completeVisitsList]=completeVisitsList [lawRegList]="lawRegList"
        (message)="helpText($event)"></app-caseOutcomeOfficer>
    <!--<app-caseOutcomeApprover  *ngIf="getCaseStatus()?true:false" [summaryreport]="summaryreport" [recordNumber]="recordNumber" [accessList]="accessList" [visitList]="visitList"  [completeVisitsList]=completeVisitsList [lawRegList]="lawRegList" (summaryReport)="onSummaryReport($event)" (message)="helpText($event)"></app-caseOutcomeApprover> 
-->
    <app-casesummary id="summaryReportDiv" *ngIf="summaryreport==true"  [lawRegList]="getNonDiscardedList()"
        [accessList]="accessList" [visitList]="visitList"></app-casesummary>



 <div mdbModal #changeTempINA="mdbModal" class="modal fade doe-table-body" tabindex="-1" role="dialog" aria-labelledby="changeTempINALabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="changeTempINA.hide()">
                <span aria-hidden="true" class="text-black">×</span>
            </button>
         
        </div>
        <div class="modal-body p-0">
                     
            
            <form class="doe-table-body p-0">
            <table mdbTable class="p-0 table-fixed" > 
                    <tbody class="p-0">
                    <tr class="p-0">
                    <td class="mt-0 w-50">
                        <div class="form-group">
                            <input mdbInput type="text" class="form-control"  name="inaId" [(ngModel)]="nqaInaId">
                            <label for="inaId">INA ID</label>
                          </div>
                        </td>
                        <td class="mt-0">
                            <button type="button" mdbBtn size="sm"
                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger "
                            mdbWavesEffect (click)="updateTempINA();changeTempINA.hide();">Update</button>
                            <button type="button" mdbBtn size="sm"
                            class="relative waves-light backcolor rounded text-white font-weight-bold trigger "
                            mdbWavesEffect (click)="changeTempINA.hide();">Cancel</button>
                        </td>
                        </tr>
                        </tbody>

               </table>
               
                <!-- Grid row -->
              
              </form>
           
        
        
        </div>

    </div>
</div>
</div>



<div mdbModal #ConfirmationModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">Are you sure? Do you want to save Evidences?</p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <mdb-icon fas icon="save" size="4x" class="animated rotateIn"></mdb-icon>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a mdbBtn color="info" outline="true" class="waves-effect" (click)="autoSave(selectedItem);ConfirmationModal.hide();"
          mdbWavesEffect>Yes</a>
        <a type="button" mdbBtn color="info" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="ConfirmationModal.hide()">No</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


<div mdbModal #ConfirmationDeleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">Are you sure? Do you want to delete?</p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <mdb-icon fas icon="times" size="4x" class="animated rotateIn"></mdb-icon>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a mdbBtn color="info" outline="true" class="waves-effect" (click)="deleteFunctionality();ConfirmationDeleteModal.hide();"
          mdbWavesEffect>Yes</a>
        <a type="button" mdbBtn color="info" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="ConfirmationDeleteModal.hide()">No</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>