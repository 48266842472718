import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { AgmCoreModule } from '@agm/core';
import {MultiSelectModule} from 'primeng/multiselect';
import { CalendarModule,  } from 'angular-calendar';
import { SharedModule } from '../shared/shared.module';

//import { FooterComponent } from '../main-layout/footer/footer.component';
import { BasicTableComponent } from './visits/visit1/visit1.component';
import { ModalsComponent } from './modals/modals.component';
import { Map1Component } from './maps/map1/map1.component';
import { StatsCardComponent } from './dashboards/common/stats-card/stats-card.component';
import { StatsCard2Component } from './dashboards/common/stats-card2/stats-card2.component';
import { Dashboard1Component } from './dashboards/dashboard1/dashboard1.component';
import { Profile1Component } from './details/detail1/centerbased/centerbased.component';
import {FDCDetailsComponent} from './details/detail1/fdc/fdc.component';
import { LandingComponent } from './landingpage/landingpage1/landingpage1.component';
import { FooterComponent } from '../main-layout/footer/footer.component';
import { CaseComponent } from './details/detail1/case/case.component';
import { CaseSummaryComponent } from './details/detail1/case/CaseSummary/caseSummary.component';
import { AddRegulationsComponent } from './Admin/AddRegulations/addRegulations.component';
import { ManageEmailComponent } from './Admin/manageEmailTemplates/manageEmail.component';
import { ServiceInputDetailsComponent } from './details/detail1/case/serviceDetailsInput/serviceDetailsInput.component.';
import { MapComponent } from './details/detail1/case/map/map.component.';
import { CaseOutcomeOfficerComponent } from './details/detail1/case/caseOutcomeOfficer/caseOutcomeOfficer.component.';
import { CaseOutcomeApproverComponent } from './details/detail1/case/caseOutcomeApprover/caseOutcomeApprover.component';
import { OAuth2ComponentComponent } from '../main-layout/oauth2-component/oauth2-component.component';
import { EvidenceQuestionsComponent } from './details/detail1/case/EvidenceQuestions/evidenceQuestions.component';
import { ChooseOfficerComponent } from './details/detail1/case/chooseOfficer/chooseOfficer.component';
import { ChangeAddressComponent } from './details/detail1/case/changeAddress/changeAddress.component';
import { EvidenceChecklistComponent } from './details/detail1/case/evidenceChecklist/evidenceChecklist.component';
import { EvidenceCommentsComponent } from './details/detail1/case/evidenceComments/evidenceComments.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPrintModule } from 'ngx-print';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SummaryGraphComponent } from './details/detail1/case/summaryGraph/summaryGraph.component';
import { ComplianceNoticeComponent } from './emailTemplates/complianceNotice/complianceNotice.component';
import { EmergencyActionComponent } from './emailTemplates/emergencyAction/emergencyAction.component';
import { ChooseRecievedResponseComponent } from './details/detail1/case/chooseRecievedResponse/chooseRecievedResponse.component';
//import { EditorModule } from '@tinymce/tinymce-angular';
import { DocumentsListComponent } from './details/detail1/case/documentsList/documentsList.component';
import { ChangeCaseStatusComponent } from './details/detail1/case/changeCaseStatus/changeCaseStatus.component';
import { UpdateBreachActionsComponent } from './details/detail1/case/updateBreachActions/updateBreachActions.component';
import { ManageLOVComponent } from './Admin/manageLOV/manageLOV.component';
import { ManageUserDetailsComponent } from './Admin/manageUserDetails/manageUserDetails.component';
import { EditorModule } from 'primeng/editor';

//import { HelpComponent } from './help/help.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MultiSelectModule,    
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
     NgxPaginationModule,
     PdfViewerModule,
    NgxPrintModule,
    EditorModule,
    /*AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
     // apiKey: 'gme-nswdepartmentofeducation',
      clientId:'gme-nswdepartmentofeducation'
     // libraries:['geometry']
    }),*/
    CalendarModule.forRoot()
    
  ],
  declarations: [
    FooterComponent,
    BasicTableComponent,
    ModalsComponent,
    MapComponent,
    Map1Component,
    StatsCardComponent,
    StatsCard2Component,
    Dashboard1Component,
    Profile1Component,
    ChangeCaseStatusComponent,
    CaseComponent,
    ChangeAddressComponent,
    CaseSummaryComponent,
    ComplianceNoticeComponent,
    EmergencyActionComponent,
    ServiceInputDetailsComponent,
    CaseOutcomeOfficerComponent,
    ChooseOfficerComponent,
    DocumentsListComponent,
    EvidenceQuestionsComponent,
    EvidenceCommentsComponent,
    CaseOutcomeApproverComponent,
    ChooseRecievedResponseComponent,
    AddRegulationsComponent,
    ManageUserDetailsComponent,
    ManageLOVComponent,
    ManageEmailComponent,
    FDCDetailsComponent,
    SummaryGraphComponent,
    LandingComponent,
    EvidenceChecklistComponent,
    OAuth2ComponentComponent,
    UpdateBreachActionsComponent,    
    
    
   // HelpComponent,

  ],
  exports: [
    FooterComponent,
    BasicTableComponent,
    ModalsComponent,
    Map1Component,
    StatsCardComponent,
    StatsCard2Component,    
    Dashboard1Component,
    LandingComponent
    
    
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }
