import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';


const getUrl = '/case/all';
const uploadUrl = '/case/uploadCase';
const postUrl = '/case/updateCase';
const getUrlAdmin = '/case/all/Admin';
const getCase = '/case';
@Injectable({
  providedIn: 'root'
})
export class CaseService {

  baseUrl : any;
  constructor( private http: HttpClient,private config: AppConfigService) {
   this.baseUrl = this.config.getConfig().baseUrl;
    
   }
  
  getVisits(user:string,isAdmin : boolean) {
    //alert("here in case service"+this.baseUrl);
    if(isAdmin)
    {
    
      return this.http.get(this.baseUrl+getUrlAdmin);
    }
    else{
      return this.http.get(this.baseUrl+getUrl+"/"+user);
    }
   
    
  }

  getCase(caseId:string) : Observable<any> 
  {
    return this.http.get(this.baseUrl+getCase+"/"+caseId);
  }

  uploadCase(uploadData: any): Observable<any> {

    return this.http.post<any>(this.baseUrl+uploadUrl , uploadData)
      .pipe(
        catchError(this.handleError('uploadEvidenceDoc', uploadData))
      );
  }


  updateCase(passeddata:any): Observable<any> {
    
    var myjson = {caseId: passeddata.CaseId, status: passeddata.CaseStatus,completedOn: passeddata.CompletedOn,
      approvalStatus: passeddata.ApprovalStatus,officerComments: passeddata.OfficerComments,reviewerComments:passeddata.ReviewerComments,
      approverComments: passeddata.ApproverComments,reviewer: passeddata.Reviewer , officer: passeddata.Officer,
    approver: passeddata.Approver,managerReviewer: passeddata.ManagerReviewer, responseType:passeddata.responseType, responseDate:passeddata.responseDate};
    
    return this.http.put<any>(this.baseUrl+postUrl,myjson)
      .pipe(
        catchError(this.handleError('updateCase', myjson))
      );
  }

 
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(JSON.stringify(error)); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
