
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view gradient-card-header blue darken-2 ">
                <h4 class="h4-responsive text-white">Regular map</h4>
            </div>
            <!--/Card image-->

           <!--Card content-->
        <!--  <mdb-card-body class="text-center">
                <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 300px" >
                    <agm-marker (mouseOver)="window.open()" (mouseOut)="window.close()" (click)="findAddressByCoordinates(marker.longitude,marker.latitude)" *ngFor="let marker of markers" [latitude]="marker.latitude" [longitude]="marker.longitude">
                        <agm-info-window #window>
                            <h6>Longitude: {{marker.longitude}}</h6>
                            <h6>Latitude: {{marker.latitude}}</h6>
                          </agm-info-window>

                    </agm-marker>


                  </agm-map>

            </mdb-card-body> -->  
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
 
   