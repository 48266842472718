<fieldset class="scheduler-border">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-briefcase"
            style="color:#041e42;padding-right: 6px;"></i>Users List</legend>
            <div class="mb-3 ">
              <table mdbTable id="usersTable" 
                      class="z-depth-1 doe-table-body border border-1 table-responsive" hover="true">
                   
                      <!--Table head-->
                      <thead class="lawReg-table-header tableheadercolor">
                          <tr class="">
                              <th *ngFor="let head of headElementsUsers; let i = index"
                                  aria-controls="usersTable" [mdbTableSort]="usersList"
                                  [sortBy]="headElementsUsersSort[i]" scope="col" style="white-space: nowrap;">
                                  {{head | titlecase}} <mdb-icon fas icon="sort"></mdb-icon>
                              
                              <div (click)="changeCaseStatus($event)">
                                  <input style="font-size:12px;" class="form-control form-control-sm text-black p-0 border border-dark" type="search"
                                  placeholder="" mdbInput id="input1" aria-label="Search"
                                  (search)="onFilter($event.target.value,head)" (keyup)="onFilter($event.target.value,head)">
                                <!--  <i class="fas fa-search text-white text-right p-0" style="font-size:12px;margin-right: 2em;" aria-hidden="true"></i> -->
                              </div>
                              </th>
                          </tr>
                          
                      </thead>
                      <!--Table body-->
                      <tbody>
          
          
                          <tr class="showpointer" *ngFor="let user of usersList | paginate: config" (click)="setselected(user);" [ngClass]="{'selectedcolor': (selectedObj.userId===user.userId)?true:false}">
                              <td class="doe-table-body">{{user.userFirstName}}</td>
                              <td class="doe-table-body">{{user.userLastName}}</td>
                              <td class="doe-table-body">{{user.status}}</td>
                              <td class="doe-table-body">{{user.intUserId}}</td>
                              <td class="doe-table-body">{{user.nqaUserId}}</td>
                              <td class="doe-table-body">{{user.roleName}}</td>
                              <td class="doe-table-body">{{user.region}}</td>
                              <td class="doe-table-body">{{user.email}}</td>
                              <td class="doe-table-body">{{user.title}}</td>
                              
               
                          </tr>
          
          
                      </tbody>
                  </table>
                  <div class="mt-2 text-center doe-table-body">
                      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> 
                  </div>
                
              </div>
          
                <div class=" text-right">
                  <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                  size="sm" type="submit" (click)="clickcreate=true;clickedit=false;">Create
               </button>
                             
                              <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                               size="sm" type="submit" (click)="validateEdit();">Edit
                            </button>
                           <!--<button mdbBtn class=" buttoncolor rounded text-white font-weight-bold px-5"
                            size="sm" type="submit" (click)="(selectedObj!==null && selectedObj!==undefined && selectedObj.userId!==undefined)?ConfirmationDeleteModal.show():validateEdit()">Delete
                         </button> --> 
                          </div>    
              
          </fieldset>

        
<fieldset class="scheduler-border" *ngIf="this.clickcreate===true">
  <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-plus"
          style="color:#041e42;padding-right: 6px;"></i>Create User</legend>

      <div >
          <!-- Form -->
          <form class="">
             
             <!-- <div class="col-md-4 py-4">
               

             <select class="browser-default custom-select" name="Hub">
                                  
                      <option selected>Choose Hub</option>
                      <option value="1">Hub1</option>
                      <option value="2">Hub2</option>
                      <option value="3">Hub3</option>
                  </select>
              </div>  -->
              <div class="form-row py-2">
                <div class="col">
                  <!-- Small input --><label class="headertexts" for="firstName">First Name</label>
                    <input class="form-control" type="text" id="firstName" name="firstName" placeholder="Enter First Name"  [(ngModel)]="createObj.userFirstName">
                  </div>
                  <div class="col">
                   <!-- Small input --><label class="headertexts" for="lastName">Last Name</label>
                   <input class="form-control" type="text" id="lastName" name="lastName" placeholder="Enter Last Name"  [(ngModel)]="createObj.userLastName">
                </div>
                 

                </div>
                <div class="form-row py-2">
                  <div class="col">
                    <!-- Small input --><label class="headertexts" for="intUserId">INT User Id</label>
                      <input class="form-control" type="text" id="intUserId" name="intUserId" placeholder="Enter INT UserId"  [(ngModel)]="createObj.intUserId">
                    </div>
                    <div class="col">
                     <!-- Small input --><label class="headertexts" for="nqaUserId">NQA User Id</label>
                     <input class="form-control" type="text" id="nqaUserId" name="nqaUserId" placeholder="Enter NQA UserId"  [(ngModel)]="createObj.nqaUserId">
                  </div>
                   
  
                  </div>
              <div class="form-row  py-2">
                <div class="col">
                  <label class="headertexts">Status</label>
                  <select class="browser-default custom-select" name="status" [(ngModel)]="createObj.status">
                                       
                           <option selected>Choose Status</option>
                           <option value="Active">Active</option>
                           <option value="Inactive">Inactive</option>
                          
                       </select>
                      </div>
                      <div class="col">
                        <label class="headertexts">Role</label>
                        <select class="browser-default custom-select" name="role" [(ngModel)]="createObj.roleId">
                                             
                                 <option selected>Choose Role</option>
                                 <option value="ADMIN">Admin</option>
                                 <option value="ACO">Officer</option>
                                 <option value="ROM">Manager</option>
                                 <option value="READ_ONLY">ReadOnly</option>

                                
                             </select>
                         </div>
               </div> 
            
           
               <div class="form-row py-2">
                <div class="col">
                  <!-- Small input --><label class="headertexts" for="email">Email</label>
                    <input class="form-control" type="text" id="email" name="email" placeholder="Enter emailId"  [(ngModel)]="createObj.email">
                  </div>
                  <div class="col">
                    <label class="headertexts">Region</label>
                    <input class="form-control" type="text" id="region" name="region" placeholder="Enter region"  [(ngModel)]="createObj.region">
             
                  </div>
                 

                </div>

                <div class="form-row py-2">
                  <div class="col-md-6">
                    <!-- Small input --><label class="headertexts" for="title">Title</label>
                      <input class="form-control" type="text" id="title" name="title" placeholder="Enter title"  [(ngModel)]="createObj.title">
                    </div>
                 
  
                  </div>

              
           
                 <div class=" text-right">

             
              <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
               size="sm" type="submit" (click)="onAddUser()">Save
            </button>
          </div>    

          </form>
          <!-- Form -->
      </div>
 </fieldset>
 <fieldset class="scheduler-border" *ngIf="this.clickedit===true">
  <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-edit"
          style="color:#041e42;padding-right: 6px;"></i>Edit User</legend>

          <div >
            <!-- Form -->
            <form class="">
               
            
                <div class="form-row py-2">
                  <div class="col">
                    <!-- Small input --><label class="headertexts" for="firstName">First Name</label>
                      <input class="form-control" type="text" id="firstName" name="firstName" placeholder="Enter First Name"  [(ngModel)]="selectedObj.userFirstName">
                    </div>
                    <div class="col">
                     <!-- Small input --><label class="headertexts" for="lastName">Last Name</label>
                     <input class="form-control" type="text" id="lastName" name="lastName" placeholder="Enter Last Name"  [(ngModel)]="selectedObj.userLastName">
                  </div>
                   
  
                  </div>
                  <div class="form-row py-2">
                    <div class="col">
                      <!-- Small input --><label class="headertexts" for="intUserId">INT User Id</label>
                        <input class="form-control" type="text" id="intUserId" name="intUserId" placeholder="Enter INT UserId"  [(ngModel)]="selectedObj.intUserId">
                      </div>
                      <div class="col">
                       <!-- Small input --><label class="headertexts" for="nqaUserId">NQA User Id</label>
                       <input class="form-control" type="text" id="nqaUserId" name="nqaUserId" placeholder="Enter NQA UserId"  [(ngModel)]="selectedObj.nqaUserId">
                    </div>
                     
    
                    </div>
                <div class="form-row  py-2">
                  <div class="col">
                    <label class="headertexts">Status</label>
                    <select class="browser-default custom-select" name="status" [(ngModel)]="selectedObj.status">
                                         
                             <option selected>Choose Status</option>
                             <option value="Active">Active</option>
                             <option value="Inactive">Inactive</option>
                            
                         </select>
                        </div>
                        <div class="col">
                          <label class="headertexts">Role</label>
                          <select class="browser-default custom-select" name="role" [(ngModel)]="selectedObj.roleId">
                                               
                                   <option selected>Choose Role</option>
                                   <option value="ADMIN">Admin</option>
                                   <option value="ACO">Officer</option>
                                   <option value="ROM">Manager</option>
                                   <option value="READ_ONLY">ReadOnly</option>
  
                                  
                               </select>
                           </div>
                 </div> 
              
             
                 <div class="form-row py-2">
                  <div class="col">
                    <!-- Small input --><label class="headertexts" for="email">Email</label>
                      <input class="form-control" type="text" id="email" name="email" placeholder="Enter emailId"  [(ngModel)]="selectedObj.email">
                    </div>
                    <div class="col">
                      <label class="headertexts">Region</label>
                      <input class="form-control" type="text" id="region" name="region" placeholder="Enter region"  [(ngModel)]="selectedObj.region">
               
                    </div>
                   
  
                  </div>
  
                  <div class="form-row py-2">
                    <div class="col-md-6">
                      <!-- Small input --><label class="headertexts" for="title">Title</label>
                        <input class="form-control" type="text" id="title" name="title" placeholder="Enter title"  [(ngModel)]="selectedObj.title">
                      </div>
                   
    
                    </div>
  
                
             
                   <div class=" text-right">
  
               
                <button mdbBtn class=" backcolor rounded text-white font-weight-bold px-5"
                 size="sm" type="submit" (click)="onEditUser()">Save
              </button>
            </div>    
  
            </form>
            <!-- Form -->
        </div>
   </fieldset>



   
<div mdbModal #ConfirmationDeleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm modal-notify modal-danger" role="document">
<!--Content-->
<div class="modal-content text-center">
  <!--Header-->
  <div class="modal-header d-flex justify-content-center">
    <p class="heading">Are you sure? Do you want to delete {{selectedObj.userFirstName}} {{selectedObj.userLastName}}?</p>
  </div>

  <!--Body-->
  <div class="modal-body">
    <mdb-icon fas icon="times" size="4x" class="animated rotateIn"></mdb-icon>
  </div>

  <!--Footer-->
  <div class="modal-footer justify-content-center">
    <a mdbBtn color="danger" outline="true" class="waves-effect" (click)="onDeleteUser();ConfirmationDeleteModal.hide();"
      mdbWavesEffect>Yes</a>
    <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect data-dismiss="modal" (click)="ConfirmationDeleteModal.hide()">No</a>
  </div>
</div>
<!--/.Content-->
</div>
</div>  