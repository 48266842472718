
<!-- <button printSectionId="print-section" [useExistingCss]="true" (click)="onPrint();">Print me</button> -->
<fieldset class="scheduler-border" *ngIf="accessList.indexOf('caseSummary')>-1">
    <legend class="scheduler-border" icon="ui-icon-check"><i class="fas fa-clipboard" style="color:#041e42;padding-right: 4px;"></i>Case Summary</legend>
  
<mdb-card id="summaryReportDiv" class="mb-3 mt-3" *ngIf="accessList.indexOf('caseSummary')>-1">
    <mdb-card-header class="p-0 cardheadercolor">
        <table mdbTable class="table-hover table-condensed mb-0">
            <tbody class="p-0">
                <tr class="p-0">
                    <td class="cardheader py-1 font-weight-bold ">Summary {{passeddata.CaseId}}</td>
               
                   
                    <td class="confirmCheck text-right text-white py-1">
                    
                        <mdb-checkbox [checked]="true"  [default]="false"  name="onlyBreachConfirmed" [(ngModel)]="onlyBreachConfirmed" > Only Confirmed Breach                                          
                        </mdb-checkbox>

                    </td>
                    <td  class="p-0 " mdbDropdown>

                

                        <button mdbBtn class="trigger dropdown-toggle waves-light  buttoncolor rounded text-white font-weight-bold  mt-0" placement="top"  mdbDropdownToggle size="sm">
                        Choose Visit ID
                    </button>
                                            
                      <div class="dropdown-menu dropdown-primary p-4 colselection " >
                          <!-- Default unchecked -->
                                  <div  *ngFor="let visit of visitList; let i = index" >
                                     <mdb-checkbox  [default]="false" (change)="addtoSelectedVisitList(visit.visitId,$event.checked)">{{visit.visitId}}</mdb-checkbox> 
                               
                                  </div>
                  
                       </div>
                  
   

              </td>
                    
                        <td class="text-right p-0"> 
                        <!-- <button type="button" mdbBtn size="sm"
                            class="relative waves-light btn-amber rounded black-text font-weight-bold ml-3 mt-0"
                            mdbWavesEffect (click)="generatePdf();"><mdb-icon fas icon="file-pdf" size="md" class="mr-1"></mdb-icon>PDF</button>-->
                          <!--  <button [useExistingCss]="true" printSectionId="print-section" ngxPrint>print</button> -->
                          <button type="button" mdbBtn size="sm"
                            class="relative waves-light btn-amber rounded black-text font-weight-bold ml-3 mt-0"
                            mdbWavesEffect [useExistingCss]="true" printSectionId="print-section" ngxPrint><mdb-icon fas icon="file-pdf" size="md" class="mr-1"></mdb-icon>PDF</button>
                </td>
                    <td class="text-right p-0">
                       
                        <mdb-icon fas icon="caret-down" class="mr-3" type="button" (click)="lawreg1.toggle()">
                        </mdb-icon>
                    </td>
                </tr>
            </tbody>
        </table>
  
    </mdb-card-header>
    <mdb-card-body class="p-0" style="padding-right: 10px;padding-left: 20px;">
        <div mdbCollapse [isCollapsed]="false" #lawreg1="bs-collapse" id="print-section">


            <form class="p-0">
                <!-- Subject -->
                <table mdbTable id="lawregTable1" 
                    class=".table-sm .table-fixed .table-hover doe-table-body summaryborder">

                    <!--Table head-->
                    <thead class="lawReg-table-header border summaryborder tableheadercolor">
                        <tr>
                            <th *ngFor="let head of headSummaryList; let i = index"
                                aria-controls="lawregTable1"   [mdbTableSort]="getuniqueLaws()"  [sortBy]="headSummaryList[i]" scope="col" class="border summaryborder" >
                                {{head}} <mdb-icon fas icon="sort"></mdb-icon>
                            </th>
                        </tr> 

                    </thead>
                    <!--Table body-->
                    <tbody *ngFor="let item of getuniqueLaws()" class="border summaryborder">

                        <!-- <tr >
        <th scope="row">Law/Regulations</th>
        <th scope="row">Description</th>
        <th scope="row">Compliance</th>
        
                          
       
    </tr>      -->
   
                        <tr class="toprow" *ngIf="!isEmpty(item)">
                           
                            <td class="w-20% font-weight-bold">{{item.lawid}}: </td>

                            <td class="w-5%">
                                <mdb-icon fas icon="flag" class="mr-3 red-text" *ngIf="item.priority=='high'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 green-text"
                                    *ngIf="item.priority=='low'">
                                </mdb-icon>
                                <mdb-icon fas icon="flag" class="mr-3 blue-text" *ngIf="item.priority=='medium'">
                                </mdb-icon>

                            </td>
                            <td><span class="font-weight-bold">{{item.lawregIndicator}}:{{item.lawid}}:{{item.description}}</span>
                            <div *ngFor="let lawreg of getAdditionalRegs(item.lawid); let i = index">
                                <span *ngIf="lawreg.additionalRegsList!==null && lawreg.additionalRegsList.length>0">{{lawreg.inaid}}: </span>
                           <div *ngFor="let addreg of lawreg.additionalRegsList; let i1 = index">
                                    <mdb-icon fas icon="chevron-circle-right" class="ml-2"></mdb-icon> 
                                  {{addreg.lawregIndicator}}:{{addreg.regId}}:{{addreg.description}}  

                                 </div> 
                                 </div>

                            </td>
                           <!--<td class="w-50%">{{item.description}}</td> --> 
                          <!-- <td class="w-25%">
                              
                                                             <div *ngIf="item.complianceStatus == 'Not reviewed'" class="text-info font-weight-bold">{{item.complianceStatus}} </div>
                                <div *ngIf="item.complianceStatus == 'Confirmed breach'" class="text-danger font-weight-bold">{{item.complianceStatus}} </div>
                                <div *ngIf="item.complianceStatus == 'Breach not confirmed'" class="text-success font-weight-bold">{{item.complianceStatus}} </div>
                           

                            </td>--> 

                          
                        </tr>
 
                        <tr class="bottomrow" *ngIf="!isEmpty(item)">
                            <td colspan="5">
                                <mdb-card class="mb-4">
                                    <mdb-card-header class="p-0">
                                        <table mdbTable class="mb-0">
                                            <tbody>

                                                <tr class="p-0" (click)="evidenceList1.toggle()">
                                                    <td class="headertexts">Evidence for non compliance</td>
                                                    <td class="text-right">
                                                        <mdb-icon fas icon="caret-down" class="mr-3"
                                                            type="button" >
                                                        </mdb-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </mdb-card-header>
                                    <mdb-card-body class="p-0">
                                        <div mdbCollapse [isCollapsed]="false" #evidenceList1="bs-collapse">
                                            <form class="p-0">

                                                <table mdbTable id="EvidenceSummaryTable" bordered="true"
                                                    class=".table-sm .table-fixed .table-hover doe-table-body ">

                                                    <!--Table head-->
                                                    <thead class="Evidence-table-header">
                                                       <tr>
                                                            <th *ngFor="let head of headEvidenceList; let i = index"
                                                                aria-controls="EvidenceSummaryTable" [mdbTableSort]="summaryList" 
                                                                [sortBy]="headEvidenceListSort[i]" scope="col">
                                                                {{head}} <mdb-icon fas
                                                                    icon="sort">
                                                                </mdb-icon>
                                                            </th>
                                                        </tr>

                                                    </thead>
                                                    <!--Table body-->
                                                    <tbody *ngFor="let inas of summaryList">



                                                        <tr  *ngIf="inas.lawid==item.lawid ">
                                                            <td class="w-5">{{inas.visitId}} </td>
                                                            <td class="w-5">{{inas.inaid}} </td>
                                                            <td class="w-10">{{getVisitsLocation(inas.visitId)}} </td>

                                                            <td class="w-5">{{inas.updatedBy}} </td>
                                                            <td class="w-50">
                                                                <div [innerHTML]="inas.evidence">
                                                                  </div>
                                                            </td>
                                                            <td class="w-10">
                                                            
                                                                <div *ngFor="let imagedata of item.receivedImageData">
                                                                    <a  (click)="loadDoc(imagedata.evidenceId,imagedata.docName);" class="text-primary" >{{ imagedata.docName}} </a>  
                                                                         
                                                                                        
                                                                  </div>
                                                            
                                                            </td>
                                                            <td td class="10">
                                                                <div *ngIf="inas.complianceStatus!==null && inas.complianceStatus.toLowerCase() === 'Not reviewed'.toLowerCase()" class="text-info font-weight-bold">{{inas.complianceStatus}} </div>
                                                                <div *ngIf="inas.complianceStatus!==null && inas.complianceStatus.toLowerCase() === 'Confirmed breach'.toLowerCase()" class="text-danger font-weight-bold">{{inas.complianceStatus}} </div>
                                                                <div *ngIf="inas.complianceStatus!==null && inas.complianceStatus.toLowerCase() === 'Breach not confirmed'.toLowerCase()" class="text-success font-weight-bold">{{inas.complianceStatus}} </div>
                                                           
                                                            </td>
                                                           



                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </form>


                                        </div>
                                    </mdb-card-body>
                                </mdb-card>
                            </td>
                        </tr>
                   
                

                    </tbody>
                </table>
            </form>
        </div>
    </mdb-card-body>
</mdb-card>
</fieldset>
